import { T } from "@/classes/i18n";
import ObjectType, { type ItemlistItemDetail, type Label, type MenuEntry, type ObjectTypePayload } from "../../objectType";
import router from "@/router/router";
import tenantHelpers from "@/helpers/helpers.tenants";
import Button from "@/templates/components/button/button";
import arrayHelpers from "@/helpers/helpers.arrays";
import permissionsHelpers from "@/helpers/helpers.permissions";
import numberHelpers from "@/helpers/helpers.numbers";
import { useAzureStore } from "../users/azure";
import config from "@/classes/config";

export type Rolename = string
export interface Role extends ObjectType<Role> {
    "rolename": Rolename,
    "tenantDomain"?: string
    "users"?: Array<string>
    "devices"?: Array<string>
    "permissionScopes"?: Array<string>
    "comment"?: string
}

export class RolesNew extends ObjectType<Role> {
    constructor(payload: ObjectTypePayload<Role>) {
        super(payload)

        this.itemlist.getSortingOptions = () => { return [{
            "id": "rolename",
            "text": "Name"
        }]}

        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            if(useAzureStore().isAzureImporting === true) {
                return T("Users are currently being imported from Entra ID. Editing users and roles is deactivated as long as the process is running.")
            }
            return ""
        }
        
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let toolBarEntries = []
            toolBarEntries.push(
                {
                    icon: 'fal fa-plus',
                    title: T('Add role'),
                    link: '#add-tenant-' + accountId + '.sms-role',
                    id: 'rolesButtonAdd',
                    vIf: false,
                    disabled: useAzureStore().isAzureImporting == true
                }
            )
            return toolBarEntries
        }


        this.itemlistItem.onClick = (accountId, item) => {
            let objectId = item[this.options.objectTypeInfo.primaryKeyProperty.property as keyof typeof item] as string
            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-role-' + objectId)
        }
        this.itemlistItem.getTitle = (item) => {
            return {
                title: item?.rolename
            }
        }
        this.itemlistItem.getStatus = (accountId: string, item) => {
            return undefined
        }
        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuEntries: MenuEntry[] = []
            if (item && useAzureStore().isAzureImporting === false) {
                let objectId = item[this.options.objectTypeInfo.primaryKeyProperty.property as keyof typeof item] as string
                menuEntries.push(
                    new Button({
                        "text": T('Edit'),
                        "title": T('Edit'),
                        "icon": 'fal fa-edit',
                        "onClick": () => {
                            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-role-' + objectId)
                        }
                    })
                )
                if (['admin', 'auditor'].indexOf(item?.rolename) == -1) {
                    menuEntries.push(new Button({
                        "text": T('Delete'),
                        "title": T('Delete'),
                        "icon": 'fal fa-trash',
                        "onClick": () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item)
                        }
                    }))
                }
            }
            return menuEntries
        }
        this.itemlistItem.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            return result
        }
        this.itemlistItem.getDetails = (accountId: string, item?) => {
            let result: ItemlistItemDetail[] = []
            let thisPermissionsArray: any = []
            item?.permissionScopes?.forEach((permissionScope: string) => {
                if (permissionScope.indexOf("usc:administration:") != -1) {
                    thisPermissionsArray.push("usc:administration:*")
                }
                else {
                    thisPermissionsArray.push(permissionScope)
                }
            });
            // Strip duplicate permissionScopes
            thisPermissionsArray = thisPermissionsArray.filter(arrayHelpers.onlyUniqueFilter)
            thisPermissionsArray = thisPermissionsArray.map((permissionScope: string) => {
                const labelText = T(permissionsHelpers.getPermissionLabelFromScope(permissionScope))
                const permissionScopeClass = function () {
                    let htmlClass: string = ""
                    if (permissionScope == "usc:administration") {
                        htmlClass = "bg-red color-white"
                    }
                    if (permissionScope == "usc:administration:*") {
                        htmlClass = "bg-yellow color-white"
                    }
                    if (permissionScope == "usc:monitoring") {
                        htmlClass = "bg-blue color-white"
                    }
                    return htmlClass
                }()
                return <Label>{
                    "id": permissionScope,
                    "text": labelText,
                    "title": labelText,
                    "onClick": undefined,
                    "displayType": "label",
                    "class": permissionScopeClass
                }
            })
            const thisUsersArray = item?.users?.map((user: string) => {
                return {
                    "id": user,
                    "text": user,
                    "title": user,
                    "onClick": () => {
                        router.navigate('#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-user-' + user)
                    },
                    "displayType": "label"
                }
            })
            result = [
                {
                    iconClass: 'fal fa-fw fa-user',
                    title: T('User'),
                    key: T('User'),
                    [item ? "labels" : "content"]: item ? thisUsersArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Permissions'),
                    key: T('Permissions'),
                    [item ? "labels" : "content"]: item ? thisPermissionsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                }
            ]
            return result
        }

        this.itemlistItem.hasCheckbox = (item) => {
            let result = true
            if (useAzureStore().isAzureImporting === true) {
                result = false
            }
            else if (['admin', 'auditor'].indexOf(item?.rolename) != -1) {
                result = false
            }
            return result
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            return useAzureStore().isAzureImporting === false
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return useAzureStore().isAzureImporting === false
        }


    }
}

const rolesNew = new RolesNew({
    "objectType":"roles",
    "productType":"unifiedSecurity",
    "slug":"roles",
    "apiInfo":{
        "getObjectListPath":"/tenants/{tenantDomain}/roles",
        "getObjectListResponseProperty":"roles",
        "url": config.mgtApiUriNext,
        "getCountGETProperties":"?props[]=null&select=data.count"
    },
    "hasStore":true,
    "objectTypeInfo": {
        "nameProperty": {
            "primary": "rolename"
        },
        "primaryKeyProperty": {
            "property": "rolename"
        },
    },
    "appearance": {
        "color": "red",
        "iconClass": "fal fa-users",
        "showInSidebar": true,
        "text": {
            "plural": "Roles",
            "sidebarName": "Roles",
            "title": "Roles",
            "singular": "Role"
        }
    }
})
export default rolesNew