import { MutationTypes } from "@/store/vuex.store";
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import requestHandler from "@/queries/requests";
import objectStores from "@/classes/init";
import { T } from "@/classes/i18n";
import { useStore } from '@/store/vuex.store'
import validationHelpers from "@/helpers/helpers.validation";
import tenantHelpers from "@/helpers/helpers.tenants";
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms";
import inputVueSelectVue from "../inputtypes/input-vue-select.vue";
import config from "@/classes/config";
import websocketHandler from "@/classes/websocket";
import timeHelpers from "@/helpers/helpers.time";
import inputPin from "../inputtypes/input-pin.vue";

const newUtmWebSessionDialogComponent = {
    mixins: [useGlobalMixin()],
    "name":"new-utm-websession-dialog",
    "template": `
        <div>
            <template v-if="!initialized || canUseNatWebsession === undefined || formdata.usePin === undefined">
                <div class="text-size-3 text-center padding-xs-t-4">
                    <div class="text-size-2">
                        <loader class="color-red"></loader>
                    </div>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2" style="opacity:0.8">
                        <span>
                            {{ T(loaderText) }}
                        </span>
                    </div>
                </template>
                <template v-if="showNotification == true">
                    <div class="text-center color-red padding-xs-b-4" style="opacity:0.8">
                        <br>
                        <span>
                            {{ T('Receiving the websocket messages takes unusually long. Please check your connection to the UTM.') }}
                        </span>
                     </div>
                </template>
                <template v-else>
                    <div class="padding-xs-b-4">
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="padding-xs-2">
                    
                    <div class="row padding-xs-y form-group" v-if="formdata.version && versionCompare(formdata.version, '12.5.0') != 1">
                        <div class="col-xs-24">
                            <div class="alert notification content bg-red">
                                <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T("From version 12.5.1, the UTM initiates a connection to our cloud services and can be administered via these without a public IP address. The older way for UTMs with version 12.5.0 or older will therefore be disabled from portal version 1.28 and the UTMs will no longer be accessible via websession. Please install the necessary update as soon as possible.") }}
                            </div>
                        </div>
                    </div>    


                    <div class="row padding-xs-y form-group">
                        <div class="col-xs-24">
                            <p style="text-align:center">
                                <template v-if="canUseNatWebsession === true">
                                    {{ T('The UTM initiates a connection to our cloud services and can be administered via these without a public IP address. Details can be found in our') + ' ' }}<a href="https://wiki.securepoint.de/USC/UTM#Websession" target="_blank">{{ T('wiki') }}</a>.
                                    <template v-if="formdata.usePin === false">
                                        <br><br>{{ T('Since the websession PIN is deactivated, automatic login is not possible. You need to log in using your username and password.') }}
                                    </template>
                                </template>
                                <template v-else>
                                    {{T('Here you can open the administrative web interface of the UTM. If the UTM is accessible via internet connection, no user credentials are required.')}}<br><br>
                                    {{T('If the UTM is not accessible via internet connection, a link to the local administration web interface is made available. To sign in without the help of USC, you need user credentials with administrative rights for the UTM, your IP must be registered as manager IP on the UTM and you need a direct connection to the UTM.')}}
                                </template>
                            </p>
                        </div>
                    </div>
                    <div class="text-center">
                        <template v-for="error in errors">
                            <p class="label bg-red" v-if="['locked','Invalid pin'].indexOf(error) == -1">
                                <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                            </p>
                        </template>
                    </div>
                    
                    
                    <div class="padding-xs-y form-group border-bottom border-top text-center" v-if="!canUseNatWebsession">
                        <label for="addresses" class="control-label">
                            {{ T('IP address') }}
                        </label>
                        
                        <div class="input">
                            <label class="form-field" v-if="selectOptions.addressesInitialized">
                                <input-vue-select
                                    id="addresses"
                                    :selectOptions="selectOptions.addresses"
                                    v-model="formdata.address"
                                />
                            </label>
                            <loader v-else class="color-red"/>
                        </div>
                    </div>

                    <div class="padding-xs-y form-group border-bottom text-center" v-if="formdata.usePin === true && hasOnlyAdmin == false">
                        <label for="user" class="control-label">
                            {{ T('User') }}
                        </label>
                        <div class="input">
                            <label class="form-field" v-if="selectOptions.users.length > 0">
                                <input-vue-select
                                    id="user"
                                    :selectOptions="selectOptions.users"
                                    :disabled="loading ? true : false"
                                    v-model="formdata.user"
                                />
                            </label>
                            <loader v-else class="color-red"/>
                        </div>
                        <div class="desc">
                            <p class="input-description">
                                {{ T('Please select the user with whom the websession should be started.') }}
                            </p>
                        </div>
                    </div>

                    <div class="row padding-xs-y form-group" v-if="(formdata.usePin === true)">
                        <div class="input col-xs-24 text-center">
                            <label class="form-field" style="display:inline-block;" v-if="formdata.usePin !== undefined">
                                <input-pin 
                                    ref="pinField"
                                    v-model="formdata.pin"
                                    :isDisabled="formdata.isPinDisabled === true"
                                    :pinLength="6"
                                    :numbersOnly="true"
                                    v-on:submit="submit"
                                    :focusOnMount="true"
                                    :hidePinOnMount="true"
                                    :canToggleVisibility="true"
                                />
                                <span></span>
                            </label>
                            <loader v-else class="color-red"/>
                        </div>
                        <div class="desc col-xs-24 text-center">
                            <p class="input-description">
                            {{ T('This UTM requires a PIN for authentication via the websession function.') }} <br>
                            {{ T('If the PIN is entered incorrectly several times (default value 5, unless otherwise configured on the UTM), login is only possible via user name and password until the PIN functionality is reactivated.') }} <br>
                            {{ T('This authentication is mandatory from UTM version 12.5.0. The initial PIN is 000000 and must be changed after the first login.') }} <br>
                            </p>
                            <label v-if="formdata.showRememberPin" class="form-field toggle checkbox">
                                <input v-model="formdata.rememberPin" id="login-rememberme" type="checkbox" class="toggle margin-xs-0">&nbsp;&nbsp;
                                <span></span>
                                {{ T('Remember PIN for the current session') }}
                            </label>
                        </div>
                    </div> 
                    
                    <template v-if="errors.indexOf('Invalid pin') != -1"> 
                        <div class="text-center">
                            <p class="label bg-red">
                                {{ T('The PIN you have entered is incorrect')}}
                            </p>
                        </div>
                        
                    </template>
                    <template v-if="errors.indexOf('locked') != -1"> 
                        <template v-if="errors.indexOf('Invalid pin') != -1">
                            <br/><br/>
                        </template>
                        <div class="text-center">
                            <p class="label bg-red">
                                {{ T('The USC websession has been blocked on this UTM due to multiple entries of an incorrect PIN. To unlock the UTM for the USC webssession again, please log in to the administrator interface.')}}
                            </p>
                        </div>
                    </template>


                    
                 
                </div>
            </template>
        </div>
    `,
    "data":() => {
        return {
            "initialized":false,
            "loaderText":"Getting UTM settings...",
            "showNotification": false,
            "errors":[],
            "selectOptions": {
                "addressesInitialized":false,
                "addresses":[],
                "users":[]
            },
            "formdata":{
                "address":"",
                "portInitialized": false,
                "port":"11115",
                "version":undefined,
                "user":"admin",
                "publicAddressSelected": false,
                "isPinDisabled":false,
                "usePin": undefined,
                "pin": "",
                "rememberPin": false,
                "showRememberPin": true
            },
            "canUseNatWebsession": undefined,
            "url":"",
            "loading": false
        }
    },
    "computed": {
        "utm": function(this: any) {
            let utm: any = useStore().getters.getObject({
                "accountId": this.properties.accountId,
                "productType": "unifiedSecurityConsole",
                "objectType": "uscUtms",
                "objectId": this.properties.utmId
            })
            return utm
        },
        "hasOnlyAdmin": function (this: any) {
            return (this.selectOptions.users || []).length == 1 && this.selectOptions.users[0]?.id == "admin"
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "accountId":undefined,
                    "utmId":undefined,
                }
            }
        }
    },
    "methods": {
        "init": async function(this:any) {
            websocketHandler.connect(this.properties.accountId)
            await objectStores.uscUtms.sendSpcliMessage(this.properties.accountId, this.properties.utmId, { "data": ["system", "info"] })
            await objectStores.uscUtms.sendSpcliMessage(this.properties.accountId, this.properties.utmId, { "data": ["interface", "address", "get"] })
            await objectStores.uscUtms.sendSpcliMessage(this.properties.accountId, this.properties.utmId, { "data": ["extc", "value", "get", "application", "webserver", "variable", "ADMIN_PORT"] })
            await objectStores.uscUtms.sendSpcliMessage(this.properties.accountId, this.properties.utmId, { "data": ["user", "admin", "get"] })

            let utm = useStore().getters.getObject({
                "accountId": this.properties.accountId,
                "productType": "unifiedSecurityConsole",
                "objectType": "uscUtms",
                "objectId": this.properties.utmId
            })

            if (objectStores.uscUtms.getUtmMessageData(utm as UscUtm, "spcli-system-info")?.version != undefined && validationHelpers.versionCompare("12.5.0", objectStores.uscUtms.getUtmMessageData(utm as UscUtm, "spcli-system-info")?.version) != 1) {
                await objectStores.uscUtms.sendSpcliMessage(this.properties.accountId, this.properties.utmId, { "data": ["extc", "value", "get", "application", "spcloudpuppet", "variable", "SESSIONAUTH_ENABLE"] })
            }
            else {
                this.formdata.usePin = false
            }
            this.initialized = true

            const storagePin = sessionStorage.getItem(this.getPinStorageKey())
            if(storagePin) {
                this.formdata.pin = storagePin
                this.formdata.showRememberPin = false
            }

            // #33726 If still loading it should display a hint
            await timeHelpers.sleep(45000)
            this.showNotification = true;
        },
        versionCompare: validationHelpers.versionCompare,
        checkReadyState: function (this: any) {

            if (useStore().getters.getActiveModal() != undefined && useStore().getters.getActiveModal().id.indexOf("newUtmSession") != -1
            && this.loading === false) {
                if (this.canUseNatWebsession === true) {
                    useStore().getters.getActiveModal().buttons[1].text = T("Start New Websession")
                    useStore().getters.getActiveModal().buttons[1].loading = true
                    useStore().getters.getActiveModal().buttons[1].disabled = true

                    if (this.formdata.usePin && this.formdata.pin.length < 6) {
                        useStore().getters.getActiveModal().buttons[1].loading = false
                        useStore().getters.getActiveModal().buttons[1].disabled = true
                    }
                    else if(this.formdata.usePin && !(this.selectOptions.users?.length)) {
                        useStore().getters.getActiveModal().buttons[1].loading = false
                        useStore().getters.getActiveModal().buttons[1].disabled = true
                    }
                    else if (this.formdata.usePin && this.formdata.pin.length >= 6) {
                        useStore().getters.getActiveModal().buttons[1].loading = false
                        useStore().getters.getActiveModal().buttons[1].disabled = false
                    }
                    else if (this.formdata.usePin === false) {
                        useStore().getters.getActiveModal().buttons[1].loading = false
                        useStore().getters.getActiveModal().buttons[1].disabled = false
                    }
                } 
                else if(this.canUseNatWebsession === false) {
                    
                    if (this.formdata.address != "" && this.formdata.address != null) {
                        this.formdata.publicAddressSelected = validationHelpers.isPublicIPv4(this.formdata.address) || validationHelpers.isPublicIpV6(this.formdata.address)
                        useStore().getters.getActiveModal().buttons[1].text = this.formdata.publicAddressSelected === false ? T('Go to webinterface') : T("Start new websession")
                    }
                    if (this.formdata.version != undefined && this.selectOptions.users.length > 0) {
                        if (this.url != "") {
                            useStore().getters.getActiveModal().buttons[1].loading = false
                            useStore().getters.getActiveModal().buttons[1].disabled = false
                        }
                        else if (this.formdata.portInitialized && this.formdata.address != null && this.formdata.address != "") {
                            useStore().getters.getActiveModal().buttons[1].loading = false
                            useStore().getters.getActiveModal().buttons[1].disabled = false
                        }
                    }
                    if (this.formdata.usePin && this.formdata.pin.length < 6) {
                        useStore().getters.getActiveModal().buttons[1].loading = false
                        useStore().getters.getActiveModal().buttons[1].disabled = true
                    }
                    else if (this.formdata.usePin && this.formdata.pin.length >= 6) {
                        useStore().getters.getActiveModal().buttons[1].loading = false
                        useStore().getters.getActiveModal().buttons[1].disabled = false
                    }

                    let utm = useStore().getters.getObject({
                        "accountId": this.properties.accountId,
                        "productType": "unifiedSecurityConsole",
                        "objectType": "uscUtms",
                        "objectId": this.properties.utmId
                    })

                    if (objectStores.uscUtms.getUtmMessageData(utm as UscUtm, "spcli-system-info")?.version != undefined && validationHelpers.versionCompare("12.5.0", objectStores.uscUtms.getUtmMessageData(utm as UscUtm, "spcli-system-info")?.version) != 1) {
                        if (this.formdata.address != "" && this.formdata.address != null) {
                                if (this.formdata.publicAddressSelected === false) {
                                    useStore().getters.getActiveModal().buttons[1].disabled = false
                                    this.formdata.usePin = false
                                    this.formdata.pin = ''
                                } else if (this.formdata.publicAddressSelected === true) {
                                    this.formdata.usePin = true
                                }
                        }
                    } else {
                        this.formdata.usePin = false
                    }
                }
                this.$forceUpdate();
            }

        },
        "submit": async function (this: any) {
            if (this.loading == false) {
                this.loading = true
                this.errors = []
                
                useStore().getters.getActiveModal().buttons[1].loading = true
                useStore().getters.getActiveModal().buttons[1].disabled = true
                this.formdata.isPinDisabled = true
                try {
                    if (this.canUseNatWebsession) {
                        let websessionPayload = {
                            "usePin": this.formdata.enabled,
                            "admin": this.formdata.user,
                            "pin": this.formdata.usePin ? this.formdata.pin : ""
                        }

                        let result = await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(this.properties.accountId) + "/utms/" + this.properties.utmId + "/jobs/model", {
                            "name": "new-ws-sessionid", "data": websessionPayload
                        })
                        if (result?.errors?.error == true) {
                            throw result.errors.payload
                        }
                    }
                    else if (this.formdata.publicAddressSelected) {
                        let websessionPayload = {
                            "targetIp": this.formdata.address,
                            "targetPort": parseInt(this.formdata.port),
                            "admin": this.formdata.user,
                            "pin": this.formdata.usePin ? this.formdata.pin : ""
                        }
                        if (this.formdata.usePin == false) delete websessionPayload.pin

                        let result = await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(this.properties.accountId) + "/utms/" + this.properties.utmId + "/jobs/model", {
                            "name": "new-sessionid", "data": websessionPayload
                        })
                        if (result?.errors?.error == true) {
                            throw result.errors.payload
                        }
                    }
                    else {
                        window.open('https://' + (validationHelpers.isIpV6(this.formdata.address) ? "[" + this.formdata.address + "]" : this.formdata.address) + ":" + this.formdata.port, '_blank');
                        useStore()?.commit(MutationTypes.removeModal,{accountId:this.activeAccountId})
                    }
                }
                catch (e: any) {
                    this.errors.push(e)
                    useStore().getters.getActiveModal().buttons[1].loading = false
                    useStore().getters.getActiveModal().buttons[1].disabled = false
                }
                finally {
                    
                }
            }
        },
        "getPinStorageKey": function(this:any) {
            return `utm_pin:${this.properties.accountId}:${this.properties.utmId}`
        }
    },
    "created": async function(this:any) {
    },
    "mounted": function(this:any) {
        useStore().commit(MutationTypes.addSubscriptionHook, {
            "accountId": this.properties.accountId,
            "hookKey": "utmInterfaces",
            "hookFunction": async (message: any) => {
                if (message.topic == ("/usc/utm/" + this.properties.utmId + "/message")) {
                    let jobType: string = message.data?.type || "spcli"
                    let jobContext: string = message.data?.clientContext.replace(jobType + '-', '').split('-').join(' ') || "UNKNOWN"
                    if(jobContext == "interface address get") {
                        let options: { "id": string, "text": string, "address": string }[] = []
                        let data: any[] = message.data?.data || []

                        let publicAddressInfos = data.filter((entry:any) => {
                            let address = entry?.address?.replaceAll("&#x2f;","/").split('/')[0] || entry.address
                            return validationHelpers.isPublicIPv4(address) || validationHelpers.isPublicIpV6(address)
                        })


                        if (publicAddressInfos.length) {
                            options = publicAddressInfos.map((entry: any) => {
                                let address = entry?.address?.replaceAll("&#x2f;","/").split('/')[0] || entry.address
                                return {
                                    "id": address,
                                    "address": address,
                                    "type": "public",
                                    "text": `${address} (${entry.device}) [Public]`
                                }
                            })       
                        }

                        options = options.concat(data.filter((entry:any) => {
                            let address = entry?.address?.replaceAll("&#x2f;","/").split('/')[0] || entry.address
                            return validationHelpers.isPrivateIPv4(address) || validationHelpers.isPrivateIpV6(address)
                        }).map((entry: any) => {
                            let address = entry?.address?.replaceAll("&#x2f;","/").split('/')[0] || entry.address
                            return {
                                "id": address,
                                "address": address,
                                "type": "local",
                                "text": `${address} (${entry.device}) [Local]`
                            }
                        }))

                        this.selectOptions.addresses = options
                        if (options.length) {
                            this.formdata.address = options[0].id
                        }
                        this.selectOptions.addressesInitialized = true
                        this.checkReadyState()
                    }
                    if (jobContext == "extc value get") {
                        let data: any[] = message.data?.data || []
                        if (data.length && data[0].name == "ADMIN_PORT") {
                            this.formdata.port = data[0].value
                            this.formdata.port = data[0].value
                            this.formdata.portInitialized = true
                        }
                        if (data.length && data[0].name == "SESSIONAUTH_ENABLE") {
                            this.formdata.usePin = data[0].value == "enabled"
                        }

                        this.checkReadyState()
                    }
                    if (jobContext == "system info") {
                        let data: any = message.data?.data || {}
                        if (data?.version != undefined) {
                            this.formdata.version = data.version
                            this.canUseNatWebsession = validationHelpers.versionCompare(data.version, "12.5.1") != -1
                        }
                        this.checkReadyState()
                    }
                    if (jobContext == "utm admin session" || jobContext == "new sessionid" || "new ws sessionid") {
                        if (message.data?.status == 200) {
                            if (message.data?.data.status == 500 || message.data?.data.status == 403) {
                                let jobStatusText: string = message.data?.data?.message || message.data?.data?.hint?.message || message.data?.data?.[0]?.message || message.data?.data?.payload?.[0]?.message || message.data?.data?.error || ''
                                if (jobStatusText.indexOf('Websession address differs from request source:') != -1) {
                                    jobStatusText = jobStatusText.replace('Websession address differs from request source:', T('Websession address differs from request source:'))
                                }
                                this.errors.push(jobStatusText)
                                this.loading = false
                                this.checkReadyState()
                            }
                        }
                        else if (message.data?.status == 201) {
                            
                            // connection url received
                            let url = JSON.parse(message.data?.data)?.url
                            if(url) {
                                this.url = url
                                window.open(url,'_blank');
                                useStore().commit(MutationTypes.deleteSubscriptionHook, {
                                    "accountId": this.properties.accountId,
                                    "hookKey": "utmInterfaces"
                                })
                                useStore()?.commit(MutationTypes.removeModal,{accountId:this.activeAccountId})
                            }

                            if(this.formdata.rememberPin) {
                                sessionStorage.setItem(this.getPinStorageKey(), this.formdata.pin)
                            }

                            this.loading = false
                            this.checkReadyState()
                        }
                    }
                    if (jobContext == "user admin get") {
                        let data: { "name": string }[] = message.data?.data || []
                        if (data.length > 0) {
 
                                this.selectOptions.users = data.map((userInfo) => {
                                    return {
                                        "id":userInfo.name,
                                        "text":userInfo.name
                                    }
                                })
                                this.formdata.user = data[0].name
                            
                        }
                        this.checkReadyState()
                    }

                }
            }
        })
        this.init()
    },
    "watch": {
        "formdata": {
            deep:true,
            handler:function(this:any,formdata:any) {
                this.checkReadyState()
            }   
        },
        "errors": {
            deep:true,
            handler:function(this:any,errorArray:any) {
                if (errorArray.indexOf("Invalid pin") != -1) {
                    this.formdata.pin = ""
                    this.formdata.isPinDisabled = false
                    setTimeout(() => {
                        this.$refs.pinField?.focusInput?.()    
                    }, 500);
                }
            }
        }
    },
    "components": {
        "loader":loaderComponent,
        "input-vue-select": inputVueSelectVue,
        "input-pin": inputPin
    },
    "beforeUnmount":function(this:any) {
        websocketHandler.connect(this.activeAccountId)
        useStore().commit(MutationTypes.deleteSubscriptionHook, {
            "accountId": this.properties.accountId,
            "hookKey": "utmInterfaces"
        })
    }
}
export default newUtmWebSessionDialogComponent
