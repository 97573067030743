<script setup lang="ts">
	import { T } from "@/classes/i18n";
	import { useGlobalMixin } from "@/mixins/mixins.global";
	import { ActionTypes } from "@/store/vuex.store";
	import { MutationTypes } from "@/store/vuex.store";
	import { useStore } from '@/store/vuex.store';
	import { ref,computed, onMounted } from "vue";

	const showUsersettings = ref(false)
	const darkmode = computed({
		get: function (this: any) {
			return useStore().state.browserSettings.darkmode == "1"
		},
		set: function (this: any, darkmode:boolean) {
			useStore().dispatch(ActionTypes.setDarkmode, darkmode ? "1" : "0")
		}
	})
	onMounted(() => {
		useStore().commit(MutationTypes.addOnClickHandler, {
			"id": "usersettings",
			"ref": "root",
			"method": () => {
				if (showUsersettings.value) {
					showUsersettings.value = false
				}
			}
		})
	})
	


</script>

<template>
	<div class="menu" v-on:click.stop>
		<ul>
			<li>
				<a class="toggle" v-on:click="showUsersettings = !showUsersettings">
						<i class="fal fa-cogs"></i>
				</a>
				<transition name="notifications"
						:duration="{ enter:300, leave:300 }"
						enterActiveClass="notifications-entering"
						enterToClass="notifications-enter-to"
						leaveActiveClass="notifications-leaving"
						leaveToClass="notifications-leave-to"
				>
					<ul v-on:blur="showUsersettings = false" class="menu" v-if="showUsersettings" :key="'default'">
						<li>
							<div class="padding-xs-2 border-bottom">
								<i class="fal fa-cogs color-red"></i>&nbsp;&nbsp; <strong>{{ T('Interface settings') }}</strong>
							</div>
							<div class="padding-xs-2">
								<div class="row form-group">
									<div class="first col-xs-12">
											<label class="control-label inputname" for="darkmodeToggle">
												{{T('Darkmode')}}
											</label>
									</div>
									<div class="input col-xs-12 toggle text-right">
										<label class="form-field margin-xs-b-0 checkbox toggle">
											<input id="darkmodeToggle" name="darkmodeToggle" type="checkbox" class="form-control checkbox toggle user-selection" v-model="darkmode"/>
											<span></span>
										</label>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</transition>
			</li>		
		</ul>
	</div>
</template>