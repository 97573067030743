<template>
  <div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
    <div class="first col-xs-24 col-lg-6 col-xl-5">
      <label for="homescreenDeviceType" class="control-label">
        Gerätetyp
      </label>
    </div>
    <div class="input col-xs-24 col-lg-8 col-xl-7">
      <label class="form-field select">
        <select id="homescreenDeviceType" v-model="deviceType">
          <option value="iPhone">iPhone</option>
          <option value="iPad">iPad</option>
          <option value="TV">TV</option>
        </select>
      </label>
    </div>
  </div>

  <loader-component v-if="loading" class="text-size-3 color-red margin-xs-t-2" />
  <div v-else class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
    <div class="first col-xs-24 col-lg-8'" :class="isBigDeviceScreen ? ['col-xl-8'] : ['col-xl-12']">
      <div class="row toolbar">
        <div class="col-xs-24 padding-xs-b">
          <div class="box-border bg-white padding-xs-t padding-xs-l app-library"
            :class="[`app-library-${deviceType?.toLowerCase() || 'unk'}`]">

            <div class="row flexrow flex-wrap margin-xs-0">
              <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                <label class="form-inputgroup margin-xs-b-0">
                  <span class="form-icon-prefix"><i class="fal fa-search"></i></span>
                  <input id="itemlistSearch" type="text" placeholder="Suche" v-model="appSearch">
                </label>
              </div>
            </div>

            <div v-if="availableApps.apps.length" class="box-border padding-xs-t padding-xs-l">
              <label class="text-center text-bold">{{ T("Apps") }}</label>

              <VueDraggableNext v-model="availableApps.apps" class="app-library-grid"
                :group="{ name: 'apps', pull: 'clone', put: 'false' }" :invertSwap="true">
                <template v-for="app in availableApps.apps">
                  <app-item :app="app" :id-info-map="idInfoMap" />
                </template>
              </VueDraggableNext>
            </div>

            <div v-if="availableApps.webclips.length && deviceType != EDeviceType.TV"
              class="box-border padding-xs-t padding-xs-l">
              <label class="text-center text-bold">{{ T("Webclips") }}</label>

              <VueDraggableNext v-model="availableApps.webclips" class="app-library-grid"
                :group="{ name: 'apps', pull: 'clone', put: 'false' }" :invertSwap="true">
                <template v-for="app in availableApps.webclips">
                  <app-item :app="app" :id-info-map="idInfoMap" />
                </template>
              </VueDraggableNext>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="first col-xs-24 col-lg-8'" :class="isBigDeviceScreen ? ['col-xl-16'] : ['col-xl-12']">
      <div class="row toolbar">
        <div class="col-xs-24 padding-xs-b">
          <div class="box-border bg-white padding-xs-t padding-xs-l">
            <template v-if="deviceType === EDeviceType.IPHONE">
              <figure class='iphone'>
                <div class="page-indicators">
                  <div v-for="(page, index) in modelValue.Pages"
                    :class="['indicator', { active: currentPage === index }]" @click="currentPage = index"></div>
                </div>

                <div v-if="folderView" class="folder-view">
                  <a @click="closeFolder" class="folder-close">X</a>

                  <input v-model="folderView.DisplayName" placeholder="Name" class="folder-title" />

                  <VueDraggableNext v-model="folderView.Pages![currentFolderPage]" class="folder-app-container"
                    group="apps"
                    @change="ensureArrLen(folderView.Pages![currentFolderPage], getMaxFolderAppCount())">
                    <template v-for="(app, index) in folderView.Pages![currentFolderPage]">
                      <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" @click="openFolder(app)"
                        @delete="deleteFromArrayIndex(folderView.Pages![currentFolderPage], index)" />
                    </template>
                  </VueDraggableNext>

                  <div v-if="modelValue.Pages.length < 15" class="page-indicators page-indicators-folder">
                    <div v-for="(page, index) in folderView.Pages!"
                      :class="['indicator', { active: currentFolderPage === index }]"
                      @click="currentFolderPage = index"></div>
                  </div>
                </div>

                <div>
                  <VueDraggableNext v-model="modelValue.Pages[currentPage]" class="device-grid-iphone" group="apps"
                    @end="onDragEnd" ghost-class="ghost-class"
                    @change="ensureArrLen(modelValue.Pages[currentPage], getMaxAppsPerPage())" :disabled="!!folderView">

                    <template v-for="(app, index) in modelValue.Pages[currentPage]">
                      <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" @click="openFolder(app)"
                        @delete="deleteFromArrayIndex(modelValue.Pages[currentPage], index)" />
                    </template>
                  </VueDraggableNext>

                  <!-- Navigation Bar Grid -->
                  <div class="navigation-bar">
                    <VueDraggableNext v-model="modelValue.Dock" class="nav-grid" group="apps"
                      @add="ensureArrLen(modelValue.Dock, getMaxAppsinNavbar())">
                      <template v-for="(app, index) in modelValue.Dock">
                        <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" :show-app-names="false"
                          @click="openFolder(app)" @delete="deleteFromArrayIndex(modelValue.Dock, index)" />
                      </template>
                    </VueDraggableNext>
                  </div>
                </div>
              </figure>
            </template>

            <!-- iPad grid -->
            <template v-if="deviceType === EDeviceType.IPAD">
              <figure class='ipad'>
                <!-- Page indicators -->
                <div class="page-indicators">
                  <div v-for="(page, index) in modelValue.Pages"
                    :class="['indicator', { active: currentPage === index }]" @click="currentPage = index"></div>
                </div>

                <div v-if="folderView" class="folder-view">
                  <a @click="closeFolder" class="folder-close">X</a>

                  <input v-model="folderView.DisplayName" placeholder="Name" class="folder-title" />

                  <VueDraggableNext v-model="folderView.Pages![currentFolderPage]" class="folder-app-container"
                    group="apps"
                    @change="ensureArrLen(folderView.Pages![currentFolderPage], getMaxFolderAppCount())">
                    <template v-for="(app, index) in folderView.Pages![currentFolderPage]">
                      <app-item :app="app" :id-info-map="idInfoMap" :deletable="true"
                        @delete="deleteFromArrayIndex(folderView.Pages![currentFolderPage], index)" />
                    </template>
                  </VueDraggableNext>

                  <div v-if="modelValue.Pages.length < 15" class="page-indicators page-indicators-folder">
                    <div v-for="(page, index) in folderView.Pages!"
                      :class="['indicator', { active: currentFolderPage === index }]"
                      @click="currentFolderPage = index"></div>
                  </div>
                </div>

                <div>
                  <!-- Current page grid -->
                  <VueDraggableNext v-model="modelValue.Pages[currentPage]" class="device-grid-ipad" group="apps"
                    @end="onDragEnd" ghost-class="ghost-class"
                    @change="ensureArrLen(modelValue.Pages[currentPage], getMaxAppsPerPage())" :disabled="!!folderView">
                    <template v-for="(app, index) in modelValue.Pages[currentPage]">
                      <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" @click="openFolder(app)"
                        @delete="deleteFromArrayIndex(modelValue.Pages[currentPage], index)" />
                    </template>
                  </VueDraggableNext>

                  <!-- Navigation Bar Grid -->
                  <div class="navigation-bar">
                    <VueDraggableNext v-model="modelValue.Dock" class="nav-grid" group="apps"
                      @add="ensureArrLen(modelValue.Dock, getMaxAppsinNavbar())">
                      <template v-for="(app, index) in modelValue.Dock">
                        <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" :show-app-names="false"
                          @click="openFolder(app)" @delete="deleteFromArrayIndex(modelValue.Dock, index)" />
                      </template>
                    </VueDraggableNext>
                  </div>
                </div>
              </figure>
            </template>

            <!-- TV grid -->
            <template v-if="deviceType === EDeviceType.TV">
              <figure class='tv'>
                <div v-if="folderView" class="folder-view">
                  <a @click="closeFolder" class="folder-close">X</a>

                  <input v-model="folderView.DisplayName" placeholder="Name" class="folder-title" />

                  <VueDraggableNext v-model="folderView.Pages![currentFolderPage]" class="folder-app-container"
                    group="apps"
                    @change="ensureArrLen(folderView.Pages![currentFolderPage], getMaxFolderAppCount())">
                    <template v-for="(app, index) in folderView.Pages![currentFolderPage]">
                      <app-item :app="app" :id-info-map="idInfoMap" :deletable="true"
                        @delete="deleteFromArrayIndex(folderView.Pages![currentFolderPage], index)" />
                    </template>
                  </VueDraggableNext>

                  <div v-if="modelValue.Pages.length < 15" class="page-indicators page-indicators-folder">
                    <div v-for="(page, index) in folderView.Pages!"
                      :class="['indicator', { active: currentFolderPage === index }]"
                      @click="currentFolderPage = index"></div>
                  </div>
                </div>

                <div>
                  <!-- Current page grid -->
                  <VueDraggableNext v-model="modelValue.Pages[currentPage]" class="device-grid-tv" group="apps"
                    @end="onDragEnd" ghost-class="ghost-class"
                    @change="ensureArrLen(modelValue.Pages[currentPage], getMaxAppsPerPage())" :disabled="!!folderView">
                    <template v-for="(app, index) in modelValue.Pages[currentPage]">
                      <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" @click="openFolder(app)"
                        @delete="deleteFromArrayIndex(modelValue.Pages[currentPage], index)" />
                    </template>
                  </VueDraggableNext>

                  <!-- Navigation Bar Grid -->
                  <div class="navigation-bar">
                    <VueDraggableNext v-model="modelValue.Dock" class="nav-grid" group="apps"
                      @add="ensureArrLen(modelValue.Dock, getMaxAppsinNavbar())">
                      <template v-for="(app, index) in modelValue.Dock">
                        <app-item :app="app" :id-info-map="idInfoMap" :deletable="true" :show-app-names="false"
                          @click="openFolder(app)" @delete="deleteFromArrayIndex(modelValue.Dock, index)" />
                      </template>
                    </VueDraggableNext>
                  </div>
                </div>
              </figure>
            </template>

            <div class="navigation-buttons">
              <button @click="previousPage" :disabled="isBackButtonDisabled"><i
                  class="fa-solid fa-angles-left"></i></button>

              <button :disabled="!!folderView" @click="addFolder">
                <i class="fa-solid fa-folder-plus"></i>
                {{ T("New Folder") }}
              </button>

              <button @click="nextPage" :disabled="isNextButtonDisabled"><i
                  class="fa-solid fa-angles-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import products from '@/classes/objectTypes';
import { HomescreenLayoutItemType, type HomescreenLayout, type HomescreenLayoutItem } from "@/classes/mobileSecurity/homescreenlayouts";
import requestHandler from '@/queries/requests';
import { useStore } from '@/store/vuex.store';
import { ref, computed, onMounted, watch } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import i18n, { T } from '@/classes/i18n';
import NativeApps from "./native-ios-apps.json"
import LoaderComponent from "@/templates/components/loader.vue";
import AppItem from "./components/app-item.vue"

// icons
import PreferencesIcon from '../../../../appIcons/com.apple.Preferences.png';
import AppStoreIcon from '../../../../appIcons/com.apple.AppStore.png';
import NewsIcon from '../../../../appIcons/com.apple.news.png';
import UnknownIcon from './question.png';

// tv icons
import PreferencesIconTV from '../../../../appIcons/tvos_com.apple.TVSettings.png';
import ArcadeIconTV from '../../../../appIcons/tvos_com.apple.Arcade.png';
import FacetimeIconTV from '../../../../appIcons/tvos_com.apple.facetime.png';
import FitnessIconTV from '../../../../appIcons/tvos_com.apple.Fitness.png';
import PodcastIconTV from '../../../../appIcons/tvos_com.apple.podcasts.png';
import AppStoreTV from '../../../../appIcons/tvos_com.apple.TVAppStore.png';
import HomeSharingIconTV from '../../../../appIcons/tvos_com.apple.TVHomeSharing.png';
import TVMoviesIcon from '../../../../appIcons/tvos_com.apple.TVMovies.png';
import TVMusicIcon from '../../../../appIcons/tvos_com.apple.TVMusic.png';
import TVPhotosIcon from '../../../../appIcons/tvos_com.apple.TVPhotos.png';
import TVSearchIcon from '../../../../appIcons/tvos_com.apple.TVSearch.png';
import TVShowsIcon from '../../../../appIcons/tvos_com.apple.TVShows.png';
import TVWatchListIcon from '../../../../appIcons/tvos_com.apple.TVWatchList.png';
import queries from '@/queries/queries';

enum EDeviceType {
  IPHONE = "iPhone",
  IPAD = "iPad",
  TV = "TV",
}

type InternalBundleIdMap = Record<string, {
  name: string
  iconUrl: string
}>
const idInfoMap = ref<InternalBundleIdMap>({});

const nonAppStoreApps = computed(() => {
  if (deviceType.value != EDeviceType.TV) {
    return {
      ["com.apple.Preferences"]: {
        name: T("Einstellungen"),
        iconUrl: PreferencesIcon
      },
      ["com.apple.AppStore"]: {
        name: "App Store",
        iconUrl: AppStoreIcon
      },
      ["com.apple.news"]: {
        name: "Nachrichten",
        iconUrl: NewsIcon
      }
    } satisfies InternalBundleIdMap
  } else {
    return {
      ["com.apple.Preferences"]: {
        name: T("Einstellungen"),
        iconUrl: PreferencesIconTV
      },
      ["com.apple.Arcade"]: {
        name: "Arcade",
        iconUrl: ArcadeIconTV
      },
      ["com.apple.facetime"]: {
        name: "Facetime",
        iconUrl: FacetimeIconTV
      },
      ["com.apple.Fitness"]: {
        name: "Fitness",
        iconUrl: FitnessIconTV
      },
      ["com.apple.podcasts"]: {
        name: "Podcasts",
        iconUrl: PodcastIconTV
      },
      ["com.apple.TVAppStore"]: {
        name: "App Store",
        iconUrl: AppStoreTV
      },
      ["com.apple.TVHomeSharing"]: {
        name: "Home Sharing",
        iconUrl: HomeSharingIconTV
      },
      ["com.apple.TVMovies"]: {
        name: "TV & Movies",
        iconUrl: TVMoviesIcon
      },
      ["com.apple.TVMusic"]: {
        name: "TV Music",
        iconUrl: TVMusicIcon
      },
      ["com.apple.TVPhotos"]: {
        name: "TV Photos",
        iconUrl: TVPhotosIcon
      },
      ["com.apple.TVSearch"]: {
        name: "TV Search",
        iconUrl: TVSearchIcon
      },
      ["com.apple.TVShows"]: {
        name: "TV Shows",
        iconUrl: TVShowsIcon
      },
      ["com.apple.TVWatchList"]: {
        name: "TV Watchlist",
        iconUrl: TVWatchListIcon
      }
    } satisfies InternalBundleIdMap
  }
})

const accountId = computed(() => {
  return useStore().state.session.activeAccountId || ''
})

const props = defineProps<{
  modelValue: HomescreenLayout
}>();

const appSearch = ref('')
const deviceType = ref<EDeviceType>()
const currentPage = ref(0)
const currentFolderPage = ref(0)
const folderView = ref<HomescreenLayoutItem | null>(null)

const isBigDeviceScreen = computed(() => {
  return deviceType.value === EDeviceType.IPAD || deviceType.value === EDeviceType.TV
})

const appLibraryHeight = computed(() => {
  switch (deviceType.value) {
    case EDeviceType.IPHONE:
      return '55em'
    case EDeviceType.IPAD:
      return '64em'
    case EDeviceType.TV:
      return '50em'
  }
})

// shitty workaround
const blockFolderOpening = ref(false)

const appLibrary = ref({
  apps: [] as HomescreenLayoutItem[],
  webclips: [] as HomescreenLayoutItem[]
});

const availableApps = computed(() => {
  function filter(item: HomescreenLayoutItem) {
    const renderName = idInfoMap.value[item.BundleID || item.URL!]?.name?.toLowerCase()
    const search = appSearch.value.toLowerCase()

    return renderName?.includes(search)
  }

  return {
    apps: appLibrary.value.apps.filter(filter),
    webclips: appLibrary.value.webclips.filter(filter)
  }
})

const loading = ref(false);

onMounted(async () => {
  // this will call the init function
  deviceType.value = props.modelValue.DeviceType as EDeviceType
})

async function init() {
  loading.value = true
  let apps = products.mobileSecurity.iosApps.useStore?.().getObjectStoreObjects(accountId.value) || []
  if(apps.length == 0) {
    await products.mobileSecurity.iosApps.queries.getObjectsFromApi(accountId.value)
    apps = products.mobileSecurity.iosApps.useStore?.().getObjectStoreObjects(accountId.value) || []
  }
  

  if (apps instanceof Error) {
    throw new Error("failed to load apps")
  }

  const tenantApps = apps.filter((app) => app.iTunesStoreID)
  const webclips = apps.filter((app) => app.platform === "WEBCLIP")
  const dockApps = props.modelValue.Dock.map((app) => app.BundleID)
  const screenApps = props.modelValue.Pages.flatMap((page) => page.map((item) => item.BundleID))
  const bundleIds = tenantApps.map((app) => app.uninstallPackageName).concat(dockApps).concat(screenApps).concat(NativeApps).filter((bundleId) => { return bundleId != undefined })
  try {
    const assetInfos = await queries.mobileSecurity.vpp.getAssetInfosMapFromAppList(bundleIds, deviceType.value === EDeviceType.TV ? 'tvOS' : 'iOS')
    ;(Object.keys(assetInfos) || []).forEach((bundleId:string) => {
      const appInfo = assetInfos[bundleId]
      idInfoMap.value[bundleId] = {
        name: appInfo.trackName || appInfo.bundleId,
        iconUrl: appInfo.artworkUrl100 || appInfo.artworkUrl60 || appInfo.artworkUrl512 || UnknownIcon
      }
    })
    Object.entries(nonAppStoreApps.value).forEach(([bundleId, app]) => {
      idInfoMap.value[bundleId] = {
        name: app.name,
        iconUrl: app.iconUrl || UnknownIcon
      }
    })
  }
  catch(e) {
    
  }

  // make sure to clear the app library
  appLibrary.value.apps = []
  appLibrary.value.webclips = []

  Object.entries(idInfoMap.value).forEach(([bundleId, app]) => {
    appLibrary.value.apps.push({
      Type: HomescreenLayoutItemType.Application,
      BundleID: bundleId,
    })
  })

  webclips.forEach((webclip) => {
    const iconData = webclip["Icon:data"] || webclip.Icon
    const url = webclip.URL

    if (!iconData || !url) {
      return
    }

    appLibrary.value.webclips.push({
      Type: HomescreenLayoutItemType.WebClip,
      URL: url,
    })

    idInfoMap.value[url] = {
      name: webclip.name!,
      iconUrl: `data:image;base64, ${iconData}`
    }
  })

  loading.value = false
}

const isBackButtonDisabled = computed<boolean>(() => {
  if (folderView.value) {
    return currentFolderPage.value === 0;
  } else {
    return currentPage.value === 0;
  }
})

const isNextButtonDisabled = computed<boolean>(() => {
  if (folderView.value) {
    return currentFolderPage.value > 10
  } else {
    return currentPage.value > 10
  }
})


const onDragEnd = (event: any) => {
  // Check if the last page is empty and remove it
  if (props.modelValue.Pages[props.modelValue.Pages.length - 1].length === 0) {
    props.modelValue.Pages.pop();
  }

  // Add a new page if all pages are full
  const maxAppsPerPage = getMaxAppsPerPage();
  if (props.modelValue.Pages.every(page => page.length >= maxAppsPerPage)) {
    props.modelValue.Pages.push([]);
  }
}

function getMaxAppsPerPage() {
  switch (deviceType.value) {
    case EDeviceType.IPHONE:
      return 24; // 4x6 grid
    case EDeviceType.IPAD:
      return 30; // 6x5 grid
    case EDeviceType.TV:
      return 24 // 6x4 grid
    default:
      throw new Error("invalid device")
  }
}

function getMaxAppsinNavbar() {
  switch (deviceType.value) {
    case EDeviceType.IPHONE:
      return 4
    case EDeviceType.IPAD:
      return 10
    case EDeviceType.TV:
      return 6
    default:
      throw new Error("invalid device")
  }
}

function getMaxFolderAppCount() {
  switch (deviceType.value) {
    case EDeviceType.IPHONE:
      return 9
    case EDeviceType.IPAD:
      return 16
    case EDeviceType.TV:
      return 9
    default:
      throw new Error("invalid device")
  }
}

const previousPage = (e: Event) => {
  e.preventDefault()

  if (folderView.value) {
    if (currentFolderPage.value > 0) {
      currentFolderPage.value--;
    }

    if (folderView.value.Pages![folderView.value.Pages!.length - 1].length === 0) {
      folderView.value.Pages!.pop()
    }

  } else {
    if (currentPage.value > 0) {
      currentPage.value--;
    }

    //delete last page if empty
    if (props.modelValue.Pages[props.modelValue.Pages.length - 1].length === 0) {
      props.modelValue.Pages.pop();
    }
  };
}

const nextPage = (e: Event) => {
  e.preventDefault()

  if (folderView.value) {
    if (currentFolderPage.value === folderView.value.Pages!.length - 1) {
      folderView.value.Pages!.push([]);
    }

    if (currentFolderPage.value < folderView.value.Pages!.length - 1) {
      currentFolderPage.value++;
    }
  } 
  else {
    if (currentPage.value === props.modelValue.Pages.length - 1) {
      props.modelValue.Pages.push([]);
    }

    if (currentPage.value < props.modelValue.Pages.length - 1) {
      currentPage.value++;
    }
  }
};

function addFolder(e: Event) {
  e.preventDefault()

  const pageLimit = getMaxAppsPerPage()

  if (props.modelValue.Pages[currentPage.value].length >= pageLimit) {
    nextPage(new Event('click'))
  }

  props.modelValue.Pages[currentPage.value].push({
    Type: HomescreenLayoutItemType.Folder,
    DisplayName: T("New Folder"),
    Pages: [[]]
  })
}

watch(deviceType, async (newValue, oldValue) => {
  if ((newValue === EDeviceType.IPHONE || newValue === EDeviceType.IPAD) && (oldValue === EDeviceType.IPHONE || oldValue === EDeviceType.IPAD)) {
    // skip init if we are switching between iPhone and iPad
  } else {
    await init()
  }

  props.modelValue.DeviceType = deviceType.value

  // tvOS apps aren't compatible with iOS / iPadOS apps, so we cleanup the layout
  if ((newValue === EDeviceType.TV && !!oldValue) || oldValue === EDeviceType.TV) {
    props.modelValue.Pages = [[]]
    props.modelValue.Dock = []
    currentPage.value = 0

    closeFolder()

    return
  }

  const maxAppsPerPage = getMaxAppsPerPage();

  props.modelValue.Pages.forEach((page, i) => {
    const nextPage = props.modelValue.Pages[i + 1];

    if (page.length > maxAppsPerPage) {
      if (!nextPage) {
        props.modelValue.Pages.push([]);
      }

      const appsToMove = page.splice(maxAppsPerPage);
      nextPage.push(...appsToMove);
    }
  });

  ensureArrLen(props.modelValue.Dock, getMaxAppsinNavbar())

  //ensure folder length
  const maxAppsPerFolder = getMaxFolderAppCount()

  props.modelValue.Pages.forEach((items) => {
    items.forEach((item) => {
      if (item.Type !== "Folder") {
        return
      }

      (item.Pages || []).forEach((page, i) => {
        const nextPage = item.Pages?.[i + 1]

        if (page.length > maxAppsPerFolder) {
          if (!nextPage) {
            item.Pages?.push([])
          }

          const appsToMove = page.splice(maxAppsPerFolder);
          nextPage?.push(...appsToMove);
        }
      })
    })
  })
});

watch(currentPage, () => {
  if (folderView.value) {
    closeFolder()
  }
})


function openFolder(folder: HomescreenLayoutItem) {
  if (blockFolderOpening.value) {
    return
  }

  if (folder.Type !== 'Folder') {
    return
  }

  folderView.value = folder;
}

function closeFolder() {
  folderView.value = null;
  currentFolderPage.value = 0;
}

function ensureArrLen(arr: any[], limit: number) {
  arr.splice(limit)
}

function deleteFromArrayIndex(array: any[], index: number) {
  blockFolderOpening.value = true

  setTimeout(() => {
    blockFolderOpening.value = false
  }, 100)

  array.splice(index, 1);
}
</script>


<style scoped lang="scss">
@use 'sass:math';

@mixin responsive($breakpoint) {
  @if $breakpoint ==tablet {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $breakpoint ==desktop {
    @media (min-width: 1024px) {
      @content;
    }
  }
}

.app-library-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 10px;
  padding: 10px;
  user-select: none;
}

.app-library {
  max-height: v-bind(appLibraryHeight);
  overflow-y: auto;
}

.app-library-tv .app-library-grid {
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
}

.device-grid-iphone {
  display: grid;

  grid-template-columns: repeat(4, minmax(4em, 1fr));
  grid-template-rows: repeat(6, minmax(6em, 1fr));

  height: 100%;
  width: 100%;
  padding: 1em 10px 35px;
  min-width: 350px;

  gap: 500px;
  // row-gap: 6em;

  @include responsive(tablet) {
    gap: 10px;
  }
}

.device-grid-ipad {
  @extend .device-grid-iphone;

  grid-template-columns: repeat(5, minmax(8em, 1fr));
  grid-template-rows: repeat(6, minmax(5.5em, 1fr));

  min-width: auto;

  gap: 2rem;
}

.device-grid-tv {
  @extend .device-grid-iphone;

  grid-template-columns: repeat(6, minmax(7em, 1fr));
  grid-template-rows: repeat(4, minmax(5em, 1fr));

  min-width: auto;
  margin-top: 1.8rem;

  gap: 1.8rem;
  column-gap: 4em;
}

.navigation-bar {
  // display: grid;
  // gap: 5px;
  padding: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
  border-radius: 15px;
  margin: 5px;

  min-height: 4rem;
  max-height: 4rem;


  @include responsive(tablet) {
    gap: 8px;
    padding: 5px;
    border-radius: 20px;
    margin: 18px;
  }
}

// apple tv navigation bar are on top
.tv .navigation-bar {
  top: 0;
}

.nav-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(4em, 1fr);

  gap: 5px;
  min-width: 100px;

  @include responsive(tablet) {
    gap: 10px;
  }
}

.ipad .nav-grid {
  grid-template-columns: repeat(10, 1fr);
}

.tv .nav-grid {
  grid-template-columns: repeat(6, 1fr);
}

.iphone,
.ipad,
.tv {
  margin: auto;
  position: relative;
  box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.33), 0 0 0 0.5px #000 inset;
  transition: all .1s linear;
  background-position: center;
  background-size: cover;
  transform-origin: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3.5rem;

  // this will bypass the lightmode and make some people happy ^_^
  color: rgba(255, 255, 255, 0.6)
}

.iphone {
  width: min(80vw, 375px);
  height: calc(min(80vw, 350px) * 2.2);
  border: solid #111 min(2vw, 10px);
  border-radius: min(5vw, 70px);
  background-image: url('../../../img/apple/ios-wallpaper.webp');
  z-index: 200;
}

.iphone::before {
  content: '';
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  width: 35%;
  height: min(4%, 40px);
  background-color: #000;
  border-radius: min(1rem, 5vw);
  z-index: 200;
}

.ipad {
  width: min(90vw, 750px);
  height: calc(min(90vw, 750px) * 1.1);
  border: solid #111 min(1.5vw, 15px);
  border-radius: min(3vw, 30px);
  background-image: url('../../../img/apple/ios-wallpaper.webp');

  @media (max-width: 750px) {
    width: 95vw;
    height: calc(95vw * 1.1);
    border-width: min(1vw, 10px);
    border-radius: min(2vw, 20px);
  }
}

.tv {
  width: min(90vw, 900px);
  height: calc(min(90vw, 550px));
  border: solid #111 min(1vw, 10px);
  border-radius: min(1vw, 10px);
  background-image: url('../../../img/apple/tvos-wallpaper.jpg');
}

.page-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 90px; // Adjust this value to position above the navigation bar
  left: 0;
  right: 0;
  z-index: 300;
}

.page-indicators-folder {
  bottom: 10px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1.2);
  }
}

.navigation-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 10px 5px;
}

.ghost-class {
  opacity: 0.5;
}

.folder-view {
  background-color: #2a2a2a;

  border-radius: 20px;
  width: 90%;
  padding: 1rem;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 100;
}

.folder-title {
  background-color: #3a3a3a;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.folder-title::after {
  content: "•";
  color: #8e8e93;
}

.folder-close {
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-left: 98%;
  user-select: none;
}

.folder-app-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  padding: 10px 0;
}

.ipad .folder-app-container {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
</style>