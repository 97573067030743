import { nextTick } from "vue";
import { useGlobalMixin } from "@/mixins/mixins.global";
import { useStore } from '@/store/vuex.store';
import { ActionTypes } from "@/store/vuex.store";
import loaderComponent from "../components/loader.vue";
import { T } from "@/classes/i18n";
import table2Component from "../components/table2";
import moment from "moment";
import { sprintf } from "sprintf-js"
import tenantHelpers from "@/helpers/helpers.tenants";
import licenseHelpers from "@/helpers/helpers.license";
import config from "@/classes/config";
import products from "@/classes/objectTypes";
import deviceHelpers from "@/helpers/helpers.devices";

const inventoryComponent = {
    mixins: [useGlobalMixin()],
    name:'inventory',
    template: `
    <div>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
            <div class="row">
                <div class="col-xs-24">
                    <div class="box bg-white padding-xs-2">
                        <template v-if="initialized">
                            <h3>
                                {{ T('Productselection') }}
                            </h3>
                            <div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="productSelection" class="control-label">
                                        {{ T('Product') }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <label class="form-field select">
                                        <select v-model="product" id="productSelection">
                                            <template v-for="product in productOptions">
                                                <option :value="product.id">
                                                    {{ product.text }}
                                                </option>
                                            </template>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <h3>
                                {{ T('Inventory list') }}
                            </h3>

                            <table2
                                ref="devicesTable"
                                class="devicesTable"
                                :table="inventoryList.table"
                                :scrollbar="true"
                                :maxheight="500"
                                :loading="loading"
                            ></table2>


                        </template>

                        <div
                            v-else
                            class="loaderbox text-center"
                        >
                            <loader
                                class="color-red"
                                :progress="$parent.loadingProgress"
                            ></loader>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        
    </div>
    `,
    data: function(this:any)  {
        return {
            "initialized":false,
            "loading": false,
            "product":"",
            "productOptions": [
                
            ],
            "inventoryList": {
                "loading":true,
                "table": {
                    "titles": [],
                    "rows": []
                }
            }
        }
    },
    computed: {
        "activeItems": function(this:any) {
            let result : any = {
                "count":0,
                "items":[]
            }
            switch (this.product) {
                case "mobileSecurity":
                    result.items = config.canUseNewObjectType("iosDevices") ? JSON.parse(JSON.stringify(products.mobileSecurity.iosDevices.useStore?.().getObjectStore(this.activeAccountId).objects)) : JSON.parse(JSON.stringify(useStore().state.session.accounts[this.activeAccountId].mobileSecurity.devices || {})).items
                    result.items = Array.isArray(result?.items) ? result.items.filter(function(device:any) {
                        return device.configured
                    }) : []

                    let resultEnterpriseDevices: any = config.canUseNewObjectType('androidDevices') ? JSON.parse(JSON.stringify(products.mobileSecurity.androidDevices.useStore?.().getObjectStore(this.activeAccountId) || result)) : JSON.parse(JSON.stringify(useStore().state.session.accounts[this.activeAccountId].mobileSecurity.enterpriseDevices || {}))
                    resultEnterpriseDevices.items = config.canUseNewObjectType('androidDevices') ? resultEnterpriseDevices.objects.filter(function (device: any) { return device.consent !== "" && device.consent !== undefined }) : resultEnterpriseDevices.items.filter(function (device: any) { return device.consent !== "" && device.consent !== undefined })

                    result.items.concat([], resultEnterpriseDevices.items || []) 
                    
                    let resultVpn: any = config.canUseNewObjectType('vpns') ? JSON.parse(JSON.stringify(products.mobileSecurity.vpns.useStore?.().getObjectStore(this.activeAccountId) || result)) : JSON.parse(JSON.stringify(useStore().state.session.accounts[this.activeAccountId].mobileSecurity.vpns || result))
                    result.count = parseInt(result.count) + parseInt(resultVpn.count)
                    result.items = config.canUseNewObjectType('vpns') ? result.items.concat(resultEnterpriseDevices.items || [], resultVpn.objects || []) : result.items.concat(resultEnterpriseDevices.items || [],resultVpn.items || [])

                    
                break;
                case "unifiedReporting":
                    result = useStore().state.session.accounts[this.activeAccountId].unifiedReporting.utms || result
                    result.items = result.items
                break;
                case "unifiedSecurityConsole":
                    result = useStore().state.session.accounts[this.activeAccountId].unifiedSecurityConsole.uscUtms || result
                    result.items = result.items
                break;
            }
            return result
        }
    },
    methods: {
        T:function(content:any) {
            return T(content)
        },
        getNumber: function(data : any) {
            let number : number = 0
            if (data != undefined) {
                typeof data == 'number'
                    ? number = data
                    : data.length != undefined
                        ? number = data.length
                        : number = data.count
            }
            return number
        },
        shortAlias: function(alias : string) {
            if (alias.length > 24) {
                return alias.slice(0,22) + "..."
            }
            else {
                return alias
            }
        },
        timestampToDate: function(timestamp : any) {
            let date : any = ""
            if (timestamp) {
                if (timestamp.length) {
                    date = moment.unix(timestamp).format("DD.MM.YYYY") || undefined
                }
            }
            return date;
        },
        getAddedMonths: function(timestamp : any, months : any) {
            return moment.unix(timestamp).add(months,'months').format("DD.MM.YYYY")
        },
        getAddedMonthsTimestamp: function(timestamp : any, months : any) {
            return moment.unix(timestamp).add(months,'months').format('X')
        },
        refresh: async function(this:any,force:boolean=false) {
            let objectTypes : string[] = []
            this.loading = true
            switch (this.product) {
                case "mobileSecurity":
                    objectTypes = []

                    if (config.canUseNewObjectType('iosDevices')) {
                        await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.activeAccountId, undefined, [
                            { "property": "props[]", "value": "deviceId" },
                            { "property": "props[]", "value": "deviceType" },
                            { "property": "props[]", "value": "signedIn" },
                            { "property": "props[]", "value": "configured" },
                            { "property": "props[]", "value": "props" },
                            { "property": "props[]", "value": "licenseUUID" },
                            { "property": "props[]", "value": "inventory" },
                            { "property": "props[]", "value": "alias" },
                            { "property": "props[]", "value": "serialNumber" },
                            { "property": "props[]", "value": "info" }
                        ])
                    }
                    else {
                        objectTypes.push('devices?props[]=deviceId&props[]=deviceType&props[]=signedIn&props[]=configured&props[]=props&props[]=licenseUUID&props[]=inventory&props[]=alias&props[]=info')
                    }


                    if(config.canUseNewObjectType('vpns')) {
                        await products.mobileSecurity.vpns.queries.getObjectsFromApi(this.activeAccountId)
                    }
                    else {
                        objectTypes.push('vpns')
                    }
                    if (tenantHelpers.hasFunctionality(this.activeAccountId,'enterprise')) {
                        if (config.canUseNewObjectType('androidDevices')) {
                            await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(this.activeAccountId,undefined,[
                                { "property":"props[]", "value":"appliedPolicyName" },
                                { "property":"props[]", "value":"appliedState" },
                                { "property":"props[]", "value":"enrollmentTime" },
                                { "property":"props[]", "value":"props" },
                                { "property":"props[]", "value":"hardwareInfo" },
                                { "property":"props[]", "value":"lastStatusReportTime" },
                                { "property":"props[]", "value":"managementMode" },
                                { "property":"props[]", "value":"name" },
                                { "property":"props[]", "value":"ownership" },
                                { "property":"props[]", "value":"policyName" },
                                { "property":"props[]", "value":"previousDeviceNames" },
                                { "property":"props[]", "value":"state" },
                                { "property":"props[]", "value":"alias" },
                                { "property":"props[]", "value":"deviceId" },
                                { "property":"props[]", "value":"username" },
                                { "property":"props[]", "value":"inventory" },
                                { "property":"props[]", "value":"consent" },
                                { "property":"props[]", "value":"licenseUUID" },
                                { "property":"props[]", "value":"signedIn" },
                                { "property": "fields", "value":"*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)" }
                            ])
                        }
                        else {
                            objectTypes.push('enterpriseDevices?props[]=appliedPolicyName&props[]=appliedState&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=inventory&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)')
                        }
                        
                    }
                break;
                case "unifiedReporting":
                    objectTypes = ['utms']
                break;
                case "unifiedSecurityConsole":
                    objectTypes = ['uscUtms']
                break;
            }
            await useStore().dispatch(ActionTypes.getObjectInfos,{
                "accountId":this.activeAccountId,
                "objectTypes":objectTypes
            })
            this.refreshCounter = this.refreshCounter + 1
            this.refreshTable()
            this.initialized = true
            nextTick(() => { this.loading = false })
        },
        refreshTable: async function(this : any) {
            let thisComponent : any = this
            let entries : any = []
            this.inventoryList.table.rows = []
            if (this.product == "mobileSecurity") {
                this.inventoryList.table.titles = [
                    {
                        "id":"alias",
                        "text":"Alias",
                        "sortable":true
                    },
                    {
                        "id":"model",
                        "text":"Model",
                        "sortable":true
                    },
                    {
                        "id":"warrantyEnd",
                        "text":"Warranty until",
                        "sortable":true
                    },
                    {
                        "id":"providerName",
                        "text":"Provider",
                        "sortable":true
                    },
                    {
                        "id":"providerTariff",
                        "text":"Tariff",
                        "sortable":true
                    },
                    {
                        "id":"contractNumber",
                        "text":"Contract number",
                        "sortable":true
                    },
                    {
                        "id":"contractEnd",
                        "text":"End of contract",
                        "sortable":true
                    },
                    {
                        "id": "action",
                        "text": "Actions",
                        "sortable": false
                    }
                ]
            }
            else {
                this.inventoryList.table.titles = [
                    {
                        "id": "alias",
                        "text": "Alias",
                        "sortable": true
                    },
                    {
                        "id": "model",
                        "text": "Model",
                        "sortable": true
                    },
                    {
                        "id": "warrantyEnd",
                        "text": "Warranty until",
                        "sortable": true
                    },
                    {
                        "id": "providerName",
                        "text": "Provider",
                        "sortable": true
                    },
                    {
                        "id": "providerTariff",
                        "text": "Tariff",
                        "sortable": true
                    },
                    {
                        "id": "contractNumber",
                        "text": "Contract number",
                        "sortable": true
                    },
                    {
                        "id": "contractEnd",
                        "text": "End of contract",
                        "sortable": true
                    },
                    {
                        "id": "action",
                        "text": "Actions",
                        "sortable": false
                    }
                ]
            }

            if (thisComponent.activeTenantDomain) {
                let devices : any = this.activeItems.items
                for(let i : number = 0; devices.length > i; i++) {
                    devices[i].inventory = devices[i].inventory || {}

                    let formlink : string = (() => {
                        let result = ""
                        switch (this.product) {
                            case 'mobileSecurity':
                                result = "#edit-tenant-"+this.activeTenantDomain+"-inventory-"+
                                (devices[i].id != undefined ? ("enterprise-"+devices[i].id) : (devices[i].deviceId != undefined 
                                    ? ("device-" + devices[i].deviceId) 
                                        : ("vpn-"+devices[i].vpnId)
                                    )
                                )
                            break;
                            case 'unifiedReporting':
                                result = "#edit-tenant-"+this.activeTenantDomain+"-inventory-reportingUtm-"+devices[i].utmId
                            break;
                            case 'unifiedSecurityConsole':
                                result = "#edit-tenant-"+this.activeTenantDomain+"-inventory-usc-utm-"+devices[i].utmId
                            break;
                        }
                        return result
                    })()
                    
                    let detailsLink : string = (() => {
                        let result = ""
                            switch (this.product) {
                            case 'mobileSecurity':
                                if (devices[i].name?.indexOf("enterprises") != -1) {
                                    result = '#show-tenant-'+thisComponent.activeTenantDomain + '-android-device-' + (devices[i].id || '')+'-details'
                                } else if (devices[i].deviceId) {
                                    result = '#show-tenant-'+thisComponent.activeTenantDomain + '-ios-device-' + (devices[i].deviceId || '')+'-details'
                                } else {
                                    result = '#edit-tenant-'+thisComponent.activeTenantDomain+'-vpn-'+(devices[i].vpnId || '')
                                }
                            break;
                            case 'unifiedReporting':
                                result = "#edit-tenant-"+this.activeTenantDomain+"-utm-"+devices[i].utmId
                            break;
                            case 'unifiedSecurityConsole':
                                result = "#show-tenant-"+this.activeTenantDomain+"-usc-utms-"+devices[i].utmId
                            break;
                        }
                        return result
                    })()

                    let thisModel : string = ""
                    if (devices[i]?.deviceFamily) {
                        thisModel = devices[i]?.deviceFamily
                    }
                    else if (useStore().state.resources.apple.deviceNames[devices[i]?.info?.productName]) {
                        thisModel = useStore().state.resources.apple.deviceNames[devices[i]?.info?.productName]
                    }
                    else if (useStore().state.resources.apple.deviceNames[devices[i]?.info?.model]) {
                        thisModel = useStore().state.resources.apple.deviceNames[devices[i]?.info?.model]
                    }
                    else {
                        thisModel = devices[i].deviceType == "IOS" ? devices[i]?.info?.productName || ("IOS "+  T("Device")) : "vpn"
                    }

                    if (devices[i].name && devices[i].name.indexOf("enterprises") != -1) {
                        thisModel = devices[i].hardwareInfo?.manufacturer + " - " + devices[i].hardwareInfo?.model
                    } 
                    else if (devices[i].deviceType != "IOS") {
                        thisModel = thisModel == "vpn" ? T("Other Device (VPN)") : (sprintf(
                            "%s - %s - %s", 
                            devices[i]?.info?.manufacturer || "", 
                            thisModel || "", 
                            (devices[i]?.info?.os || "") + " " + (devices[i]?.info?.version || "")
                        ) || "")
                    }

                    let alias = devices[i].name?.indexOf("enterprises") != -1 ? deviceHelpers.getAliasedShortDeviceId(devices[i].alias ? devices[i].deviceId : "", (devices[i].alias || deviceHelpers.getShortDeviceId(devices[i].deviceId)), false) : deviceHelpers.getAliasedShortDeviceId(devices[i].vpnId || devices[i].deviceId, (devices[i].alias || devices[i].vpnname || ''), false)

                    if (devices[i].vpnname || devices[i].vpnId) {
                        alias = deviceHelpers.getAliasedShortDeviceId(devices[i].vpnId, devices[i].vpnname || '', false)
                    }

                    if(this.product == "mobileSecurity") {
                        this.inventoryList.table.rows.push(
                            {
                                data:{
                                    "alias": alias,
                                    "model": thisModel,
                                    "warrantyEnd": (devices[i].inventory.warrantyEndDate != "NaN" ? thisComponent.timestampToDate(devices[i].inventory.warrantyEndDate) || '' : ''),
                                    "providerName": devices[i].inventory.providerName || '',
                                    "providerTariff": devices[i].inventory.providerTariff || '',
                                    "contractNumber": devices[i].inventory.contractNumber || '',
                                    "contractEnd": devices[i].inventory.contractDate ? thisComponent.getAddedMonths(devices[i].inventory.contractDate, devices[i].inventory.contractDuration) : '',
                                    "action": {
                                        "buttons": [
                                            {
                                                "href": formlink,
                                                "icon": "fal fa-edit",
                                                "text": T("Edit"),
                                                "loading": false,
                                                "disabled": false
                                            },
                                            {
                                                "href": devices[i].inventory.inventoryExternalLink != "http://" ? devices[i].inventory.inventoryExternalLink : undefined,
                                                "icon": "fal fa-link",
                                                "text": T("Open external inventory link"),
                                                "loading": false,
                                                "disabled": devices[i].inventory.inventoryExternalLink == "http://" || devices[i].inventory.inventoryExternalLink == "",
                                                "title": devices[i].inventory.inventoryExternalLink == "http://" || devices[i].inventory.inventoryExternalLink == "" ? T("No link is configured") : undefined
                                            }
                                        ]
                                    }
                                }
                            }
                        )
                    }
                    else {
                        this.inventoryList.table.rows.push(
                            {
                                data: {
                                    "alias": deviceHelpers.getAliasedShortDeviceId(devices[i].utmId, (devices[i].utmname || ''), false),
                                    "model": devices[i].model,
                                    "warrantyEnd": (devices[i].inventory.warrantyEndDate != "NaN" ? thisComponent.timestampToDate(devices[i].inventory.warrantyEndDate) || '' : ''),
                                    "providerName": devices[i].inventory.providerName || '',
                                    "providerTariff": devices[i].inventory.providerTariff || '',
                                    "contractNumber": devices[i].inventory.contractNumber || '',
                                    "contractEnd": devices[i].inventory.contractDate ? thisComponent.getAddedMonths(devices[i].inventory.contractDate, devices[i].inventory.contractDuration) : '',
                                    "action": {
                                        "buttons": [
                                            {
                                                "href": formlink,
                                                "icon": "fal fa-edit",
                                                "text": T("Edit"),
                                                "loading": false,
                                                "disabled": false
                                            },
                                            {
                                                "href": devices[i].inventory.inventoryExternalLink != "http://" ? devices[i].inventory.inventoryExternalLink : undefined,
                                                "icon": "fal fa-link",
                                                "text": T("Open external inventory link"),
                                                "loading": false,
                                                "disabled": devices[i].inventory.inventoryExternalLink == "http://" || devices[i].inventory.inventoryExternalLink == "",
                                                "title": devices[i].inventory.inventoryExternalLink == "http://" || devices[i].inventory.inventoryExternalLink == "" ? T("No link is configured") : undefined
                                            }
                                        ]
                                    }
                                }
                            }
                        )
                    }                  
                }
            }
        },
    },
    watch: {
        activePage: {
            handler: function(activePage : any) {
                let thisComponent : any = this
            }
        },
        activeTenantDomain: {
            handler: function() {
                let thisComponent : any = this
            }
        },
        activeItems: {
            handler: function(this:any,items : any) {
                if(this.initialized) {
                    this.refreshTable()
                }
            },
            deep:true
        },
        product: {
            handler: function(this:any,product : any) {
                if(this.initialized) {
                    this.refresh()
                }
            },
            deep:true
        }
    },
    mounted: function(this:any) {

    },
    beforeCreate(this: any) {
    },
    components: {
        "table2": table2Component,
        "loader": loaderComponent
    },
    created: function(this:any) {
        if(licenseHelpers.hasOneOfLicenses(this.activeAccountId,["MDM","Mobile Security"],'valid')) {
            this.productOptions.push({
                "id":"mobileSecurity",
                "text":"Mobile Security"
            })
            this.product = "mobileSecurity"
        }
        if(licenseHelpers.hasOneOfLicenses(this.activeAccountId,["laas"],'valid')) {
            this.productOptions.push({
                "id":"unifiedReporting",
                "text":"Unified Reporting"
            })
            if(!this.product) {
                this.product = "unifiedReporting"
            }
        }
        if(licenseHelpers.hasOneOfLicenses(this.activeAccountId,["unifiedSecurityConsole"],'valid')) {
            this.productOptions.push({
                "id":"unifiedSecurityConsole",
                "text":"Unified Security Console"
            })
            if(!this.product) {
                this.product = "unifiedSecurityConsole"
            }
        }

        this.refresh()
    },
}
export default inventoryComponent
