import moment from "moment";
interface NextCronDayInfo {
    hours: string;
    day: string;
    nextMonth: boolean
}

const timeHelpers = {
    sleep: function (milliseconds: number) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    },
    getDateFormatI18n: function (langFlag: string, withTime: boolean = false) {
        let result: string = ""
        if (langFlag == "de") {
            result = "DD.MM.YYYY" + (withTime ? " HH:mm:ss" : "")
        }
        else {
            result = "DD/MM/YYYY" + (withTime ? " hh:mm:ss a" : "")
        }
        return result
    },
    getCurrentDate: function (format: string) {
        return moment().format(format)
    },
    formatDate: function (date: any, format: string) {
        return moment(date).format(format)
    },
    getUnixTimeStamp: function (date?: any) {
        return moment(date).unix()
    },
    /**
     * Returns the Next Day where an update should be performed
     * @param cronString 7-23 * * 0F,1F,2F,3F,4F,5F,6F || 23 * * 0,1,2,3,4,5,6
     * @return An Object with the Next Weekday, Hour of the next Update and boolean if it is next month
     */
    getNextDayFromCron: function (cronString: string, mockDate=-1): NextCronDayInfo | undefined {
        
        // String does not match the format
        if (!cronString.includes("* *")) {
            return undefined
        }
        let result: NextCronDayInfo = { day: '', hours: '', nextMonth: false }
        result.hours = cronString.split("* *")[0]?.trim()
        
        // The current Date
        const currentDate = new Date();

        // Current Day in the calendar (e.g. 18.08 => 18)
        const dayOfMonth = mockDate === -1 ? currentDate.getDate() -1 : mockDate -1

        // First Weekday of the current Month (e.g. 01.08.2023 => Tuesday => 2)
        const firstDayOfCurrentMonth = this.getFirstDayOfMonth()

        // First Weekday of next month (e.g. 01.09.2023 => Friday => 5)
        const firstDayOfNextMonth = this.getFirstDayOfMonth((currentDate.getMonth() + 1) % 12)
        // Weekdays arranged to english time
        const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

        // Days of the cron value
        const scheudleDays: string = cronString.split("* *")[1]
        
        
        // Only once a month updates
        if (scheudleDays.match(/[0-6]F/)) {

            // If an entire week of the current month has passed
            if (dayOfMonth > 6) {
                result.nextMonth = true
                result.day = cronMagic(scheudleDays, firstDayOfNextMonth, undefined)
                return result
            }
            
            // Checks if there is still an update within the first week of the month
            for (let i = dayOfMonth; i < 7; i++) {
                const day = (i + firstDayOfCurrentMonth) % 7
                if (scheudleDays.includes(day.toString())) {
                    result.day = i === 0 ? "today" : weekdays[day]
                    return result
                }
            }

            // There is no update to be made within the first week, so the next update is next month -> Same as in if
            result.nextMonth = true
            result.day = cronMagic(scheudleDays, firstDayOfNextMonth, undefined)
            return result

        }
        // Daily updates 
        else {
            result.day = cronMagic(scheudleDays, undefined, currentDate.getDay())
            return result
        }
    },
    getFirstDayOfMonth: function (month?: number): number {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), month ? month : currentDate.getMonth(), 1);
        return firstDayOfMonth.getDay();
    }
}

// I recommend not to touch it
function cronMagic(scheudleDays: string, firstDayOfNextMonth: number = 0, currentDay = 0): string {
    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    
    for (let i = firstDayOfNextMonth; i < 7 + firstDayOfNextMonth; i++) {
        const nextDay = (currentDay + i) % 7
        if (scheudleDays.includes(nextDay.toString())) {
            if(firstDayOfNextMonth === 0 && i === 0) {
                return "today"
            } else {
                return weekdays[nextDay]
            }
        }
    }
    return "UNKNOWN"
}


export default timeHelpers