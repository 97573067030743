import type { License } from "@/classes/objectTypes/unifiedSecurity/licenses/licenses"
import ObjectType, { type ObjectTypePayload } from "../../objectType"
import type { ZeroTouchDevice } from "./zerotouchDevices"
import apis from "@/classes/apis"
import requestHandler from "@/queries/requests"
import tenantHelpers from "@/helpers/helpers.tenants"
import { useVue } from "@/app"
import timeHelpers from "@/helpers/helpers.time"

export type EnterpriseValue = any

export type ManagementMode = "MANAGEMENT_MODE_UNSPECIFIED" | "DEVICE_OWNER" | "PROFILE_OWNER"

export type DeviceState = "DEVICE_STATE_UNSPECIFIED" | "ACTIVE" | "DISABLED" | "DELETED" | "PROVISIONING" | "LOST"
export type NonComplianceReason = "NON_COMPLIANCE_REASON_UNSPECIFIED" | "API_LEVEL" | "MANAGEMENT_MODE" | "USER_ACTION" | "INVALID_VALUE" | "APP_NOT_INSTALLED" | "UNSUPPORTED" | "APP_INSTALLED" | "PENDING" | "APP_INCOMPATIBLE" | "APP_NOT_UPDATED"
export type InstallationFailureReason = "INSTALLATION_FAILURE_REASON_UNSPECIFIED" | "INSTALLATION_FAILURE_REASON_UNKNOWN" | "IN_PROGRESS" | "NOT_FOUND" | "NOT_COMPATIBLE_WITH_DEVICE" | "NOT_APPROVED" | "PERMISSIONS_NOT_ACCEPTED" | "NOT_AVAILABLE_IN_COUNTRY" | "NO_LICENSES_REMAINING" | "NOT_ENROLLED" | "USER_INVALID"
export interface NonComplianceDetail {
    "settingName": string,
    "nonComplianceReason": NonComplianceReason,
    "packageName": string,
    "fieldPath": string,
    "currentValue": EnterpriseValue,
    "installationFailureReason": InstallationFailureReason
}
export interface UserFacingMessage {
    "localizedMessages": {
        [key: string]: string
    },
    "defaultMessage": string
}
export type UpdateStatus = "UPDATE_STATUS_UNKNOWN" | "UP_TO_DATE" | "UNKNOWN_UPDATE_AVAILABLE" | "SECURITY_UPDATE_AVAILABLE" | "OS_UPDATE_AVAILABLE"
export interface SystemUpdateInfo {
    "updateStatus": UpdateStatus,
    "updateReceivedTime": string
}
export interface SoftwareInfo {
    "androidVersion": string,
    "androidDevicePolicyVersionCode": number,
    "androidDevicePolicyVersionName": string,
    "androidBuildNumber": string,
    "deviceKernelVersion": string,
    "bootloaderVersion": string,
    "androidBuildTime": string,
    "securityPatchLevel": string,
    "primaryLanguageCode": string,
    "deviceBuildSignature": string,
    "systemUpdateInfo": SystemUpdateInfo
}
export interface HardwareInfo {
    "brand": string,
    "hardware": string,
    "deviceBasebandVersion": string,
    "manufacturer": string,
    "serialNumber": string,
    "model": string,
    "batteryShutdownTemperatures"?: number[],
    "batteryThrottlingTemperatures"?: number[],
    "cpuShutdownTemperatures"?: number[],
    "cpuThrottlingTemperatures"?: number[],
    "gpuShutdownTemperatures"?: number[],
    "gpuThrottlingTemperatures"?: number[],
    "skinShutdownTemperatures"?: number[],
    "skinThrottlingTemperatures"?: number[]
}
export type DisplayState = "DISPLAY_STATE_UNSPECIFIED	" | "OFF" | "ON" | "DOZE" | "SUSPENDED"
export interface Display {
    "name": string,
    "displayId": number,
    "refreshRate": number,
    "state": DisplayState,
    "width": number,
    "height": number,
    "density": number
}
export type ApplicationEventType = "APPLICATION_EVENT_TYPE_UNSPECIFIED" | "INSTALLED" | "CHANGED" | "DATA_CLEARED" | "REMOVED" | "REPLACED" | "RESTARTED" | "PINNED" | "UNPINNED"
export interface ApplicationEvent {
    "eventType": ApplicationEventType,
    "createTime": string
}
export type Severity = "SEVERITY_UNSPECIFIED" | "INFO" | "ERROR"
export interface KeyedAppState {
    "key": string,
    "severity": Severity,
    "message": string,
    "data": string,
    "createTime": string,
    "lastUpdateTime": string
}
export type ApplicationSource = "APPLICATION_SOURCE_UNSPECIFIED" | "SYSTEM_APP_FACTORY_VERSION" | "SYSTEM_APP_UPDATED_VERSION" | "INSTALLED_FROM_PLAY_STORE"
export type ApplicationState = "APPLICATION_STATE_UNSPECIFIED" | "REMOVED" | "INSTALLED"
export interface ApplicationReport {
    "packageName": string,
    "versionName": string,
    "versionCode": number,
    "events": ApplicationEvent[],
    "displayName": string,
    "packageSha256Hash": string,
    "signingKeyCertFingerprints": string[],
    "installerPackageName": string,
    "applicationSource": ApplicationSource,
    "state": ApplicationState,
    "keyedAppStates": KeyedAppState[]
}
export interface TelephonyInfo {
    "phoneNumber": string,
    "carrierName": string
}
export interface NetworkInfo {
    "imei": string,
    "meid": string,
    "wifiMacAddress": string,
    "networkOperatorName": string,
    "telephonyInfos": TelephonyInfo[]
}
export interface MemoryInfo {
    "totalRam": string,
    "totalInternalStorage": string
}
export type MemoryEventType = "MEMORY_EVENT_TYPE_UNSPECIFIED" | "RAM_MEASURED" | "INTERNAL_STORAGE_MEASURED" | "EXTERNAL_STORAGE_DETECTED" | "EXTERNAL_STORAGE_REMOVED" | "EXTERNAL_STORAGE_MEASURED"
export interface MemoryEvent {
    "eventType": MemoryEventType,
    "createTime": string,
    "byteCount": string
}
export type PowerManagementEventType = "POWER_MANAGEMENT_EVENT_TYPE_UNSPECIFIED" | "BATTERY_LEVEL_COLLECTED" | "POWER_CONNECTED" | "POWER_DISCONNECTED" | "BATTERY_LOW" | "BATTERY_OKAY" | "BOOT_COMPLETED" | "SHUTDOWN"
export interface PowerManagementEvent {
    "eventType": PowerManagementEventType,
    "createTime": string,
    "batteryLevel": number
}
export interface HardwareStatus {
    "createTime": string,
    "batteryTemperatures": number[],
    "cpuTemperatures": number[],
    "gpuTemperatures": number[],
    "skinTemperatures": number[],
    "fanSpeeds": number[],
    "cpuUsages": number[]
}
export type EncryptionStatus = "ENCRYPTION_STATUS_UNSPECIFIED" | "UNSUPPORTED" | "INACTIVE" | "ACTIVATING" | "ACTIVE" | "ACTIVE_DEFAULT_KEY" | "ACTIVE_PER_USER"
export interface DeviceSettings {
    "isDeviceSecure": boolean,
    "unknownSourcesEnabled": boolean,
    "developmentSettingsEnabled": boolean,
    "adbEnabled": boolean,
    "isEncrypted": boolean,
    "encryptionStatus": EncryptionStatus,
    "verifyAppsEnabled": boolean
}
export interface EnterpriseUser {
    "accountIdentifier": string
}
export type DevicePosture = "POSTURE_UNSPECIFIED" | "SECURE" | "AT_RISK" | "POTENTIALLY_COMPROMISED"
export type SecurityRisk = "SECURITY_RISK_UNSPECIFIED" | "UNKNOWN_OS" | "COMPROMISED_OS"
export interface PostureDetail {
    "securityRisk": SecurityRisk,
    "advice": UserFacingMessage[]
}
export interface SecurityPosture {
    "devicePosture": DevicePosture,
    "postureDetails": PostureDetail[]
}
export type Ownership = "OWNERSHIP_UNSPECIFIED" | "COMPANY_OWNED" | "PERSONALLY_OWNED"
export type CommonCriteriaModeStatus = "COMMON_CRITERIA_MODE_STATUS_UNKNOWN" | "COMMON_CRITERIA_MODE_DISABLED" | "COMMON_CRITERIA_MODE_ENABLED"
export interface CommonCriteriaModeInfo {
    "commonCriteriaModeStatus": CommonCriteriaModeStatus
}
export type PasswordQuality = "PASSWORD_QUALITY_UNSPECIFIED" | "BIOMETRIC_WEAK" | "SOMETHING" | "NUMERIC" | "NUMERIC_COMPLEX" | "ALPHABETIC" | "ALPHANUMERIC" | "COMPLEX" | "COMPLEXITY_LOW" | "COMPLEXITY_MEDIUM" | "COMPLEXITY_HIGH"
export type PasswordPolicyScope = "SCOPE_UNSPECIFIED" | "SCOPE_DEVICE" | "SCOPE_PROFILE"
export type RequirePasswordUnlock = "REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED" | "USE_DEFAULT_DEVICE_TIMEOUT" | "REQUIRE_EVERY_DAY"
export interface PasswordRequirements {
    "passwordMinimumLength": number,
    "passwordMinimumLetters": number,
    "passwordMinimumLowerCase": number,
    "passwordMinimumNonLetter": number,
    "passwordMinimumNumeric": number,
    "passwordMinimumSymbols": number,
    "passwordMinimumUpperCase": number,
    "passwordQuality": PasswordQuality,
    "passwordHistoryLength": number,
    "maximumFailedPasswordsForWipe": number,
    "passwordExpirationTimeout": string,
    "passwordScope": PasswordPolicyScope,
    "requirePasswordUnlock": RequirePasswordUnlock
}
export interface EnterpriseDevice extends ObjectType<EnterpriseDevice> {
    "id": string,
    "deviceId": string,
    "alias"?: string,
    "licenseUUID"?: string,
    "licenseInformation"?: License | undefined,
    "consent"?: string,
    "signedIn"?: boolean,
    "username"?: string,
    "inventory"?: ObjectInventory
    "isInExhaustedSunPools"?:string[]

    "name": string
    "userName"?: string,
    "managementMode"?: ManagementMode,
    "state"?: DeviceState,
    "appliedState"?: DeviceState,
    "policyCompliant"?: boolean,
    "nonComplianceDetails"?: NonComplianceDetail[],
    "enrollmentTime"?: string,
    "lastStatusReportTime"?: string,
    "lastPolicyComplianceReportTime"?: string,
    "lastPolicySyncTime"?: string,
    "policyName"?: string,
    "appliedPolicyName"?: string,
    "appliedPolicyVersion"?: string,
    "apiLevel"?: number,
    "enrollmentTokenData"?: string,
    "enrollmentTokenName"?: string,
    "disabledReason"?: UserFacingMessage,
    "softwareInfo"?: SoftwareInfo,
    "hardwareInfo"?: HardwareInfo,
    "displays"?: Display[],
    "applicationReports"?: ApplicationReport[],
    "previousDeviceNames"?: string[],
    "networkInfo"?: NetworkInfo,
    "memoryInfo"?: MemoryInfo,
    "memoryEvents"?: MemoryEvent[],
    "powerManagementEvents"?: PowerManagementEvent[],
    "hardwareStatusSamples"?: HardwareStatus[],
    "deviceSettings"?: DeviceSettings,
    "user"?: EnterpriseUser,
    "systemProperties"?: {
        [key: string]: string
    },
    "securityPosture"?: SecurityPosture,
    "ownership"?: Ownership,
    "commonCriteriaModeInfo"?: CommonCriteriaModeInfo,
    "appliedPasswordPolicies"?: PasswordRequirements[],
    "zeroTouchDevice"?:boolean
    "lostMode"?:boolean
}

class EnterpriseDevices extends ObjectType<EnterpriseDevice> {
    constructor(payload: ObjectTypePayload<EnterpriseDevice>) {
        super(payload)
        this.queries.getObjectsFromApi = async (accountId,customerId?,props?,updateLocalStore=false) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = function() {
                 
                return store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5 
            }()
            if((store && isRequestable) || store == undefined) {
                if(store) { 
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                let result : EnterpriseDevice[] = []
                let pageSize = props?.find((prop) => { return prop.property == "pageSize" })?.value as string | undefined
                if (pageSize == undefined) {
                    props?.push({
                        "property": "pageSize",
                        "value": "75"
                    })
                }
                const propertiesString: string = props ? this.getPropertiesString(props) : ""
                let batchLoop = async (batchProps: string, pageToken?: string) => {
                    let response = await requestHandler.request("GET", "/sms-mgt-api/api/" + apis.getApiVersionForFeature('androidEnterprise') + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/android/emm/enterprise/devices/" + propertiesString + (pageToken ? "&nextPageToken=" + pageToken : ""))
                    if(response.emm != undefined) {
                        (result as EnterpriseDevice[]).push(...response.emm)
                    }
                    else if(response.devices != undefined) {
                        (result as EnterpriseDevice[]).push(...response.devices)
                    }

                    if (response.nextPageToken != undefined) {
                        await batchLoop(batchProps, response.nextPageToken)
                    }
                }
                try {
                    await batchLoop(propertiesString)
                    if(store) { store.gettingObjects = false }
                }
                catch(e) {
                    if(store) { store.gettingObjects = false }
                    throw e
                }
                return result
            }
            else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }
    }

}

const enterpriseDevices = new EnterpriseDevices({
    "productType": "mobileSecurity",
    "objectType": "enterpriseDevices",
    "slug": "enterpriseDevices",
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.count",
        // GET
        "getObjectListResponseProperty": "emm",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/android/emm/enterprise/devices",
        // Update
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/android/emm/enterprise/devices/{objectId}",
    }
})
export default enterpriseDevices