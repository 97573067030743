<script setup lang="ts">
import { type Connection } from '@/classes/objectTypes/mobileSecurity/windows/windowsVpns';
import { computed, ref, watch } from 'vue';
import { T } from "@/classes/i18n"
import InputPin from '../inputtypes/input-pin.vue';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';
import { MutationTypes, useStore } from '@/store/vuex.store';
import getterHelpers from '@/helpers/helpers.getters';
import timeHelpers from '@/helpers/helpers.time';
import requestHandler from '@/queries/requests';
import config from '@/classes/config';
import tenantHelpers from '@/helpers/helpers.tenants';

const props = defineProps<{
    properties: {
        connection: Connection,
        vpnId: string
    }
}>();

const pin = ref("")
const errorMessage = ref("")

const activeAccountId = computed(() => { return getterHelpers.useStore().getters.getActiveAccountId })


const submit = async () => {
    console.log("Submit")
    if(pin.value && pin.value.length == 6) {
        try {
            errorMessage.value = ""
            useStore().getters.getActiveModal().buttons[1].disabled = true
            useStore().getters.getActiveModal().buttons[0].disabled = true
            useStore().getters.getActiveModal().buttons[1].loading = true
        
            await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value) + "/windows/devices/" + props.properties.vpnId + "/jobs/vpn", {
                "name": "connection-start",
                "data": {
                    "connectionId": props.properties.connection.id,
                    "pin": pin.value
                }
            })
            await timeHelpers.sleep(2000)
            throw "Some generic errorMessage"
        }
        catch(e : unknown) {
            useStore().getters.getActiveModal().buttons[1].disabled = false
            useStore().getters.getActiveModal().buttons[0].disabled = false
            useStore().getters.getActiveModal().buttons[1].loading = false
            
            if(e instanceof Error) {
                errorMessage.value = e.message
            }
            else if(typeof e == "string") {
                errorMessage.value = e
            }
        }
    }
}

// Watch
watch(pin, () => {
    if(pin.value && pin.value.length == 6) {
        useStore().getters.getActiveModal().buttons[1].disabled = false
    }
    else {
        useStore().getters.getActiveModal().buttons[1].disabled = true
    }
})

// Lifecycles
onMounted(() => {
    useStore().commit(MutationTypes.addSubscriptionHook, {
        "accountId": activeAccountId.value,
        "hookKey": "enterWireguardPin",
        "hookFunction": (message: any) => {
            const messageTopic = message?.topic || message?.data?.topic || ""
            const clientContext = message?.data?.clientContext || ""
            if (typeof messageTopic == "string" && messageTopic.includes("/windows/vpn/") && clientContext) {
                console.log(messageTopic)
                console.log(clientContext)
                // loading.value = false
            }
        }
    })
})
onUnmounted(() => {
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        "accountId": activeAccountId.value,
        "hookKey": "enterWireguardPin",
    })
})

// Expose
defineExpose({
    submit
})

</script>
<template>
    <div class="flexrow" style="justify-content: center; flex-wrap: wrap;">
        <span class="col-xs-24"
            v-html="T('Die WireGuard-Verbindung auf dem Securepoint VPN Client ist durch eine PIN geschützt.') + ' ' +
                T('Diese Einstellung wird direkt auf dem Client vorgenommen.') + ' ' +
                T(`Details finden Sie in unserem <a href='https://wiki.securepoint.de/VPN/VPN-Client' target='_blank'>Wiki</a>.`)">
        </span>
    </div>
    <div class="flexrow margin-xs-t-3" style="justify-content: center;">
        <InputPin v-model="pin" :numbers-only="true" :pin-length="6" :hide-pin-on-mount="true"
            :can-toggle-visibility="true" :focus-on-mount="true"></InputPin>
    </div>
    <div class="flexrow margin-xs-t-1" style="justify-content: center;">
        <p class="input-description">
            {{ T("Diese Verbindung erfordert eine PIN zur Authentifizierung.") }}
        </p>
    </div>
    <div class="flexrow" style="justify-content: center;" v-if="errorMessage">
        <p class="label bg-red">
            {{ T(errorMessage) }}
        </p>
    </div>
</template>