import { useVue } from "@/app"
import i18n from "@/classes/i18n"
import objectStores from "@/classes/init"
import dialogs from "@/dialogs/dialogs"
import tenantHelpers from "@/helpers/helpers.tenants"
import queries from "@/queries/queries"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { MutationTypes } from "@/store/vuex.store"
import { nextTick } from "vue" 
import useRouterStore from "./routerStore"
import getterHelpers from "@/helpers/helpers.getters"


interface Router {
    routes: any[]
    mode: string | null
    root: string
    lastFragment: string
    interval: any,
    config(options: any): void
    getFragment(): string
    clearSlashes(path: string): string
    add(re: RegExp, handler: Function): any
    remove(param: any): any
    flush(): any
    check(f: string): any
    listen(): any
    navigate(path: string): any

}
export const useRouter = () => {
    return Router || undefined
}
var Router = <Router>{
    routes: [],
    mode: null,
    root: '/',
    lastFragment: '',
    interval:<any>undefined,
    config: function (options:any) {
        this.mode = options && options.mode && options.mode == 'history'
            && !!(history.pushState) ? 'history' : 'hash';
        this.root = options && options.root ? '/' + this.clearSlashes(options.root) + '/' : '/';
        return this;
    },
    getFragment: function () {
        var fragment = '';
        if (this.mode === 'history') {
            fragment = this.clearSlashes(decodeURI(location.pathname + location.search));
            fragment = fragment.replace(/\?(.*)$/, '');
            fragment = this.root != '/' ? fragment.replace(this.root, '') : fragment;
        } else {
            var match = window.location.href.match(/#(.*)$/);
            fragment = match ? match[1] : '';
        }
        return this.clearSlashes(fragment) as string;
    },
    clearSlashes: function (path:string) {
        return path.toString().replace(/\/$/, '').replace(/^\//, '');
    },
    add: function (re: RegExp|string, handler: Function) {
        if (typeof re == 'function') {
            handler = re;
            re = '';
        }
        this.routes.push({ re: re, handler: handler });
        return this;
    },
    remove: function (param: any) {
        for (var i = 0, r; i < this.routes.length, r = this.routes[i]; i++) {
            if (r.handler === param || r.re.toString() === param.toString()) {
                this.routes.splice(i, 1);
                return this;
            }
        }
        return this;
    },
    flush: function () {
        this.routes = [];
        this.mode = null;
        this.root = '/';
        this.lastFragment = '';
        return this;
    },
    check: function (f: string) {
        var fragment = f || this.getFragment();
        this.routes.some(function (route) {
            var match = (fragment == route.re) || (route.re.test && route.re.test(fragment));
            if (match) {
                route.handler.apply({}, [fragment, route.re]);
                return true;
            }
        });
        return this;
    },
    listen: function () {
        var self = this;
        var current = self.getFragment();
        this.lastFragment = current;

        var fn = function () {
            if (current !== self.getFragment()) {
                self.lastFragment = current;
                current = self.getFragment();
                self.check(current);
            }
        }

        if ("onhashchange" in window) {
            (function (onhashchange) {
                window.onhashchange = function () {
                    if (typeof onhashchange == "function") {
                        //@ts-ignore
                        onhashchange.apply(window, arguments)
                    }
                    fn()
                }
            })(window.onhashchange)
        }
        else {

            clearInterval(this.interval);
            this.interval = setInterval(fn, 50);
        }

        return this;
    },
    navigate: function (path: string) {
        path = path ? path.replace(/^#/, '') : '';
        if (this.mode === 'history') {
            //@ts-ignore
            history.pushState(null, null, this.root + this.clearSlashes(path));
        } else {
            window.location.hash = path;
        }
        return this;
    }
};

const router = Router
router.config({ mode: 'hash', root: "/sms/" })

let r = <any>router
r.add = (function (add) {
    return function (this: any) {
        add.apply(this, arguments)
        return this
    }
})(r.add)

export module navigation {
    export function openSubmenu(submenuName: string, event?: any): void {
        if (event) {
            event.preventDefault();
        }
        if (useVue()?.$refs?.navigation) {
            (<any>useVue().$refs.navigation).openSubmenu(submenuName)
        }
        else {
            setTimeout(function () {
                navigation.openSubmenu(submenuName, event || undefined)
            }, 1000)
        }
    }
    export function closeSubmenu(submenuName: string, event?: any): void {
        if (event) {
            event.preventDefault();
        }
        if (useVue()?.$refs?.navigation) {
            (<any>useVue().$refs.navigation).closeSubmenu(submenuName)
        }
        else {
            setTimeout(function () {
                navigation.closeSubmenu(submenuName, event || undefined)
            }, 1000)
        }
    }
    export function toggleSubmenu(submenuName: string, event?: any): void {
        if (event) {
            event.preventDefault();
        }
        if (useVue()?.$refs?.navigation) {
            (<any>useVue().$refs.navigation).toggleSubmenu(submenuName)
        }
        else {
            setTimeout(function () {
                navigation.toggleSubmenu(submenuName, event || undefined)
            }, 1000)
        }
    }
    export function toggleLog(): void {
        getterHelpers.useVue()?.toggleLog()
    }
}


router
    .add(/^login$/, async function () {
        useRouterStore().setRouter("login")
    })
    .add(/^login-rsp-2fa-error$/, async function () {
        useRouterStore().setRouter("login","rsp2faError")
    })
    .add(/logout/, async function () {
        queries.unifiedSecurity.logout()
    })
    .add(/^logout/, async function () {
        queries.unifiedSecurity.logout()
    })
    .add(/^show-tenant-([^-]+?)-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("tenant-dashboard")
    })
    .add(/^show-tenant-(.+?)-my-mobile-security-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("msMyDashboard")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('msDashboardTree')
    })
    .add(/^show-tenant-(.+?)-mobile-security-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("msDashboard")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('msDashboardTree')
    })
    .add(/^show-tenant-(.+?)-inventory-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory")
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-inventory-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory","editVPN",objectId)
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-inventory-device-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editDevice", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-inventory-android-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editAndroid", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-inventory-enterprise-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editEnterprise", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-inventory-usc-utm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editUscUtm", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-inventory-reportingUtm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editReportingUtm", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-vpp-assets-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vppassets")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-windows-enrollmenttokens-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsEnrollmentTokens")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^add-tenant-(.+?)-windows-enrollmenttoken$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsEnrollmentTokens","add")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^edit-tenant-(.+?)-windows-enrollmenttoken-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsEnrollmentTokens", "edit", objectId)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-windows-vpns-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsVpns")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^add-tenant-(.+?)-windows-vpn$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsVpns", "add")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-windows-vpns-(.+?)-details$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsVpns","details",objectId)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-vpp-asset-(.+?)-details$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vppassets", "details", objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })

    .add(/^show-tenant-(.+?)-ios-apps-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-ios-apps-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
    })
    .add(/^add-tenant-(.+?)-ios-app$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps", "add")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^edit-tenant-(.+?)-ios-app-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps", "edit", objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })

    .add(/^show-tenant-(.+?)-apps-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-apps-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
    })
    .add(/^add-tenant-(.+?)-app$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps", "add")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^edit-tenant-(.+?)-app-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps", "edit", objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^delete-tenant-(.+?)-app-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps")
        dialogs.mobileSecurity.renderDeleteAppDialog(tenantDomain, objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-roles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("roles")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-roles-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vppassets", "details", undefined,undefined,objectId)
        navigation.openSubmenu('general')
    })
    .add(/^add-tenant-(.+?)-role$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("roles", "add")
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-role-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("roles", "edit", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-licenses-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("licenses")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-license-(.+?)-details$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("licenses","details",objectId)
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-general-settings$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("generalSettings")
        navigation.openSubmenu('general')
    })
    .add(/^user-sync-azure-error-tenant-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("generalSettings")
        navigation.openSubmenu('general')
        function openDialog() {
            if (useVue().$refs.unifiedSecuritySettings) {
                useVue().$refs.unifiedSecuritySettings.getFailedAzureAdDialog()
            }
            else {
                setTimeout(function () { openDialog() }, 500)
            }
        }
        openDialog()
    })
    .add(/^user-sync-azure-success-tenant-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("generalSettings")
        navigation.openSubmenu('general')
        function openDialog() {
            if (useVue().$refs.unifiedSecuritySettings) {               
                useVue().$refs.unifiedSecuritySettings.getConfirmAzureAdDialog()
            }
            else {
                setTimeout(function () { openDialog() }, 500)
            }
        }
        openDialog()
    })

    .add(/^show-tenant-(.+?)-users-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-users-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", undefined,undefined,undefined,searchString)
        navigation.openSubmenu('general')
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
    })
    .add(/^add-tenant-(.+?)-user$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", "add")
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-user-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users","edit",objectId)
        navigation.openSubmenu('general')
    })
    .add(/^enroll-tenant-(.+?)-enterprise-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("enterpriseDevices")
        dialogs.mobileSecurity.enrollDeviceDialog(tenantDomain.replace(".sms",""),"enterpriseDevices")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-enterprise-devices-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("enterpriseDevices")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-enterprise-devices-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("enterpriseDevices", undefined,undefined,undefined,searchString)
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-enterprise-device-(.+?)-details-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let deviceId = template.match(re)![2]
        let tab = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("enterpriseDevices", "details", deviceId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^enroll-tenant-(.+?)-android-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices")
        dialogs.mobileSecurity.enrollDeviceDialog(tenantDomain.replace(".sms", ""),"enterpriseDevices")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-android-devices$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-android-devices-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices", undefined,undefined,undefined,searchString)
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-android-device-(.+?)-details-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let deviceId = template.match(re)![2]
        let tab = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices", "details", deviceId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-ios-devices-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-ios-devices-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices",undefined,undefined,undefined,searchString)
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^set-tenant-(.+?)-device-(.+?)-ownership$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let deviceId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("devices")
        dialogs.mobileSecurity.assignDeviceOwnership(tenantDomain.replace('.sms', ''), 'mobileSecurity', 'devices', deviceId)
        navigation.openSubmenu('mobSec')
    })
    .add(/^show-tenant-(.+?)-ios-device-(.+?)-details-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let deviceId = template.match(re)![2]
        let tab = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices","details",deviceId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^enroll-tenant-(.+?)-ios-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")
        dialogs.mobileSecurity.enrollDeviceDialog(tenantDomain.replace(".sms", "") ,"iosDevices")
        navigation.openSubmenu('mobSec')
    })
    .add(/^invite-tenant-(.+?)-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")

        function openInviteDialog() {
            if (useVue().$refs.itemlist) {
                useVue().$refs.itemlist.renderInviteDeviceDialog()
            }
            else {
                setTimeout(function () {
                    openInviteDialog()
                }, 500)
            }
        }
        nextTick(function () {
            {
                openInviteDialog()
            }
        })
        navigation.openSubmenu('mobSec')

    })
    .add(/^download-tenant-(.+?)-device-(.+)-mobileconfig$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let deviceId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")
        queries.mobileSecurity.downloadMobileConfig(tenantDomain, deviceId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })

    .add(/^show-tenant-(.+?)-account-info$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("account-info")
    })
    .add(/^show-tenant-(.+?)-enterprise-dialog$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("mobileSecuritySettings")

        function openDialog() {
            if (useVue().$refs.mobileSecuritySettings) {
                useVue().$refs.mobileSecuritySettings.getEnterpriseDialog()
            }
            else {
                setTimeout(function () { openDialog() }, 500)
            }
        }
        openDialog()
    })
    .add(/^show-tenant-(.+?)-zero-touch-dialog$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("mobileSecuritySettings")
        function openDialog() {
            if (useVue().$refs.mobileSecuritySettings) {
                useVue().$refs.mobileSecuritySettings.getConfirmZeroTouchDialog()
            }
            else {
                setTimeout(function () { openDialog() }, 500)
            }
        }
        openDialog()
    })
    .add(/^show-tenant-(.+?)-privacylevel-config$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("privacy")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-audit-log$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("auditlog")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-dep-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-dep-profiles-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles", undefined,undefined,undefined,searchString)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
        
        nextTick(() => { tenantHelpers.setItemlistSearchValue(searchString) })
    })
    .add(/^add-tenant-(.+?)-dep-profile$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles", "add")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^edit-tenant-(.+?)-dep-profile-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles", "edit",objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-android-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-android-profiles-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles",undefined,undefined,undefined,searchString)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
        nextTick(() => { tenantHelpers.setItemlistSearchValue(searchString) })
    })
    .add(/^add-tenant-(.+?)-android-profile$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles", "add")
        navigation.openSubmenu('mobSec')
    })
    .add(/^edit-tenant-(.+?)-android-profile-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles", "edit", decodeURI(objectId))
        navigation.openSubmenu('mobSec')
    })
    .add(/^show-tenant-(.+?)-ios-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-ios-profiles-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles",undefined,undefined,undefined,searchString)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
        nextTick(() => { tenantHelpers.setItemlistSearchValue(searchString) })
    })
    .add(/^add-tenant-(.+?)-ios-profile$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles", "add")
        navigation.openSubmenu('mobSec')
    })
    .add(/^edit-tenant-(.+?)-ios-profile-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles", "edit", objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')

    })
    .add(/^show-tenant-(.+?)-vpns-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns")
        navigation.openSubmenu('mobSec')
    })
    .add(/^show-tenant-(.+?)-vpns-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns", undefined,undefined,undefined,searchString)
        navigation.openSubmenu('mobSec')
    })

    .add(/^show-tenant-(.+?)-secureDnsProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles")
        navigation.openSubmenu('secureDns')
    })
    .add(/^show-tenant-(.+?)-secureDnsProfiles-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles", undefined,undefined,undefined,searchString)
        navigation.openSubmenu('secureDns')
    })
    .add(/^add-tenant-(.+?)-secureDnsProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles", "add")
        navigation.openSubmenu('secureDns')
    })
    .add(/^edit-tenant-(.+?)-secureDnsProfiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles","edit",objectId)
        navigation.openSubmenu('secureDns')
    })
    .add(/^show-tenant-(.+?)-secureDns-stats?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDns-stats")
 
        navigation.openSubmenu('secureDns')
    })
    .add(/^show-tenant-(.+?)-secureDns-logs?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDns-logs")
 
        navigation.openSubmenu('secureDns')
    })

    .add(/^show-tenant-(.+?)-uscZtProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-uscZtProfiles-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles", undefined,undefined,undefined,searchString)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^add-tenant-(.+?)-uscZtProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles", "add")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^edit-tenant-(.+?)-uscZtProfiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles","edit",objectId)
        navigation.openSubmenu('unifiedSecurityConsole')
    })

    .add(/^show-tenant-(.+?)-windows-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-windows-profiles-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles", undefined,undefined,undefined,searchString)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^add-tenant-(.+?)-windows-profiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles", "add")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^edit-tenant-(.+?)-windows-profiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles","edit",objectId)
        navigation.openSubmenu('unifiedSecurityConsole')
    })

    .add(/^add-tenant-(.+?)-vpn$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns", "add")
        navigation.openSubmenu('mobSec')
    })
    .add(/^edit-tenant-(.+?)-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns","edit",objectId)
        navigation.openSubmenu('mobSec')
    })
    .add(/^delete-tenant-(.+?)-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns")
        dialogs.mobileSecurity.renderDeleteVPNDialog(tenantDomain, objectId)
        navigation.openSubmenu('mobSec')
    })
    .add(/^show-tenant-(.+?)-certificates-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("certificates")
        navigation.openSubmenu('mobSec')
    })
    .add(/^add-tenant-(.+?)-certificate$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("certificates")
        dialogs.mobileSecurity.renderAddCertificateDialog(tenantDomain)
        navigation.openSubmenu('mobSec')
    })
    .add(/^show-tenant-([^-]+?)-mobile-security-settings$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("mobileSecuritySettings")
    })
    .add(/^show-tenant-(.+?)-stats-dashboard?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("stats-dashboard")
        if (/^deanonymize-tenant/.test(Router.lastFragment)) {
            return
        }
        navigation.openSubmenu('mobSec')
    })
    .add(/^show-tenant-(.+?)-stats-dashboard-(.+?)-(.+?)?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchPeriod = template.match(re)![2]
        let searchRelate = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("stats-dashboard")

        const setSearch = () => {
            if (useVue().$refs.statspage) {
                useVue().$refs.statspage.search.period = searchPeriod
                useVue().$refs.statspage.search.type = searchRelate
            }
            else {
                // component not yet rendered, retry in 1s
                setTimeout(() => {
                    setSearch()
                },1000)
            }
        }
        setSearch()

        if (/^deanonymize-tenant/.test(Router.lastFragment)) {
            return
        }
        navigation.openSubmenu('mobSec')
    })
    .add(/^deanonymize-tenant-(.+?)-device-(.+?)?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let anonymousDeviceId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        dialogs.mobileSecurity.deanonymizeDevice(tenantDomain.replace('.sms', ''), 'devices', anonymousDeviceId)
    })
    .add(/^show-tenant-(.+?)-notifications-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-notifications-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications",undefined,undefined,undefined,search)
        navigation.openSubmenu('general')
    })
    .add(/^add-tenant-(.+?)-notification$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications", "add")
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-notification-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications", "edit", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^delete-tenant-(.+?)-notification-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications")
        dialogs.unifiedSecurity.renderDeleteNotificationDialog(tenantDomain, objectId)
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-zerotouchconfigurations-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("zeroTouchConfigurations")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-zerotouchconfigurations-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("zeroTouchConfigurations", undefined, undefined, undefined,searchString)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
        nextTick(() => { tenantHelpers.setItemlistSearchValue(searchString) })
    })
    .add(/^add-tenant-(.+?)-zerotouchconfiguration$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let customerId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("zeroTouchConfigurations", "add", undefined, customerId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^edit-tenant-(.+?)-zerotouchconfiguration-(.+)-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let customerId = template.match(re)![2]
        let objectId = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("zeroTouchConfigurations","edit",objectId,customerId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^dashboard/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("dashboard")
        let firstAccountId = Object.keys(useStore().state.session.accounts)[0]
        router.navigate("show-tenant-" + tenantHelpers.getTenantDomain(firstAccountId) + "-dashboard")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('android')
    })
    .add(/^show-tenant-(.+?)-my-usc-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscMyDashboard")
        navigation.openSubmenu('unifiedSecurityConsole')
        navigation.openSubmenu('uscDashboardTree')
    })
    .add(/^show-tenant-(.+?)-usc-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscDashboard")
        navigation.openSubmenu('unifiedSecurityConsole')
        navigation.openSubmenu('uscDashboardTree')
    })
    .add(/^show-tenant-(.+?)-usc-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-usc-profiles-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles", "edit", undefined,undefined,search)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^edit-tenant-(.+?)-usc-profiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles", "edit",objectId)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^add-tenant-(.+?)-usc-profiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles","add")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-usc-utms-commandcenter$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscCommandCenter")
    })
    .add(/^show-tenant-(.+?)-usc-utms-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-usc-utms-dashboard-action-(.+)-utm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let actionType = template.match(re)![2]
        let utmId = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms")
        navigation.openSubmenu('unifiedSecurityConsole')

        function doActionOnInitializedAccount() {
            if(useVue()?.activeAccount?.initialized) {
                if (actionType == "websession") {
                    objectStores.uscUtms.dialogs.renderNewWebSessionDialog(tenantHelpers.getAccountId(tenantDomain), utmId)
                }
            }
            else {
                setTimeout(() => { doActionOnInitializedAccount() },1000)
            }
        }
        doActionOnInitializedAccount() 
    })
    .add(/^show-tenant-(.+?)-usc-utms-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms", undefined,undefined,undefined,search)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-usc-utms-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms", "details", objectId)
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-utms-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms")
        navigation.openSubmenu('laas')
    })
    .add(/^show-tenant-(.+?)-utms-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms",undefined,undefined,undefined,search)
        navigation.openSubmenu('laas')
    })
    .add(/^edit-tenant-(.+?)-utm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms","edit",objectId)
        navigation.openSubmenu('laas')
    })

    .add(/^show-tenant-(.+?)-usrutms-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms")
        navigation.openSubmenu('laas')
    })
    .add(/^show-tenant-(.+?)-usrutms-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms", undefined, undefined, undefined, search)
        navigation.openSubmenu('laas')
    })
    .add(/^edit-tenant-(.+?)-usrutm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms", "edit", objectId)
        navigation.openSubmenu('laas')
    })

    .add(/^show-tenant-(.+?)-reports-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("reports")
        navigation.openSubmenu('laas')
    })
    .add(/^show-tenant-(.+?)-reports-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("reports", undefined, undefined,undefined,search)
        navigation.openSubmenu('laas')
    })
    .add(/^edit-tenant-(.+?)-report-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("reports", "edit", objectId)
        navigation.openSubmenu('laas')
    })
    .add(/^show-tenant-(.+?)-warnings$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("warnings")
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-branding$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("branding")
    })
    .add(/^show-tenant-(.+?)-user-(.+?)-details-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        let tab = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", "details", objectId)
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-homescreenlayouts-dashboard/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("homescreenlayouts")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^add-tenant-(.+?)-homescreenlayout/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("homescreenlayouts", "add")
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^edit-tenant-(.+?)-homescreenlayout-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("homescreenlayouts", "edit", objectId)
        navigation.openSubmenu('mobSec')
        navigation.openSubmenu('ios')
    })
    .add(/^show-tenant-(.+?)-apikeys-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apiKeys")
        navigation.openSubmenu('general')
    })
    .add(/^add-tenant-(.+?)-apikey$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apiKeys", "add")
        navigation.openSubmenu('general')
    })
    .add(/^edit-tenant-(.+?)-apikey-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apiKeys","edit",objectId)
        navigation.openSubmenu('general')
    })
    .add(/^show-tenant-(.+?)-usc-vpn-configuration$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("unifiedNetworkConfiguration")
        navigation.openSubmenu('unifiedSecurityConsole')
    })
    .add(/^show-tenant-(.+?)-usc-vpn-configuration-highlight-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let rowId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("unifiedNetworkConfiguration")
        navigation.openSubmenu('unifiedSecurityConsole')
        function doActionOnInitializedAccount() {
            if(useVue()?.activeAccount?.initialized && useVue()?.$refs.unifiedNetwork) {
                useVue()?.$refs.unifiedNetwork?.highLightRowById(rowId)
            }
            else {
                setTimeout(() => { doActionOnInitializedAccount() },1000)
            }
        }
        doActionOnInitializedAccount() 
    })
    .add(/^sandbox/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("sandbox")
    })
    .add(/^docs/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("docs")
    })
    .add(/^pluginLicenses/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("pluginLicenses")
    })
    .add(/^refresh/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("refreshing")
    })
    .add(/^404$/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("404")
    })
    .add(/^404-(.+?)$/, async (template: string, re: RegExp) => {
        let objectId = template.match(re)![1]
        useRouterStore().setRouter("404",undefined,objectId)
    })
    .add(/^(.+?)$/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("404")
    })

export default router