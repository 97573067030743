const en = {
    "XPlease enter": "Please enter",
    "Xto confirm":"to confirm",
    "XLink":"Link",
    "Xo'clock":"o'clock",
    "The apple servers have reported following error:":"Die Apple Server haben folgenden Fehler zurückgeliefert:",
    "Automatic VPP license assignment":"Automatische VPP Lizenzzuweisung",
    "Choose wether the VPP app license should be automatically assigned to the device or not.":"Wählen Sie aus, ob die VPP-App-Lizenz automatisch dem Gerät zugewiesen werden soll oder nicht.",
    "A profile with this name already exists. Saving this profile would overwrite the existing one.":"Ein Profil mit diesem Namen existiert bereits. Speichern dieses Profils würde das bestehende überschreiben.",
    "Productselection":"Produktauswahl",
    "Inventory list":"Inventarliste",
    "xFrom":"From",
    "xState": "State",
    "must match a schema in anyOf":"must match one of the points mentioned",
    "Resuming job at label dryrun":"Resuming Job at \"Dryrun\".",
    "Resuming job at label reboot": "Resuming Job at \"Reboot\".",
    "Resuming job at label finalize": "Resuming Job at \"Finalize\".",
    "Job is parked with label dryrun. Waiting for resume response.": "Job is parked at \"Dryrun\". Waiting for resume response.",
    "Job is parked with label reboot. Waiting for resume response.": "Job is parked at \"Reboot\". Waiting for resume response.",
    "Job is parked with label finalize. Waiting for resume response.": "Job is parked at \"Finalize\". Waiting for resume response.",
    "Job is done [Update is done]": "Job is done (Update)",
    "xDashboard": "Dashboard",
    "Benutzerdaten sind gespeichert": "Saved Credentials",
    "Favorit": "Favorite",
    "Zum Starten wird ein PIN benötigt": "A PIN is needed to start the connection",
    "Letzte Änderung": "Last Change",
    "Log von": "Log of",
    "Hole Logeinträge...": "Getting log entrys...",
    "Das Verbindungs-Log des VPN-Clients kann nach den Schweregraden gefiltert oder sortiert werden. Die Schweregrade sind:": "The connection log of the VPN client can be filtered or sorted according to the severity levels. The severity levels are:",
    "Information(Info)": "Information(Info)",
    "Warnungen(Warning)": "Warnings(Warning)",
    "Fehler(Error)": "Error(Error)",
    "Datum": "Date",
    "Schweregrad": "Severity",
    "Nachricht": "Message",
    "WSC-Risiko": "WSC risk",
    "WSC-Gefährdung": "WSC danger",
    "Keine Aktion erforderlich": "No action required",
    "Status wird nicht von der WSC überwacht": "Status is not monitored by the WSC",
    "Computer ist möglicherweise gefährdet": "Computer may be at risk",
    "WSC schützt den Computer nicht aktiv": "WSC does not actively protect the computer",
    "Firewall": "Firewall",
    "Automatische Updates": "Automatic updates",
    "Antivirus": "Antivirus",
    "Internet-Einstellungen": "Internet settings",
    "WSC-Service-Status": "WSC service status",
    "UAC-Einstellungen": "UAC settings",
    "Fehlende Berechtigung":"Missing permission",
    "PIN deaktiviert":"PIN disabled",
    "PIN gesperrt":"PIN locked",
    "Die Websession PIN ist aufgrund mehrerer Fehleingaben gesperrt. Bitte schalten Sie die Websession PIN auf der Core-UTM wieder frei.":"The websession PIN is locked due to several incorrect entries. Please unlock the websession PIN on the Core-UTM again.",
    "Die Websession PIN ist auf der UTM deaktiviert. Bitte aktivieren Sie die PIN um die Konfiguration veröffentlichen zu können.":"The websession PIN is deactivated on the UTM. Please activate the PIN to be able to publish the configuration.",
    "Die Core-UTM ist offline. Bitte stellen Sie sicher dass die Core-UTM erreichbar ist.":"The Core-UTM is offline. Please make sure that the Core-UTM is accessible.",
    "Die Core-UTM ist nicht für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für die Core-UTM.":"The Core-UTM is not enabled for the Unified Network Console. Please activate the Unified Network Console for the Core-UTM.",
    "Nicht alle UTMs in der Konfiguration sind online. Unter Umständen müssen Sie die Veröffentlichung der Konfiguration erneut durchführen, wenn die betroffenen UTMs wieder online sind.":"Not all UTMs in the configuration are online. You may have to publish the configuration again when the affected UTMs are online again.",
    "Nicht alle UTMs in der Konfiguration sind für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für alle UTMs aus dieser Konfiguration, um die Funktionalität auf allen UTMs nutzen zu können.":"Not all UTMs in the configuration are enabled for the Unified Network Console. Please activate the Unified Network Console for all UTMs in this configuration in order to be able to use the functionality on all UTMs.",
    "Es fehlen Satelliten oder Roadwarrior.":"Missing satellites or roadwarrior.",
    "Es gibt nichts zu veröffentlichen.":"There is nothing to publish.",
    "Diese UTM wird von der Unified Network Console unterstützt und befindet sich in einer Konfiguration":"This UTM is supported by the Unified Network Console and is in a configuration",
    "Verfügbar":"Available",
    "Nicht verfügbar":"Unavailable",
    "CPU Auslastung:": "CPU utilization:",
    "Arbeitsspeicherinformationen": "Working memory information",
    "Speicher": "Memory",
    "Diagnose der Verbindung": "Connection diagnosis",
    "Remote-Host aus der Konfiguration": "Remote host of the configuration",
    "Remote-Port aus der Konfiguration": "Remote Port of the configuration",
    "Aufgelöste IP des Remote-Hostes": "Resolved ip of the remote host",
    "Remote-Host und Port sind erreichbar": "Remote host and port are reachable",
    "Das Abrufen der Verbindungsinformationen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.": "Fetching the connection information takes unusually long. Please check the connection status of your VPN.",
    "Öffentliche IP des Clients": "Public ip of the client",
    "Öffentliche IP": "Public IP",
    "Erreichbar": "Reachable",
    "Hole Verbindungsinformationen...": "Fetching connection information...",
    'Vergeben Sie einen Alias (wie z. B. "LG1"), um generierte Objekte auf der UTM leichter zuordnen zu können. (max 32 Zeichen (a-zA-Z0-9_-)).':'Assign an alias (such as "LG1") to make it easier to identify generated objects on the UTM. (max 32 characters (a-zA-Z0-9_-))',
    "Die UTM wurde erst kürzlich aus der Konfiguration entfernt und kann erst wieder hinzugefügt werden, wenn sie sich nach Löschung der alten Konfigurationsdaten einmal zurückgemeldet hat. Bitte haben Sie einen Moment Geduld.":"The UTM was only recently removed from the configuration and can only be added again once it has reported back after the old configuration data has been deleted. Please be patient for a moment.",
    "Aktualisiere...":"Refreshing...",
    "Die Daten der Windows-Sicherheit kommen von dem Windows-Security-Center-Dienst (wscsvc). Das WSC sammelt die aktuellen Informationen zur Sicherheit des Windows-Clients. Neben dem Schutz durch Antivirenprodukte von Drittanbietern, Windows Firewall, dem Status des Dienstes, wird die Konfiguration der Internet-Einstellungen, des Automatischen-Updates und der Benutzerkontensteuerung (UAC) ausgewertet.": "The Windows security data of the Windows Security Center service (wscsvc). The WSC collects current information on the security of the Windows client. In addition to the protection provided by third-party antivirus products, Windows Firewall, the status of the service, the configuration of the Internet settings, Automatic Update and User Account Control (UAC) are analysed.",
    'Jeder dieser Security-Provider hat einen Status von "Gut", "Nicht überwacht", "Schlecht" und "Temporär deaktiviert".': 'Each of these security providers has a status of ‘Good’, ‘Not monitored’, ‘Poor’ and ‘Snooze’.',
    "Erklärungen:": "Explanation:",
    "Windows-Sicherheit": "Windows-Security",
    "Gut: Der Status der Sicherheitsanbieterkategorie ist gut und erfordert keine Aufmerksamkeit des Benutzers.": "Good: The status of the security provider category is good and requires no attention from the user.",
    "Nicht überwacht: Der Status der Sicherheitsanbieterkategorie wird von WSC nicht überwacht.": "Not monitored: The status of the security provider category is not monitored by WSC.",
    "Gut": "Good",
    "Nicht überwacht": "Not monitored",
    "Schlecht": "Poor",
    "Temporär deaktiviert": "Snooze",
    "Automatisches-Update": "Automatic Updates",
    "Benutzerkontensteuerung": "User account control",
    "Dienst-Status": "Service status",
    "Schlecht: Der Sicherheitsanbieterkategorie ist schlecht, und der Computer ist möglicherweise gefährdet.": "Poor: The security provider category is bad and the computer may be at risk.",
    'Temporär deaktiviert: Die Sicherheitsanbieterkategorie befindet sich im Zustand "Temporär deaktiviert", der Computer ist möglicherweise gefährdet.': "Snooze: The security provider category is in the Temporarily Disabled state, and the computer may be at risk.",
    "Pflichtfeld":"Required",
    "Wählen Sie ein bestehendes Netzwerkobjekt aus oder legen Sie ein neues an.":"Select an existing network object or create a new one.",
    "Wählen Sie einen bestehenden Dienst aus oder legen Sie einen neuen an.":"Select an existing service or create a new one.",
    "Wählen Sie aus ob Sie eine UTM oder einen Roadwarrior hinzufügen möchten":"Select whether you want to add a UTM or a Roadwarrior",
    "Der Name der für die zu vergebenen Adressen verwendet wird":"The name used for the addresses to be assigned",
    "Sie besitzen aktuell keine Lizenzen, die Sie zur Nutzung des Securepoint Unified Security Portals berechtigen. Bitte nutzen Sie das Reseller Portal für eine Neulizenzierung oder Verlängerung.": "You currently do not have any licenses that entitle you to use the Securepoint Unified Security Portal. Please use the Reseller Portal for a relicensing or renewal.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität für dieses Gerät mehr.":"This device is assigned to a profile that is part of a transfer network in the VPN-Configuration. This transfer network does not have sufficient capacity for this device.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität mehr.":"This device is assigned to a profile that is part of a transfer network in the VPN-Configuration. This transfer network does not have sufficient capacity.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist.":"This device is assigned to a profile that is part of a transfer network in the VPN-Configuration.",
    "Das Gerät muss online sein, um die Daten anzufordern": "The device must be online to request the data",
    "Websession starten":"Start websession",
    "Nicht verbunden": "Not connected",
    "Um diese Aktion durchführen zu können, muss das Gerät Online sein": "To perform this action, the device must be online",
    "Keine Änderung vorhanden": "There is no change",
    "<b>Achtung</b>: Diese Funktion ist veraltet. In Profilen vor Version 1.31 können Apps gelöscht, aber nicht neu hinzugefügt werden. Bitte nehmen Sie die Neuzuweisung von Applikationen zu Geräten stattdessen über den im Seitenmenü befindlichen Menüpunkt \"Apps\" vor. Dies ermöglicht Ihnen auch die spätere Deinstallation der Anwendungen.": "<b>Attention</b>: This function is deprecated. In profiles prior to version 1.31, apps can be deleted but not added. Instead, please reassign applications to devices via the menu item \“Apps\” in the side menu. This also allows you to uninstall the applications later.",
    "<b>Achtung</b>: Diese Funktion ist veraltet. In Profilen vor Version 1.31 können Webclips gelöscht, aber nicht neu hinzugefügt werden. Bitte nehmen Sie die Neuzuweisung von Webclips zu Geräten stattdessen über den im Seitenmenü befindlichen Menüpunkt \"Apps\" vor. Dies ermöglicht Ihnen auch die spätere Deinstallation der Webclips.": "<b>Attention</b>: This function is deprecated. In profiles prior to version 1.31, web clips can be deleted but not added. Instead, please reassign web clips to devices via the menu item \“Apps\” in the side menu. This also allows you to uninstall the web clips later.",
    "<b>Achtung</b>: Das Verwalten von Apps und Webclips über Profile ist veraltet und nicht mehr verfügbar. Bitte nehmen Sie die Zuweisung von Apps und Webclips zu Geräten stattdessen über den im Seitenmenü befindlichen Menüpunkt \"Apps\" vor.": "<b>Attention</b>: Managing apps and web clips via profiles is deprecated and no longer available. Please assign apps and web clips to devices via the menu item \“Apps\” in the side menu instead.",
    "Trennen": "Disconnect",
    "Verbinden": "Connect",
    "Benutzerdaten müssen gespeichert sein": "User data must be saved",
    "Diese Aktion kann nicht durchgeführt werden, da Otp aktiv ist": "This action cannot be performed because Otp is active",
    "Diese Aktion kann nicht durchgeführt werden,da Pin aktiv ist": "This action cannot be performed because Pin is active",
    "Verbindet...": "Connecting...",
    "WireGuard-Verbindung starten": "Start a WireGuard-Connection",
    "Diese Verbindung erfordert eine PIN zur Authentifizierung.": "This connection requires a PIN for authentication.",
    "Die WireGuard-Verbindung auf dem Securepoint VPN Client ist durch eine PIN geschützt.": "The WireGuard connection on the Securepoint VPN Client is protected by a PIN.",
    "Diese Einstellung wird direkt auf dem Client vorgenommen.": "This setting is set directly on the client.",
    'Details finden Sie in unserem <a href="https://wiki.securepoint.de/VPN/VPN-Client" target="_blank">Wiki</a>.': 'You can find details in our  <a href="https://wiki.securepoint.de/VPN/VPN-Client" target="_blank">Wiki</a>.',
    "Eine andere Verbindung wird aktuell aufgebaut / getrennt. Bitte warten Sie bis der Vorgang abgeschlossen ist": "Another connection is currently being established / disconnected. Please wait until the process is completed",
    "Einstellungen": "Preferences",
    "Verbindung konnte nicht aufgebaut werden.": "Connection could not be established.",
    "Verbindung konnte nicht getrennt werden": "Connection could not be disconnected",
    "Beim Versuch die Verbindung von %s auf %y zu trennen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.": "The time was exceeded when trying to disconnect %s on %y. Please try again.",
    "Beim Versuch die Verbindung von %s auf %y aufzubauen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.": "The time was exceeded when trying to establish the connection from %s on %y. Please try again.",
    "Um dieses Feature nutzern zu können, muss das Gerät Online sein": "In order to use this feature, the device has to be online",
    "Verbindung konnte nicht gelöscht werden": "Connection could not be deleted",
    "Beim Versuch die Verbindung %s auf %y zu löschen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.": "The time was exceeded when trying to delete the connection %s on %y. Please try again.",
    "Entfernen": "Remove",
    "Clientoperationen bieten die Möglichkeit, den Client beim Benutzer zu starten oder zu beenden. Diese Aktionen benötigen einen aktuellen Status. Dieser kann über die Schaltfläche <strong>Status abfragen</strong> ermittelt werden. Ist keine aktive Benutzersitzung vorhanden, können keine Aktionen durchgeführt werden.": "Client operations allow the client to be started or ended by the user. These actions require a current state. This can be determined using the <strong>Query state</strong> button. If there is no active user session, no actions can be performed.",
    "Status abfragen": "Query state",
    "Client starten": "Start client",
    "Client beenden": "Exit client",
    "Achtung: Momentan besteht keine aktive Benutzer-Sitzung. Der Securepoint VPN Client kann nicht gestartet werden oder andere benutzerdefinierte Aktionen ausführen.": "Attention: There is currently no active user session. The Securepoint VPN Client cannot be started or perform other user-defined actions.",
    "Aktionen": "Actions",
    "Die Verbindung wird automatisch bei Trennung neu verbunden": "The connection is automatically reconnected when disconnected",
    "Suche Logeinträge": "Search log entries",
    "Keine Daten gefunden": "No data found",
    "Geben Sie eine privates IPv4- oder IPv6-Netz ein":"Enter a private IPv4 or IPv6 network",
    "Satellit":"Satellite",

    // Non Compliance
    "Dieser Wert wird nicht verwendet.": "This value is not used.",
    "Die Einstellung wird von der API-Ebene der auf dem Gerät laufenden Android-Version nicht unterstützt.": "The setting is not supported in the API level of the Android version running on the device.",
    "Der Verwaltungsmodus (z. B. vollständig verwaltet oder Arbeitsprofil) unterstützt diese Einstellung nicht.": "The management mode (such as fully managed or work profile) doesn't support the setting.",
    "Der Benutzer hat nicht die erforderlichen Maßnahmen ergriffen, um der Einstellung zu entsprechen.": "The user has not taken required action to comply with the setting.",
    "Die Einstellung hat einen ungültigen Wert.": "The setting has an invalid value.",
    "Die für die Umsetzung der Richtlinie erforderliche Anwendung ist nicht installiert.": "The app required to implement the policy is not installed.",
    "Die Richtlinie wird von der Version der Android-Geräterichtlinie auf dem Gerät nicht unterstützt.": "The policy is not supported by the version of Android Device Policy on the device.",
    "Eine blockierte Anwendung ist installiert.": "A blocked app is installed.",
    "Die Einstellung wurde zum Zeitpunkt des Berichts noch nicht vorgenommen, wird aber voraussichtlich in Kürze vorgenommen werden.": "The setting hasn't been applied at the time of the report, but is expected to be applied shortly.",
    "Die Einstellung kann nicht auf die App angewendet werden, weil die Anwendung sie nicht unterstützt, z. B. weil ihre Ziel-SDK-Version nicht hoch genug ist.": "The setting can't be applied to the app because the app doesn't support it, for example because its target SDK version is not high enough.",
    "Die Anwendung ist zwar installiert, wurde aber nicht auf den von der Richtlinie vorgeschriebenen Mindestversionscode aktualisiert.": "The app is installed, but it hasn't been updated to the minimum version code specified by policy.",
    "Das Gerät ist nicht mit den Anforderungen der Richtlinie kompatibel.": "The device is incompatible with the policy requirements.",
    
    //InstallationFailureReason
    "Dieser Wert ist unzulässig.": "This value is disallowed.",
    "Eine unbekannte Bedingung verhindert, dass die Anwendung installiert werden kann. Mögliche Gründe sind, dass das Gerät nicht über genügend Speicherplatz verfügt, die Netzwerkverbindung des Geräts unzuverlässig ist oder die Installation länger dauert als erwartet. Die Installation wird automatisch erneut versucht.": "An unknown condition is preventing the app from being installed. Some potential reasons are that the device doesn't have enough storage, the device network connection is unreliable, or the installation is taking longer than expected. The installation will be retried automatically.",
    "Die Installation ist noch im Gange.": "The installation is still in progress.",
    "Die Anwendung wurde in Play nicht gefunden.": "The app was not found in Play.",
    "Die Anwendung ist nicht mit dem Gerät kompatibel.": "The app is incompatible with the device.",
    "Die Anwendung wurde vom Administrator nicht genehmigt.": "The app has not been approved by the admin.",
    "Die Anwendung hat neue Berechtigungen, die vom Administrator nicht akzeptiert wurden.": "The app has new permissions that have not been accepted by the admin.",
    "Die Anwendung ist in dem Land des Nutzers nicht verfügbar.": "The app is not available in the user's country.",
    "Es sind keine Lizenzen verfügbar, die dem Benutzer zugewiesen werden können.": "There are no licenses available to assign to the user.",
    "Das Unternehmen ist nicht mehr bei Managed Google Play angemeldet oder der Administrator hat die aktuellen Managed Google Play-Nutzungsbedingungen nicht akzeptiert.": "The enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.",
    "Der Benutzer ist nicht mehr gültig. Der Benutzer wurde möglicherweise gelöscht oder deaktiviert.": "The user is no longer valid. The user may have been deleted or disabled.",
    "Das Gerät des Benutzers verfügt nicht über genügend Speicherplatz, um die Anwendung zu installieren. Dies kann behoben werden, indem Sie den Speicherplatz auf dem Gerät freimachen. Die Installation oder Aktualisierung der Anwendung wird automatisch fortgesetzt, sobald das Gerät über ausreichend Speicherplatz verfügt.": "The user's device does not have sufficient storage space to install the app. This can be resolved by clearing up storage space on the device. App install or update will automatically resume once the device has sufficient storage.",
    "Ein Netzwerkfehler auf dem Gerät des Benutzers hat verhindert, dass die Installation erfolgreich durchgeführt werden konnte. Dies geschieht in der Regel, wenn die Internetverbindung des Geräts beeinträchtigt oder nicht verfügbar ist oder wenn ein Problem mit der Netzwerkkonfiguration vorliegt. Bitte stellen Sie sicher, dass das Gerät Zugang zu einer vollständigen Internetverbindung in einem Netzwerk hat, das die Android Enterprise Network Requirements erfüllt. Die Installation oder Aktualisierung der Anwendung wird automatisch fortgesetzt, sobald dies der Fall ist.": "A network error on the user's device has prevented the install from succeeding. This usually happens when the device's internet connectivity is degraded, unavailable or there's a network configuration issue. Please ensure the device has access to full internet connectivity on a network that meets Android Enterprise Network Requirements. App install or update will automatically resume once this is the case.",

    "Es gibt aktuell keine UTM, die als Satellit verwendet werden kann. Bitte stellen Sie sicher, dass Ihre UTMs auf dem neuesten Stand sind und mit der Unified Security Console verbunden sind, um sie zu konfigurieren.": "There is currently no UTM that can be used as a satellite. Please ensure that your UTMs are up to date and connected to the Unified Security Console in order to configure them.",
    "Laufwerk": "Drive",
    "Das kopierte Profil enthält eine VPN-Konfiguration, die nicht in das neue Profil übernommen wird":"The copied profile contains a VPN configuration that is not transferred to the new profile",
    "VPN-Konfiguration":"VPN configuration",
    "Die VPN-Konfiguration ermöglicht die einfache Vernetzung Ihrer UTMs über eine sternförmige Netzwerktopologie.":"The VPN configuration enables simple networking of your UTMs via a star-shaped network topology.",
    "Im Zentrum steht die Core-UTM, die als zentraler Knotenpunkt fungiert.":"At the center is the Core-UTM, which acts as a central hub.",
    "Von hier aus können mehrere Standorte über Satelliten-UTMs sicher miteinander verbunden werden.":"From here, several locations can be securely connected via satellite UTMs.",
    "Zusätzlich können Gruppen von mobilen Endgeräten und Windows VPN Clients als Roadwarrior angebunden werden.":"In addition, groups of mobile end devices and Windows VPN clients can be connected as Roadwarrior.",
    "Erste Konfigurationsschritte:":"First configuration steps:",
    "Auswahl der Unternehmenszentrale als Core-UTM. Sie bildet das Zentrum der Sterntopologie.":"Selection of the company headquarters as the core UTM. It forms the center of the star topology.",
    "Anbindung weiterer UTMs Ihrer anderen Standorte als Satelliten":"Connection of additional UTMs from your other locations as satellites",
    "Konfiguration der Roadwarrior für Windows VPN-Clients und mobile Endgeräte":"Configuration of the Roadwarrior for Windows VPN clients and mobile end devices",
    "Core-UTM jetzt hinzufügen":"Add Core-UTM now",
    "Weitere Informationen finden Sie in unserem Wiki unter":"You can find further information in our wiki at",
    "Möchten Sie die VPN-Verbindung wirklich löschen?": "Do you really want to delete the VPN connection?",
    "Das Löschen der Verbindung hat die Zeit überschritten. Bitte überprüfen Sie die Internetverbindung des VPN-Clients und versuchen es erneut.": "Deleting the connection has timeouted. Please check the internet connection of the VPN client and try again.",
    "Profile auswählen":"Select profiles",
    "Profile":"Profiles",
    "Geräte aus diesen Profilen können sich nach Veröffentlichung der Konfiguration mit diesem Netzwerk verbinden.":"Devices from these profiles can connect to this network once the configuration has been published.",
    "Regel kopieren":"Copy rule",
    "In Zwischenablage kopiert!":"Copied to clipboard!",
    "Geben Sie einen Namen ein":"Enter a name",
    "Erlaubt die Änderungen der Einstellungen im Client. Achtung: Eine neue Veröffentlichung des Profiles überschreibt die lokalen Einstellungen wieder": "Allows the settings to be changed in the client. Attention: A new publication of the profile overwrites the local settings again",
    "Änderung der nachfolgenden Client-Einstellungen erlauben": "Allow changes to the following client settings",
    "IPs in Benutzung":"Used IPs",
    "Abgelaufene Profile einblenden":"Show expired profiles",
    "Ablaufdatum":"Expiry",
    "Aussehen": "Appearance",
    "Umstellung auf Darkmode": "Switch to dark mode",
    "Durch das Setzen kann das Farbschema des Systems überschrieben werden.": "The system's color scheme can be overwritten by setting this.",
    "Sprache": "Language",
    "Hier kann die Sprache der Anwendung eingestellt werden.": "The language of the application can be set here.",
    "Deutsch": "German",
    "Englisch": "English",
    "Anwendungsstart": "Application start",
    "Beim Start ausführen": "Execute on startup",
    "Die Anwendung startet automatisch beim Hochfahren des Computers.": "The application starts automatically when the computer boots up.",
    "In verkleinerter Ansicht starten": "Start in minimized view",
    "Der Client wird in einer reduzierten Ansicht ohne Detailinformationen gestartet.": "The client is started in a reduced view without detailed information.",
    "Anwendung minimiert starten": "Start application minimized",
    "Anwendung startet im Hintergrund und ist über das Tray-Icon verfügbar.": "Application starts in the background and is available via the tray icon.",
    "Migrationsassistent erneut anzeigen": "Show migration wizard again",
    "Beim Starten der Anwendung wird der Migrationsassistent angezeigt. Damit können alte Verbindungen direkt importiert werden.": "The migration wizard is displayed when the application is started. This allows old connections to be imported directly.",
    "Verbindungen": "Connections",
    "Nach Verbindung minimieren": "Minimize after connection",
    "Die Anwendung wird nach Verbindungsaufbau automatisch minimiert.": "The application is automatically minimized after the connection is established.",
    "Mehrere VPN-Verbindungen erlauben": "Allow multiple VPN connections",
    "Die Option mehrerer gleichzeitiger VPN-Verbindungen erfordert sortfältige Konfiguration, um Probleme zu vermeiden.": "The option of multiple simultaneous VPN connections requires careful configuration to avoid problems.",
    "Herunterfahren blockieren": "Block shutdown",
    "Der VPN-Client verhindert das Herunterfahren des Computers, solange eine aktive VPN-Verbindung besteht.": "The VPN client prevents the computer from shutting down as long as there is an active VPN connection.",
    "Beim Minimieren Pop-Up anzeigen": "Show pop-up when minimizing",
    "Informationen werden mittels eines Popup-Fensters angezeigt, wenn die Anwendung minimiert ist.": "Information is displayed in a pop-up window when the application is minimized.",
    "Erweiterte Einstellungen": "Advanced settings",
    "Updates automatisch durchführen": "Perform updates automatically",
    "Updates werden automatisch durchgeführt, um sicherzustellen, dass der Client immer auf dem neusten Stand ist.": "Updates are carried out automatically to ensure that the client is always up to date.",
    "DNS-Leaks vermeiden": "Avoid DNS leaks",
    "DNS-Anfragen werden ausschließlich über den verschlüsselten Tunnel geleitet, damit Online-Aktivitäten nicht von Dritten eingesehen werden können.": "DNS requests are routed exclusively via the encrypted tunnel so that online activities cannot be viewed by third parties.",
    "Aktuellste": "Current",
    "Weitere Informationen": "Further Information",
    "Informationen anzeigen": "Show Information",
    "Informationen ausblenden": "Hide Information",
    "Ist die diese Einstellungen aktiv, sind die nachfolgenden Einstellungen im Client sichtbar und können vom Benutzer geändert werden.": "If this setting is active, the following settings are visible in the client and can be changed by the user.",
    "Windows Enrollmentx": "Windows Enrollment",
    'Entscheidet, ob diese Verbindung bei Bedarf automatisch gestartet wird, wenn sie als aktive Verbindung ausgewählt wird.':'Decides whether this connection is started automatically if required when it is selected as the active connection.',
    'Diese Einstellung kann auf dem Gerät vom Benutzer selbst anschließend verändert werden.':'This setting can then be changed on the device by the user.',
    'Entscheidet, ob diese Verbindung bei sofort gestartet wird, wenn sie als aktive Verbindung ausgewählt wird.':'Decides whether this connection is started immediately if it is selected as the active connection.',
    'Bei einem Verbindungsabbruch wird sie automatisch neu gestartet.':'It is automatically restarted if the connection is lost.'
}
export default en