import { useVue } from "@/app";
import { T } from "@/classes/i18n";
import { type TSecureDns } from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns";
import tenantHelpers from "@/helpers/helpers.tenants"
import { useStore } from "@/store/vuex.store";

const secureDnsDialogs = {
    showEndpointGenerator(accountId: string, item: TSecureDns, isNew: boolean) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw 'Wrong accountid, stopping process';
            }

            let modal: any = {
				"id": "securedns-endpoint-generator",
				"abortable": true,
				"content": {
					"title": {
						"text": isNew ? T('Integrate your new profile') : T('Integrate your profile'),
						"icon": "fal fa-shield-alt"
					},
					"body": {
						"use": true,
						"content": undefined,
						"component": "securedns-endpoint-generator",
                        "properties": item.endpoints,
						"maxHeight": "1000",
					}
				},
				"buttons": [
					{
						"text": T("OK"),
						"icon": "fal fa-check",
						"onClick": async function () {
							useVue().$refs.modals.removeModal()
						},
						"align": "left",
						"disabled": false,
						"loading": false
					},
				]
			}

            useVue().$refs.modals.addModal(modal)
        }
        catch (e: unknown) {
            console.error(e)
        }
    }
}
export default secureDnsDialogs