<script setup lang="ts">
    import { T } from '@/classes/i18n';
    import getterHelpers from '@/helpers/helpers.getters';
    import { computed } from 'vue';
    import inputToggleComponent from './input-toggle-async';
    import useRouterStore from '@/router/routerStore';

    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })
    const activePage = computed(() => { return useRouterStore().getActivePage || '' })
    const activeObjectId = computed(() => { return useRouterStore().getObjectId || '' })
    

    const props = defineProps<{
        sunInfo?: any[]|undefined,
    }>();
</script>
<template>
    <div>
        <template v-if="(sunInfo || []).length > 0">
            <template v-for="info in sunInfo">
                <template v-for="pool in info.pools">
                    <template v-for="entity in pool.entities">
                        <div class="box box-shadow padding-xs-x-2" v-if="entity.id == activeObjectId || entity.id.split('/')?.[3] == activeObjectId">
                            <div class="row padding-xs-y form-group border-bottom">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T('Roadwarrior') }}:</label>
                                </div>
                                <div class="input col-xs-24 col-lg-18 col-xl-19">
                                    <a style="margin-top:6px; display:inline-block;" :href="'#show-tenant-'+activeAccountId+'.sms-usc-vpn-configuration-highlight-'+pool.id">{{ pool.name }}</a> | TN: {{ pool.transferNetwork }} | Core-UTM: {{ info.coreUtmName }} | {{ T("IPs in Benutzung") }}: {{ (pool.numberOfDevices || 0) + "/" + pool.transferNetworkCapacity }}
                                </div>
                            </div>
                            <div class="row padding-xs-t form-group">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname">{{ T( activePage == 'iosProfiles' ? 'VPN on Demand' :'Auto-Start') }}:</label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <input-toggle-component v-model="entity.vpnOnDemand"></input-toggle-component>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        {{ T('Entscheidet, ob diese Verbindung bei sofort gestartet wird, wenn sie als aktive Verbindung ausgewählt wird.') }}<br>
                                        {{ T('Bei einem Verbindungsabbruch wird sie automatisch neu gestartet.') }}<br>
                                        {{ T('Diese Einstellung kann auf dem Gerät vom Benutzer selbst anschließend verändert werden.') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </template>
        </template>
    </div>
</template>