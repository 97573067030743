import { T } from "@/classes/i18n"
import { useGlobalMixin } from "../../mixins/mixins.global"
const devicePasswordResetComponent = {
    name:'device-password-reset',
    mixins: [useGlobalMixin()],
    template: `
        <div class="form-group">
            <div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname">
                        {{ T('New password') }}
                    </label>
                </div>
                <div class="input toggle col-xs-24 col-lg-8 col-xl-7">
                    <label>
                        <input
                            id="device-new-password"
                            type="text"
                            class="form-control"
                            v-model="newPassword"
                        >
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-10 col-xl-12"></div>
            </div>


            <div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname">
                        {{ T('Require entry') }}
                    </label>
                </div>
                <div class="input toggle col-xs-24 col-lg-8 col-xl-7">
                    <label class="toggle checkbox">
                        <input type="checkbox" class="toggle" v-model="REQUIRE_ENTRY"><span></span>
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                    {{ T("Don't allow other admins to change the password again until the user has entered it.") }}
                </div>
            </div>

            <div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname">
                        {{ T('No credentials') }}
                    </label>
                </div>
                <div class="input toggle col-xs-24 col-lg-8 col-xl-7">
                    <label class="toggle checkbox">
                        <input type="checkbox" class="toggle" v-model="DO_NOT_ASK_CREDENTIALS_ON_BOOT"><span></span>
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                    {{ T("Don't ask for user credentials on device boot.") }}
                </div>
            </div>

            <div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname">
                        {{ T('Lock device') }}
                    </label>
                </div>
                <div class="input toggle col-xs-24 col-lg-8 col-xl-7">
                    <label class="toggle checkbox">
                        <input type="checkbox" class="toggle" v-model="LOCK_NOW"><span></span>
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                    {{ T("Lock the device after password reset.") }}
                </div>
            </div>

        </div>
    `,
    data: function() {
        return {
            "newPassword":"",
            "REQUIRE_ENTRY":false,
            "DO_NOT_ASK_CREDENTIALS_ON_BOOT":false,
            "LOCK_NOW":false
        }
    },
    methods: {
        "T":function(content:string) {
            return T(content)
        }
    },
    created: async function(this : any) {
        if(this.$parent.passData != undefined) {
            this.value = this.$parent.passData
        }
    },
    mounted: function(this:any) {
    }
}
export default devicePasswordResetComponent
