<script setup lang="ts">
	const showQaDialog = () => {
        
    }
	
	


</script>

<template>
	<div class="menu" v-on:click.stop>
		<ul>
			<li>
				<a class="toggle" v-on:click="showQaDialog">
					QA
				</a>
			</li>		
		</ul>
	</div>
</template>