import tenantHelpers from "@/helpers/helpers.tenants"
import cookies from "./cookieHandler"
const envVars = import.meta.env
const devModeCookie = cookies.get("iddqd")
const qaModeCookie = cookies.get("iddqa")
const isDevmodeOn = (devModeCookie == "1" || envVars.VITE_CONFIG_DEV_MODE == "1" ? true : false)
const isQAmodeOn = (qaModeCookie == "1" || envVars.VITE_CONFIG_QA_MODE == "1" ? true : false)

const config = {
    "server": envVars.VITE_SERVER,
    "hostname": envVars.VITE_CONFIG_HOSTNAME,
    "version": envVars.VITE_CONFIG_VERSION,
    "mgtApiVersion": envVars.VITE_CONFIG_MGT_API_VERSION,
    "mgtApiVersionNext": envVars.VITE_CONFIG_MGT_API_VERSION_NEXT,
    "mgtApiUri": envVars.VITE_CONFIG_MGT_API_URI.replace('{mgtApiVersion}', envVars.VITE_CONFIG_MGT_API_VERSION),
    "mgtApiUriNext": envVars.VITE_CONFIG_MGT_API_URI.replace('{mgtApiVersion}', envVars.VITE_CONFIG_MGT_API_VERSION_NEXT),
    "authApiUri": envVars.VITE_CONFIG_AUTH_API_URI,
    "websocketApiUri": envVars.VITE_CONFIG_WEBSOCKET_API_URI,
    "cacheTimeout": envVars.VITE_CONFIG_CACHE_TIMEOUT,
    "itunesApiUri": envVars.VITE_CONFIG_ITUNES_API_URI,
    "googleMapsApiKey": envVars.VITE_CONFIG_GOOGLEMAPSAPIKEY,
    "devMode": isDevmodeOn,
    "qaMode": isQAmodeOn,
    "loglevel": Number(envVars.VITE_CONFIG_LOGLEVEL || "0"),
    "defaultTimezone": envVars.VITE_CONFIG_STATS_DEFAULT_TIMEZONE,
    "devicesConsentDate": envVars.VITE_CONFIG_DEVICES_CONSENT_DATE,
    "mobileSecurity": {
        "googleMapsApiKey": envVars.VITE_CONFIG_GOOGLEMAPSAPIKEY,
        "googleOAuthClientId": envVars.VITE_CONFIG_GOOGLEOAUTHCLIENTID,
        "vppGuid": envVars.VITE_CONFIG_VPPGUID
    },
    "unifiedSecurityConsole": {
        "googleMapsApiKey": envVars.VITE_CONFIG_GOOGLEMAPSAPIKEY,
        "utmVersionForPin": "14.1.0"
    },
    "pluggable": {
        "newObjectTypes": new Set(import.meta.env.VITE_USE_NEW_OBJECTTYPES.split(",")),
        "enterprise": import.meta.env.VITE_USE_ENTERPRISE == "true",
        "appleDEP": import.meta.env.VITE_USE_APPLEDEP == "true",
        "appleVPP": import.meta.env.VITE_USE_APPLEVPP == "true",
        "zeroTouch": import.meta.env.VITE_USE_ZEROTOUCH == "true",
        "requiredShemaFields": import.meta.env.VITE_ENABLE_REQUIRED_SHEMA_FIELDS == "true"
    },
    getApiUrl: (apiVersion:string,accountId?:string|number,postFix?:string) => {
        const tenantDomain = accountId ? tenantHelpers.getTenantDomain(String(accountId)) : undefined       
        return (apiVersion == "2.0" ? config.mgtApiUriNext : config.mgtApiUri ) + (tenantDomain ? "/tenants/" + tenantDomain : "") + postFix
    },
    canUseNewObjectType: (objectTypeName:string) => {
        return config.pluggable.newObjectTypes.has(objectTypeName)
    }
}
export default config