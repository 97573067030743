import { defineStore } from "pinia"
import Button, { type ButtonOptions } from "@/templates/components/button/button"
import { T } from "../i18n";
import tenantHelpers from "@/helpers/helpers.tenants";
import jsonHelpers from "@/helpers/helpers.json";
import miscDialogs from "@/dialogs/dialogs.misc";
import devLog from "../log";
import requestHandler from "@/queries/requests";
import apis from "../apis";
import arrayHelpers from "@/helpers/helpers.arrays";
import type { ProductType } from "@/resources/registeredProducts";
import stringHelpers from "@/helpers/helpers.strings";
import timeHelpers from "@/helpers/helpers.time";

const envVars = import.meta.env

export default class ObjectType<T> {
    constructor(payload: ObjectTypePayload<T>) {
        // OPTIONS
        const thisClass = this
        // APPEARANCE
        if (payload.objectType != undefined) this.options.objectType = payload.objectType;
        if (payload.productType != undefined) this.options.productType = payload.productType;

        if (payload.appearance?.color != undefined) this.options.appearance.color = payload.appearance?.color;
        if (payload.appearance?.iconClass != undefined) this.options.appearance.iconClass = payload.appearance.iconClass;
        if (payload.appearance?.text != undefined) {
            if (payload.appearance.text.title != undefined) this.options.appearance.text.title = payload.appearance.text.title;
            if (payload.appearance.text.sidebarName != undefined) this.options.appearance.text.sidebarName = payload.appearance.text.sidebarName;
            if (payload.appearance.text.plural != undefined) this.options.appearance.text.plural = payload.appearance.text.plural;
            if (payload.appearance.text.singular != undefined) this.options.appearance.text.singular = payload.appearance.text.singular;
        }
        if (payload.appearance?.showOnDashboard != undefined) this.options.appearance.showOnDashboard = payload.appearance.showOnDashboard;
        if (payload.appearance?.showInSidebar != undefined) this.options.appearance.showInSidebar = payload.appearance.showInSidebar;

        // OBJECTTYPE INFO
        if (payload.objectTypeInfo?.primaryKeyProperty != undefined) {
            if (payload.objectTypeInfo.primaryKeyProperty.property != undefined) this.options.objectTypeInfo.primaryKeyProperty.property = payload.objectTypeInfo.primaryKeyProperty.property;
            if (payload.objectTypeInfo.primaryKeyProperty.pathToPrimaryProperty != undefined) this.options.objectTypeInfo.primaryKeyProperty.pathToPrimaryProperty = payload.objectTypeInfo.primaryKeyProperty.pathToPrimaryProperty;
            if (payload.objectTypeInfo.primaryKeyProperty.encode != undefined) this.options.objectTypeInfo.primaryKeyProperty.encode = payload.objectTypeInfo.primaryKeyProperty.encode;
            if (payload.objectTypeInfo.primaryKeyProperty.decode != undefined) this.options.objectTypeInfo.primaryKeyProperty.decode = payload.objectTypeInfo.primaryKeyProperty.decode;
        }
        if (payload.objectTypeInfo?.nameProperty != undefined) {
            if (payload.objectTypeInfo.nameProperty.primary != undefined) this.options.objectTypeInfo.nameProperty.primary = payload.objectTypeInfo.nameProperty.primary;
            if (payload.objectTypeInfo.nameProperty.pathToPrimaryProperty != undefined) this.options.objectTypeInfo.nameProperty.pathToPrimaryProperty = payload.objectTypeInfo.nameProperty.pathToPrimaryProperty;
            if (payload.objectTypeInfo.nameProperty.secondary != undefined) this.options.objectTypeInfo.nameProperty.secondary = payload.objectTypeInfo.nameProperty.secondary;
            if (payload.objectTypeInfo.nameProperty.pathToSecondaryProperty != undefined) this.options.objectTypeInfo.nameProperty.pathToSecondaryProperty = payload.objectTypeInfo.nameProperty.pathToSecondaryProperty;
        }

        // API INFO
        if (payload.apiInfo?.url != undefined) this.options.apiInfo.url = payload.apiInfo.url;
        if (payload.apiInfo?.getObjectListPath != undefined) this.options.apiInfo.getObjectListPath = payload.apiInfo.getObjectListPath;
        if (payload.apiInfo?.getObjectListMethod != undefined) this.options.apiInfo.getObjectListMethod = payload.apiInfo.getObjectListMethod;
        if (payload.apiInfo?.getObjectListResponseProperty != undefined) this.options.apiInfo.getObjectListResponseProperty = payload.apiInfo.getObjectListResponseProperty;
        if (payload.apiInfo?.getObjectUrl != undefined) this.options.apiInfo.getObjectUrl = payload.apiInfo.getObjectUrl;
        if (payload.apiInfo?.getObjectPath != undefined) this.options.apiInfo.getObjectPath = payload.apiInfo.getObjectPath;
        if (payload.apiInfo?.getObjectMethod != undefined) this.options.apiInfo.getObjectMethod = payload.apiInfo.getObjectMethod;
        if (payload.apiInfo?.getObjectResponseProperty != undefined) this.options.apiInfo.getObjectResponseProperty = payload.apiInfo.getObjectResponseProperty;
        if (payload.apiInfo?.addObjectUrl != undefined) this.options.apiInfo.addObjectUrl = payload.apiInfo.addObjectUrl;
        if (payload.apiInfo?.addObjectPath != undefined) this.options.apiInfo.addObjectPath = payload.apiInfo.addObjectPath;
        if (payload.apiInfo?.addObjectMethod != undefined) this.options.apiInfo.addObjectMethod = payload.apiInfo.addObjectMethod;
        if (payload.apiInfo?.updateObjectUrl != undefined) this.options.apiInfo.updateObjectUrl = payload.apiInfo.updateObjectUrl;
        if (payload.apiInfo?.updateObjectPath != undefined) this.options.apiInfo.updateObjectPath = payload.apiInfo.updateObjectPath;
        if (payload.apiInfo?.updateObjectMethod != undefined) this.options.apiInfo.updateObjectMethod = payload.apiInfo.updateObjectMethod;
        if (payload.apiInfo?.deleteObjectUrl != undefined) this.options.apiInfo.deleteObjectUrl = payload.apiInfo.deleteObjectUrl;
        if (payload.apiInfo?.deleteObjectPath != undefined) this.options.apiInfo.deleteObjectPath = payload.apiInfo.deleteObjectPath;
        if (payload.apiInfo?.deleteObjectMethod != undefined) this.options.apiInfo.deleteObjectMethod = payload.apiInfo.deleteObjectMethod;
        if (payload.apiInfo?.getCountGETProperties != undefined) this.options.apiInfo.getCountGETProperties = payload.apiInfo.getCountGETProperties;
        if (payload.apiInfo?.getCountGETResponseProperty != undefined) this.options.apiInfo.getCountGETResponseProperty = payload.apiInfo.getCountGETResponseProperty;

        // STORE
        // create store only if hasStore is set to true
        if (payload.hasStore) {
            this.options.hasStore = true

            this.useStore = defineStore(this.options.objectType, {
                state: () => {
                    return {
                        accounts: <ObjectTypeStores<T>>{}
                    }
                },
                actions: {
                    /**
                    * Sets stores for each tenant in list
                    */
                    addAccount(accountId: string) {
                        accountId = tenantHelpers.getAccountId(accountId)
                        if (!this.accounts[accountId]) {
                            this.accounts[accountId] = {
                                "objects": <T[]>[],
                                "objectsTimestamp": 0,
                                "gettingObjects":false,
                                "count": undefined,
                                "countTimestamp": 0,
                            }
                        }
                    },
                    /**
                    * Sets stores for each tenant in list
                    */
                    setAccounts(tenants: { tenantDomain: string }[]) {
                        tenants?.forEach((tenant) => {
                            this.addAccount(tenantHelpers.getAccountId(tenant.tenantDomain))
                        })
                    },
                    /**
                    * clear store
                    */
                    clearStore() {
                        this.accounts = <ObjectTypeStores<T>>{}
                    },
                    /**
                     * Adds array of objects to store
                     * @param accountId 
                     * @param objects 
                     */
                    async setObjectTypeObjects(accountId: AccountId, objects: T[]) {
                        if (thisClass.replaceStoreFunctionWith.setObjectTypeObjects != undefined) {
                            thisClass.replaceStoreFunctionWith.setObjectTypeObjects(accountId,objects)
                        }
                        else {
                            let accountObjectStore = this.getObjectStore(accountId)
                            let objectTypeInfo = thisClass?.options.objectTypeInfo
                            let idProperty = objectTypeInfo?.primaryKeyProperty.property
                            let idPropertyPath = objectTypeInfo?.primaryKeyProperty.pathToPrimaryProperty                        
                            if (accountObjectStore?.objects?.length) {
                                if (idProperty) {
                                    let existingObject: T | undefined = undefined
                                    objects.forEach((object) => {
                                        if((<any>object).tags != undefined && Array.isArray((<any>object).tags)) {
                                            (<any>object).tags = (<any>object).tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
                                        }

                                        if (object && thisClass?.convertObjectForStore) {
                                            thisClass.convertObjectForStore(accountId, object)
                                        }
                                        existingObject = undefined
                                        // check if object already exists
                                        const thisObjectId = (idPropertyPath ? jsonHelpers.getObjectProperty(object, idPropertyPath + "." + String(idProperty)) : object[idProperty as keyof typeof object])
                                        existingObject = this.getObjectStoreObject(accountId, thisObjectId)
                                        if (existingObject != undefined) {
                                            // replace object
                                            Object.assign(existingObject,object)
                                        }
                                        else if (accountObjectStore) {
                                            // add object
                                            accountObjectStore.objects?.push(object)
                                        }

                                    })
                                }
                            }
                            else if (accountObjectStore) {
                                objects.forEach((object) => {
                                    if ((<any>object).tags != undefined && Array.isArray((<any>object).tags)) {
                                        (<any>object).tags = (<any>object).tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
                                    }
                                    if (object && thisClass?.convertObjectForStore) {
                                        thisClass.convertObjectForStore(accountId, object)
                                    }
                                })
                                accountObjectStore.objects = objects
                            }
                        }
                    },
                    /**
                    * Sets Account specific Count for this objectType
                    */
                    setObjectTypeCount(accountId: AccountId, count: number) {
                        let accountObjectStore = this.getObjectStore(accountId)

                        if (accountObjectStore && accountObjectStore.objects.length < count) {
                            accountObjectStore.count = count
                        }
                        else if (accountObjectStore.objects.length >= count) {
                            accountObjectStore.count = accountObjectStore.objects.length
                        }
                    },
                    /**
                    * Adds Object to Account specific Objectlist for this objectType
                    */
                    addObjectTypeObject(accountId: AccountId, object: T) {
                        if ((<any>object).tags != undefined && Array.isArray((<any>object).tags)) {
                            (<any>object).tags = (<any>object).tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
                        }
                        if (thisClass.replaceStoreFunctionWith.addObjectTypeObject != undefined) {
                            thisClass.replaceStoreFunctionWith.addObjectTypeObject(accountId, object)
                        }
                        else {
                            let accountObjectStore = this.getObjectStore(accountId)
                            if (accountObjectStore) {
                                if (object && thisClass?.convertObjectForStore) {
                                    thisClass.convertObjectForStore(accountId, object)
                                }
                                accountObjectStore.objects?.push(object)
                                accountObjectStore.count = (accountObjectStore.count || 0) + 1
                            }
                        }
                    },
                    /** 
                    * Sets Object in Account specific Objectlist for this objectType
                    */
                    setObjectTypeObject(accountId: AccountId, objectId: string, object: T) {
                        if ((<any>object).tags != undefined && Array.isArray((<any>object).tags)) {
                            (<any>object).tags = (<any>object).tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
                        }
                        //@ts-ignore
                        if (!object[thisClass.options.objectTypeInfo.primaryKeyProperty.property]) {
                            //@ts-ignore
                            object[thisClass.options.objectTypeInfo.primaryKeyProperty.property] = objectId
                        }
                        if (thisClass.replaceStoreFunctionWith.setObjectTypeObject != undefined) {
                            thisClass.replaceStoreFunctionWith.setObjectTypeObject(accountId, objectId ,object)
                        }
                        else {
                            
                            this.setObjectTypeObjects(accountId,[object])
                        }
                    },
                    /**
                    * Sets properties for object in Account specific Objectlist for this objectType
                    */
                    setObjectTypeObjectProperty(accountId: AccountId, objectId: string, value: any, property?: string, propertyPath?: string[]|string) {
                        let object: any = this.getObjectStoreObject(accountId, objectId)
                        if(propertyPath && typeof propertyPath == "string") {
                            propertyPath = propertyPath.split(".")
                        }
                        if (property == "tags") {
                            if (Array.isArray(value)) {
                                value = value.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
                            }
                            else if(value?.tags) {
                                value.tags = value.tags.sort?.((tagA: string, tagB: string) => { return tagA.toLowerCase() > tagB.toLowerCase() ? 1 : -1 })
                            }
                        }
                        if (object != undefined) {
                            let objectBase = object
                            if (propertyPath?.length) {
                                for (let i: number = 0; propertyPath.length > i; i++) {
                                    let subProperty = propertyPath[i] as keyof T
                                    if (object[subProperty] == undefined) {
                                        object[subProperty] = {}
                                    }
                                    object = object[subProperty]
                                }
                            }
                            if (property && typeof value == "object" && !Array.isArray(value) && value.hasOwnProperty(property)) {
                                value = value[property]
                            }

                            if (property) {
                                object[property] = value
                                if (thisClass?.convertObjectForStore) {
                                    thisClass.convertObjectForStore(accountId, objectBase)
                                }
                            }
                            else if (!property && typeof value == "object" && !Array.isArray(value)) {
                                Object.keys(value).forEach((key) => {
                                    object[key] = value[key]
                                })
                                if (thisClass?.convertObjectForStore) {
                                    thisClass.convertObjectForStore(accountId, objectBase)
                                }
                            }
                        }
                        else {
                            throw 'Object "'+objectId+'" not found'
                        }
                    },
                    /**
                    * Delete object in Account specific Objectlist for this objectType
                    */
                    deleteObjectTypeObjectFromStore(accountId: AccountId, objectId: string|number) {
                        if (thisClass) {
                            const objectIdProperty = thisClass.options.objectTypeInfo.primaryKeyProperty.property
                            let objectStore = this.getObjectStore(accountId)
                            if (objectStore?.objects) {
                                objectStore.objects = objectStore.objects.filter((currentObject) => {
                                    return currentObject[objectIdProperty as keyof typeof currentObject] != objectId
                                })
                                if (objectStore.count != undefined) {
                                    objectStore.count--
                                }
                            }
                        }
                    },
                    /**
                    * remove objects from Account specific Objectlist for this objectType
                    */
                    clearObjectTypeObjectsFromStore(accountId: AccountId) {
                        if (thisClass) {
                            let objectStore = this.getObjectStore(accountId)
                            if (objectStore?.objects) {
                                objectStore.objects = []
                            }
                        }
                    },

                },
                getters: {
                    /**
                    * Check if account exists
                    * @returns true or false
                    */
                    hasAccount(state) {
                        return (accountId: AccountId | undefined) => {
                            return accountId ? state.accounts[accountId] != undefined : false
                        }
                    },
                    /**
                    * Gets Account specific Store for this objectType
                    * @returns objectStore or undefined
                    */
                    getObjectStore(state) {
                        return (accountId: AccountId,sortingOptions?:any,filterOptions?:any) => {
                            let result = accountId && !filterOptions ? state.accounts[accountId] : { 
                                "count": <number|undefined>undefined, 
                                "objects":<T[]>[],
                                "gettingObjects":false,
                                "objectsTimestamp": 0,
                                "countTimestamp": 0
                            }
                            if (filterOptions) {
                                result.count = state.accounts[accountId].count
                                result.objects = state.accounts[accountId].objects
                            }

                            // sorting
                            if (sortingOptions && sortingOptions?.sortBy != "") {
                                result.objects = arrayHelpers.sortByObjProperty(result?.objects, sortingOptions.sortBy, sortingOptions.direction)
                            }
                
                            // filter
                            if (filterOptions && filterOptions?.searchString != "" && filterOptions.searchString.length > 2) {
                                let searchForValues = (<string>filterOptions.searchString).toLowerCase().split(' ')
                                result.objects = result.objects.filter((item: any) => {
                                    let thisResult: boolean = false
                                    if (item) {
                                        let searchResults = function () {
                                            return searchForValues.map(() => { return false })
                                        }()
                                        let searchArray = tenantHelpers.generateSearchArrayFromObject(item)
                                        searchForValues.forEach((searchForValue, index) => {
                                            searchResults[index] = searchArray.some((entry: any) => {
                                                return entry.indexOf(searchForValue) != -1
                                            })
                                        });
                                        thisResult = searchResults.indexOf(false) == -1
                                    }
                                    return thisResult
                                })
                            }
                            return result
                        }
                    },
                    /**
                    * Gets Account specific Count for this objectType
                    * @returns number or undefined
                    */
                    getObjectStoreCount(state) {
                        return (accountId: AccountId) => {
                            return typeof this.getObjectStore(accountId)?.count == 'number' ? this.getObjectStore(accountId)?.count : undefined
                        }
                    },
                    /**
                    * Gets Account specific Objects for this objectType
                    * @returns Array of Objects or undefined
                    */
                    getObjectStoreObjects(state) {
                        return (accountId: AccountId, search?: string) => {
                            let result = this.getObjectStore(accountId)?.objects
                            if (search && result) {
                                return result.filter((item) => {
                                    return JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) != -1
                                })
                            }
                            return result
                        }
                    },
                    /**
                    * Gets Account specific Object for this objectType
                    * @returns Objects or undefined
                    */
                    getObjectStoreObject(state) {
                        return (accountId: AccountId, objectId: string|number, altIdProperty?: string): T | undefined => {
                            let objectList = this.getObjectStoreObjects(accountId) || undefined
                            let objectTypeInfo = thisClass.options.objectTypeInfo
                            let idProperty = altIdProperty ? altIdProperty : objectTypeInfo?.primaryKeyProperty.property
                            let idPropertyPath = objectTypeInfo?.primaryKeyProperty.pathToPrimaryProperty
                            return objectList?.find((object) => {
                                return (idPropertyPath ? jsonHelpers.getObjectProperty(object, idPropertyPath + "." + String(idProperty)) : object[idProperty as keyof typeof object]) == objectId
                            })
                        }
                    } 
                }
            })
        }
    }

    // STORE
    public useStore?
    
    // OPTIONS
    public options: ObjectTypeOptions<T> = {
        "objectType": "objectType",
        "productType": "unifiedSecurity",
        "slug": "object-type",
        "appearance": {
            "iconClass": "fal fa-block-question",
            "color": "red",
            "text": {
                "title": "ObjectType",
                "sidebarName": "ObjectType",
                "plural": "Objecttypes",
                "singular": "Objecttype"
            },
            "showOnDashboard": false,
            "showInSidebar": false,
        },
        "hasStore": false,
        "objectTypeInfo": {
            "primaryKeyProperty": {
                "property": "id",
                "pathToPrimaryProperty": undefined
            },
            "nameProperty": {
                "primary": "alias",
                "pathToPrimaryProperty": undefined,
                "secondary": undefined,
                "pathToSecondaryProperty": undefined
            }
        },
        "apiInfo": {
            url: envVars.VITE_CONFIG_MGT_API_URI.replace('{mgtApiVersion}', envVars.VITE_CONFIG_MGT_API_VERSION),
            getObjectListPath: "",
            getObjectListMethod: "GET",
            getObjectListResponseProperty: "",
            getObjectListUrl: "",
            getObjectPath: "",
            getObjectUrl: "",
            getObjectMethod: "GET",
            getObjectResponseProperty: "",
            addObjectPath: "",
            addObjectMethod: "POST",
            addObjectUrl: "",
            updateObjectPath: "",
            updateObjectMethod: "PUT",
            updateObjectUrl: "",
            deleteObjectPath: "",
            deleteObjectMethod: "DELETE",
            deleteObjectUrl: "",
            getCountGETProperties: "?props[]=null&select=count",
            getCountGETResponseProperty: ""
        }
    }
    /** 
    * Gets URL for objectType specific API
    */
    getApiUrl = (type:"url"|"getObjectListUrl"|"getObjectUrl"|"addObjectUrl"|"updateObjectUrl"|"deleteObjectUrl"="url") => {
        return type && this.options.apiInfo[type as keyof typeof this.options.apiInfo] ? this.options.apiInfo[type as keyof typeof this.options.apiInfo] : this.options.apiInfo["url"]
    }
    /**
    * Gets Object-List URL for objectType specific API
    */
    getListUrl = (accountId:AccountId,customerId?:string) => {
        let tenantDomain = tenantHelpers.getTenantDomain(accountId)
        return this.getApiUrl("getObjectListUrl") + this.options.apiInfo.getObjectListPath.replace("{tenantDomain}", tenantDomain).replace("{customerId}", customerId || "")
    }
    /**
    * Gets Object-Specific URL for objectType specific API
    */
    getSingleObjectUrl = (accountId:AccountId, objectId : string | number, customerId?:string) => {
        let tenantDomain = tenantHelpers.getTenantDomain(accountId)
        const encodeId = this.options.objectTypeInfo.primaryKeyProperty.encode
        objectId = encodeId ? encodeId(String(objectId)) : objectId
        return this.getApiUrl("getObjectUrl") + (this.options.apiInfo.getObjectPath || this.options.apiInfo.getObjectListPath + "/{objectId}" ).replace("{tenantDomain}", tenantDomain).replace("{objectId}", objectId.toString()).replace("{customerId}", customerId || "")
    }
    /**
    * Gets Object-Add URL for objectType specific API
    */
    getAddObjectApiUrlPath = (accountId:AccountId, customerId?:string) => {
        let tenantDomain = tenantHelpers.getTenantDomain(accountId)
        return this.getApiUrl("addObjectUrl") + (this.options.apiInfo.addObjectPath || this.options.apiInfo.getObjectListPath).replace("{tenantDomain}", tenantDomain).replace("{customerId}", customerId || "")
    }
    /**
    * Gets Object-Update URL for objectType specific API
    */
    getUpdateObjectApiUrlPath = (accountId:AccountId, objectId : string | number, customerId?:string) => {
        let tenantDomain = tenantHelpers.getTenantDomain(accountId)
        const encodeId = this.options.objectTypeInfo.primaryKeyProperty.encode
        objectId = encodeId ? encodeId(String(objectId)) : objectId
        return this.getApiUrl("updateObjectUrl") + (this.options.apiInfo.updateObjectPath || this.options.apiInfo.getObjectListPath + "/{objectId}").replace("{tenantDomain}", tenantDomain).replace("{objectId}", objectId.toString()).replace("{customerId}", customerId || "")
    }
    /**
    * Gets Object-Delete URL for objectType specific API
    */
    getDeleteObjectApiUrlPath = (accountId:AccountId, objectId : string | number, customerId?:string) => {
        let tenantDomain = tenantHelpers.getTenantDomain(accountId)
        const encodeId = this.options.objectTypeInfo.primaryKeyProperty.encode
        objectId = encodeId ? encodeId(String(objectId)) : objectId
        return this.getApiUrl("deleteObjectUrl") + (this.options.apiInfo.deleteObjectPath || this.options.apiInfo.getObjectListPath + "/{objectId}").replace("{tenantDomain}", tenantDomain).replace("{objectId}", objectId.toString()).replace("{customerId}", customerId || "")
    }
    /**
    * Gets Object ID from object
    */
    getObjectId = (object: T) => {
        return object[this.options.objectTypeInfo.primaryKeyProperty.property as keyof T] as string | number
    }

    // QUERIES
    public queries: ObjectTypeQueries<T> = {
        /**
        * Gets object-count from api
        * @param accountId
        */
        getCountFromApi: async (accountId,updateStore = true) => {
            const propertiesString: string = this.options.apiInfo.getCountGETProperties
            let result: number | Error | undefined = undefined
            try {
                let response = await requestHandler.request(this.options.apiInfo.getObjectListMethod, this.getListUrl(accountId) + propertiesString)
                response = apis.parseApiResponse(response)
                let propertyInresponse = this.options.apiInfo.getCountGETResponseProperty
                if (typeof response == "number") {
                    result = response
                }
                else if (typeof propertyInresponse == "string" && propertyInresponse.length > 0 && response[propertyInresponse]) {
                    result = response[propertyInresponse as string]
                }
                else if (typeof propertyInresponse == "function") {
                    result = response[propertyInresponse()]
                }
                else if (response.length) {
                    result = response
                }
                result = Number(response)
                if (typeof result !== "number") {
                    throw "Error getting count"
                }
                else if (updateStore) {
                    this.useStore?.().setObjectTypeCount(accountId,Number(result))
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                return 0// e as Error
            }
        },
        /**
        * Gets object-list from api
        * @param accountId
        * @param props (optional) GET request Payoad as array
        */
        getObjectsFromApi: async (accountId, customerId?, props?, updateStore = true, removeOldObjects = false) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = function() {
                 
                return store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5 
            }()
            if((store && isRequestable) || store == undefined) {
                if(store) { 
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
            
                let propertiesString: string = props ? this.getPropertiesString(props) : ""
                if (this.getListUrl(accountId).indexOf("?") != -1) {
                    propertiesString = propertiesString.replace("?","&")
                }

                let result: T[] | Error

                try {
                    let response = await requestHandler.request(this.options.apiInfo.getObjectListMethod, this.getListUrl(accountId) + propertiesString)
                    response = apis.parseApiResponse(response)
                    let propertyInresponse = this.options.apiInfo.getObjectListResponseProperty
                    if (typeof propertyInresponse == "string" && propertyInresponse.length > 0 && response[propertyInresponse]) {
                        result = response[propertyInresponse as string] as T[]
                    }
                    else if (typeof propertyInresponse == "function" && response[propertyInresponse()]) {
                        result = response[propertyInresponse()] as T[]
                    }
                    else if (Array.isArray(response)) {
                        result = response as T[]
                    }
                    else {
                        throw new Error("Error getting Objects")
                    }
                    if (updateStore) {
                        if(removeOldObjects == true) {
                            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }
                        this.useStore?.().setObjectTypeObjects(accountId, result)
                    }
                    
                    if(store) { store.gettingObjects = false }
                    return result
                }
                catch (e: any) {
                    if(store) { store.gettingObjects = false }
                    devLog.log("ObjectType", e.message, e, "error")
                    throw e as Error
                }
            }
            else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        },
        /**
        * Gets single object from api
        * @param accountId 
        * @param objectId 
        * @param props
        */
        getObjectFromApi: async (accountId, objectId, customerId?, props?, updateStore = true, deleteOldObject = false) => {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let result: T | Error
            try {
                if (!objectId) throw "Missing objectId"
                let response = await requestHandler.request(this.options.apiInfo.getObjectMethod, this.getSingleObjectUrl(accountId,objectId,customerId) + propertiesString)
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response as T
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    if(deleteOldObject == true) {
                        this.useStore?.().deleteObjectTypeObjectFromStore(accountId, objectId)
                    }
                    this.useStore?.().setObjectTypeObject(accountId, String(objectId), result)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        },

        /**
        * Adds object to sms-mgt-api
        * @param accountId 
        * @param object 
        * @param customerId 
        */
        addObjectToApi: async (accountId, object, customerId?, updateStore = true) => {
            let result: T | Error
            try {
                let response = await requestHandler.request(this.options.apiInfo.addObjectMethod, this.getAddObjectApiUrlPath(accountId, customerId),object)
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = object
                    if(response[this.options.objectTypeInfo.primaryKeyProperty.property]) {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] = response[this.options.objectTypeInfo.primaryKeyProperty.property] 
                    }
                    else if(typeof response == "string") {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] = response
                    }
                }
                else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    this.useStore?.().addObjectTypeObject(accountId, object)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        },

        /**
        * Updates object in sms-mgt-api
        * @param accountId 
        * @param objectId 
        * @param object 
        * @param customerId 
        */
        updateObjectFromApi: async (accountId, objectId, object, customerId, updateStore = true,altMethod?) => {
            let result: T | Error
            try {
                let response = await requestHandler.request(this.options.apiInfo.updateObjectMethod, this.getUpdateObjectApiUrlPath(accountId,objectId, customerId),object)
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response as T
                }
                else {
                    throw new Error("Error updating objects")
                }
                if (updateStore) {
                    this.useStore?.().setObjectTypeObject(accountId, String(objectId),object)
                }
                return result
            }
            catch (e: any) {
                devLog.log("ObjectType", e?.message, e, "error")
                throw e as Error
            }
        },
        /**
        * updates object property from api
        * @param object 
        * @param accountId  accountId
        * @param objectId objects primary key
        * @param value the payload that will be sent
        * @param property (optional) non-functional yet (is used to set single property in setter)
        * @param customerId (optional) adds customerId to url if set & needed: "{customerId}" will be replaced
        * @param urlPostfix (optional) adds entered string to url
        */
        updateObjectPropertiesFromApi: async (accountId, objectId, value, property?, propertyPath?:string[]|string, customerId?, urlPostfix?, altMethod?, updateStore = true): Promise<true | Error> => {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            
            let response: any
            try {
                response = await requestHandler.request(altMethod ? altMethod : this.options.apiInfo.updateObjectMethod, this.getUpdateObjectApiUrlPath(tenantDomain, objectId as string, customerId) + (urlPostfix || ""), value)
                if (!response) {
                    throw "Error updating object"
                }
                if (property == "inventoryLocation") {
                    if (typeof value == "object" && value.hasOwnProperty("inventoryLocation")) {
                        value = value.inventoryLocation
                    }
                    if (typeof value == "string") {
                        value = JSON.parse(value)
                    }
                    
                }
                this.useStore?.().setObjectTypeObjectProperty(accountId,objectId,value,property,propertyPath)
                return true
            }
            catch (e: any) {
                devLog.log("ObjectTypeClass", e.message, e, "error")
                throw e
            }
        },


        /**
        * Adds or updates object to sms-mgt-api
        * @param accountId 
        * @param objectId 
        * @param object 
        * @param customerId 
        */
        addOrUpdateObjectFromApi: async (accountId, objectId, object, customerId?, updateStore = true) => {
            if (objectId) {
                return await this.queries.updateObjectFromApi(accountId, objectId, object,customerId,updateStore)
            }
            else {
                return await this.queries.addObjectToApi(accountId, object,customerId,updateStore)
            }
        },

        /**
        * Deletes object from api
        * @param accountId 
        * @param objectId 
        * @param customerId 
        */
        deleteObjectFromApi: async (accountId, objectId, customerId?, updateStore = true,customPayload?:any) => {
            try {
                let response = await requestHandler.request(this.options.apiInfo.deleteObjectMethod, this.getDeleteObjectApiUrlPath(accountId, objectId, customerId))
                response = apis.parseApiResponse(response)
                if(updateStore) {
                    this.useStore?.().deleteObjectTypeObjectFromStore(accountId,objectId)
                }
                return true
            }
            catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }
    }

    // ITEMLIST 
    public itemlist: ObjectTypeItemlistHelpers = {
        // get itemlist toolbar buttons
        getToolbarButtons: (accountId, itemlistComponent) => [],
        // get itemlist infobox content
        getInfoBoxContent: (accountId, itemlistComponent) => {return ""},
        // get itemlist sortingOptions 
        getSortingOptions: (accountId, itemlistComponent) => [{ "id": this.options.objectTypeInfo.nameProperty.primary, "text": "Name" }]
    }

    // ITEMLIST-ITEM 
    public itemlistItem: ObjectTypeItemlistItemHelpers<T> = {
        // itemlist-item onClick action
        onClick: (accountId: AccountId, item: ObjectTypeObject<T>) => {
            console.log('onClick action is not defined for "' + T(this.options.appearance.text.plural) + '"')
        },
        // get object title
        getTitle: (item, component) => {
            let result: TitleInfo = {
                "small": "",
                "title": ""
            }
            if(item) {
                let primaryNameKey = this.options.objectTypeInfo.nameProperty.primary as keyof ObjectTypeObject<T>
                let secondaryNameKey = this.options.objectTypeInfo.nameProperty.secondary as keyof ObjectTypeObject<T>
                let primaryName = primaryNameKey && item[primaryNameKey] ? String(item[primaryNameKey]) : undefined
                let secondaryName = secondaryNameKey && item[secondaryNameKey] ? String(item[secondaryNameKey]) : undefined
                result = {
                    "title": primaryName,
                    "small": <string | undefined>secondaryName,
                }
            }
            return result
        },
        // get itemlist-item menu entries
        getMenuEntries: (accountId, item,component) => [],
        // get itemlist-item labels
        getLabels: (accountId, item) => [],
        // get itemlist-item details
        getDetails: (accountId, item, component) => {
            let result: ItemlistItemDetail[] = []
            const primaryKey = this.options.objectTypeInfo.primaryKeyProperty.property as keyof ObjectTypeObject<T>
            const id = item?.[primaryKey]
            result.push({
                "key": "ID",
                "title": "ID",
                "value": String(id),
                "iconClass": "fa fa-hashtag",
            })
            return result
        },
        // get itemlist-item status
        getStatus: (accountId, item) => undefined,
        // get itemlist-item disabled state
        getDisabledState: (accountId, item) => false,
        // get itemlist-item clickable state
        isClickable: (accountId, item) => true,
        // get itemlist-item icon class
        getIconBackground: (accountId, item) => {
            return this.itemlistItem.isChecked(item) ? "bg-" + this.options.appearance.color : "hover-bg-" + this.options.appearance.color
        },
        // get itemlist-item icon background-image
        getIconBackgroundImage: (accountId, item) => null,
        // get itemlist-item checkbox existence,
        hasCheckbox: (item) => false,
        // get itemlist-item checkbox isChecked state
        isChecked: (item) => item?.$itemlist?.isCheckboxChecked || false,
    }

    /** ObjectType specific dialogs */
    public dialogs : ObjectTypeDialogs<T> = {
        getDeleteObjectDialog: (accountId, object, customerId,confirm = false) => {
            let singularOfObjectType = this.options.appearance.text.singular.toLocaleLowerCase()
            let objectIdProperty = this.options.objectTypeInfo.primaryKeyProperty.property
            let objectId = this.getObjectId(object)
            let objectName = this.itemlistItem.getTitle(object as ObjectTypeObject<T>,undefined).title
            miscDialogs.confirmDialog(accountId, T("Confirm delete"), T('Do you really want to delete this ' + singularOfObjectType + '?') + (objectName ? " (" + objectName +")" : ""), async () => {
                await this.queries.deleteObjectFromApi(accountId, objectId,customerId)
            }, confirm ? T("Delete") : undefined)
        }
    }

    public replaceStoreFunctionWith : ObjectTypeStoreReplacements<T> = {}

    /**
    * Generates a stringified GET payload  
    * @param props
    * @param withTimeStamp
    */
    getPropertiesString(props: GetPropertiesObjectList, withTimeStamp: boolean = false) {
        let timeStamp: number = Date.now()
        let propertiesString: string = ""
        if (withTimeStamp === true) {
            propertiesString += "?_=" + timeStamp
        }
        (props || []).forEach((prop) => {
            if (propertiesString.length == 0) {
                propertiesString += "?" + prop.property + "=" + prop.value
            }
            else {
                propertiesString += "&" + prop.property + "=" + prop.value
            }
        })
        return propertiesString
    }

    /**
     * Converts Object for ObjectTypeStore
     */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<T>) {
        objectBase.$itemlist = {
            "isCheckboxChecked":false,
            "isCheckboxHovering":false,
        }
        let newObject = jsonHelpers.copyObject(objectBase) as ObjectTypeObject<T>
        objectBase.toJSON = () => {
            delete newObject.$itemlist
            delete newObject.toJSON
            return newObject as T
        }
    }
}

// TYPES

export interface ObjectTypeStore<T> {
    "objects": Array<T>,
    "count": undefined | number,
    "gettingObjects":boolean,
    "objectsTimestamp": number,
    "countTimestamp": number
}
export type AccountId = string
export type ObjectTypeStores<T> = { [accountId: AccountId]: ObjectTypeStore<T> }

export type GetPropertiesObjectList = Array<{ 'property': string, 'value': string | number | boolean }>
export interface ObjectTypePayload<T> {
    "objectType": ObjectTypeOptions<T>["objectType"],
    "productType": ObjectTypeOptions<T>["productType"],
    "slug": ObjectTypeOptions<T>["slug"],
    "appearance"?: {
        "iconClass"?: ObjectTypeOptions<T>["appearance"]["iconClass"],
        "color"?: ObjectTypeOptions<T>["appearance"]["color"],
        "text"?: ObjectTypeOptions<T>["appearance"]["text"],
        "showOnDashboard"?: boolean,
        "showInSidebar"?: boolean,
    },
    "hasStore"?: ObjectTypeOptions<T>["hasStore"],
    "objectTypeInfo"?: {
        "primaryKeyProperty"?: ObjectTypeOptions<T>["objectTypeInfo"]["primaryKeyProperty"],
        "nameProperty"?: ObjectTypeOptions<T>["objectTypeInfo"]["nameProperty"]
    },
    "apiInfo": {
        "url"?: ObjectTypeOptions<T>["apiInfo"]["url"],
        "getObjectListPath"?: ObjectTypeOptions<T>["apiInfo"]["getObjectListPath"],
        "getObjectListMethod"?: ObjectTypeOptions<T>["apiInfo"]["getObjectListMethod"],
        "getObjectListUrl"?: ObjectTypeOptions<T>["apiInfo"]["getObjectListUrl"],
        "getObjectListResponseProperty"?: ObjectTypeOptions<T>["apiInfo"]["getObjectListResponseProperty"],
        "getObjectPath"?: ObjectTypeOptions<T>["apiInfo"]["getObjectPath"],
        "getObjectMethod"?: ObjectTypeOptions<T>["apiInfo"]["getObjectMethod"],
        "getObjectUrl"?: ObjectTypeOptions<T>["apiInfo"]["getObjectUrl"],
        "getObjectResponseProperty"?: ObjectTypeOptions<T>["apiInfo"]["getObjectResponseProperty"],
        "addObjectPath"?: ObjectTypeOptions<T>["apiInfo"]["addObjectPath"],
        "addObjectMethod"?: ObjectTypeOptions<T>["apiInfo"]["addObjectMethod"],
        "addObjectUrl"?: ObjectTypeOptions<T>["apiInfo"]["addObjectUrl"],
        "updateObjectPath"?: ObjectTypeOptions<T>["apiInfo"]["updateObjectPath"],
        "updateObjectMethod"?: ObjectTypeOptions<T>["apiInfo"]["updateObjectMethod"],
        "updateObjectUrl"?: ObjectTypeOptions<T>["apiInfo"]["updateObjectUrl"],
        "deleteObjectPath"?: ObjectTypeOptions<T>["apiInfo"]["deleteObjectPath"],
        "deleteObjectMethod"?: ObjectTypeOptions<T>["apiInfo"]["deleteObjectMethod"],
        "deleteObjectUrl"?: ObjectTypeOptions<T>["apiInfo"]["deleteObjectUrl"],
        "getCountGETProperties"?: ObjectTypeOptions<T>["apiInfo"]["getCountGETProperties"],
        "getCountGETResponseProperty"?: ObjectTypeOptions<T>["apiInfo"]["getCountGETResponseProperty"],
    }
}

export type ObjectTypeStoreReplacements<T> = {
    setObjectTypeObjects?:(accountId:AccountId,Objects:T[]) => void,
    setObjectTypeObject?: (accountId: AccountId, objectId:string, Object: T) => void,
    addObjectTypeObject?: (accountId: AccountId, Object: T) => void
}

export type AddObjectTypeObject<T> = T & {
    "$itemlist"?: ItemlistItemInfo,
    "toJSON"?: () => T,
}
export type ObjectTypeObject<T> = T & {
    "$itemlist"?: ItemlistItemInfo
    "toJSON"?: () => T
}
export interface ItemlistItemInfo {
    "isCheckboxChecked"?: boolean
    "isCheckboxHovering"?: boolean
}
export interface ObjectTypeOptions<T> {
    "objectType": string,
    "productType": ProductType,
    "slug": string
    "appearance": {
        "iconClass": string,
        "color": "red" | "orange" | "yellow" | "green" | "purple" | "blue" | "lightblue" | "darkblue",
        "text": {
            "title": string,
            "sidebarName": string,
            "plural": string,
            "singular": string
        },
        "showOnDashboard": boolean,
        "showInSidebar": boolean,
    },
    "hasStore": boolean,
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": string | keyof T | keyof ObjectTypeObject<T>,
            "pathToPrimaryProperty"?: string | undefined,
            "encode"?:(id:string) => string,
            "decode"?:(id:string) => string,
        },
        "nameProperty": {
            "primary": string,
            "pathToPrimaryProperty"?: string | undefined,
            "secondary"?: string | undefined,
            "pathToSecondaryProperty"?: string | undefined
        }
    },
    "apiInfo": {
        "url": string
        "getObjectListPath": string,
        "getObjectListMethod": "GET" | "POST",
        "getObjectListUrl": string,
        "getObjectListResponseProperty": string | Function
        "getObjectPath": string,
        "getObjectMethod": "GET" | "POST",
        "getObjectUrl": string,
        "getObjectResponseProperty": string | Function
        "addObjectPath": string,
        "addObjectMethod": "POST" | "PUT",
        "addObjectUrl": string,
        "updateObjectPath": string,
        "updateObjectMethod": "PUT" | "POST",
        "updateObjectUrl": string,
        "deleteObjectPath": string,
        "deleteObjectMethod": "DELETE"|"POST",
        "deleteObjectUrl": string,
        "getCountGETProperties": string
        "getCountGETResponseProperty": string | Function
    }
}

export interface ItemlistItemStatus {
    "color": "red" | "orange" | "yellow" | "green"
    "tooltip"?: {
        "title": string
        "text": string
    }
}

export interface TitleInfo {
    title: string | undefined,
    small?: string | undefined,
    link?: {
        "innerHtml": string
        "onClick": Function
        "showIf": Function
    } | undefined
}

export interface MenuEntry extends ButtonOptions {}

export interface Label {
    text: string
    title: string
    icon?: string
    class?: string
    htmlTooltip?:boolean
    onClick?: () => any
    displayType?: "label",
    lineBreakAfter?: boolean,
    lineBreakBefore?: boolean,
    float?: "right" | "left"
}

export interface ItemlistItemDetail {
    key: string
    value?: string | number | Array<any>
    title: string
    iconClass: string
    labels?: Array<Label>
    buttons?: Array<Button>
    iconValue?: string
    editableContent?: {
        type: string,
        options: string,
        ref: string,
        value: any,
        editingBoolProperty: string,
        editButton: Button,
        select2Settings?: any,
        submitFunction: Function | Promise<any>
        abortFunction: Function | Promise<any>,
        multiple?: boolean
    },
    regularlyUpdatedData?: {
        "inputValue": any,
        "method": Function,
        "refreshAfterMs": number,
        "frequency"?: number
    }
}



export interface ObjectTypeQueries<T> {
    getCountFromApi(accountId: AccountId, updateStore?:boolean): Promise<any>,
    getObjectsFromApi(accountId: AccountId, customerId?: string, props?: GetPropertiesObjectList,updateStore?:boolean,removeOldObjects?:boolean): Promise<T[] | Error>
    getObjectFromApi(accountId: AccountId, objectId: string | number, customerId?: string, props?: GetPropertiesObjectList,updateStore?:boolean,deleteOldObject?:boolean): Promise<T | Error>,
    addObjectToApi(accountId: AccountId, object: any,customerId?:string,updateStore?:boolean): Promise<T | Error>,
    updateObjectFromApi(accountId: AccountId, objectId: string | number, object: T, customerId?: string, updateStore?: boolean, altMethod?: "PUT" | "POST"): Promise<T | Error>,
    updateObjectPropertiesFromApi(accountId: AccountId, objectId: string, value: any, property?: string,propertyPath?:string[], customerId?: string, urlPostfix?: string,altMethod?:"PUT"|"POST",updateStore?:boolean): Promise<true | Error>,
    addOrUpdateObjectFromApi(accountId: AccountId, objectId: string | number | undefined, object: ObjectTypeObject<T>,customerId?:string,updateStore?:boolean): Promise<T | Error>,
    deleteObjectFromApi(accountId: AccountId, objectId: string | number,customerId?:string,updateStore?:boolean,customPayload?:any): Promise<true | Error>,
    updateEnrollmentlimitFromLicense?:(accountId: string, licenseId: string, enrollmentLimit: number) => Promise<T | Error>,
    deleteEntrollmentlimitFromLicense?:(accountId: string, licenseId: string) => Promise<true | Error>
} 

export interface ObjectTypeItemlistHelpers {
    getToolbarButtons: (accountId: AccountId, itemlistComponent: any) => {
        icon: string,
        title: string,
        link?: string,
        onClick?: Function,
        id: string,
        disabled?: boolean,
        vIf?: boolean | string
    }[],
    getInfoBoxContent: (accountId: AccountId, itemlistComponent: any) => string,
    getSortingOptions: (accountId: AccountId, itemlistComponent: any) => { "id": string, "text": string }[]
}
export interface ObjectTypeItemlistItemHelpers<T> {
    onClick: (accountId: AccountId, item: ObjectTypeObject<T>) => void
    getTitle: (item: ObjectTypeObject<T>, component: any) => {
        title: string | undefined,
        small?: string | undefined,
        link?: {
            "innerHtml": string
            "onClick": Function
            "showIf": Function
        } | undefined
    },
    getMenuEntries: (accountId: AccountId, item: ObjectTypeObject<T>,component:any) => MenuEntry[],
    getLabels: (accountId: AccountId, item: ObjectTypeObject<T>) => Label[],
    getDetails: (accountId: AccountId, item: ObjectTypeObject<T>, component: any) => ItemlistItemDetail[]
    getStatus: (accountId: AccountId, item: ObjectTypeObject<T>) => ItemlistItemStatus | undefined
    getDisabledState: (accountId: AccountId, item: ObjectTypeObject<T>) => boolean
    isClickable: (accountId: AccountId, item: ObjectTypeObject<T>) => boolean
    getIconBackground: (accountId: AccountId, item: ObjectTypeObject<T>) => string | undefined
    getIconBackgroundImage: (accountId: AccountId, item: ObjectTypeObject<T>) => string | null
    hasCheckbox: ((item: ObjectTypeObject<T>) => boolean) | boolean
    isChecked: (item: ObjectTypeObject<T>) => boolean
}


export interface ObjectTypeDialogs<T> {
    getDeleteObjectDialog: (accountId: AccountId, object: T,customerId?:string, confirm?: boolean) => void
}