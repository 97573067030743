import type { License } from "@/classes/unifiedSecurity/licenses";
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "../components/loader.vue";
import { T } from "@/classes/i18n";
import queries from "@/queries/queries";
import objectStores from "@/classes/init";
import moment from "moment"
import tenantHelpers from "@/helpers/helpers.tenants";
import { useStore } from '@/store/vuex.store'
import mixinHelpers from "@/helpers/helpers.mixins";
import licenseHelpers from "@/helpers/helpers.license";
import config from "@/classes/config";
import products from "@/classes/objectTypes";

const licenseAssignmentDialogComponent = {
    mixins: [useGlobalMixin()],
    "name":"license-assignment-dialog",
    "template": `
        <div>
            <div class="row padding-xs-y form-group border-bottom">
                <div class="input col-xs-24 col-lg-12">
                    <label class="form-field text">
                        <span class="form-icon-prefix">
                            <i class="fal fa-search"></i>
                        </span>
                        <input id="search" type="text" :placeholder="T('Search')" v-model="search.value">
                    </label>
                </div>
            </div>
            <template v-if="initialized">
                <div class="padding-xs-t padding-xs-x">
                    <template v-for="device in allDevices">
                        <div class="row padding-xs-y form-group border-bottom">
                            <div class="first col-xs-24 col-lg-10">
                                <label
                                    class="control-label inputname"
                                    :for="'input'+device.id"
                                    :style="!canSetLicense(device) ? 'opacity:0.5' : ''"
                                >
                                    <i class="fa-fw" :class="{
                                        'fal fa-mobile-alt':device.objectType == 'devices' || device.objectType == 'enterpriseDevices' || device.objectType == 'androidDevices',
                                        'fal fa-laptop':device.objectType == 'vpns'
                                    }"></i>&nbsp;&nbsp;<span v-html="device.text"></span>
                                </label>
                            </div>
                            <div class="input toggle col-xs-24 col-lg-14">
                                <select
                                    v-model="device.license.selectedUUID"
                                    :disabled="!canSetLicense(device) || null"
                                    :id="'input'+device.id"
                                >
                                    <option value="" disabled>{{ T('Please select a license') }}</option>
                                    <template v-for="license in licenseOptions">
                                        <option
                                            :value="license.id"
                                            :disabled="license.optionDisabled || null"
                                        >
                                            {{ license.optionText }}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
                    <span v-if="loaderInfo"  style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
                </div>
            </template>
        </div>
    `,
    "data":() => {
        return {
            "initialized":false,
            "loaderInfo":"Loading...",
            "initialHint": undefined,
            "licenseOptions":{},
            "allDevices":[],
            "refresh":undefined,
            "search":{ "value":"" }
        }
    },
    "computed": {
        "activeAccountId": function(this: any) {
            return useStore().state.session.activeAccountId || undefined
        },
        "licenses": function(this:any) {
            return (config.canUseNewObjectType("licenses")) ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(this.activeAccountId) : this.$getObjectProperty(this,"$store.state.session.accounts."+this.properties.accountid+".unifiedSecurity.objectTypes.licenses.items")
        }
    },
    "props": {
        "properties":{
            required:true,
            default:() => {
                return {
                    "accountid":undefined
                }
            }
        }
    },
    "methods": {
        "init": async function(this:any) {
            let allDevices : any[] = []

            for(let i : number = 0; this.licenses.length > i; i++) {
                let license : License = this.licenses[i]
                let licenceUser = mixinHelpers.getLicenseLimit(license)
                if ((license.type == 'Mobile Security' || license.type == 'MDM') && license.uuid && !this.hasNewerLicense(this.licenses,license)) {
                    this.licenseOptions[license.uuid] = {
                        "id":license.uuid,
                        "optionText": license.name + " | "+license.type+" ["+license.assignedUser+"/"+licenceUser+"] ("+license.uuid.substring(0,4)+")",
                        "optionDisabled": license.assignedUser >= licenceUser || license.state == 'valid',
                        "devicesCount":0,
                        "maxDevices":licenceUser,
                        "state":license.state,
                        "type":license.type,
                        "expirationDate":license.expirationDate
                    }
                }
            }
            const enterpriseGetProperties = [{ "property": "props[]", "value": "appliedPolicyName" }, { "property": "props[]", "value": "appliedState" }, { "property": "props[]", "value": "enrollmentTime" }, { "property": "props[]", "value": "props" }, { "property": "props[]", "value": "hardwareInfo" }, { "property": "props[]", "value": "lastStatusReportTime" }, { "property": "props[]", "value": "managementMode" }, { "property": "props[]", "value": "name" }, { "property": "props[]", "value": "ownership" }, { "property": "props[]", "value": "policyName" }, { "property": "props[]", "value": "previousDeviceNames" }, { "property": "props[]", "value": "state" }, { "property": "props[]", "value": "alias" }, { "property": "props[]", "value": "deviceId" }, { "property": "props[]", "value": "username" }, { "property": "props[]", "value": "consent" }, { "property": "props[]", "value": "licenseUUID" }, { "property": "props[]", "value": "signedIn" }, { "property": "fields", "value": "*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)" }]
            let devicesResult : any = await Promise.all([
                licenseHelpers.hasOneOfLicenses(this.activeAccountId, ['Mobile Security', 'MDM'], 'valid') ? config.canUseNewObjectType("iosDevices") ? products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.properties.accountid) : queries.mobileSecurity.getObjectInfos(this.properties.accountid,'devices'): null,
                tenantHelpers.hasFunctionality(this.properties.accountid, "enterprise") ? config.canUseNewObjectType("androidDevices") ? products.mobileSecurity.androidDevices.queries.getObjectsFromApi(this.properties.accountid, undefined, enterpriseGetProperties) : objectStores.enterpriseDevices.getObjectsFromApi(this.properties.accountid, enterpriseGetProperties) : null,
                licenseHelpers.hasLicense(this.activeAccountId, 'Mobile Security', 'valid') ? config.canUseNewObjectType('vpns') ? await products.mobileSecurity.vpns.queries.getObjectsFromApi(this.properties.accountid) : queries.mobileSecurity.getObjectInfos(this.properties.accountid,'vpns') : null
            ])

            if (devicesResult[0]) {
                for (let i : number = 0; devicesResult[0].devices.length > i; i++) {
                    let device : any = devicesResult[0].devices[i]
                    if (device.configured && device.licenseUUID != '') {
                        let licenseUUID : string = device.licenseUUID
                        if (this.licenseOptions[licenseUUID] != undefined) {
                            this.licenseOptions[licenseUUID].devicesCount++
                        }
                        else {
                            this.licenseOptions[licenseUUID] = {
                                "id":licenseUUID,
                                "text":T("unknown license"),
                                "devicesCount":0,
                                "maxDevices":0,
                                "state":"invalid"
                            }
                        }

                        allDevices.push({
                            "id": device.deviceId,
                            "text": device.alias != '' ? device.alias + ' (' + device.deviceId.substr(0,4) + ')' : device.deviceId.substr(0,4),
                            "license":{
                                "current":this.licenseOptions[licenseUUID],
                                "currentUUID":licenseUUID,
                                "selectedUUID":licenseUUID
                            },
                            "objectType":"devices",
                            "info": device
                        })
                    }
                }
            }
            if (devicesResult[1]) {
                for (let i : number = 0; devicesResult[1].devices.length > i; i++) {
                    let device : any = devicesResult[1].devices[i]
                    if (device.licenseUUID != '') {
                        let licenseUUID : string = device.licenseUUID
                        if (this.licenseOptions[licenseUUID] != undefined) {
                            this.licenseOptions[licenseUUID].devicesCount++
                        }
                        else {
                            this.licenseOptions[licenseUUID] = {
                                "id":licenseUUID,
                                "text":T("unknown license"),
                                "devicesCount":0,
                                "maxDevices":0,
                                "state":"invalid"
                            }
                        }

                        allDevices.push({
                            "id": device.deviceId,
                            "text": device.alias != '' ? device.alias + ' (' + device.deviceId.substr(0,4) + ')' : device.deviceId.substr(0,4),
                            "license":{
                                "current":this.licenseOptions[licenseUUID],
                                "currentUUID":licenseUUID,
                                "selectedUUID":licenseUUID
                            },
                            "objectType": config.canUseNewObjectType("androidDevices") ? "androidDevices" : "enterpriseDevices",
                            "info": device
                        })
                    }
                }
            }
            if (devicesResult[2]) {
                for (let i : number = 0; devicesResult[2].vpns.length > i; i++) {
                    let vpn : any = devicesResult[2].vpns[i]
                    if (vpn.licenseUUID != '') {
                        let licenseUUID : string = vpn.licenseUUID
                        if (this.licenseOptions[licenseUUID] != undefined) {
                            this.licenseOptions[licenseUUID].devicesCount++
                        }
                        else {
                            this.licenseOptions[licenseUUID] = {
                                "id":licenseUUID,
                                "text":T("unknown license"),
                                "devicesCount":0,
                                "maxDevices":0,
                                "state":"invalid"
                            }
                        }

                        allDevices.push({
                            "id": vpn.vpnId,
                            "text": vpn.vpnname,
                            "license":{
                                "current":this.licenseOptions[licenseUUID],
                                "currentUUID":licenseUUID,
                                "selectedUUID":licenseUUID
                            },
                            "objectType":"vpns",
                            "info": vpn
                        })
                    }
                }
            }

            this.allDevices = allDevices

            this.initialized = true
        },
        hasNewerLicense: function(this:any,licenseMap:License[],license:License) {
            let result : boolean = false
            for (let license2 of licenseMap) {
                if ((license.uuid == license2.uuid) && (license.id != license2.id) && (!moment(license.expirationDate).isSameOrAfter(moment(license2.expirationDate)) )) {
                    result = true
                    break
                }
            }
            return result
        },
        canSetLicense: function(this:any,device:any) {
            let result : boolean = false
            if(!licenseHelpers.hasLicense(this.activeAccountId,'Mobile Security','valid') && !licenseHelpers.hasLicense(this.activeAccountId,'MDM','valid')) {
                // ignore accounts without licenses
            }
            else if ((["devices", "enterpriseDevices", "androidDevices"].indexOf(device.objectType) != -1) && (!device.info.configured)) {
                // ignore devices that are not configured
            }
            else if (["MDM","Mobile Security"].indexOf(device.license.current?.type) != -1 && device.license.current.state == "valid") {
                // ignore devices with correct licenses
            }
            else if (device.info.signedIn === false) {
                // ignore devices that are signed out
            }
            else if (device.license.currentUUID == "") {
                // accept devices with no license information
                result = true
            }
            else if (device.license.current == undefined) {
                // accept devices with no license information
                result = true
            }
            else if (device.license.current.expirationDate < (new Date()).toISOString().substring(0, 10)) {
                // accept devices with expired license information
                result = true
            }

            return result
        },

    },
    "created": async function(this:any) { },
    "mounted": function(this:any) {
        this.init()
    },
    "watch": {},
    "beforeUnmount": function(this:any) {},
    "components": {
        "loader":loaderComponent
    }
}
export default licenseAssignmentDialogComponent