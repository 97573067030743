<script lang="ts" setup>
    import i18n, { T } from "@/classes/i18n";
    import type { FrontendNotification } from "@/classes/notifications";
    import timeHelpers from "@/helpers/helpers.time";
    import frontendNotifications from "@/classes/notifications";
    import { sprintf } from "sprintf-js";
    import { computed, onMounted, ref } from "vue";
    import getterHelpers from "@/helpers/helpers.getters";

    const props = withDefaults(defineProps<({
        index?:number,
        notification:FrontendNotification,
        addSelectedNotifications:(notifications:Array<FrontendNotification["id"]>) => void
        removeSelectedNotifications:(notifications:Array<FrontendNotification["id"]>) => void
    })>(), {
        notification: function () {
                return {
                "accountId":"",
                "id":"",
                "content":{
                    "title":{
                        "text":"Notification",
                        "icon":"fal fa-info-circle",
                    },
                    "body":{
                        "content":"",
                        "component":undefined,
                        "properties":undefined
                    },
                },
                "onClick":function() {

                },
                "state":"new",
                "highlightNew":false,
                "product":"Unified Security",
                "timestamp":timeHelpers.getUnixTimeStamp() * 1000
            }}
        }
    )

    const activeAccountId = computed(() => {
        return getterHelpers.useStore().state.session.activeAccountId
    })

    const selected = ref(false)
    const hovering = ref(false)



// METHODS
    const translate = (content:string) => {
        content = T(content)
        let finalizedRegex = new RegExp(/An update from ([a-z0-9.-]*) to ([a-z0-9.-]*) has been finalized for (.*)/)
        if (finalizedRegex.test(content)) {              
            const found = content.match(finalizedRegex)
            if (found) {
                const fromVersion = found[1]
                const toVersion = found[2]
                const deviceName = found[3]
                return sprintf(T("An update from %1s to %2s has been finalized for %3s"),fromVersion, toVersion, deviceName)
            }
        }
        let finalizeRegex = new RegExp(/An update from ([a-z0-9.-]*) to ([a-z0-9.-]*) can be finalized for (.*)/)
        if (finalizeRegex.test(content)) {              
            const found = content.match(finalizeRegex)
            if (found) {
                const fromVersion = found[1]
                const toVersion = found[2]
                const deviceName = found[3]
                return sprintf(T("An update from %1s to %2s can be finalized for %3s"),fromVersion, toVersion, deviceName)
            }
        }

        if (content.indexOf("An invalid Websession pin was entered on") != -1) {
            let utmInfo = content.replace("An invalid Websession pin was entered on","")
            return T("An invalid Websession pin was entered on $1").replace("$1",utmInfo)
        }
        else if (content.indexOf("An invalid websession pin entered on") != -1) {
            let utmInfo = content.replace("An invalid websession pin entered on","")
            return T("An invalid Websession pin was entered on $1").replace("$1",utmInfo)
        }
        else if (content.indexOf("The Websession functionality was locked due to too many incorrect pin entries.") != -1) {
            let utmInfo = content.replace("The Websession functionality was locked due to too many incorrect pin entries. ", "")
            return T("The Websession functionality was locked due to too many incorrect pin entries. $1").replace("$1", utmInfo)
        }
        else if (content.indexOf("The websession functionality was locked due to too many incorrect pin entries.") != -1) {
            let utmInfo = content.replace("The websession functionality was locked due to too many incorrect pin entries. ", "")
            return T("The Websession functionality was locked due to too many incorrect pin entries. $1").replace("$1", utmInfo)
        }
        
        return content
    }
    const deleteNotification = async () => {
        return await removeNotification(props.notification.id, props.notification.accountId)
    }
    const getDate = (timestamp:any) => {
        return timeHelpers.formatDate(timestamp,timeHelpers.getDateFormatI18n(i18n.getLanguage()))
    }
    const getIconClass = (notification:any) => {
        return selected.value ? "fal fa-check-square" : hovering.value ? "fal fa-square" : notification.content.title.icon
    }
    const onClick = async ($event:any) => {
        let notification: FrontendNotification = props.notification

        if (typeof notification.onClick == 'function') {
            await notification.onClick()
            notification.onClick = undefined
        }
        notification.state = "read"
    }
    const removeNotification = (id:string,accountId?:string) => {
        accountId = accountId ? accountId : activeAccountId.value
        frontendNotifications.removeNotification(<string>accountId, id)
    }

    onMounted(async () => {
        if (props.notification.highlightNew == true) {
            await timeHelpers.sleep(5000)
            props.notification.highlightNew = false
        }
    })

</script>

<template>
    <div 
        class="content" 
        v-on:click="onClick"
        style="cursor:pointer"
        :class="[notification.state,{'selected':selected}]"
    >
        <small class="product">
            {{ notification.product || 'System' }}
        </small>
        <small v-if="notification.timestamp" class="date">
            {{ getDate(notification.timestamp) }}
        </small>
        <div class="title">
            <span 
                class="icon"
                :class="{'selected bg-red color-white':selected, 'color-red':selected}"
                v-on:mouseenter="hovering = true"
                v-on:mouseleave="hovering = false"
                v-on:click.stop.prevent="() => { selected ? removeSelectedNotifications([notification.id]) : addSelectedNotifications([notification.id]); selected = !selected }"
            >
                <i 
                    class="fa-fw" 
                    :class="getIconClass(notification)"></i>
            </span>
            <span>
                {{ T(notification.content.title.text) }}
            </span>
        </div>
        <div class="body">
            <span>
                {{ translate(notification.content?.body?.content || "") }}
            </span>
        </div>
        <a class="deleteButton" v-on:click.stop.prevent="deleteNotification" :title="T('Delete')">
            <i class="fal fa-trash"></i>
        </a>
    </div>
</template>
<script lang="scss">

</script>

