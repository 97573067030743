import tenantHelpers from "@/helpers/helpers.tenants";
import requestHandler from "@/queries/requests";
import { defineStore } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export const useFeatureStore = defineStore('features', () => {
    const vuexStore = useStore()

    const features = ref(new Set<string>())
    const accountId = computed(() => vuexStore.state.session.activeAccountId)

    async function updateFeatures() {
        try {
            if (!accountId.value) {
                return
            }

            const apiFeatures = await requestHandler.request("GET", `/sms-mgt-api/api/2.0/tenants/${tenantHelpers.getTenantDomain(accountId.value)}/features`)
            features.value = new Set(apiFeatures)
        } catch (e) {
            console.error("failed to load features", e)
        }
    }

    function isEnabled(feature: string) {
        return features.value.has(feature)
    }

    watch(accountId, () => updateFeatures())
    onMounted(() => updateFeatures())

    return {
        isEnabled
    }
})