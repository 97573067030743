import router from "@/router/router"
import { GenericObjectStore, type GenericObject, type MenuEntry, type Label, type ItemlistDetail, type GetPropertiesObjectList, type shemaErrors } from "../genericObjectStore"
import devLog from "../log"
import { T } from "../i18n"
import requestHandler from "@/queries/requests"
import tenantHelpers from "@/helpers/helpers.tenants"
import Button from "@/templates/components/button/button"
import encodingHelpers from "@/helpers/helpers.encoding"

export interface HomescreenLayoutBase {
  "Dock": HomescreenLayoutPage,
  "Pages": HomescreenLayoutPage[]
}
// Interfaces for default homescreenlayout payload
export interface HomescreenLayout extends GenericObject<HomescreenLayout> {
  "Name": string,
  "layoutId":string,
  "Dock": HomescreenLayoutPage,
  "DeviceType"?: "iPhone" | "iPad" | "TV"
  "Pages": HomescreenLayoutPage[]
}
export type HomescreenLayoutPage = HomescreenLayoutItem[] | HomescreenLayoutFormItem[]

export interface HomescreenLayoutItem {
  "BundleID"?: string, // Only if Type is "Application"
  "DisplayName"?: string,
  "Pages"?: HomescreenLayoutPage[], // Only if Type is "Folder"
  "Type": HomescreenLayoutItemType.Application | HomescreenLayoutItemType.Folder | HomescreenLayoutItemType.WebClip,
  
  "URL"?: string // Only if Type is "WebClip"
}
export interface HomescreenLayoutFormItem extends HomescreenLayoutItem {
  "assetInfos"?: any // Only used on frontend
  "draggingItemOver"?: boolean // Only used on frontend
  "randomId"?: string // Only used on frontend
  "webclipId"?: string // Only used on frontend
}

export enum HomescreenLayoutItemType {
  "Application" = "Application",
  "Folder" = "Folder",
  "WebClip" = "WebClip"
}

export default class Homescreenlayouts extends GenericObjectStore<HomescreenLayout> {
  constructor() {
    super()
    this.settings.primaryKeyProperty = "layoutId"
    this.settings.nameProperty.primary = "Name"
    this.settings.productType = "mobileSecurity"
    this.settings.objectType = "homescreenlayouts"
    this.settings.appearance.iconClass = "fal fa-mobile"
    this.settings.appearance.text.singular = "Home Screen Layout"
    this.settings.appearance.text.plural = "Home Screen Layouts"
    this.settings.appearance.text.title = "Home Screen Layouts"

    this.settings.apiInfo.url = "/sms-mgt-api/api/2.0"
    this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/ios/homeScreenLayout/layouts"
    this.settings.apiInfo.objectListPropertyInResponse = "layouts"
    // setup itemlist options

    this.itemlist.getToolbar = (accountId, itemlist) => {
      return [
        {
          icon: 'fal fa-plus',
          title: T('Add layout'),
          link: "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-homescreenlayout",
          id: 'addHomescreenLayout'
        }
      ]
    }

    this.itemlist.isDisabled = (accountId, item: HomescreenLayout): boolean => {
      return false
    }
    this.itemlist.isClickable = (accountId, item: HomescreenLayout): boolean => {
      return true
    }
    this.itemlist.onClick = (accountId: string, item: HomescreenLayout) => {
      let objectId = item[this.settings.primaryKeyProperty] as string
      if (objectId) {
        router.navigate('edit-tenant-'+tenantHelpers.getTenantDomain(accountId)+'-homescreenlayout-'+objectId)
      }
    }
    this.itemlist.hasCheckBox = true
    this.itemlist.getTitle = (item, useSmallTags) => {
      return {
        title: item?.Name
      }
    }
    this.itemlist.getStatus = (accountId: string,item) => {
      return undefined
    }
    this.itemlist.getMenuEntries = (accountId: string,item) => {
      let menuEnties: MenuEntry[] = [
        new Button({
          title: T('Edit'),
          text: T('Edit'),
          link: '#edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-homescreenlayout-' + item.layoutId,
          icon: 'fal fa-edit'
        }),
        new Button({
          "icon":"fal fa-trash",
          "text": T("Delete"),
          "onClick":() => { 
            this.dialogs.getDeleteObjectDialog(accountId,item)
          },
          "loading":false,
          "disabled":false,
        })
      ]
      return menuEnties
    }
    this.itemlist.getLabels = (accountId: string,item) => {
      let result: Label[] = []
      return result
    }
    this.itemlist.getDetails = (accountId: string,item?) => {
      let result: ItemlistDetail[] = [
        {
          "iconClass":"fal fa-rocket",
          "key":"Apps",
          "title":"Apps",
          "value": this.countItems(item,HomescreenLayoutItemType.Application).toString()
        },
        {
          "iconClass":"fal fa-video",
          "key":"Webclips",
          "title":"Webclips",
          "value": this.countItems(item,HomescreenLayoutItemType.WebClip).toString()
        },
        {
          "iconClass":"fal fa-folder-closed",
          "key":T("Folders"),
          "title":T("Folders"),
          "value": this.countItems(item,HomescreenLayoutItemType.Folder).toString()
        }
      ]
      return result
    }
  }

  countAppsItems(page: HomescreenLayoutPage, type: HomescreenLayoutItemType.Application | HomescreenLayoutItemType.WebClip | HomescreenLayoutItemType.Folder) {
    let count = 0
    page.forEach((item) => {
      if (item.Type == type) {
        count++
      }
      if (item.Type == HomescreenLayoutItemType.Folder && item.Pages != undefined) {
        item.Pages?.forEach((childPage) => {
          const childCount = this.countAppsItems(childPage,type)
          count = count + childCount
        })
      }
    })
    return count
  }
  countItems(homescreenLayout:HomescreenLayout,type:HomescreenLayoutItemType.Application|HomescreenLayoutItemType.WebClip | HomescreenLayoutItemType.Folder) {
    
    let count = (homescreenLayout?.Dock || []).filter((item) => {
      return item.Type == type
    }).length;

    ;(homescreenLayout?.Pages || [])?.forEach((childPage) => {
      count = count + this.countAppsItems(childPage,type)
    })
    return count
  }

  async getCountFromApi(accountId: string,updateLocalStore: boolean = true) {
    let result: number | Error = 0
    try {
      const countResult = await requestHandler.request("GET", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/homeScreenLayout/layouts?select=data.count")
      if (typeof countResult === "number") {
        result = countResult
        if (updateLocalStore) {
          this.setCount(accountId,countResult)
        }
      }
      else if (countResult.error) {
        throw new Error(countResult.error)
      }
      else {
        throw new Error('Error getting count for homescreenlayouts')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting count for homescreenlayouts')
      }
      devLog.log("HomescreenlayoutsStoreClass", result.message, result, "error")
    }
    return result
  }

  async getObjectsFromApi(accountId: string, props?: GetPropertiesObjectList, updateLocalStore: boolean = true) {
    let result: HomescreenLayout[] | Error
    try {
      const propertiesString: string = props ? this.getPropertiesString(props) : ""
      const response = await requestHandler.request("GET", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/homeScreenLayout/layouts" + propertiesString)
      if (response.homeScreenLayout) {
        result = response.homeScreenLayout as HomescreenLayout[]
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, result) // Add homescreenlayout to store
        }
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error getting homescreenlayouts')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting homescreenlayouts')
      }
      devLog.log("HomescreenlayoutsStoreClass", result.message, result, "error")
    }
    return result
  }
  
  async getObjectFromApi(accountId: string,objectId: HomescreenLayout[keyof HomescreenLayout], props?: GetPropertiesObjectList, updateLocalStore: boolean = true) {
    let result: HomescreenLayout | Error
    try {
      const propertiesString: string = props ? this.getPropertiesString(props) : ""
      const response = await requestHandler.request("GET", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/homeScreenLayout/layouts/" + encodingHelpers.encodeURI(objectId as string) + propertiesString)
      if (response.layoutId) {
        result = response as HomescreenLayout
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId,result) // Add homescreenlayout to store
        }
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error getting homescreenlayout')
      }
    }
    catch (e : any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error getting homescreenlayout')
      }
      devLog.log("HomescreenlayoutsStoreClass", result.message, result, "error")
    }
    return result
  }
  async updateObjectFromApi(accountId: string,objectId:HomescreenLayout["layoutId"],object: HomescreenLayout, updateLocalStore: boolean = true) {
    let result: HomescreenLayout | Error | shemaErrors
    try {
      const response = await requestHandler.request("PUT", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/homeScreenLayout/layouts/" + encodingHelpers.encodeURI(object.layoutId), object)
      if (response.layoutId) {
        result = object
        result.layoutId = response.layoutId
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId,object) // Add homescreenlayout to store
        }
      }
      else if (response.errors) {
        throw response.errors
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error updating homescreenlayout')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
        devLog.log("HomescreenlayoutsStoreClass", result.message, result, "error")
      }
      else {
        result = e as shemaErrors
        devLog.log("HomescreenlayoutsStoreClass", "Error updating homescreenlayout", result, "error")
      }
    }
    return result
  }
  async addObjectToApi(accountId: string, object: HomescreenLayout, updateLocalStore: boolean = true) {
    let result: HomescreenLayout | Error | shemaErrors
    try {
      const response = await requestHandler.request("POST", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/homeScreenLayout/layouts/", object)
      if (typeof response.data == "string") {
        result = object
        result.layoutId = response.data
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, object) // Add homescreenlayout to store
        }
      } else if (typeof response == "string") {
        result = object
        result.layoutId = response
        if (updateLocalStore) {
          this.addOrUpdateObjectsInStore(accountId, object) // Add homescreenlayout to store
        }
      }
      else if (response.errors) {
        throw response.errors
      }
      else if (response.error) {
        throw new Error(response.error)
      }
      else {
        throw new Error('Error adding homescreenlayout')
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
        devLog.log("HomescreenlayoutsStoreClass", result.message, result, "error")
      }
      else {
        result = e as shemaErrors
        devLog.log("HomescreenlayoutsStoreClass", "Error adding homescreenlayout", result, "error")
      }
    }
    return result
  }

  async deleteObjectFromApi(accountId: string, objectId: HomescreenLayout["layoutId"], updateLocalStore: boolean = true) {
    let result: true | Error
    try {
      const response = await requestHandler.request("DELETE", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/ios/homeScreenLayout/layouts/" + encodingHelpers.encodeURI(objectId as string))
      if (response.error) {
        throw new Error(response.error)
      }
      else {
        result = true
        if (updateLocalStore) {
          this.removeObjectFromStore(accountId, objectId) // Remove homescreenlayout from store
        }
      }
    }
    catch(e:any) {
      if (e instanceof Error) {
        result = new Error(e.message)
      }
      else {
        result = new Error('Error deleting homescreenlayout')
      }
      devLog.log("HomescreenlayoutsStoreClass", result.message, result, "error")
    }
    return result
  }
}
