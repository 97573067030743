<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
    import tableNext, { type TableEntryIcons, type TableEntryInfo } from '../components/tableNext.vue';
    import Button from "@/templates/components/button/button";
    import { T } from '@/classes/i18n';
    import stickyScrollNav from '../components/stickyScrollNav.vue';
    import type { Modal } from '../templates/modal';
    import getterHelpers from '@/helpers/helpers.getters';
    import { ActionTypes, MutationTypes, useStore } from '@/store/vuex.store';
    import objectStores from '@/classes/init';
    import deviceHelpers from '@/helpers/helpers.devices';
    import { type Nodes, defineConfigs, type Edges, type Layouts, type ViewEvent, type NodeEvent, type EdgeEvent, type EventHandlers } from "v-network-graph"
    import { ForceLayout, type ForceNodeDatum, type ForceEdgeDatum } from "v-network-graph/lib/force-layout"
    import itemlistItem from '../components/itemlist-item.vue';
    import products from '@/classes/objectTypes';
    import dialogs from '@/dialogs/dialogs';
    import type { SunRoadwarrior, SunRoadwarriorEntity, SunRoadwarriorOnAdd, SunRoadwarriorRule, SunRoadwarriorRuleOnAdd, SunNkViewService, SunReferenceNode, SunRule, SunRuleOnAdd, SunRuleOnUpdate, SunSatellite, SunTopology, SunTopologyChangedSite, SunUTMRuleOnAdd } from "@/classes/objectTypes/unifiedNetwork/topologies";
    import loader from '../components/loader.vue';
    import btn from '../components/button/button.vue';
    import { createPopper, preventOverflow } from '@popperjs/core';
    import encodingHelpers from '@/helpers/helpers.encoding';
    import arrayHelpers from '@/helpers/helpers.arrays';
    import stringHelpers from '@/helpers/helpers.strings';
    import ipaddr from '@/lib/ipaddr';
    import sessionHelpers from '@/helpers/helpers.session';
    import Label from '@/templates/components/label/label';
    import Icon from '@/templates/components/icon/icon';
    import tenantHelpers from '@/helpers/helpers.tenants';
    import licenseHelpers from '@/helpers/helpers.license';

    import diagramImage from '@/img/unc_diagramm_light.svg';
    import diagramImageDark from '@/img/unc_diagramm_dark.svg';


    const qaButtons = ref(<Button[]>[])


    const initialized = ref(false)
    const buttonUpdater = ref(0)
    const errors = ref(<any[]>[])
    const selectedTopologyId = ref(<string>"")

    const ruleClipboard = ref(<SunRule|SunRoadwarriorRule|undefined>undefined)

    const darkmode = computed(() => {
        return getterHelpers.useStore().state.browserSettings.darkmode == "1"
    })
    const activeAccountId = computed(() => {
        return getterHelpers.useStore()?.getters.getActiveAccountId
    })

    const nodes = computed(() => {
        return products.unifiedSecurityConsole.utmNodes.useStore?.().getObjectStoreObjects(activeAccountId.value) || []
    })
    const topologies = computed(() => {
        return products.unifiedSecurityConsole.topologies.useStore?.().getObjectStoreObjects(activeAccountId.value) || []
    })
    const selectedTopology = computed(() => {
        topologies.value;
        return products.unifiedSecurityConsole.topologies.useStore?.().getObjectStoreObject(activeAccountId.value,selectedTopologyId.value)
    })
    const topologyChanges = computed(() => {

        return selectedTopology.value?.changes?.data
    })
    const selectedCoreUtmId = computed(() => {
        return selectedTopology.value?.data.coreId || ""
    })
    const hasAnyChanges = computed(() => {
        const changes = selectedTopology.value?.changes.data
        const count = (changes?.addedNodes?.length || 0) + (changes?.changedNodes?.length || 0) + (changes?.changedSites?.length || 0) + (changes?.removedNodes?.length || 0) + (changes?.removedPools?.length || 0) + (changes?.addedPools?.length || 0) + (changes?.changedPools?.length || 0)
        return count > 0
    })
    const isPublishable = computed(() => {
        hasAnyChanges.value; // for reactivity
        if(selectedTopology.value && ((selectedTopology.value?.data.satellites || []).length > 0 || (selectedTopology.value?.data.pools || []).length > 0) && computedCoreUtmErrors.value.length == 0) {
            let errors = products.unifiedSecurityConsole.topologies.view.getSatelliteErrors(selectedTopology.value)
            if (errors.some((error) => { return error.indexOf("Model versions mismatch") != -1 })) {
                return true
            }
        }
        products.unifiedSecurityConsole.topologies.showDialogOnBeforeUnload = hasAnyChanges.value
        return hasAnyChanges.value && ((selectedTopology.value?.data.satellites || []).length > 0 || (selectedTopology.value?.data.pools || []).length > 0) && computedCoreUtmErrors.value.length == 0
    })
    const isReversible = computed(() => {
        return hasAnyChanges.value || false
    })
    const canAddCoreUtm = computed(() => {
        return topologies?.value?.length == 0 || false
    })
    const canAddSatellite = computed(() => {
        const transferNetwork = selectedTopology.value?.data.properties.transferNetwork
        if(transferNetwork && computedCoreUtmErrors.value.length == 0) {
            const address = new ipaddr(transferNetwork)
            if ((address.isIPv4() || address.isIPv6()) && address.hasCidr()) {

                const satelliteCount = (selectedTopology.value?.data.satellites.filter((satellite) => {
                    return topologyChanges.value?.removedNodes.indexOf(satellite.id) == -1
                }).length || 0) + 1 // add one for core

                const v4CidrToIpLimit = products.unifiedSecurityConsole.topologies.v4CidrToIpLimit
                const v6CidrToIpLimit = products.unifiedSecurityConsole.topologies.v6CidrToIpLimit

                if (address.isIPv4() && (Number(address.cidr) <= 30 && Number(address.cidr) >= 24) && satelliteCount >= v4CidrToIpLimit[String(address.cidr) as keyof typeof v4CidrToIpLimit]) {
                    return false
                }
                if (address.isIPv6() && (Number(address.cidr) <= 126 || Number(address.cidr) >= 120) && satelliteCount >= v6CidrToIpLimit[String(address.cidr) as keyof typeof v6CidrToIpLimit]) {
                    return false
                }
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    })
    

    const coreUtmInfo = computed(() => {
        return objectStores.uscUtms.getObjectFromStore(activeAccountId.value,selectedCoreUtmId.value)
    })
    const utmStates = computed(() => {
        return getterHelpers.useStore()?.getters.getObjects({
            "accountId": activeAccountId.value,
            "productType": "unifiedSecurityConsole",
            "objectType": "ccutmStates",
        })
    })
    const corePinState = computed(() => {
        return coreUtmInfo.value?.pinState || { locked:true, enabled :false}
    })

    const isCoreOnline = computed(() => {
        return (utmStates.value || []).find((utmstate:any) => {
            return utmstate.deviceId == selectedCoreUtmId.value
        })?.online === true
    })
    const hasCorePermission = computed(() => {
        return coreUtmInfo.value?.permissions?.manageVPNEnabled === true
    })
    const computedCoreUtmErrors = computed(() => {
        let result = []
        if(corePinState.value?.locked == true) {
            result.push(T("Die Websession PIN ist aufgrund mehrerer Fehleingaben gesperrt. Bitte schalten Sie die Websession PIN auf der Core-UTM wieder frei."))
        }
        else if(corePinState.value?.enabled == false) {
            result.push(T("Die Websession PIN ist auf der UTM deaktiviert. Bitte aktivieren Sie die PIN um die Konfiguration veröffentlichen zu können."))
        }
        if(isCoreOnline.value === false) {
            result.push(T("Die Core-UTM ist offline. Bitte stellen Sie sicher dass die Core-UTM erreichbar ist."))
        }
        if(hasCorePermission.value === false) {
            result.push(T("Die Core-UTM ist nicht für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für die Core-UTM."))
        }

        return result
    })


    const hasFakedVars = ref(false)
    // TABLE

    const maxTableHeight = ref(500)

    const calcMaxTableHeight = () => {
        let contentWrapper = document.getElementsByTagName("body")?.[0]
        if (contentWrapper) {
            maxTableHeight.value = contentWrapper.clientHeight - 50 - 66 - 32
        }
    }
    
    
    const tableButtons = ref([
        new Button({
            'text': T('Core-UTM'),
            'title': T('Add Core-UTM'),
            "icon": "fal fa-plus",
            "onClick": () => {
                products.unifiedSecurityConsole.topologies.view.getAddCoreDialog(activeAccountId.value,
                    (result) => {
                        if( "id" in result && result.id) {
                            selectedTopologyId.value = result.id
                        }
                    } 
                )
            },
            "disabled": () => { return canAddCoreUtm.value == false }
        }),
        new Button({
            'text': T('Revert changes'),
            "title": T('Revert not published changes'),
            "icon": "fal fa-clock-rotate-left",
            "loading": false,
            "disabled": () => { return !isReversible.value },
            "onClick": () => { 
                tableButtons.value[1].loading = true
                tableButtons.value[2].loading = true
                products.unifiedSecurityConsole.topologies.view.getRevertDialog(activeAccountId.value,selectedTopologyId.value,
                // success
                () => { 
                    init(true) 
                    tableButtons.value[1].loading = false
                    tableButtons.value[2].loading = false
                }, 
                // abort
                () => {
                    tableButtons.value[1].loading = false
                    tableButtons.value[2].loading = false
                },
                // error
                (e) => {
                    tableButtons.value[1].loading = false
                    tableButtons.value[2].loading = false    
                    console.error(e)
                }) 
            }
        }),
        new Button({
            'text': T('Publish'),
            'title': T('Publish'),
            "htmlTooltip":true,
            "icon": "fal fa-save",
            "loading": false,
            "disabled": false,
            "onClick":async () => {
                tableButtons.value[1].loading = true
                tableButtons.value[2].loading = true

                if(!hasFakedVars.value) {
                    await init(true)
                }
                products.unifiedSecurityConsole.topologies.view.getPublishDialog(activeAccountId.value,selectedTopologyId.value,
                    () => {
                        selectedTopology.value?.data.satellites.forEach((satellite) => {
                            satellite.state = "PENDING"
                            satellite.errors = []
                        })
                        selectedTopology.value?.data.pools.forEach((pool) => {
                            const wasAdded = selectedTopology.value?.changes.data.addedPools.indexOf(pool.id) != -1 || false
                            const wasChanged = selectedTopology.value?.changes.data.changedPools.some((change) => { return change.poolId == pool.id }) || false
                            const wasRemoved = selectedTopology.value?.changes.data.removedPools.indexOf(pool.id) != -1 || false
                            if(wasAdded ||  wasChanged || wasRemoved) {
                                pool.state = "PENDING"
                                pool.errors = []
                            }
                        })
                        if (selectedTopology.value && selectedTopology.value?.data.satellites.length !== 0) {
                            selectedTopology.value.changes.data.addedNodes = []
                            selectedTopology.value.changes.data.changedNodes = []
                            selectedTopology.value.changes.data.changedSites = []
                            selectedTopology.value.changes.data.removedNodes = []
                        }
                        if (selectedTopology.value && selectedTopology.value?.data.pools.length !== 0) {
                            selectedTopology.value.changes.data.addedPools = []
                            selectedTopology.value.changes.data.changedPools = []
                            selectedTopology.value.changes.data.removedPools = []
                        }
                        let modal = getterHelpers.useStore()?.getters.getActiveModal(activeAccountId.value)
                        tableButtons.value[1].loading = false
                        tableButtons.value[2].loading = false
                        tableButtons.value[2].icon = "fal fa-check"
                        tableButtons.value[2].disabled = true
                        modal.buttons[1].loading = false
                        modal.buttons[1].disabled = false
                        setTimeout(() => {
                            tableButtons.value[2].icon = "fal fa-save"
                            
                                init(true)
                            
                            
                        }, 2000)
                    },
                    () => {
                        let modal = getterHelpers.useStore()?.getters.getActiveModal(activeAccountId.value)
                        tableButtons.value[1].loading = false
                        tableButtons.value[2].loading = false
                        modal.buttons[1].loading = false
                        modal.buttons[1].disabled = false
                    },
                    (e) => {
                        let modal = getterHelpers.useStore()?.getters.getActiveModal(activeAccountId.value)
                        tableButtons.value[1].loading = false
                        tableButtons.value[2].loading = false
                        tableButtons.value[2].icon = "fal fa-save"
                        tableButtons.value[2].disabled = false
                        modal.buttons[1].loading = false
                        modal.buttons[1].disabled = false
                        console.error(e)
                    }
                )


            }
        })
    ])


    const altAddCoreButton = ref(new Button({
        'icon':new Icon({'class':'fal fa-plus'}),
        'text':T('Core-UTM jetzt hinzufügen'),
        'title':T('Core-UTM jetzt hinzufügen'),
        'onClick':tableButtons.value[0].onClick
    }))


    const tableCollumns: TableEntryInfo[] = [
        {
            "text": "Core-UTM",
            "property": "utmname",
            "displayType": "text",
            "tdId": (entry : SunTopology) => {
                return entry?.data?.coreId
            },
            "getValue": (entry : SunTopology) => {
                let utm = getterHelpers.useStore().getters.getObject({
                    "accountId": activeAccountId.value,
                    "objectType": "uscUtms",
                    "productType": "unifiedSecurityConsole",
                    "objectId": entry.data.coreId
                })
                return utm?.utmname ? (utm.utmname + " (" + deviceHelpers.getShortDeviceId(utm.utmId) + ")") : utm ? deviceHelpers.getShortDeviceId(utm.utmId) : "Missing UTM-Info"
            },
            "title": (entry : SunTopology) => {
                const coreNodeInfo = products.unifiedSecurityConsole.utmNodes.useStore?.().getObjectStoreObject(activeAccountId.value,entry.data.coreId)

                const interfaceInfo = entry.data.properties?.interfaceId ? coreNodeInfo?.nkView?.interfaces.find((thisInterface) => {
                    return thisInterface.id == entry.data.properties?.interfaceId
                }) : undefined
                return `
                <table style="margin:0;">
                    <tr><td>${T('Interface')}:</td><td>${encodingHelpers.escapeHTML(interfaceInfo?.name || T("Unknown"))}</td></tr>
                    <tr><td>IP/${T('Dynamic DNS')}:</td><td>${encodingHelpers.escapeHTML(entry?.data?.properties?.hostname || T("Unknown"))}</td></tr>
                    <tr><td>${T('Transfer network')}:</td><td>${encodingHelpers.escapeHTML(entry?.data?.properties?.transferNetwork || T("Unknown"))}</td></tr>
                    <tr><td>Port:</td><td>${encodingHelpers.escapeHTML(String(entry?.data?.properties?.listenPort || T("Unknown")) || T("Unknown"))}</td></tr>
                </table>`},
            "htmlTooltip": true,
            "iconsBefore": (entry:SunTopology) => {
                return products.unifiedSecurityConsole.topologies.view.getIconForTableEntry(activeAccountId.value,entry)
            },
            "subContent": {
                "displayOrder": ["subText"],
                "subText": (entry: SunTopology) => {
                    const coreNodeInfo = products.unifiedSecurityConsole.utmNodes.useStore?.().getObjectStoreObject(activeAccountId.value,entry.data.coreId)
                    const interfaceInfo = entry.data.properties?.interfaceId ? coreNodeInfo?.nkView?.interfaces.find((thisInterface) => {
                        return thisInterface.id == entry.data.properties?.interfaceId
                    }) : undefined
                    let result = `${T('Interface')}: ${interfaceInfo?.name || T("Unknown")} | IP/DNS: ${entry?.data?.properties?.hostname || T("Unknown")} | TN: ${entry?.data?.properties?.transferNetwork || T("Unknown")} | Port: ${entry?.data?.properties?.listenPort || T("Unknown")} | Alias: ${entry?.data?.properties?.alias || '-'}`
                    return result
                }
            },
            "rowClassList": (entry: SunTopology) => {
                let result: string[] = [];
                const hasBeenAdded = selectedTopology.value?.changes?.data?.addedNodes.indexOf(selectedCoreUtmId.value || '') != -1
                const hasBeenChanged = (selectedTopology.value?.changes?.data?.changedNodes.length || 0) > 0 || (selectedTopology.value?.changes?.data?.changedSites.length || 0) > 0 || (selectedTopology.value?.changes?.data?.addedNodes.length || 0) > 0 || (selectedTopology.value?.changes?.data?.removedNodes.length || 0) > 0|| (selectedTopology.value?.changes?.data?.addedPools.length || 0) > 0|| (selectedTopology.value?.changes?.data?.changedPools.length || 0) > 0|| (selectedTopology.value?.changes?.data?.removedPools.length || 0) > 0

                if (hasBeenAdded) {
                    result.push('toBeAdded')
                }
                else if (hasBeenChanged) {
                    result.push('toBeChanged')
                }
                return result
            },
            "labelsAfter": (entry: SunTopology) => {
                let result: Label[] = []
                
                const utm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, entry.data.coreId)

                if (selectedTopology.value && utm) {
                    result = products.unifiedSecurityConsole.topologies.getUtmLabels(activeAccountId.value,utm)
                    const hasBeenAdded = selectedTopology.value?.changes?.data?.addedNodes.indexOf(selectedCoreUtmId.value || '') != -1
                    const hasBeenChanged = (selectedTopology.value?.changes?.data?.changedNodes.length || 0) > 0 || (selectedTopology.value?.changes?.data?.changedSites.length || 0) > 0 || (selectedTopology.value?.changes?.data?.addedNodes.length || 0) > 0 || (selectedTopology.value?.changes?.data?.removedNodes.length || 0) > 0|| (selectedTopology.value?.changes?.data?.addedPools.length || 0) > 0|| (selectedTopology.value?.changes?.data?.changedPools.length || 0) > 0|| (selectedTopology.value?.changes?.data?.removedPools.length || 0) > 0

                    const satelliteStates = products.unifiedSecurityConsole.topologies.view.getSatelliteStates(selectedTopology.value)
                    const lowestSatelliteState = (satelliteStates.indexOf("FAILED") != -1) ? "FAILED" : (satelliteStates.indexOf("NOT_PUBLISHED") != -1) ? "NOT_PUBLISHED" : (satelliteStates.indexOf("PENDING") != -1) ? "PENDING" : "PUBLISHED"
                    let state: "NOT_PUBLISHED" | "PUBLISHED" | "PENDING" | "FAILED" = lowestSatelliteState == "PUBLISHED" ? (hasBeenAdded || hasBeenChanged ? "NOT_PUBLISHED" : "PUBLISHED") : lowestSatelliteState;

                    const allErrors : string[] = []
                    
                    const hasSunPermission = utm.permissions?.manageVPNEnabled || false
                    const isPinEnabled = utm.pinState?.enabled || false
                    const isPinLocked = utm.pinState?.locked || false

                    if(!hasSunPermission || !isPinEnabled || isPinLocked) {
                        state = "FAILED"
                        if(!hasSunPermission) {
                            allErrors.push(T("Fehlende Berechtigung"))
                        }
                        if(!isPinEnabled) {
                            allErrors.push(T("PIN deaktiviert"))
                        }
                        if(isPinLocked) {
                            allErrors.push(T("PIN gesperrt"))
                        }
                    }


                    const thisUtmState = getterHelpers.useStore()?.getters.getObject({
                        "accountId": activeAccountId.value,
                        "productType": "unifiedSecurityConsole",
                        "objectType": "ccutmStates",
                        "objectId":entry.data ? entry.data.coreId : entry.id
                    })
                    
                    if (objectStores.uscUtms.utmHasLicenseConflict(activeAccountId.value, utm)) {
                        result.push(new Label({
                            "class": "bg-red",
                            "text": T('License conflict'),
                            "title": T('A license conflict occurs when the same license is installed on several UTMs. In addition to billing problems, this can lead to unpredictable side effects and incorrect configurations. Make sure that a unique license is installed on each UTM to ensure stable and reliable operation of the UTM.'),
                            "icon": new Icon({
                                "class":"fal fa-fw fa-exclamation-triangle"
                            })
                        }))
                    }

                    if (thisUtmState?.online) {

                        if(state == "FAILED") {
                            let errorText = T('Error')
                            if(!hasSunPermission) {
                                errorText = T("Fehlende Berechtigung")
                            }
                            else if(!isPinEnabled) {
                                errorText = T("PIN deaktiviert")
                            }
                            else if(isPinLocked) {
                                errorText = T("PIN gesperrt")
                            }
                            result.push(new Label({
                                "class": "bg-red",
                                "text": errorText,
                                "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                "htmlTooltip":true,
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Errors')}:</strong></td><td> ${ allErrors.join(', ') }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                            </td>
                                        </tr>
                                    </table>
                                `
                            }))
                        }
                        else if(state == "NOT_PUBLISHED") {
                            result.push(new Label({
                                "class": "bg-yellow",
                                "text": T('Not published'),
                                "icon": new Icon({ "class":(hasBeenAdded ? "fal fa-plus" : "fal fa-arrows-rotate"), }),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${(hasBeenAdded ? T('The UTM will be added') : T('The UTM will be updated')) }. ${T('The changes have not been published yet.') }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip":true
                            }))
                        }
                        else if(state == "PENDING") {
                            result.push(new Label({
                                "class": "bg-yellow",
                                "text": T('Publishing')+"...",
                                "icon": new Icon({ "class":"fal fa-hourglass", }),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('The changes are being published.')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip":true
                            }))
                        }
                        else if(state == 'PUBLISHED') {
                            result.push(new Label({
                                "class": "bg-green",
                                "text": "",
                                "icon": new Icon({ "class":"fal fa-check", }),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('There are no changes to be published.')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip":true
                            }))
                        }


                    }
                    else {
                        if (state == "FAILED") {
                            result.push(new Label({
                                "class": "bg-red",
                                "text": T('Offline'),
                                "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                "htmlTooltip": true,
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Errors')}:</strong></td><td> ${allErrors.join(', ')}
                                            </td>
                                        </tr>
                                    </table>
                                `
                            }))
                        }
                        else if (state == "NOT_PUBLISHED") {
                            result.push(new Label({
                                "class": "bg-red",
                                "text": T('Offline'),
                                "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${(hasBeenAdded ? T('Added') : T('Updated')) + ". " + T('The changes have not been published yet.') }
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip": true
                            }))
                        }
                        else if (state == "PENDING") {
                            result.push(new Label({
                                "class": "bg-yellow",
                                "text": T('Publishing') + "...",
                                "icon": new Icon({ "class":"fal fa-hourglass", }),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('The changes are being published.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip": true
                            }))
                        }
                        else if (state == 'PUBLISHED') {
                            result.push(new Label({
                                "class": "bg-red",
                                "text": T("Offline"),
                                "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('There are no changes to be published.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip": true
                            }))
                        }
                    }

                    if(entry.data.satellites.length == 0 && entry.data.pools.length == 0) {
                        result.push(new Label({
                            "class": "bg-red",
                            "text": T("Missing Satellites / Roadwarrior"),
                            "title": T("Missing Satellites / Roadwarrior"),
                            "htmlTooltip": false,
                            "icon": new Icon({ "class":"fal fa-info-circle", }),
                        }))
                    }
                
                }
                if(products.unifiedSecurityConsole.utmNodes.gettingNetworkViews.value.indexOf(entry.data.coreId) != -1) {
                    result.push(new Label({
                        "class": "bg-blue",
                        "text": T('Aktualisiere...'),
                        "title": T('Aktualisiere...'),
                        "icon": new Icon({ "class":"fal fa-fw fa-sync", }),
                    }))
                }
                return result
            }
        },
        {
            "text": T("Actions"),
            "property": "actions",
            "displayType": "buttons",
            "sortable": false,
            "width": 450,
            "tdClass":"text-right actions",
            "getValue": (entry: SunTopology) => {
                const topology = selectedTopology.value
                if (topology) {
                    return [
 
                        new Button({
                            "text": T('Satellite / Roadwarrior'),
                            "title": T('Add Satellite or Roadwarrior'),
                            "icon": "fal fa-plus",
                            "disabled": !canAddSatellite.value,
                            "onClick": () => {
                                const modal: Modal = {
                                    "accountId": activeAccountId.value,
                                    "id": "addSatellite",
                                    "content": {
                                        "title": {
                                            "text": T('Add Satellite or Roadwarrior'),
                                            "icon": "fal fa-plus"
                                        },
                                        "body": {
                                            "component": "add-edit-satellite",
                                            "properties": {
                                                "coreUtmId": entry.data.coreId,
                                                "existingSatellites": products.unifiedSecurityConsole.topologies.view.getSatelliteUtmIdsForCoreUtm(topology, entry.data.coreId).filter((utmId) => {
                                                    return topologyChanges.value?.removedNodes.indexOf(utmId) == -1
                                                })
                                            }
                                        }
                                    },
                                    "buttons": [
                                        {
                                            'text': T('Abort'),
                                            'icon': 'fal fa-times',
                                            'disabled': false,
                                            'onClick': () => {
                                                getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                            }
                                        },
                                        {
                                            'text': T('Add'),
                                            'icon': 'fal fa-plus',
                                            'disabled': false,
                                            'onClick': async (modalWrapper: any) => {
                                                modalWrapper.buttons[1].loading = true
                                                modalWrapper.buttons[1].disabled = true
                                                modalWrapper.buttons[0].disabled = true
                                                try {
                                                    const selectedType = modalWrapper.$refs.modalComponent.selectedType
                                                    const selectedUtmId = modalWrapper.$refs.modalComponent.selectedUtm
                                                    const roadwarriorName = modalWrapper.$refs.modalComponent.roadwarriorName
                                                    const transferNetwork = modalWrapper.$refs.modalComponent.transferNetwork
                                                    const selectedProfiles = modalWrapper.$refs.modalComponent.selectedProfiles
                                                    const alias = modalWrapper.$refs.modalComponent.alias
                                                    const entities = (<string[]>selectedProfiles).map((profileId) => {
                                                        const existingAndroidProfile = products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObject(activeAccountId.value, profileId.split("/")[3])
                                                        return {
                                                            "id":profileId,
                                                            "type":existingAndroidProfile ? 'ANDROID_PROFILE' : 'IOS_PROFILE'
                                                        } as SunRoadwarriorEntity
                                                    })
                                                    

                                                    // clear potential errors from last request
                                                    modalWrapper.$refs.modalComponent.clearErrors()

                                                    if (selectedType == "satellite") {
                                                        // ADD SATELLITE
                                                        let result = await products.unifiedSecurityConsole.topologies.addSatelliteToApi(activeAccountId.value, entry.id, {
                                                            "id": selectedUtmId,
                                                            "alias":alias
                                                        })
                                                        if(result && selectedTopology.value) {
                                                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                        }
                                                    }
                                                    // ADD ROADWARRIOR
                                                    else {
                                                        let roadwarriorPayload : SunRoadwarriorOnAdd = {
                                                            "name":roadwarriorName,
                                                            "transferNetwork":transferNetwork,
                                                            "entities":entities
                                                        }
                                                        let roadwarriorResult = await products.unifiedSecurityConsole.topologies.addRoadwarriorToApi(activeAccountId.value,entry.id,roadwarriorPayload)

                                                        if(roadwarriorResult && selectedTopology.value) {
                                                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>roadwarriorResult))
                                                        }
                                                    }
                                                    modalWrapper.buttons[0].disabled = false
                                                    modalWrapper.buttons[1].loading = false
                                                    modalWrapper.buttons[1].disabled = false
                                                    init(true)
                                                    getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                                }
                                                catch (e:any) {
                                                    console.error(e)
                                                    if(e?.data?.data?.error?.error) {
                                                        modalWrapper.$refs.modalComponent.setErrors(e?.data?.data?.error?.error)
                                                    }
                                                    if(e?.data?.data?.error?.message) {
                                                        modalWrapper.$refs.modalComponent.setErrors([e?.data?.data?.error?.message])
                                                    }
                                                    modalWrapper.buttons[0].disabled = false
                                                    modalWrapper.buttons[1].loading = false
                                                    modalWrapper.buttons[1].disabled = false
                                                }
                                                

                                            }
                                        }
                                    ]
                                }
                                getterHelpers.useStore().commit(MutationTypes.addModal, modal)
                            },
                            "size": "sm"
                        }),
                        new Button({
                            "text": T('Websession'),
                            "title": T('Websession starten'),
                            "icon": "fal fa-laptop",
                            "topRightCircle": { "icon": "fa fa-lock" },
                            "disabled":!(isCoreOnline.value && corePinState.value.enabled && corePinState.value.locked == false && sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"])),
                            "onClick": () => {
                                objectStores.uscUtms.dialogs.renderNewWebSessionDialog(activeAccountId.value, entry.data.coreId)
                            }
                        }),
                        new Button({
                            "icon": "fal fa-sync",
                            "title": T("Refresh"),
                            "onClick": () => {
                                refreshNetworkViews([entry.data.coreId])
                            }
                        }),
                        new Button({
                            "icon": "fal fa-wrench",
                            "title": T("Edit"),
                            "disabled":computedCoreUtmErrors.value.length > 0,
                            "onClick": () => {
                                const modal: Modal = {
                                    "accountId": activeAccountId.value,
                                    "id": "addCoreUtm",
                                    "content": {
                                        "title": {
                                            "text": T('Edit Core-UTM'),
                                            "icon": "fal fa-plus"
                                        },
                                        "body": {
                                            "component": "add-edit-core-utm",
                                            "properties": {
                                                "utmId": entry.data.coreId,
                                                "port": entry.data.properties.listenPort,
                                                "hostname": entry.data.properties.hostname,
                                                "interface": entry.data.properties.interfaceId,
                                                "transferNetwork": entry.data.properties.transferNetwork,
                                                "alias": entry.data.properties.alias,
                                                "satelliteCount":entry.data.satellites.filter((satellite) => {
                                                    return topologyChanges.value?.removedNodes.indexOf(satellite.id) == -1
                                                }).length
                                            }
                                        }
                                    },
                                    "buttons": [
                                        {
                                            'text': T('Abort'),
                                            'icon': 'fal fa-times',
                                            'disabled': false,
                                            'onClick': () => {
                                                getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                            }
                                        },
                                        {
                                            'text': T('Save'),
                                            'icon': 'fal fa-save',
                                            'disabled': false,
                                            'onClick': async (modalWrapper: any) => {
                                                try {
                                                    modalWrapper.buttons[1].disabled = true
                                                    modalWrapper.buttons[1].loading = true
                                                    modalWrapper.$refs.modalComponent.clearErrors()
                                                    const selectedUtmId = modalWrapper.$refs.modalComponent.selectedUtm
                                                    const utm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, selectedUtmId)

                                                    const hostnameBefore = entry.data.properties.hostname
                                                    const interfaceIdBefore = entry.data.properties.interfaceId
                                                    const transferNetworkBefore = entry.data.properties.transferNetwork
                                                    const hostnameAfter = modalWrapper.$refs.modalComponent.selectedHostname
                                                    const interfaceIdAfter = modalWrapper.$refs.modalComponent.selectedInterface
                                                    const transferNetworkAfter = modalWrapper.$refs.modalComponent.transferNetwork
                                                    const aliasBefore = entry.data.properties.alias
                                                    const alias = modalWrapper.$refs.modalComponent.alias

                                                    const changedProperties = []
                                                    if(hostnameBefore != hostnameAfter) { changedProperties.push("hostname") } 
                                                    if(interfaceIdBefore != interfaceIdAfter ) { changedProperties.push("interfaceId") }
                                                    if(transferNetworkBefore != transferNetworkAfter) { changedProperties.push("transferNetwork") }
                                                    if(aliasBefore != alias) { changedProperties.push("alias") }

                                                    if (utm) {
                                                        // If has any changes
                                                        if (changedProperties.length > 0) {

                                                            let payload = {
                                                                "coreId": selectedUtmId,
                                                                "properties": {
                                                                    "interfaceId": modalWrapper.$refs.modalComponent.selectedInterface,
                                                                    "transferNetwork": modalWrapper.$refs.modalComponent.transferNetwork,
                                                                    "hostname": modalWrapper.$refs.modalComponent.selectedHostname,
                                                                    "alias":alias
                                                                }
                                                            }
                                                            // @ts-ignore
                                                            let result = await products.unifiedSecurityConsole.topologies.queries.updateObjectFromApi(activeAccountId.value, entry.id, payload) as Error | { data:SunTopology }
                                                            // Set new properties
                                                            entry.data.properties.interfaceId = interfaceIdAfter
                                                            entry.data.properties.transferNetwork = transferNetworkAfter
                                                            entry.data.properties.hostname = hostnameAfter || modalWrapper.$refs.modalComponent.selectedInterfaceInfo.dynDns?.hostname || modalWrapper.$refs.modalComponent.selectedInterfaceInfo?.addresses?.map((address: any) => { return address.address }).join(", ")

                                                            
                                                        }
                                                        init(true)
                                                        modalWrapper.buttons[1].disabled = false
                                                        modalWrapper.buttons[1].loading = false
                                                        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                                    }
                                                }
                                                catch (e) {
                                                    console.error(e)
                                                    modalWrapper.$refs.modalComponent.addError(e)
                                                    modalWrapper.buttons[1].disabled = false
                                                    modalWrapper.buttons[1].loading = false
                                                }
                                            }
                                        }
                                    ]
                                }
                                getterHelpers.useStore().commit(MutationTypes.addModal, modal)
                            }
                        }),

                        new Button({
                            "icon": "fal fa-trash",
                            "title": T("Delete"),
                            "onClick": () => {
                                if(selectedCoreUtmId.value && selectedTopology.value) {
                                    const doPublishAfterDelete = products.unifiedSecurityConsole.topologies.isPublishAfterCoreDeletionMandatory(activeAccountId.value,selectedCoreUtmId.value,selectedTopology.value)
                                    products.unifiedSecurityConsole.topologies.view.getDeleteCoreDialog(activeAccountId.value,selectedTopologyId.value,selectedCoreUtmId.value,doPublishAfterDelete,isReversible.value)
                                }
                            }
                        }) 
                    ]
                }
                else {
                    return []
                }
            }
        },
        {
            "displayType": "subTable",
            "draggableRows": true,
            "isOpened": (entry: any) => {
                return entry.data?.satellites.length ? true : false
            },
            "getObjects": (entry: any) => {
                return entry.data?.satellites
            },
            "entries": [
                {
                    "text": "",
                    "property": "openFolder",
                    "displayType": "buttons",
                    "replaceButtonWithStatus":(entry:SunSatellite) => {
                        let removedNodes = topologyChanges.value?.removedNodes || []
                        let addedNodes = topologyChanges.value?.addedNodes || []
                        const hasBeenRemoved = removedNodes.indexOf(entry.id) != -1 || entry.toBeDeleted
                        const willBeAdded = addedNodes.indexOf(entry.id) != -1
                        return hasBeenRemoved || (willBeAdded && entry.rules.length == 0)
                    },
                    "getValue": (subEntry: SunSatellite) => {
                        let removedNodes = topologyChanges.value?.removedNodes || []
                        let addedNodes = topologyChanges.value?.addedNodes || []
                        const hasBeenRemoved = removedNodes.indexOf(subEntry.id) != -1 || subEntry.toBeDeleted
                        const willBeAdded = addedNodes.indexOf(subEntry.id) != -1

                        if (!(willBeAdded && subEntry?.rules?.length == 0) && !hasBeenRemoved && subEntry?.rules?.length !== 0) {
                            return [
                                new Button({
                                    //@ts-ignore
                                    "icon": "fal " + (subEntry.openedSubTable ? "fa-folder-open" : "fa-folder"),
                                    "onClick": () => {
                                        //@ts-ignore
                                        subEntry.openedSubTable = subEntry.openedSubTable ? false : true
                                    },
                                    "type": "icon",
                                    "topRightCircle":{
                                        "counter":subEntry.rules.length
                                    }
                                })
                            ]
                        }
                        else if(hasBeenRemoved) {
                            return {
                                "color": 'red',
                                "tooltip": T('Removed') + '. ' + T('The changes have not been published yet.'),
                                "icon": 'fa fa-minus'
                            }
                        }
                        else if (willBeAdded && subEntry?.rules?.length == 0) {
                            return {
                                "color": 'green',
                                "tooltip": T('Added') + '. ' + T('The changes have not been published yet.'),
                                "icon": 'fa fa-plus'
                            }
                        }
                        else {
                            return []
                        }
                    },
                    "width": 50,
                    "sortable": false
                },
                {
                    "text": T("Satellite-UTM"),
                    "property": "utmname",
                    "displayType": "text",
                    "title": (entry : SunSatellite) => {

                        return `
                        <table style="margin:0;">
                            <tr><td>${T('IP')}:</td><td>${ entry?.siteIps?.clientAddress?.split("/")[0] || T("Unknown")}</td></tr>                            
                        </table>`},
                    "htmlTooltip": true,
                    "tdId": (entry: SunSatellite) => {
                        return entry?.id
                    },
                    "getValue": (entry: SunSatellite) => {
                        let utm = getterHelpers.useStore().getters.getObject({
                            "accountId": activeAccountId.value,
                            "objectType": "uscUtms",
                            "productType": "unifiedSecurityConsole",
                            "objectId": entry?.id
                        })
                        return utm?.utmname ? (utm.utmname + " (" + deviceHelpers.getShortDeviceId(entry?.id) + ")") : utm ? deviceHelpers.getShortDeviceId(entry?.id) : "Missing UTM-Info"
                    },
                    "iconsBefore": (entry: SunSatellite) => {
                        return products.unifiedSecurityConsole.topologies.view.getIconForTableEntry(activeAccountId.value,entry)
                    },
                    "labelsAfter": (entry: SunSatellite) => {
                        let result: Label[] = []
                        const utm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, entry?.id)
                        let removedNodes = topologyChanges.value?.removedNodes || []
                        const hasBeenRemoved = removedNodes.indexOf(entry.id) != -1 || entry.toBeDeleted
                        const hasBeenAdded = selectedTopology.value?.changes?.data?.addedNodes.indexOf(entry.id || '') != -1
                        const hasBeenChanged = selectedTopology.value?.changes?.data?.changedNodes.some((node) => { return node.nodeId == entry.id }) || selectedTopology.value?.changes?.data?.changedSites.some((site) => {
                            return site.clientId == entry.id
                        })

                        const thisUtmState = getterHelpers.useStore()?.getters.getObject({
                            "accountId": activeAccountId.value,
                            "productType": "unifiedSecurityConsole",
                            "objectType": "ccutmStates",
                            "objectId":entry.id
                        })
                        
                        const allErrors = entry.errors.filter(arrayHelpers.onlyUniqueFilter)
                        let state = entry.state != 'FAILED' ? hasBeenAdded || hasBeenChanged || hasBeenRemoved ? 'NOT_PUBLISHED' : entry.state : 'FAILED';

                        result = products.unifiedSecurityConsole.topologies.getUtmLabels(activeAccountId.value,utm)

                        const hasSunPermission = utm?.permissions?.manageVPNEnabled || false

                        if(!hasSunPermission) {
                            state = "FAILED"
                            if(!hasSunPermission) {
                                allErrors.push(T("Fehlende Berechtigung"))
                            }
                        }

                        if (thisUtmState?.online) {
                            if (state == "FAILED") {
                                let errorText = T('Error')
                                if(!hasSunPermission) {
                                    errorText = T("Fehlende Berechtigung")
                                }
                                result.push(new Label({
                                    "class": "bg-red",
                                    "text": errorText,
                                    "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                    "htmlTooltip": true,
                                    "title": `
                                        <table style="margin:0;">
                                            <tr>
                                                <td>
                                                    <strong>${T('Errors')}:</strong></td><td> ${allErrors.join(', ')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                                </td>
                                            </tr>
                                        </table>
                                    `
                                }))
                            }
                            else if (state == "NOT_PUBLISHED") {
                                result.push(new Label({
                                    "class": "bg-yellow",
                                    "text": T('Not published'),
                                    "icon": new Icon({ "class":(hasBeenAdded ? "fal fa-plus" : hasBeenRemoved ? "fal fa-trash" : hasBeenChanged ? "fal fa-arrows-rotate" : "fal fa-info-cicle"), }),
                                    "title": `
                                        <table style="margin:0;">
                                            <tr>
                                                <td>
                                                    <strong>${T('xState')}:</strong></td><td> ${(hasBeenAdded ? T('Added') + ". " : hasBeenChanged ? T('Updated') + ". " : hasBeenRemoved ? T('Removed') + ". " : '') + T('The changes have not been published yet.')}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                                </td>
                                            </tr>
                                        </table>
                                    `,
                                    "htmlTooltip": true
                                }))
                            }
                            else if (state == "PENDING") {
                                result.push(new Label({
                                    "class": "bg-yellow",
                                    "text": T('Publishing') + "...",
                                    "icon": new Icon({ "class":"fal fa-hourglass", }),
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('The changes are being published.')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                    "htmlTooltip": true
                                }))
                            }
                            else if (state == 'PUBLISHED') {
                                result.push(new Label({
                                    "class": "bg-green",
                                    "text": "",
                                    "icon": new Icon({ "class":"fal fa-check", }),
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('There are no changes to be published.')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Online')}:</strong></td><td> ${T('This UTM is connected to the Unified Security Console')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                    "htmlTooltip": true
                                }))
                            }


                        }
                        else {
                            if (state == "FAILED") {
                                result.push(new Label({
                                    "class": "bg-red",
                                    "text": T('Offline'),
                                    "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                    "htmlTooltip": true,
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('Errors')}:</strong></td><td> ${allErrors.join(', ')}
                                            </td>
                                        </tr>
                                    </table>
                                `
                                }))
                            }
                            else if (state == "NOT_PUBLISHED") {
                                result.push(new Label({
                                    "class": "bg-red",
                                    "text": T('Offline'),
                                    "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${(hasBeenAdded ? T('Added') + ". " : hasBeenChanged ? T('Updated') + ". " : hasBeenRemoved ? T('Removed') + ". " : '') + T('The changes have not been published yet.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                    "htmlTooltip": true
                                }))
                            }
                            else if (state == "PENDING") {
                                result.push(new Label({
                                    "class": "bg-red",
                                    "text": T('Offline'),
                                    "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('The changes are being published.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                    "htmlTooltip": true
                                }))
                            }
                            else if (state == 'PUBLISHED') {
                                result.push(new Label({
                                    "class": "bg-red",
                                    "text": T('Offline'),
                                    "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Offline')}:</strong></td><td> ${T('This UTM is disconnected from the Unified Security Console')}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('There are no changes to be published.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                    "htmlTooltip": true
                                }))
                            }
                        }
                        if(products.unifiedSecurityConsole.utmNodes.gettingNetworkViews.value.indexOf(entry.id) != -1) {
                            result.push(new Label({
                                "class": "bg-blue",
                                "text": T('Aktualisiere...'),
                                "title": T('Aktualisiere...'),
                                "icon": new Icon({ "class":"fal fa-fw fa-sync", }),
                            }))
                        }

                        return result
                    },
                    "subContent": {
                        "displayOrder": ["subText"],
                        "subText": (entry: SunSatellite) => {
                            return T("IP") + ": " + entry?.siteIps?.clientAddress?.split("/")[0] + " | Alias: " + entry.alias || "-"
                        }
                    },
                    "rowClassList": (entry: SunSatellite) => {
                        let result: string[] = [];
                        let removedNodes = topologyChanges.value?.removedNodes || []
                        const hasBeenRemoved = removedNodes.indexOf(entry.id) != -1 || entry.toBeDeleted
                        const hasBeenAdded = selectedTopology.value?.changes?.data?.addedNodes.indexOf(entry.id || '') != -1
                        const hasBeenChanged = selectedTopology.value?.changes?.data?.changedNodes.some((node) => { return node.nodeId == entry.id }) || selectedTopology.value?.changes?.data?.changedSites.some((site) => {
                            return site.clientId == entry.id
                        })

                        if (hasBeenRemoved) {
                            result.push('toBeRemoved')
                        }
                        else if (hasBeenAdded) {
                            result.push('toBeAdded')
                        } 
                        else if (hasBeenChanged) {
                            result.push('toBeChanged')
                        }
                        return result
                    }
                },
                {
                    "text": T("Actions"),
                    "property": "actions",
                    "displayType": "buttons",
                    "sortable": false,
                    "width": 449,
                    "tdClass":"text-right actions",
                    "getValue": (subEntry: SunSatellite) => {
                        let removedNodes = topologyChanges.value?.removedNodes || []
                        const hasBeenRemoved = removedNodes.indexOf(subEntry.id) != -1 || subEntry.toBeDeleted
                        let result : Button[] = []
                        if (!hasBeenRemoved) {
                            result.push(new Button({
                                    "text": T('Rule'),
                                    "title": T('Rule'),
                                    "icon": "fal fa-plus",
                                    "disabled": function() {
                                        const hasSunPermission = (objectStores.uscUtms.getObjectFromStore(activeAccountId.value,subEntry.id))?.permissions?.manageVPNEnabled
                                        const isOnline = (getterHelpers.useStore()?.getters.getObject({
                                            "accountId": activeAccountId.value,
                                            "productType": "unifiedSecurityConsole",
                                            "objectType": "ccutmStates",
                                            "objectId":subEntry.id
                                        })?.online || false)
                                        return !(isOnline && hasSunPermission && isCoreOnline.value && hasCorePermission.value)
                                    }(),
                                    "onClick": () => {

                                        products.unifiedSecurityConsole.topologies.view.getRuleDialog(
                                            activeAccountId.value,
                                            selectedTopologyId.value,
                                            selectedCoreUtmId.value,
                                            subEntry.id,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            async (payload) => {
                                                try {
                                                    if(Object.hasOwn(payload,"src")) {
                                                        const thisPayload = payload as SunUTMRuleOnAdd
                                                        let result = await products.unifiedSecurityConsole.topologies.addRuleForSatelliteInApi(activeAccountId.value, selectedTopology.value?.id || "", subEntry.id, thisPayload)
                                                        
                                                        if(result && selectedTopology.value) {
                                                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                        }
                                                        if(subEntry?.rules?.length > 0) {
                                                            //@ts-ignore
                                                            subEntry.openedSubTable = true
                                                        }
                                                    }
                                                    else {
                                                        throw new Error("Missing property: 'src'")
                                                    }
                                                }
                                                catch(e) {
                                                    throw e
                                                }
                                            },
                                            (e) => {
                                                console.error(e)
                                            },
                                            () => {
                                                init(true)
                                            }
                                        )
                                    }
                                }))
                                if(ruleClipboard.value && products.unifiedSecurityConsole.topologies.view.isSameSatelliteRule(ruleClipboard.value,subEntry)) {
                                    result.push(new Button({
                                        "icon": "fal fa-paste",
                                        "title": T('Paste'),
                                        "onClick": (e,component) => {                                              
                                            if(ruleClipboard.value && selectedTopology.value) {
                                                products.unifiedSecurityConsole.topologies.view.pasteRule(activeAccountId.value,ruleClipboard.value,selectedTopology.value,selectedCoreUtmId.value,subEntry)
                                                ruleClipboard.value = undefined
                                            }
                                        }
                                    }))
                                }
                                result.push(new Button({
                                    "text": T('Websession'),
                                    "title": T('Websession starten'),
                                    "topRightCircle": { "icon": "fa fa-lock" },
                                    "icon": "fal fa-laptop",
                                    "disabled": function () {
                                        const utm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, subEntry.id)
                                        const isPinEnabled = utm?.pinState?.enabled || false
                                        const isPinLocked = utm?.pinState?.locked || false
                                        const isOnline = (getterHelpers.useStore()?.getters.getObject({
                                            "accountId": activeAccountId.value,
                                            "productType": "unifiedSecurityConsole",
                                            "objectType": "ccutmStates",
                                            "objectId":subEntry.id
                                        })?.online || false)
                                        return !(isPinEnabled && !isPinLocked && isOnline && sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"]))
                                    }(),
                                    "onClick": () => {
                                        objectStores.uscUtms.dialogs.renderNewWebSessionDialog(activeAccountId.value, subEntry.id)
                                    }
                                }))
                                result.push(new Button({
                                    "icon": "fal fa-sync",
                                    "title": T("Refresh"),
                                    "onClick": () => {
                                        refreshNetworkViews([selectedCoreUtmId.value,subEntry.id])
                                    }
                                }))
                                result.push(new Button({
                                    "icon": "fal fa-wrench",
                                    "title": T("Edit"),
                                    "disabled": function() {
                                        const hasSunPermission = (objectStores.uscUtms.getObjectFromStore(activeAccountId.value,subEntry.id))?.permissions?.manageVPNEnabled
                                        const isOnline = (getterHelpers.useStore()?.getters.getObject({
                                            "accountId": activeAccountId.value,
                                            "productType": "unifiedSecurityConsole",
                                            "objectType": "ccutmStates",
                                            "objectId":subEntry.id
                                        })?.online || false)
                                        return !(isOnline && hasSunPermission)
                                    }(),
                                    "onClick": () => {
                                        const modal: Modal = {
                                            "accountId": activeAccountId.value,
                                            "id": "addSatellite",
                                            "content": {
                                                "title": {
                                                    "text": T('Edit Satellite'),
                                                    "icon": "fal fa-wrench"
                                                },
                                                "body": {
                                                    "component": "add-edit-satellite",
                                                    "properties": {
                                                        "coreUtmId": selectedCoreUtmId.value,
                                                        "utmId":subEntry.id,
                                                        "alias":subEntry.alias,
                                                        "type":"utm",
                                                        "existingSatellites": products.unifiedSecurityConsole.topologies.view.getSatelliteUtmIdsForCoreUtm(selectedTopology.value, selectedCoreUtmId.value).filter((utmId) => {
                                                            return topologyChanges.value?.removedNodes.indexOf(utmId) == -1 && utmId !== subEntry.id
                                                        })
                                                    }
                                                }
                                            },
                                            "buttons": [
                                                {
                                                    'text': T('Abort'),
                                                    'icon': 'fal fa-times',
                                                    'disabled': false,
                                                    'onClick': () => {
                                                        getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                                    }
                                                },
                                                {
                                                    'text': T('Save'),
                                                    'icon': 'fal fa-save',
                                                    'disabled': false,
                                                    'onClick': async (modalWrapper: any) => {
                                                        try {
                                                            const alias = modalWrapper.$refs.modalComponent.alias
                                                            modalWrapper.buttons[0].disabled = true
                                                            modalWrapper.buttons[1].loading = true
                                                            modalWrapper.buttons[1].disabled = true

                                                            // clear potential errors from last request
                                                            modalWrapper.$refs.modalComponent.clearErrors()

                                                            // UPDATE SATELLITE
                                                            let result = await products.unifiedSecurityConsole.topologies.updateSatelliteInApi(activeAccountId.value, selectedTopologyId.value, {
                                                                "alias":alias
                                                            },subEntry.id)
                                                            if(result && selectedTopology.value) {
                                                                products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                            }
                                                            
                                                            modalWrapper.buttons[0].disabled = false
                                                            modalWrapper.buttons[1].loading = false
                                                            modalWrapper.buttons[1].disabled = false
                                                            init(true)
                                                            getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                                        }
                                                        catch (e:any) {
                                                            console.error(e)
                                                            if(e?.data?.data?.error?.error) {
                                                                modalWrapper.$refs.modalComponent.setErrors(e?.data?.data?.error?.error)
                                                            }
                                                            if(e?.data?.data?.error?.message) {
                                                                modalWrapper.$refs.modalComponent.setErrors([e?.data?.data?.error?.message])
                                                            }
                                                            modalWrapper.buttons[0].disabled = false
                                                            modalWrapper.buttons[1].loading = false
                                                            modalWrapper.buttons[1].disabled = false
                                                        }
                                                        

                                                    }
                                                }
                                            ]
                                        }
                                        getterHelpers.useStore().commit(MutationTypes.addModal, modal)
                                    }
                                }))
                                result.push(new Button({
                                    "icon": "fal fa-trash",
                                    "title": T('Delete'),
                                    "onClick": () => {
                                        dialogs.misc.confirmDialog(activeAccountId.value, T("Delete UTM"), T("Do you really want to mark this satellite's connection to the core UTM for deletion?"), async () => {
                                            try {
                                                
                                                let result = await products.unifiedSecurityConsole.topologies.deleteSatelliteInApi(activeAccountId.value, selectedTopology.value?.id || '', subEntry.id)
                                                if(result && selectedTopology.value) {
                                                    products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                } 
                                            }
                                            catch (e) {
                                                console.error(e)
                                            }
                                        })
                                    }
                                }))
                        }
                        else {
                            return []
                        }
                        return result
                    }
                },
                {
                    "displayType": "subTable",
                    "draggableRows": true,
                    "isOpened": (entry: any) => {
                        let removedNodes = topologyChanges.value?.removedNodes || []
                        const hasBeenRemoved = removedNodes.indexOf(entry.id) != -1 || entry.toBeDeleted
                        // TO BE DONE: aktivate if table is opened
                        return hasBeenRemoved || entry.rules.length === 0 ? false : entry.openedSubTable
                    },
                    "getObjects": (entry: SunSatellite) => {
                        return entry.rules
                    },
                    "entries": [
                        {
                            "text": "",
                            "property": "change",
                            "displayType": "status",
                            "getValue": (ruleEntry: SunRule,parentEntry?:SunSatellite) => {
                                const satelliteId = parentEntry?.id
                                const currentChangedSite = selectedTopology.value?.changes?.data?.changedSites.find((satellite) => {
                                    return satellite.clientId == satelliteId
                                })
                                const willBeDeleted = (parentEntry?.toBeDeleted || currentChangedSite?.rules.removed.some((ruleId) => { return ruleId == ruleEntry.id }));
                                const willBeChanged = currentChangedSite?.rules.changed.some((ruleId) => { return ruleId == ruleEntry.id });
                                const willBeAdded = currentChangedSite?.rules.added.some((ruleId) => { return ruleId == ruleEntry.id });
                                
                                if (willBeDeleted) {
                                    return {
                                        "color": 'red',
                                        "tooltip": T('Removed') + '. ' + T('The changes have not been published yet.'),
                                        "icon": 'fa fa-minus'
                                    }
                                }
                                else if (willBeChanged) {
                                    return {
                                        "color": 'yellow',
                                        "tooltip": T('Changed') + '. ' + T('The changes have not been published yet.'),
                                        "icon": 'fa fa-arrows-rotate'
                                    }
                                }
                                else if (willBeAdded) {
                                    return {
                                        "color": 'green',
                                        "tooltip": T('Added') + '. ' + T('The changes have not been published yet.'),
                                        "icon": 'fa fa-plus'
                                    }
                                }
                                else {
                                    return undefined
                                }
                            },
                            "width": 42,
                            "rowClassList": (ruleEntry: SunRule, parentEntry?: SunSatellite) => {
                                let result: string[] = [];
                                const satelliteId = parentEntry?.id
                                const currentChangedSite = selectedTopology.value?.changes?.data?.changedSites.find((satellite) => {
                                    return satellite.clientId == satelliteId
                                })
                                const willBeDeleted = (parentEntry?.toBeDeleted || currentChangedSite?.rules.removed.some((ruleId) => { return ruleId == ruleEntry.id }));
                                const willBeChanged = currentChangedSite?.rules.changed.some((ruleId) => { return ruleId == ruleEntry.id });
                                const willBeAdded = currentChangedSite?.rules.added.some((ruleId) => { return ruleId == ruleEntry.id });
                                if (willBeDeleted) {
                                    result.push('toBeRemoved')
                                }
                                else if (willBeAdded) {
                                    result.push('toBeAdded')
                                }
                                else if (willBeChanged) {
                                    result.push('toBeChanged')
                                }
                                return result
                            }
                        },
                        {
                            "text": T("Source"),
                            "property": "sourceNodeName",
                            "displayType": "text",
                            "getValue": (ruleEntry : SunRule) => {
                                return ruleEntry.src?.node.name
                            },
                            "iconsBefore": (ruleEntry : SunRule) => {
                                let result = []
                                if (selectedCoreUtmId.value == ruleEntry.src?.utmId) {
                                    result.push({
                                        "class": "fal fa-crown margin-xs-r margin-xs-l",
                                        "title": "Core-UTM"
                                    })
                                }
                                else {
                                    result.push({
                                        "class": "fal fa-satellite-dish margin-xs-r margin-xs-l",
                                        "title": T("Satellite-UTM")
                                    })
                                }

                                if (ruleEntry.src?.node) {
                                    let iconClass = products.unifiedSecurityConsole.topologies.view.getIconClassForNode(ruleEntry.src?.node)
                                    result.push({
                                        "class": iconClass
                                    })
                                }
                                return result
                            },
                            "iconsAfter": (ruleEntry:SunRule) => {
                                const nodeInfo = ruleEntry.src?.node
                                if (nodeInfo?.node_zone) {
                                    return [{
                                        "class": "fal fa-info-circle",
                                        "title": `
                                                <table style="margin:0;">
                                                    <tr><td>${T('Address')}:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_address)}</td></tr>
                                                    <tr><td>Zone:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_zone.name)}</td></tr>
                                                </table>`,
                                        "htmlTooltip": true
                                    }]
                                }
                                else {
                                    return []
                                }
                            }
                        },

                        {
                            "text": T("Destination"),
                            "property": "destinationNodeName",
                            "displayType": "text",
                            "getValue": (ruleEntry: SunRule) => {
                                return ruleEntry.dst?.node.name
                            },
                            "iconsBefore": (ruleEntry: SunRule) => {
                                let result = []

                                if (selectedCoreUtmId.value == ruleEntry.dst?.utmId) {
                                    result.push({
                                        "class": "fal fa-crown margin-xs-r margin-xs-l",
                                        "title": "Core-UTM"
                                    })
                                }
                                else {
                                    result.push({
                                        "class": "fal fa-satellite-dish margin-xs-r margin-xs-l",
                                        "title": T("Satellite-UTM")
                                    })
                                }

                                if (ruleEntry.dst?.node) {
                                    let iconClass = products.unifiedSecurityConsole.topologies.view.getIconClassForNode(ruleEntry.dst?.node)
                                    result.push({
                                        "class": iconClass
                                    })
                                }
                                return result
                            },
                            "iconsAfter": (ruleEntry: SunRule) => {
                                const nodeInfo = ruleEntry.dst?.node
                                if (nodeInfo?.node_zone) {
                                    return [{
                                        "class": "fal fa-info-circle",
                                        "title": `<table style="margin:0;">
                                                <tr><td>${T('Address')}:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_address)}</td></tr>
                                                <tr><td>Zone:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_zone.name)}</td></tr>
                                                </table>`,
                                        "htmlTooltip": true
                                    }]
                                }
                                else {
                                    return []
                                }
                            }
                        },
                        {
                            "text": T("Service"),
                            "property": "serviceName",
                            "displayType": "text",
                            "getValue": (ruleEntry: SunRule) => {
                                return ruleEntry.dst?.service.name
                            },
                            "iconsBefore": (ruleEntry: SunRule) => {
                                const service: SunNkViewService | undefined = ruleEntry.dst?.service 
                                if (service) {
                                    return [{
                                        "class": (/^(tcp|udp|icmp|ipv6-icmp)$/.test(service.protocol) ? 'icon icon-serviceobject-' + service.protocol : (Array.isArray(service.service_refs) || (Array.isArray(service?.services) && service.id == undefined)) ? 'icon icon-serviceobject-group' : 'icon icon-serviceobject-other')
                                    }]
                                }
                                else {
                                    return []
                                }
                            },
                            "iconsAfter": (ruleEntry: SunRule) => {
                                const service = ruleEntry.dst?.service

                                if (service?.id) {
                                    const returnValue = [{
                                        "class": "fal fa-info-circle",
                                        "title": Array.isArray(service.service_refs) ? `
                                            <table style="margin:0;">
                                                <tr>
                                                    <td><strong>${T('Service Group')}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Services:')}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        ${service.service_refs.join('<br>')}
                                                    </td>
                                                </tr>
                                            </table>
                                        ` : `
                                            <table style="margin:0;">
                                                <tr>
                                                    <td><strong>${T('Service')}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Protocol')}:</td>
                                                    <td>${service.protocol}</td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Source ports')}:</td>
                                                    <td>${(service["src-ports"]?.join(", ") || '-')}</td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Destination ports')}:</td>
                                                    <td>${(service["dst-ports"]?.join(", ") || '-')}</td>
                                                </tr>
                                            </table>
                                        `,
                                        "htmlTooltip": true
                                    }]

                                    return returnValue
                                }
                                else if (Array.isArray(service?.services) && service?.id == undefined) {
                                    let allServices = ""
                                    service.services.forEach((service) => {
                                        allServices = allServices + encodingHelpers.escapeHTML(service.name) + "<br>"
                                    })
                                    return [{
                                        "htmlTooltip": true,
                                        "class": "fal fa-info-circle",
                                        "title": `
                                                <table style="margin:0;">
                                                    <tr>
                                                        <td><strong>${T('Service Group')}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>${T('Services:')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            ${allServices}
                                                        </td>
                                                    </tr>
                                                </table>`
                                    }]
                                }
                                else {
                                    return []
                                }
                            },
                            
                        },
                        {
                            "text": T("Actions"),
                            "property": "actions",
                            "displayType": "buttons",
                            "tdClass":"text-right actions",
                            "sortable": false,
                            "width": 448,
                            "getValue": (ruleEntry: SunRule,parentEntry?:SunSatellite) => {
                                const currentChangedSite = selectedTopology.value?.changes?.data?.changedSites.find((satellite) => {
                                    return satellite.clientId == parentEntry?.id
                                })
                                const willBeDeleted = (parentEntry?.toBeDeleted || currentChangedSite?.rules.removed.some((ruleId) => { return ruleId == ruleEntry.id }));
                                if(!willBeDeleted) {
                                    return [
                                        new Button({
                                            "title": T('Edit rule'),
                                            "icon": "fal fa-wrench",
                                            "disabled":function() {
                                                const hasSunPermission = (objectStores.uscUtms.getObjectFromStore(activeAccountId.value,parentEntry?.id))?.permissions?.manageVPNEnabled
                                                const isOnline = (getterHelpers.useStore()?.getters.getObject({
                                                    "accountId": activeAccountId.value,
                                                    "productType": "unifiedSecurityConsole",
                                                    "objectType": "ccutmStates",
                                                    "objectId":parentEntry?.id
                                                })?.online || false)
                                                return !(isOnline && hasSunPermission && isCoreOnline.value && hasCorePermission.value)
                                            }(),
                                            "onClick": () => {

                                                products.unifiedSecurityConsole.topologies.view.getRuleDialog(
                                                    activeAccountId.value,
                                                    selectedTopologyId.value,
                                                    selectedCoreUtmId.value,
                                                    parentEntry?.id,
                                                    undefined,
                                                    ruleEntry.src.utmId,
                                                    ruleEntry.src.node.id,
                                                    ruleEntry.dst.utmId,
                                                    ruleEntry.dst.node.id,
                                                    ruleEntry.dst.service.id,
                                                    ruleEntry.id,
                                                    async (payload) => {
                                                        try {
                                                            if(!(<SunRuleOnUpdate>payload).id) throw "missing id"
                                                            let result = await products.unifiedSecurityConsole.topologies.updateRuleForSatelliteInApi(activeAccountId.value, selectedTopology.value?.id || "", parentEntry?.id || "",(<SunRuleOnUpdate>payload))
                                                            init(true)
                                                            if(result && selectedTopology.value) {
                                                                products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                            }
                                                            if((parentEntry?.rules || []).length > 0) {
                                                                //@ts-ignore
                                                                subEntry.openedSubTable = true
                                                            }
                                                        }
                                                        catch(e) {
                                                            throw e
                                                        }
                                                    },
                                                    (e) => {
                                                        console.error(e)
                                                    },
                                                    () => {
                                                        init(true)
                                                    }
                                                )
                                            }
                                        }),
                                        new Button({
                                            "icon": "fal fa-copy",
                                            "title": T('Regel kopieren'),
                                            "onClick": (e,component) => {                                              
                                                ruleClipboard.value = ruleEntry
                                                component.showMessage(T('In Zwischenablage kopiert!'))
                                            }
                                        }),
                                        new Button({
                                            "icon": "fal fa-trash",
                                            "title": T('Delete'),
                                            "onClick": () => {
                                                dialogs.misc.confirmDialog(activeAccountId.value, T("Delete Rule"), T("Do you really want to delete this rule?"), async () => {
                                                    try {
                                                        
                                                        let result = await products.unifiedSecurityConsole.topologies.deleteRuleForSatelliteInApi(activeAccountId.value, selectedTopology.value?.id || '', parentEntry?.id || '', ruleEntry.id)
                                                        init(true)
                                                        if(result && selectedTopology.value) {
                                                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                        }                                              
                                                    }
                                                    catch (e) {
                                                        console.error(e)
                                                    }
                                                })
                                            }
                                        })
                                    ]
                                }
                                else {
                                    return []
                                }
                            }
                        }
                    ]
                }
            ]
        },
        {
            "displayType": "subTable",
            "draggableRows": true,
            "isOpened": (entry: any) => {
                return entry.data.pools?.length
            },
            "getObjects": (entry: SunTopology) => {
                return entry.data.pools
            },
            "entries": [
            {
                    "text": "",
                    "property": "openFolder",
                    "displayType": "buttons",
                    "replaceButtonWithStatus":(entry:SunRoadwarrior) => { 
                        const removedPools = topologyChanges.value?.removedPools || []
                        const addedPools = topologyChanges.value?.addedPools || []
                        const hasBeenRemoved = removedPools?.indexOf(entry.id) != -1 || false
                        const willBeAdded = addedPools?.indexOf(entry.id) != -1 || false
                        return hasBeenRemoved || (willBeAdded && entry.rules.length == 0)
                    },
                    "getValue": (subEntry: SunRoadwarrior) => {
                        const removedPools = topologyChanges.value?.removedPools || []
                        const addedPools = topologyChanges.value?.addedPools || []
                        const hasBeenRemoved = removedPools?.indexOf(subEntry.id) != -1 || false
                        const willBeAdded = addedPools?.indexOf(subEntry.id) != -1 || false

                        if (!(willBeAdded && subEntry?.rules?.length == 0) && !hasBeenRemoved && subEntry?.rules?.length !== 0) {
                            return [
                                new Button({
                                    //@ts-ignore
                                    "icon": "fal " + (subEntry.openedSubTable ? "fa-folder-open" : "fa-folder"),
                                    "onClick": () => {
                                        //@ts-ignore
                                        subEntry.openedSubTable = subEntry.openedSubTable ? false : true
                                    },
                                    "type": "icon",
                                    "topRightCircle":{
                                        "counter":subEntry.rules.length
                                    }
                                })
                            ]
                        }
                        else if(hasBeenRemoved) {
                            return {
                                "color": 'red',
                                "tooltip": T('Removed') + '. ' + T('The changes have not been published yet.'),
                                "icon": 'fa fa-minus'
                            }
                        }
                        else if (willBeAdded) {
                            return {
                                "color": 'green',
                                "tooltip": T('Added') + '. ' + T('The changes have not been published yet.'),
                                "icon": 'fa fa-plus'
                            }
                        }
                        else {
                            return []
                        }
                    },
                    "width": 50,
                    "sortable": false
                },
                {
                    "text": "Roadwarrior",
                    "property": "name",
                    "displayType": "text",
                    "title": (entry : SunRoadwarrior) => {
                        return `
                        <table style="margin:0;">
                            <tr><td>${T('TN')}:</td><td>${ entry.transferNetwork }</td></tr>                            
                        </table>`},
                    "htmlTooltip": true,
                    "tdId": (entry: SunRoadwarrior) => {
                        return entry?.id
                    },
                    "getValue": (entry: SunRoadwarrior) => {
                        return entry.name
                    },
                    "iconsBefore": (entry: SunRoadwarrior) => {
                        return [{
                            "class":"fal fa-chart-network margin-xs-l"
                        }]
                    },
                    "labelsAfter": (entry: SunRoadwarrior) => {
                        let result: Label[] = []
                        let removedPools = topologyChanges.value?.removedPools || []
                        const hasBeenRemoved = removedPools.indexOf(entry.id) != -1
                        const hasBeenAdded = selectedTopology.value?.changes?.data?.addedPools.indexOf(entry.id || '') != -1
                        const hasBeenChanged = selectedTopology.value?.changes?.data?.changedPools.some((pool) => { return pool.poolId == entry.id })
                        
                        let state = entry.state != 'FAILED' ? hasBeenAdded || hasBeenChanged || hasBeenRemoved ? 'NOT_PUBLISHED' : entry.state : 'FAILED';
                        const allErrors = entry.errors.filter(arrayHelpers.onlyUniqueFilter)

                        const androidProfiles = entry.entities.filter((entity) => {
                            return entity.type == "ANDROID_PROFILE"
                        })
                        const iosProfiles = entry.entities.filter((entity) => {
                            return entity.type == "IOS_PROFILE"
                        })

                        
                        if (androidProfiles.length > 0) {
                            result.push(new Label({
                                "class": "",
                                "text": T(''),
                                "icon": new Icon( { "class":"fab fa-android",}),
                                "title": androidProfiles.map((entity) => { return entity.id.split("/")[3] }).join(", ")
                            }))
                        }
                        if (iosProfiles.length > 0) {
                            result.push(new Label({
                                "class": "",
                                "text": T(''),
                                "icon": new Icon( { "class":"fab fa-apple",}),
                                "title":iosProfiles.map((entity) => { 
                                    let profile = products.mobileSecurity.iosProfiles.useStore?.().getObjectStoreObject(activeAccountId.value,entity.id)
                                    return profile?.profilename || entity.id 
                                }).join(", ")
                            }))
                        }



                        if (state == "FAILED") {
                            let errorText = T('Error')
                            result.push(new Label({
                                "class": "bg-red",
                                "text": errorText,
                                "icon": new Icon({ "class":"fal fa-exclamation-triangle", }),
                                "htmlTooltip": true,
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('Errors')}:</strong></td><td> ${allErrors.join(', ')}
                                            </td>
                                        </tr>
                                    </table>
                                `
                            }))
                        }
                        else if (state == "NOT_PUBLISHED") {
                            result.push(new Label({
                                "class": "bg-yellow",
                                "text": T('Not published'),
                                "icon": new Icon({ class:(hasBeenAdded ? "fal fa-plus" : hasBeenRemoved ? "fal fa-trash" : hasBeenChanged ? "fal fa-arrows-rotate" : "fal fa-info-cicle"),}),
                                "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${(hasBeenAdded ? T('Added') + ". " : hasBeenChanged ? T('Updated') + ". " : hasBeenRemoved ? T('Removed') + ". " : '') + T('The changes have not been published yet.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                "htmlTooltip": true
                            }))
                        }
                        else if (state == "PENDING") {
                                result.push(new Label({
                                    "class": "bg-yellow",
                                    "text": T('Publishing') + "...",
                                    "icon": new Icon({ "class":"fal fa-hourglass", }),
                                    "title": `
                                    <table style="margin:0;">
                                        <tr>
                                            <td>
                                                <strong>${T('xState')}:</strong></td><td> ${T('The changes are being published.')}
                                            </td>
                                        </tr>
                                    </table>
                                `,
                                    "htmlTooltip": true
                                }))
                            }
                        else if (state == 'PUBLISHED') {
                            result.push(new Label({
                                "class": "bg-green",
                                "text": "",
                                "icon": new Icon({ class:"fal fa-check",}),
                                "title": `
                                <table style="margin:0;">
                                    <tr>
                                        <td>
                                            <strong>${T('xState')}:</strong></td><td> ${T('There are no changes to be published.')}
                                        </td>
                                    </tr>
                                </table>
                            `,
                                "htmlTooltip": true
                            }))
                        }


                        return result
                    },
                    "subContent": {
                        "displayOrder": ["subText"],
                        "subText": (entry: SunRoadwarrior) => {
                                              return T("TN") + ": " + entry.transferNetwork + " | " + T("IPs in Benutzung") + ": " + (entry.numberOfDevices || 0) + "/" + entry.transferNetworkCapacity
                        }
                    },
                    "rowClassList": (entry: SunRoadwarrior) => {
                        let result: string[] = [];
                        let removedPools = topologyChanges.value?.removedPools || []
                        const hasBeenRemoved = removedPools?.indexOf(entry.id) != -1
                        const hasBeenAdded = selectedTopology.value?.changes?.data?.addedPools?.indexOf(entry.id || '') != -1
                        const hasBeenChanged = selectedTopology.value?.changes?.data?.changedPools?.some((pool) => { return pool.poolId == entry.id })

                        if (hasBeenRemoved) {
                            result.push('toBeRemoved')
                        }
                        else if (hasBeenAdded) {
                            result.push('toBeAdded')
                        } 
                        else if (hasBeenChanged) {
                            result.push('toBeChanged')
                        }
                        return result
                    }
                },
                {
                    "text": T("Actions"),
                    "property": "actions",
                    "displayType": "buttons",
                    "sortable": false,
                    "tdClass":"text-right actions",
                    "width": 449,
                    "getValue": (subEntry: SunRoadwarrior) => {
                        let removedPools = topologyChanges.value?.removedPools || []
                        const hasBeenRemoved = removedPools.indexOf(subEntry.id) != -1
                        let result : Button[] = []
                        if (!hasBeenRemoved) {
                            

                                    result.push(new Button({
                                        "text": T('Rule'),
                                        "title": T('Add rule'),
                                        "icon": "fal fa-plus",
                                        "disabled":function() {
                                            return !(isCoreOnline.value && hasCorePermission.value)
                                        }(),
                                        "onClick": () => {
                                            products.unifiedSecurityConsole.topologies.view.getRuleDialog(
                                                activeAccountId.value,
                                                selectedTopologyId.value,
                                                selectedCoreUtmId.value,
                                                undefined,
                                                subEntry.id,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                async (payload: SunRuleOnAdd) => {
                                                    try {
                                                        let result = await products.unifiedSecurityConsole.topologies.addRuleForRoadwarriorInApi(activeAccountId.value, selectedTopology.value?.id || "", subEntry.id, payload)
                                                        init(true)
                                                        if(result && selectedTopology.value) {
                                                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result)) 
                                                        }
                                                        if(subEntry?.rules?.length > 0) {
                                                            //@ts-ignore
                                                            subEntry.openedSubTable = true
                                                        }
                                                        
                                                    }
                                                    catch(e) {
                                                        throw e
                                                    }
                                                },
                                                (e) => {
                                                    console.error(e)
                                                },
                                                () => {
                                                    init(true)
                                                }
                                            )
                                        }
                                    }))
                                    if(ruleClipboard.value && products.unifiedSecurityConsole.topologies.view.isRoadwarriorRule(ruleClipboard.value)) {
                                        result.push(new Button({
                                            "icon": "fal fa-paste",
                                            "title": T('Paste'),
                                            "onClick": (e,component) => {                                              
                                                if(ruleClipboard.value && selectedTopology.value) {
                                                    products.unifiedSecurityConsole.topologies.view.pasteRule(activeAccountId.value,ruleClipboard.value,selectedTopology.value,selectedCoreUtmId.value,subEntry)
                                                    ruleClipboard.value = undefined
                                                }
                                            }
                                        }))
                                    }
                                    result.push(new Button({
                                        "icon": "fal fa-sync",
                                        "title": T("Refresh"),
                                        "onClick": () => {
                                            refreshNetworkViews([selectedCoreUtmId.value])
                                        }
                                    }))
                                    
                                    result.push(new Button({
                                        "title": T('Roadwarrior bearbeiten'),
                                        "icon": "fal fa-wrench",
                                        "disabled":function() {
                                            return !(isCoreOnline.value && hasCorePermission.value)
                                        }(),
                                        "onClick": () => {
                                            if(selectedTopology.value) {
                                                const modal: Modal = {
                                                    "accountId": activeAccountId.value,
                                                    "id": "addSatellite",
                                                    "content": {
                                                        "title": {
                                                            "text": T('Roadwarrior bearbeiten'),
                                                            "icon": "fal fa-wrench"
                                                        },
                                                        "body": {
                                                            "component": "add-edit-satellite",
                                                            "properties": {
                                                                "coreUtmId": selectedCoreUtmId.value,
                                                                "existingSatellites": products.unifiedSecurityConsole.topologies.view.getSatelliteUtmIdsForCoreUtm(selectedTopology.value, selectedCoreUtmId.value).filter((utmId) => {
                                                                    return topologyChanges.value?.removedNodes.indexOf(utmId) == -1
                                                                }),
                                                                type:"roadwarrior",
                                                                roadwarriorName:subEntry.name,
                                                                transferNetwork:subEntry.transferNetwork,
                                                                profiles:subEntry.entities


                                                            }
                                                        }
                                                    },
                                                    "buttons": [
                                                        {
                                                            'text': T('Abort'),
                                                            'icon': 'fal fa-times',
                                                            'disabled': false,
                                                            'onClick': () => {
                                                                getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                                            }
                                                        },
                                                        {
                                                            'text': T('Save'),
                                                            'icon': 'fal fa-save',
                                                            'disabled': false,
                                                            'onClick': async (modalWrapper: any) => {
                                                                modalWrapper.buttons[0].disabled = true
                                                                modalWrapper.buttons[1].loading = true
                                                                modalWrapper.buttons[1].disabled = true
                                                                try {
                                                                    const roadwarriorName = modalWrapper.$refs.modalComponent.roadwarriorName
                                                                    const transferNetwork = modalWrapper.$refs.modalComponent.transferNetwork
                                                                    const selectedProfiles = modalWrapper.$refs.modalComponent.selectedProfiles
                                                                    const entities = (<string[]>selectedProfiles).map((profileId) => {
                                                                        const existingAndroidProfile = products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObject(activeAccountId.value, profileId.split("/")[3])
                                                                        return {
                                                                            "id":profileId,
                                                                            "type":existingAndroidProfile ? 'ANDROID_PROFILE' : 'IOS_PROFILE'
                                                                        } as SunRoadwarriorEntity
                                                                    })
                                                                    
                                                                    // clear potential errors from last request
                                                                    modalWrapper.$refs.modalComponent.clearErrors()

                                                                    let roadwarriorPayload : any = {
                                                                        "name":roadwarriorName,
                                                                        "transferNetwork":transferNetwork,
                                                                        "entities":entities
                                                                    }

                                                                    let roadwarriorResult = await products.unifiedSecurityConsole.topologies.updateRoadwarriorInApi(activeAccountId.value,selectedTopologyId.value,subEntry.id,roadwarriorPayload)
                                                                    
                                                                    subEntry.name = roadwarriorName
                                                                    subEntry.transferNetwork = transferNetwork
                                                                    subEntry.entities = entities

                                                                    

                                                                    modalWrapper.buttons[0].disabled = false
                                                                    modalWrapper.buttons[1].loading = false
                                                                    modalWrapper.buttons[1].disabled = false
                                                                    
                                                                    init(true)

                                                                    getterHelpers.useStore().commit(MutationTypes.removeModal, { 'accountId': activeAccountId.value })
                                                                }
                                                                catch (e:any) {
                                                                    console.error(e)
                                                                    if(e?.data?.data?.error?.error) {
                                                                        modalWrapper.$refs.modalComponent.setErrors(e?.data?.data?.error?.error)
                                                                    }
                                                                    if(e?.data?.data?.error?.message) {
                                                                        modalWrapper.$refs.modalComponent.setErrors([e?.data?.data?.error?.message])
                                                                    }
                                                                    modalWrapper.buttons[0].disabled = false
                                                                    modalWrapper.buttons[1].loading = false
                                                                    modalWrapper.buttons[1].disabled = false
                                                                }
                                                                

                                                            }
                                                        }
                                                    ]
                                                }
                                                getterHelpers.useStore().commit(MutationTypes.addModal, modal)
                                            }
                                        },
                                        "size": "sm"
                                    }))
                                    result.push(new Button({
                                        "icon": "fal fa-trash",
                                        "title": T('Delete'),
                                        "onClick": () => {
                                            dialogs.misc.confirmDialog(activeAccountId.value, T("Roadwarrior löschen"), T("Möchten Sie diesen Roadwarrior wirklich löschen?"), async () => {
                                                try {
                                                    let result = await products.unifiedSecurityConsole.topologies.deleteRoadwarriorInApi(activeAccountId.value, selectedTopology.value?.id || '', subEntry.id)
                                                    if(result && result.code == 200) {
                                                        init(true)
                                                    }                                                              
                                                }
                                                catch (e) {
                                                    console.error(e)
                                                }
                                            })
                                        }
                                    }))
                                
                            
                        }
                        else {
                            return []
                        }
                        return result
                    }
                },
                {
                    "displayType": "subTable",
                    "draggableRows": true,
                    "isOpened": (entry: SunRoadwarrior) => {
                        let removedNodes = topologyChanges.value?.removedPools || []
                        const hasBeenRemoved = removedNodes.indexOf(entry.id) != -1
                        //@ts-ignore
                        return hasBeenRemoved || entry.rules.length === 0 ? false : entry.openedSubTable
                    },
                    "getObjects": (entry: SunRoadwarrior) => {
                        return entry.rules
                    },
                    "entries": [
                        {
                            "text": "",
                            "property": "change",
                            "displayType": "status",
                            "getValue": (ruleEntry: SunRule,parentEntry?:SunRoadwarrior) => {
                                const poolId = parentEntry?.id
                                const currentChangedSite = selectedTopology.value?.changes?.data?.changedPools.find((pool) => {
                                    return pool.poolId == poolId
                                })
                                const willBeDeleted = (currentChangedSite?.rules.removed.some((ruleId) => { return ruleId == ruleEntry.id }));
                                const willBeChanged = currentChangedSite?.rules.changed.some((ruleId) => { return ruleId == ruleEntry.id });
                                const willBeAdded = currentChangedSite?.rules.added.some((ruleId) => { return ruleId == ruleEntry.id });
                                
                                if (willBeDeleted) {
                                    return {
                                        "color": 'red',
                                        "tooltip": T('Removed') + '. ' + T('The changes have not been published yet.'),
                                        "icon": 'fa fa-minus'
                                    }
                                }
                                else if (willBeChanged) {
                                    return {
                                        "color": 'yellow',
                                        "tooltip": T('Changed') + '. ' + T('The changes have not been published yet.'),
                                        "icon": 'fa fa-arrows-rotate'
                                    }
                                }
                                else if (willBeAdded) {
                                    return {
                                        "color": 'green',
                                        "tooltip": T('Added') + '. ' + T('The changes have not been published yet.'),
                                        "icon": 'fa fa-plus'
                                    }
                                }
                                else {
                                    return undefined
                                }
                            },
                            "width": 42,
                            "rowClassList": (ruleEntry: SunRoadwarriorRule, parentEntry?: SunRoadwarrior) => {
                                let result: string[] = [];
                                const poolId = parentEntry?.id
                                const currentChangedSite = selectedTopology.value?.changes?.data?.changedPools.find((pool) => {
                                    return pool.poolId == poolId
                                })
                                const willBeDeleted = (currentChangedSite?.rules.removed.some((ruleId) => { return ruleId == ruleEntry.id }));
                                const willBeChanged = currentChangedSite?.rules.changed.some((ruleId) => { return ruleId == ruleEntry.id });
                                const willBeAdded = currentChangedSite?.rules.added.some((ruleId) => { return ruleId == ruleEntry.id });
                                if (willBeDeleted) {
                                    result.push('toBeRemoved')
                                }
                                else if (willBeAdded) {
                                    result.push('toBeAdded')
                                }
                                else if (willBeChanged) {
                                    result.push('toBeChanged')
                                }
                                return result
                            }
                        },
                        {
                            "text": T("Source"),
                            "property": "sourceNodeName",
                            "displayType": "text",
                            "getValue": (ruleEntry: SunRoadwarriorRule, parentEntry?:SunRoadwarrior) => {
                                return parentEntry?.name + (parentEntry?.transferNetwork ?  " | " + parentEntry?.transferNetwork : "")
                            },
                            "iconsBefore": (ruleEntry: SunRoadwarriorRule) => {
                                let result = []

                                result.push({
                                    "class": "fal fa-chart-network margin-xs-r margin-xs-l",
                                    "title": "Core-UTM"
                                })           
    
                                result.push({
                                    "class": "icon icon-node-network"
                                })
                                
                                return result
                            },
                            "iconsAfter": (ruleEntry: SunRoadwarriorRule) => {
                                const nodeInfo = ruleEntry.dst?.node
                                if (nodeInfo?.node_zone) {
                                    return [{
                                        "class": "fal fa-info-circle",
                                        "title": `<table style="margin:0;">
                                                <tr><td>${T('Address')}:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_address)}</td></tr>
                                                <tr><td>Zone:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_zone.name)}</td></tr>
                                                </table>`,
                                        "htmlTooltip": true
                                    }]
                                }
                                else {
                                    return []
                                }
                            }
                        },
                        {
                            "text": T("Destination"),
                            "property": "destinationNodeName",
                            "displayType": "text",
                            "getValue": (ruleEntry: SunRoadwarriorRule) => {
                                return ruleEntry.dst?.node.name
                            },
                            "iconsBefore": (ruleEntry: SunRoadwarriorRule) => {
                                let result = []

                                if (selectedCoreUtmId.value == ruleEntry.dst?.utmId) {
                                    result.push({
                                        "class": "fal fa-crown margin-xs-r margin-xs-l",
                                        "title": "Core-UTM"
                                    })
                                }
                                else {
                                    result.push({
                                        "class": "fal fa-satellite-dish margin-xs-r margin-xs-l",
                                        "title": T("Satellite-UTM")
                                    })
                                }

                                if (ruleEntry.dst?.node) {
                                    let iconClass = products.unifiedSecurityConsole.topologies.view.getIconClassForNode(ruleEntry.dst?.node)
                                    result.push({
                                        "class": iconClass
                                    })
                                }
                                return result
                            },
                            "iconsAfter": (ruleEntry: SunRoadwarriorRule) => {
                                const nodeInfo = ruleEntry.dst?.node
                                if (nodeInfo?.node_zone) {
                                    return [{
                                        "class": "fal fa-info-circle",
                                        "title": `<table style="margin:0;">
                                                <tr><td>${T('Address')}:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_address)}</td></tr>
                                                <tr><td>Zone:</td><td>${encodingHelpers.escapeHTML(nodeInfo.node_zone.name)}</td></tr>
                                                </table>`,
                                        "htmlTooltip": true
                                    }]
                                }
                                else {
                                    return []
                                }
                            }
                        },
                        {
                            "text": T("Service"),
                            "property": "serviceName",
                            "displayType": "text",
                            "getValue": (ruleEntry: SunRoadwarriorRule) => {
                                return ruleEntry.dst?.service.name
                            },
                            "iconsBefore": (ruleEntry: SunRoadwarriorRule) => {
                                const service: SunNkViewService | undefined = ruleEntry.dst?.service 
                                if (service) {
                                    return [{
                                        "class": (/^(tcp|udp|icmp|ipv6-icmp)$/.test(service.protocol) ? 'icon icon-serviceobject-' + service.protocol : (Array.isArray(service.service_refs) || (Array.isArray(service?.services) && service.id == undefined)) ? 'icon icon-serviceobject-group' : 'icon icon-serviceobject-other')
                                    }]
                                }
                                else {
                                    return []
                                }
                            },
                            "iconsAfter": (ruleEntry: SunRoadwarriorRule) => {
                                const service = ruleEntry.dst?.service

                                if (service?.id) {
                                    const returnValue = [{
                                        "class": "fal fa-info-circle",
                                        "title": Array.isArray(service.service_refs) ? `
                                            <table style="margin:0;">
                                                <tr>
                                                    <td><strong>${T('Service Group')}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Services:')}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        ${service.service_refs.join('<br>')}
                                                    </td>
                                                </tr>
                                            </table>
                                        ` : `
                                            <table style="margin:0;">
                                                <tr>
                                                    <td><strong>${T('Service')}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Protocol')}:</td>
                                                    <td>${service.protocol}</td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Source ports')}:</td>
                                                    <td>${(service["src-ports"]?.join(", ") || '-')}</td>
                                                </tr>
                                                <tr>
                                                    <td>${T('Destination ports')}:</td>
                                                    <td>${(service["dst-ports"]?.join(", ") || '-')}</td>
                                                </tr>
                                            </table>
                                        `,
                                        "htmlTooltip": true
                                    }]

                                    return returnValue
                                }
                                else if (Array.isArray(service?.services) && service?.id == undefined) {
                                    let allServices = ""
                                    service.services.forEach((service) => {
                                        allServices = allServices + encodingHelpers.escapeHTML(service.name) + "<br>"
                                    })
                                    return [{
                                        "htmlTooltip": true,
                                        "class": "fal fa-info-circle",
                                        "title": `
                                                <table style="margin:0;">
                                                    <tr>
                                                        <td><strong>${T('Service Group')}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>${T('Services:')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            ${allServices}
                                                        </td>
                                                    </tr>
                                                </table>`
                                    }]
                                }
                                else {
                                    return []
                                }
                            },
                            
                        },
                        {
                            "text": T("Actions"),
                            "property": "actions",
                            "displayType": "buttons",
                            "sortable": false,
                            "tdClass":"text-right actions",
                            "width": 448,
                            "getValue": (ruleEntry: SunRoadwarriorRule,parentEntry?:SunRoadwarrior) => {
                                const currentChangedSite = selectedTopology.value?.changes?.data?.changedPools.find((pool) => {
                                    return pool.poolId == parentEntry?.id
                                })
                                const willBeDeleted = (currentChangedSite?.rules.removed.some((ruleId) => { return ruleId == ruleEntry.id }));
                                if(!willBeDeleted) {
                                    return [
                                        new Button({
                                            "title": T('Edit rule'),
                                            "icon": "fal fa-wrench",
                                            "disabled":function() {
                                                return !(isCoreOnline.value && hasCorePermission.value)
                                            }(),
                                            "onClick": () => {
                                                products.unifiedSecurityConsole.topologies.view.getRuleDialog(
                                                    activeAccountId.value,
                                                    selectedTopologyId.value,
                                                    selectedCoreUtmId.value,
                                                    undefined,
                                                    parentEntry?.id,
                                                    undefined,
                                                    undefined,
                                                    ruleEntry.dst.utmId,
                                                    ruleEntry.dst.node.id,
                                                    ruleEntry.dst.service.id,
                                                    ruleEntry.id,
                                                    async (payload) => {
                                                        try {
                                                            if(!(<SunRuleOnUpdate>payload).id) throw "missing id"
                                                            let result = await products.unifiedSecurityConsole.topologies.updateRuleForRoadwarriorInApi(activeAccountId.value, selectedTopology.value?.id || "", parentEntry?.id || "", payload)
                                                            init(true)
                                                            if(result && selectedTopology.value) {
                                                                products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                            }
                                                            if(parentEntry?.rules.length > 0) {
                                                                //@ts-ignore
                                                                ruleEntry.openedSubTable = true
                                                            }
                                                            
                                                        }
                                                        catch(e) {
                                                            throw e
                                                        }
                                                    },
                                                    (e) => {
                                                        console.error(e)
                                                    },
                                                    () => {
                                                        init(true)
                                                    }
                                                )
                                            }
                                        }),
                                        new Button({
                                            "icon": "fal fa-copy",
                                            "title": T('Regel kopieren'),
                                            "onClick": (e,component) => {                                              
                                                ruleClipboard.value = ruleEntry
                                                component.showMessage(T('In Zwischenablage kopiert!'))
                                            }
                                        }),
                                        new Button({
                                            "icon": "fal fa-trash",
                                            "title": T('Delete'),
                                            "onClick": () => {
                                                dialogs.misc.confirmDialog(activeAccountId.value, T("Delete Rule"), T("Do you really want to delete this rule?"), async () => {
                                                    try {
                                                        let result = await products.unifiedSecurityConsole.topologies.deleteRuleForRoadwarriorInApi(activeAccountId.value, selectedTopology.value?.id || '', parentEntry?.id || '', ruleEntry.id)
                                                        init(true)                                             
                                                        if(result && selectedTopology.value) {
                                                            products.unifiedSecurityConsole.topologies.view.mergeTopologies(selectedTopology.value,(<any>result))
                                                        }     
                                                    }
                                                    catch (e) {
                                                        console.error(e)
                                                    }
                                                })
                                            }
                                        })
                                        
                                    ]
                                }
                                else {
                                    return []
                                }
                            }
                        }
                    ]
                }
            ]
        }
    ]
    
    

    // GRAPH
    const data = computed(() => {
        let nodes = <Nodes>{}
        let edges = <Edges>{}
        let layouts = <Layouts>{}
        
        let topology = selectedTopology.value

        if (topology) {
            const utm = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, topology?.data.coreId)
            let coreUtmName = utm.utmname

            nodes['node' + utm.utmId] = {
                name: coreUtmName, 
                size: 30,
                utmInfo: utm
            }
            
            topology?.data.satellites?.forEach((satelliteUTM,index:number) => {
                
                const satelliteUtmInfo = objectStores.uscUtms.getObjectFromStore(activeAccountId.value, satelliteUTM.id)
                

                nodes['node' + satelliteUTM.id] = {
                    name: satelliteUtmInfo?.utmname, 
                    size:20,
                    utmInfo: satelliteUtmInfo
                }
                edges[coreUtmName + index] = {
                    source: 'node' + utm.utmId, 
                    target: 'node' + satelliteUTM.id,
                    connected:false,
                    dashed: true
                }
            });
            
            return {
                nodes,edges,layouts
            }
        }
        else {
            return {
                nodes:{},
                edges:{},
                layout:{}
            }
        }
    })

    function showContextMenu(element: HTMLElement, event: MouseEvent) {
        if (event.target) {
            let wrapper = event.target as HTMLElement;
            if (wrapper) {
                createPopper(wrapper, element, {
                    placement: "auto",
                    "onFirstUpdate": (e) => {
                        element.style.visibility = "visible"
                        element.style.display = "block"
                    },
                    'modifiers': [
                        preventOverflow,

                    ]
                })
                const handler = (event: PointerEvent) => {
                    if (!event.target || !element.contains(event.target as HTMLElement)) {
                        element.style.display = "none"
                        document.removeEventListener("pointerdown", handler, { capture: true })
                    }
                }
                document.addEventListener("pointerdown", handler, { passive: true, capture: true })
            }
        }
    }

    const nodeMenu = ref<HTMLDivElement>()
    const menuTargetUtm = ref(<any>{})

    function showNodeContextMenu(params: NodeEvent<MouseEvent>) {
        const { node, event } = params
        // Disable browser's default context menu
        event.stopPropagation()
        event.preventDefault()
        if (nodeMenu.value) {
            const nodes = data.value.nodes
            menuTargetUtm.value = (<any>nodes[node as keyof typeof nodes])?.utmInfo
            showContextMenu(nodeMenu.value, event)
        }
    }

    function graphNodeOnClick(params: NodeEvent<MouseEvent>) {
        const { node, event } = params
        // Disable browser's default context menu
        event.stopPropagation()
        event.preventDefault()
        highLightRowById(node.replace('node', ''))
    }
    function highLightRowById(id:string) {
        if(initialized.value) {
            const utmTd = document.getElementById(id)
            const scrollWrapper = document.getElementsByTagName("main")[0]
            const elementPosition = utmTd?.getBoundingClientRect().top || 0;
            var offsetPosition = elementPosition + scrollWrapper.scrollTop - 310;
            scrollWrapper?.scrollTo({
                "top": offsetPosition,
                "behavior":"smooth"
            })
            //@ts-ignore
            utmTd?.parentNode?.classList.add("highlight")
            let removeClass = () => {
                setTimeout(() => {
                    //@ts-ignore
                    utmTd?.parentNode?.classList.remove("highlight")

                }, 1000)
                scrollWrapper?.removeEventListener("scroll", removeClass)
            }
            setTimeout(() => {
                utmTd?.parentNode?.addEventListener("mouseover", removeClass)
                scrollWrapper?.addEventListener("scroll", removeClass)
            },500)
        }
        else {
            setTimeout(() => {
                highLightRowById(id)
            },1000)
        }
    }

    const thegraph = ref(<any>null)

    const graphZoomIn = () => {
        thegraph.value?.zoomIn()
    }

    const graphZoomOut = () => {
        thegraph.value?.zoomOut()
    }

    const eventHandlers: EventHandlers = {
        "node:contextmenu": showNodeContextMenu,
        "node:click": graphNodeOnClick
    }

    const configs = defineConfigs({
        view: {
            mouseWheelZoomEnabled:false,
            layoutHandler: new ForceLayout({
                positionFixedByDrag: false,
                positionFixedByClickWithAltKey: true,
                createSimulation: (d3, nodes, edges) => {
                    // d3-force parameters
                    const forceLink = d3.forceLink<ForceNodeDatum, ForceEdgeDatum>(edges).id((d : any) => d.id)
                    return d3
                        .forceSimulation(nodes)
                        .force("edge", forceLink.distance(150).strength(0.5))
                        .force("charge", d3.forceManyBody().strength(-800))
                        .force("center", d3.forceCenter().strength(0.05))
                        .alphaMin(0.001)
                }
            })
        },
        node: {
            selectable: false,
            normal: {
                radius: node => node.size || 20,
            },
            hover: {
                radius: node => node.size + 3,
            },
            label: {
                visible: true,
                fontFamily: undefined,
                fontSize:  12,
                lineHeight: 1.2,
                color: "rgba(0,0,0,0.7)",
                margin: 16,
                direction: "south",
                background: {
                    visible: true,
                    color: "rgba(255,255,255,0.7)",
                    padding: {
                        vertical: 3,
                        horizontal: 3,
                    },
                    borderRadius: 3,
                },
            },
        },
        edge: {
            selectable: false,
            gap: 40,
            type:"curve",
            normal: {
                width: 3,
                color: (edge) => { 
                    if (edge.connected) {
                        return darkmode.value ? "#AAA" : "#CCC"
                    }
                    else {
                        return darkmode.value ? "#AAA" : "#CCC"
                    }
                },
                dasharray: (edge) => { return edge.connected ? "0" : "4" },
            },
            hover: {
                width: 3,
                color: (edge) => { 
                    if (edge.connected) {
                        return darkmode.value ? "#AAA" : "#CCC"
                    }
                    else {
                        return darkmode.value ? "#AAA" : "#CCC"
                    }
                },
                dasharray: (edge) => { return edge.connected ? "0" : "4" },
            },
            selected: {
                width: 3,
                color: (edge) => { 
                    if (edge.connected) {
                        return darkmode.value ? "#AAA" : "#CCC"
                    }
                    else {
                        return darkmode.value ? "#AAA" : "#CCC"
                    }
                },
                dasharray: (edge) => { return edge.connected ? "0" : "4" },
            },
        }
    })

    const init = async (refresh:boolean=false) => {
        try {
            products.unifiedSecurityConsole.topologies.view.handleWebsocketHooks(activeAccountId.value)
            let requestObjectTypes = ['ccutmStates']
            requestObjectTypes.push("uscUtms?props[]=dead&props[]=cluster&props[]=license&props[]=lastContact&props[]=appliedProfileId&props[]=expirationDate&props[]=licensed&props[]=messages&props[]=model&props[]=offlineReason&props[]=spare&props[]=tags&props[]=tenantDomain&props[]=utmId&props[]=utmname&props[]=pinState&props[]=permissions")
            await getterHelpers.useStore().dispatch(ActionTypes.getObjectInfos, {
                "accountId": activeAccountId.value,
                "objectTypes": requestObjectTypes
            })
            const topologiesResponse = await products.unifiedSecurityConsole.topologies.queries.getObjectsFromApi(activeAccountId.value)
            const newTopologies = products.unifiedSecurityConsole.topologies.useStore?.().getObjectStoreObjects(activeAccountId.value)
            if (!refresh && Array.isArray(newTopologies) && newTopologies.length > 0) {
                selectedTopologyId.value = newTopologies[0]?.id
            }

            if(selectedTopologyId.value) {
                if(selectedTopology.value?.data.pools.length) {
                    try {
                        if(licenseHelpers.hasOneOfLicenses(activeAccountId.value,['MDM','Mobile Security'])) {
                            if (tenantHelpers.hasFunctionality(activeAccountId.value,"enterprise")) {
                                await products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(activeAccountId.value)
                            }
                            await products.mobileSecurity.iosProfiles.queries.getObjectsFromApi(activeAccountId.value)
                        }
                    }
                    catch (e) {
                        console.log(e)
                    }
                }
                if(!refresh) {
                    await products.unifiedSecurityConsole.utmNodes.queries.getObjectsFromApi(activeAccountId.value,undefined,[{ "property":"topologyId", "value":selectedTopologyId.value }])
                }
            }
            initialized.value = true
            calcMaxTableHeight()
        }
        catch (e) {
            errors.value.push(e)
        }
    }
    
    

    const refreshNetworkViews = async (ids:string[]) => {
        const propertiesArray : any[] = ids.map((id) => { 
            return { "property":"ids[]", "value":id }
        })
        propertiesArray.push({ "property":"force", "value":true })

        ids.forEach((id) => {
            products.unifiedSecurityConsole.utmNodes.view.waitForNetworkViewViaWebsocket(activeAccountId.value,id)
        })

        await products.unifiedSecurityConsole.utmNodes.queries.getObjectsFromApi(activeAccountId.value,undefined,propertiesArray)

    }

    onMounted(async () => {
        init()
        
    })

    window?.addEventListener("resize", calcMaxTableHeight)

    provide("itemlistRefresh", () => { })
    provide("itemlistShowinfo", true)
    provide("itemlistItemcolor", "red")

    watch(selectedTopology, () => {
        calcMaxTableHeight()
        updatePublishButton()
    }, { "deep": true })
    watch(topologyChanges, () => {
        buttonUpdater.value++
        updatePublishButton()
    }, { "deep": true })
    watch(computedCoreUtmErrors, () => {
        updatePublishButton()
    }, { "deep": true })

    watch(hasAnyChanges, () => {
        updatePublishButton()
    })
    watch(isPublishable, () => {
        updatePublishButton()
    })

    watch(activeAccountId, () => {
        selectedTopologyId.value = ""
        init()
    })

    const updatePublishButton = () => {
        tableButtons.value[2].disabled = !isPublishable.value
        if(!isPublishable.value) {
            if(computedCoreUtmErrors.value?.length > 0) {
                tableButtons.value[2].title = computedCoreUtmErrors.value.join("<br><br>")
            }
            else if (selectedTopology.value?.data.satellites.length == 0 && selectedTopology.value?.data.pools.length == 0) {
                tableButtons.value[2].title = T('Es fehlen Satelliten oder Roadwarrior.')
            }
            else {
                tableButtons.value[2].title = T("Es gibt nichts zu veröffentlichen.")
            }
        }
        else {
            tableButtons.value[2].title = T("Publish")
        }
        buttonUpdater.value++
    }

    onBeforeUnmount(async () => {
        products.unifiedSecurityConsole.topologies.view.deleteWebsocketHooks(activeAccountId.value)
    })
    
    defineExpose({
        highLightRowById
    })

</script>
<template>
    <section class="pagecontent">

        <template v-if="!initialized">
            <transition name="fade">
                <div class="overlay" style="line-height: calc(100vh - 190px); text-align: center;">
                    <loader style="font-size:5em;" class="color-primary" />
                </div>
            </transition>
        </template>
        <template v-if="errors.length">
            <h2>Fehler beim laden der Topologie</h2>
            <template v-for="err in errors">
                <pre>{{  err }}</pre>
            </template>
        </template>
        <template v-else-if="initialized && topologies?.length == 0">
            <section>
                <div class="row flexrow margin-xs-0 padding-xs-x-4 padding-xs-t-4" style="flex-wrap: wrap;">
                    <div class="col-xs-24 col-lg-16">
                        <div class="box-shadow padding-xs-2" style="height:100%;">
                            <h4 class="margin-xs-b-2">
                                <i class="fal fa-fw fa-puzzle"></i> {{T('VPN-Konfiguration')}}
                            </h4>
                            <p>{{T('Die VPN-Konfiguration ermöglicht die einfache Vernetzung Ihrer UTMs über eine sternförmige Netzwerktopologie.')}}</p>

                            <p>{{T('Im Zentrum steht die Core-UTM, die als zentraler Knotenpunkt fungiert.')}}</p>

                            <p>{{T('Von hier aus können mehrere Standorte über Satelliten-UTMs sicher miteinander verbunden werden.')}}</p>

                            <p>{{T('Zusätzlich können Gruppen von mobilen Endgeräten und Windows VPN Clients als Roadwarrior angebunden werden.')}}</p>

                            <p class="text-bold margin-xs-t-3">{{T('Erste Konfigurationsschritte:')}}</p>
                            <ol class="margin-xs-b-3">
                                <li>{{T('Auswahl der Unternehmenszentrale als Core-UTM. Sie bildet das Zentrum der Sterntopologie.')}}</li>
                                <li>{{T('Anbindung weiterer UTMs Ihrer anderen Standorte als Satelliten')}}</li>
                                <li>{{T('Konfiguration der Roadwarrior für Windows VPN-Clients und mobile Endgeräte')}}</li>
                            </ol>
                           
                            <p>{{T('Weitere Informationen finden Sie in unserem Wiki unter')}} <a href="https://wiki.securepoint.de/VPN-Konfiguration" target="_blank">{{T('VPN-Konfiguration')}}</a></p>

                            <p class="margin-xs-t-4 text-center" style="font-size:1.15em">
                                <btn 
                                    :buttonUpdater="buttonUpdater" 
                                    :button-options="altAddCoreButton"
                                >
                                </btn>
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-24 col-lg-8">
                        <div class="box-shadow padding-xs-2 text-center"  style="height:100%;">
                            <img :src="darkmode ? diagramImageDark : diagramImage" style="display: inline; max-width:400px;width:100%; filter:grayscale(1); opacity:0.7">
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-else-if="topologies?.length > 0">
            <section id="sunTable">
                <div class="row margin-xs-0">
                    <div class="col-xs-24 padding-xs-x-4 padding-xs-t-4">
                        <div class="box-shadow padding-xs-2" style="height:100%">
                            <div class="controls text-right padding-xs-b">
                                <div class="btn-group margin-xs-b margin-xs-r" v-if="qaButtons">
                                    <template v-for="button in qaButtons">
                                        <btn :buttonUpdater="buttonUpdater" :button-options="button"></btn>
                                    </template>
                                </div>
                                <div class="btn-group margin-xs-b">
                                    <btn :buttonUpdater="buttonUpdater" :button-options="tableButtons[0]"></btn>
                                </div>
                                <div class="btn-group margin-xs-l  margin-xs-b">
                                    <btn :buttonUpdater="buttonUpdater" :button-options="tableButtons[1]"></btn>
                                    <btn :buttonUpdater="buttonUpdater" :button-options="tableButtons[2]"></btn>
                                </div>

                            </div>
                            <tableNext 
                                :max-height="maxTableHeight" 
                                :selectable-columns="tableCollumns"
                                :subTablePaddingLeft="45" 
                                :object-list="selectedTopology ? [selectedTopology] : []"
                                :has-options="false" 
                                :is-searchable="false" 
                                :is-filterable="false"
                            ></tableNext>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="false" >
            <stickyScrollNav :sections="[
                { 
                    'id':'sunTable',
                    'icon':'fa-table',
                    'text': T('Table')
                },
                {
                    'id': 'changes',
                    'icon': 'fa-arrows-rotate',
                    'text': T('Changes'),
                    'visible': () => { return selectedTopology != undefined }
                },
                {
                    'id': 'graph',
                    'icon': 'fa-graph',
                    'text': T('Graph'),
                    'visible': () => { return selectedTopology != undefined }
                }
                
            ]" :scrollContainerTag="'main'">
                <template v-slot:sunTable>
                    <div class="row margin-xs-0">
                        <div class="col-xs-24 padding-xs-x-4 padding-xs-t-4">
                            <div class="box-shadow padding-xs-2" style="height:100%">
                                <div class="controls text-right padding-xs-b">

                                    <div class="btn-group margin-xs-b">
                                        <btn :buttonUpdater="buttonUpdater" :button-options="tableButtons[0]"></btn>
                                    </div>
                                    <div class="btn-group margin-xs-l  margin-xs-b">
                                        <btn :buttonUpdater="buttonUpdater" :button-options="tableButtons[1]"></btn>
                                        <btn :buttonUpdater="buttonUpdater" :button-options="tableButtons[2]"></btn>
                                    </div>

                                </div>
                                <tableNext :max-height="maxTableHeight" :selectable-columns="tableCollumns"
                                    :subTablePaddingLeft="45" :object-list="selectedTopology ? [selectedTopology] : []"
                                    :has-options="false" :is-searchable="false" :is-filterable="false"></tableNext>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:changes v-if="selectedTopology">
                    <div class="row margin-xs-0">
                        <div class="col-xs-24 padding-xs-x-4 padding-xs-t-4 padding-xs-b-4">
                            <h3>Changes:</h3>
                            <template v-for="(value, key) in topologyChanges">
                                <h4 v-if="value.length > 0">{{ stringHelpers.capitalizeFirstLetter(key).replace('nodes', ' Nodes').replace('sites',' Sites') }}</h4>
                                <template v-if="value.length && (key == 'addedNodes' || key =='changedNodes' || key == 'removedNodes')">
                                    <template v-for="node in value">
                                        <div class="box box-shadow padding-xs-2">
                                            {{ node }}
                                        </div>
                                    </template>
                                </template>
                                <template v-if="value.length && (key == 'changedSites')">
                                    <template v-for="site in (value as SunTopologyChangedSite[])">
                                        <div class="box box-shadow padding-xs-2">
                                            <p>Satellite: {{ site.clientId }}</p>
                                            <template v-if="site.rules.added.length">
                                                <strong>Added rules:</strong>
                                                <template v-for="ruleId in site.rules.added" >
                                                    <p>{{ ruleId }} | {{ selectedTopology != undefined ? products.unifiedSecurityConsole.topologies.view.getRuleInfo(selectedTopology,site.clientId,ruleId)?.dst.service.name : "" }}</p>
                                                    <hr>
                                                </template>
                                                <br>
                                            </template>
                                            <template v-if="site.rules.changed.length">
                                                <strong>Changed rules:</strong>
                                                <template v-for="ruleId in site.rules.changed">
                                                    <p>{{ ruleId }} | {{ selectedTopology != undefined ? products.unifiedSecurityConsole.topologies.view.getRuleInfo(selectedTopology,site.clientId,ruleId)?.dst.service.name : "" }}</p>
                                                    <hr>
                                                </template>
                                                <br>
                                            </template>
                                            <template v-if="site.rules.removed.length">
                                                <strong>Removed rules:</strong>
                                                <template v-for="ruleId in site.rules.removed">
                                                    <p>{{ ruleId }} | {{ selectedTopology != undefined ? products.unifiedSecurityConsole.topologies.view.getRuleInfo(selectedTopology,site.clientId,ruleId)?.dst.service.name : "" }}</p>
                                                    <hr>
                                                </template>
                                                <br>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-slot:graph v-if="selectedTopology">
                    <div class="row margin-xs-0">
                        <div class="col-xs-24 padding-xs-x-4 padding-xs-y-4">
                            <div id="graphWrapper" class="box-shadow padding-xs-2">
                                <v-network-graph :nodes="data.nodes" :edges="data.edges" :layouts="data.layouts"
                                    :configs="configs" :event-handlers="eventHandlers" id="thegraph" ref="thegraph">
                                    <template #override-node="{ nodeId, scale, config, ...slotProps }">
                                        <!-- circle for filling background -->
                                        <circle :r="config.radius * scale" :fill="darkmode ? '#303338' : '#fff'"
                                            v-bind="slotProps" :stroke-width="4" :stroke="darkmode ? '#AAA' : '#CCC'" />
                                        <text text-anchor="middle" dominant-baseline="central"
                                            :font-size="12 * (scale * 1.5)" v-html="'&#xf233'"
                                            style="font-family: 'Font Awesome 6 Pro';"
                                            :fill="darkmode ? 'rgba(255,255,255,0.6)' : 'rgba(120,120,120,0.7)'" />
                                    </template>

                                    <template #edge-overlay="{ edge, scale, length, pointAtLength }">
                                        <!-- source side -->
                                        <g class="edge-icon">
                                            <circle :cx="pointAtLength(length / 2).x" :cy="pointAtLength(length / 2).y"
                                                :r="10 * scale" stroke="none" :stroke-width="0"
                                                :fill="edge.connected ? '#2DCC71' : '#E74C3C'" />
                                            <text v-bind="pointAtLength(length / 2)" text-anchor="middle"
                                                dominant-baseline="central" :font-size="12 * scale"
                                                v-html="edge.connected ? '&#xf00c' : '&#xf00d'"
                                                style="font-family: 'Font Awesome 6 Pro';" fill="#fff" />
                                        </g>
                                    </template>

                                </v-network-graph>
                                <div ref="nodeMenu" class="context-menu" style="z-index:10;position:fixed;">
                                    <div class="row itemlist show-details view-grid">
                                        <div class="col-xs-24 padding-xs item" style="z-index:999999">
                                            <itemlistItem v-if="menuTargetUtm" :item="menuTargetUtm"
                                                alt-product-type="unifiedSecurityConsole" alt-object-type="uscUtms"
                                                :index="1" :showInfo="true" class="col-xs-24 padding-xs"
                                                :refreshCounterProp="0" :viewMode="'grid'">
                                            </itemlistItem>
                                        </div>
                                    </div>
                                </div>
                                <div id="zoomControls">
                                    <a class="zoomIn" v-on:click="graphZoomIn">
                                        <i class="fal fa-plus"></i>
                                    </a>
                                    <a class="zoomOut" v-on:click="graphZoomOut">
                                        <i class="fal fa-minus"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </stickyScrollNav>
        </template>
    </section>
</template>
<style lang="scss">
    
    @import "../../styles/sass/settings";

    #graphWrapper {
        stroke:none !important;
        height: calc(100vh - 64px - 40px - 64px);
        #thegraph {
            background-image: url("data:image/svg+xml,<svg id='patternId' width='2000px' height='2000px' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='50' height='50' patternTransform='scale(1) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 100%, 0)'/><path d='M11 6a5 5 0 01-5 5 5 5 0 01-5-5 5 5 0 015-5 5 5 0 015 5'  stroke-width='1' stroke='none' fill='hsla(259, 0%, 0%, 0.1)'/></pattern></defs><rect width='100%' height='100%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
            background-size: 300px;
        }
        .context-menu {
            width: 33vw;
            padding: 10px;
            position: fixed !important;
            visibility: hidden;
            font-size: 12px;
        }
    }

    #sunTable {
        max-height:calc(100vh - 64px - 40px);
        table {
            tr {
                td {
                    padding:4px;
                    
                }
                &.toBeChanged {
                    background:rgba(#F0AD4E, 0.1);
                }
                &.toBeRemoved {
                    background:rgba(#E74C3C, 0.1);
                }
                &.toBeAdded {
                    background:rgba(#2FCC72, 0.1);
                }
                
            }
        }
    }

    #sunDraw {
        max-height:calc(100vh - 64px - 40px);
        .drawflowWrapper {
            height:calc(100vh - 64px - 40px);
            #drawflow {
                height:calc(100vh - 64px - 40px);
            }
        }
    }

    #zoomControls {
        position: absolute;
        bottom: 24px;
        right: 24px;
        a {
            display: block;
            width:24px;
            height:24px;
            background:$primaryColor;
            color:#fff;
            text-align: center;
            line-height: 24px;
            margin-bottom:2px;
            box-shadow:1px 1px 2px 0 rgba(0,0,0,0.2);
        }
    }

    main.fullscreen #sunDraw {
        height:calc(100vh);
        .drawflowWrapper {
            height:calc(100vh);
            #drawflow {
                height:calc(100vh);
            }
        }
    }


    i.icon {
        width: 1em;
        height: 1em;
        line-height:1em;
        margin-top:2px;
        margin-bottom:-2px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        
        &.icon-node-host { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/host.svg"); }
        &.icon-node-network { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/network.svg"); }
        &.icon-node-vpn-host { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/vpn-host.svg"); }
        &.icon-node-vpn-network { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/vpn-network.svg"); }
        &.icon-node-interface { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/interface.svg"); }
        &.icon-node-world { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/world.svg"); }
        &.icon-node-geo-ip { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/map-marked-alt.svg"); }
        &.icon-node-ipsetgroup { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/ipsetgroup.svg"); }
        &.icon-node-geo-ip-group { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/map-marked-alt-custom-multiple.svg"); }
        &.icon-node-group { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/network-objects/node-group-16x16.svg"); }

        &.icon-node-host, &.icon-node-network, &.icon-node-vpn-host, &.icon-node-vpn-network, &.icon-node-interface, &.icon-node-world, &.icon-node-geo-ip, &.icon-node-ipsetgroup, &.icon-node-geo-ip-group, &.icon-node-group {
            opacity: 0.5;
            filter: contrast(5000%) brightness(0%);
        }

        &.icon-serviceobject-tcp { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/service-objects/tcp.svg"); }
        &.icon-serviceobject-udp { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/service-objects/udp.svg"); }
        &.icon-serviceobject-icmp { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/service-objects/icmp.svg"); }
        &.icon-serviceobject-ipv6-icmp { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/service-objects/icmp.svg"); }
        &.icon-serviceobject-other { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/service-objects/other.svg"); }
        &.icon-serviceobject-group { background-image: url("./../../classes/objectTypes/unifiedNetwork/utmIcons/service-objects/group-16x16.svg"); }
    }
    
    .darkmode i.icon {
        &.icon-node-host,
        &.icon-node-network,
        &.icon-node-vpn-host,
        &.icon-node-vpn-network,
        &.icon-node-interface,
        &.icon-node-world,
        &.icon-node-geo-ip,
        &.icon-node-ipsetgroup,
        &.icon-node-geo-ip-group,
        &.icon-node-group {
            filter: contrast(5000%) brightness(0%) invert(1);
        }
    }

    i.fal.fa-overlay-xmark::after {
        content: "\e59b";
        display: block;
        position: absolute;
        top: 50%;
        font-size: 1.5em;
        transform: translate(0, -50%);
        margin-left: 0px;
        font-weight: 600;
    }
</style>