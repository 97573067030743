
import encodingHelpers from "@/helpers/helpers.encoding"
import apis from "../apis"
import Button from "@/templates/components/button/button"
import { GenericObjectStore, type GenericObject, type GetPropertiesObjectList, type ItemlistDetail, type Label, type MenuEntry, type shemaErrors } from "../genericObjectStore"
import { T } from "../i18n"
import devLog from "../log"
import router from "@/router/router"
import frontendNotifications from "../notifications"
import tenantHelpers from "@/helpers/helpers.tenants"
import numberHelpers from "@/helpers/helpers.numbers"
import requestHandler from "@/queries/requests"
import ClipboardHandler from "../clipboard"
import deviceHelpers from "@/helpers/helpers.devices"

export interface ApiKey extends GenericObject<ApiKey> {
    "name": string,
    "id": string,
    "key"?: string
    "tenants"?: Array<string>
    "scopes"?: Array<"metrics">
    "issuedAt"?:number
    "expiresOn"?:number
    "comment"?:string
}


export default class ApiKeys extends GenericObjectStore<ApiKey> {
    constructor() {
        super()
        this.settings.primaryKeyProperty = "id"
        this.settings.nameProperty.primary = "name"
        this.settings.productType = "unifiedSecurity"
        this.settings.objectType = "apiKeys"
        this.settings.appearance.iconClass = "fal fa-key"
        this.settings.appearance.text.singular = "API Key"
        this.settings.appearance.text.plural = "API Keys"
        this.settings.appearance.text.title = "API Keys"
        this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/apikeys"
        this.settings.apiInfo.objectListPropertyInResponse = "apiKeys"
        this.settings.apiInfo.url = "/sms-mgt-api/api/2.0"

        this.itemlist.getToolbar = (accountId, itemlistComponent) => {
            let toolBarEntries = []
            toolBarEntries.push(
                {
                    icon: 'fal fa-plus',
                    title: T('Add API Key'),
                    link: '#add-tenant-' + accountId + '.sms-apikey',
                    id: 'apiKeyButtonAdd',
                    vIf: false
                }
            )
            return toolBarEntries
        }

        this.itemlist.isDisabled = (accountId, item): boolean => {
            return false
        }
        this.itemlist.isClickable = (accountId, item): boolean => {
            return true
        }
        this.itemlist.onClick = (accountId: string, item) => {
            let objectId = item[this.settings.primaryKeyProperty] as string
            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-apikey-' + objectId)
        }
        this.itemlist.hasCheckBox = true
        this.itemlist.getTitle = (item) => {
            return {
                title: item?.name ? item.name : item?.id ? deviceHelpers.getShortDeviceId(item?.id) : T("undefined")
            }
        }
        this.itemlist.getStatus = (accountId: string, item) => {
            return undefined
        }
        this.itemlist.getMenuEntries = (accountId: string, item) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let objectId = item[this.settings.primaryKeyProperty] as string
                menuEntries.push(
                    new Button({
                        "text": T('Edit'),
                        "title": T('Edit'),
                        "icon": 'fal fa-edit',
                        "onClick": () => {
                            router.navigate('edit-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-apikey-' + objectId)
                        }
                    })
                )
                menuEntries.push(
                    new Button({
                        "text": T('Copy'),
                        "title": T('Copy'),
                        "icon": 'fal fa-copy',
                        "onClick": () => {
                            if (item.key) {
                                const clipboard = new ClipboardHandler()
                                clipboard.copy(item.key || "")
                                frontendNotifications.addNotification(accountId, {
                                    "accountId": accountId,
                                    "content": {
                                        "body": {
                                            "content": item.key?.substring(0, 20) + "...",
                                        },
                                        "title": {
                                            "text": "Text " + T("copied"),
                                            "icon": "fal fa-check"
                                        }
                                    },
                                    "highlightNew": true,
                                })
                            }
                        },
                    })
                )
                if (['admin', 'auditor'].indexOf(item?.id) == -1) {
                    menuEntries.push(new Button({
                        "text": T('Delete'),
                        "title": T('Delete'),
                        "icon": 'fal fa-trash',
                        "onClick": () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item)
                        }
                    }))
                }
            }

            return menuEntries
        }
        this.itemlist.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            return result
        }

        this.itemlist.getDetails = (accountId: string, item?) => {
            let result: ItemlistDetail[] = []

            const thisTenantsArray = item?.tenants?.map((tenant: string) => {
                return {
                    "id": tenant,
                    "text": tenant,
                    "title": tenant,
                    "displayType": "label"
                }
            })
            const thisScopesArray = item?.scopes?.map((scope: string) => {
                return {
                    "id": scope,
                    "text": scope,
                    "title": scope,
                    "displayType": "label"
                }
            })
            result = [           
                {
                    iconClass: 'fal fa-fw fa-user',
                    title: T('Tenants'),
                    key: T('Tenants'),
                    [item ? "labels" : "content"]: item ? thisTenantsArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Scope'),
                    key: T('Scope'),
                    [item ? "labels" : "content"]: item ? thisScopesArray : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Key'),
                    key: T('Key'),
                    [item ? "labels" : "content"]: item ? [{
                        title: T("Copy to clipboard"),
                        text: T("Copy to clipboard"),
                        onClick: function() { 
                            if(item.key) {
                                const clipboard = new ClipboardHandler()
                                clipboard.copy(item.key || "")
                                frontendNotifications.addNotification(accountId,{
                                    "accountId":accountId,
                                    "content":{
                                        "body":{
                                            "content": item.key?.substring(0, 20) + "...",
                                        },
                                        "title": {
                                            "text":"Text " + T("copied"),
                                            "icon":"fal fa-check"
                                        }
                                    },
                                    "highlightNew":true,
                                })
                            }
                        },
                        icon: 'fal fa-copy',
                        class: "bg-red",
                        displayType: "label"
                    }] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                }
            ]
            return result
        }
        this.itemlist.sortingOptions = []
    }
    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            let response = await requestHandler.request("GET", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/apikeys?select=data.count")
            response = apis.parseApiResponse(response)
            if (typeof response === "number") {
                result = response
                if (updateLocalStore) {
                    this.setCount(accountId, response)
                }
            }
            else if (response.error) {
                throw new Error(response.error)
            }
            else {
                throw new Error('Error getting count for apiKeys')
            }
        }
        catch(e:any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            }
            else {
                result = new Error('Error getting count for apiKeys')
            }
            devLog.log("ApiKeysStoreClass", result.message, result, "error")
        }
        return result
    }


    async getObjectFromApi(accountId: string, objectId: string, props?: GetPropertiesObjectList, updateLocalStore: boolean = true) {
        let result: ApiKey | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let response = await requestHandler.request("GET", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/apikeys/" + encodingHelpers.encodeURI(objectId) + propertiesString)
            response = apis.parseApiResponse(response)
            if (response.id) {
                result = response as ApiKey
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add key to store
                }
            }
            else if (response.error) {
                throw new Error(response.error)
            }
            else {
                throw new Error('Error getting ApiKey')
            }
        }
        catch(e:any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            }
            else {
                result = new Error('Error getting ApiKey')
            }

            devLog.log("ApiKeysStoreClass", result.message, result, "error")
        }
        return result
    }

    async addObjectToApi(accountId: string, object: ApiKey, updateLocalStore: boolean = true) {
        let result: ApiKey | Error | shemaErrors
        try {
            let response = await requestHandler.request("POST", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/apikeys/", object)
            response = apis.parseApiResponse(response)
            if (response.id) {
                result = object
                result.id = response.id
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add key to store
                }
            }
            else if (response.errors) {
                throw response.errors
            }
            else if (response.error) {
                throw new Error(response.error)
            }
            else {
                throw new Error('Error adding ApiKey')
            }
        }
        catch(e:any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("ApiKeysStoreClass", result.message, result, "error")
            }
            else {
                result = e as shemaErrors
                devLog.log("ApiKeysStoreClass", "Error adding ApiKey", result, "error")
            }
        }
        return result
    }

    async updateObjectFromApi(accountId: string, objectId: ApiKey['id'], object: ApiKey, updateLocalStore: boolean = true) {
        let result: ApiKey | Error | shemaErrors
        try {
            let response = await requestHandler.request("PUT", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/apikeys/" + encodingHelpers.encodeURI(objectId), object)
            response = apis.parseApiResponse(response)
            if (response.id) {
                result = object
                result.id = objectId
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add key to store
                }
            }
            else if (response.errors) {
                throw response.errors
            }
            else if (response.error) {
                throw new Error(response.error)
            }
            else {
                throw new Error('Error updating ApiKey')
            }
        }
        catch(e:any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("ApiKeysStoreClass", result.message, result, "error")
            }
            else {
                result = e as shemaErrors
                devLog.log("ApiKeysStoreClass", "Error updating ApiKey", result, "error")
            }
        }
        return result
    }

    async deleteObjectFromApi(accountId: string, objectId: string, updateLocalStore: boolean = true) {
        let result: true | Error
        try {
            let response = await requestHandler.request("DELETE", this.settings.apiInfo.url + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/apikeys/" + encodingHelpers.encodeURI(objectId))
            response = apis.parseApiResponse(response)
            if (response.error) {
                throw new Error(response.error)
            }
            else {
                result = true
                if (updateLocalStore) {
                    this.removeObjectFromStore(accountId, objectId) // Remove key from store
                }
            }
        }
        catch(e:any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            }
            else {
                result = new Error('Error deleting ApiKey')
            }
            devLog.log("ApiKeysStoreClass", result.message, result, "error")
        }
        return result
    }
}
