<script lang="ts" setup>
    import usersettingsComponent from "./user-settings.vue"
    import notificationsComponent from './notifications.vue'
    import activeUsersComponent from "./active-users.vue"
    import qaActionsComponent from "./qa-actions.vue"
    import config from '@/classes/config'
    import { computed, ref } from "vue";

// VARS
    const collapsed = ref(false)
    const openMenu = ref(false)
// COMPUTED VARS
    const devMode = computed(() => config.devMode )
    const qaMode = computed(() => config.qaMode )
// METHODS
    // toggle the sidebars "collapsed" mode
    const toggleCollapsed = () => {
        collapsed.value = !collapsed.value
        if(collapsed.value) {
            document.body.classList.add("collapsed-menu")
        }
        else {
            document.body.classList.remove("collapsed-menu")
        }
    }
    // mobile menu
    const toggleMenu = () => {
        openMenu.value = !openMenu.value
        if(openMenu.value) {
            document.body.classList.add("open-menu")
        }
        else {
            document.body.classList.remove("open-menu")
        }
    }


</script>
<template>
    <header id="main-header" class="media-screen-only" >
            <a v-on:click="toggleCollapsed()" class="action-toggle-class" data-class="collapsed-menu">
                <i class="html-icon" :class="{'collapse-menu': collapsed, 'open-menu':!collapsed}">
                    <span></span>
                    <span></span>
                </i>
            </a>
            <a v-on:click="toggleMenu()" class="action-toggle-class" data-class="open-menu">
                <i class="html-icon open-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                </i>
            </a>
            <span class="app-name">
                Unified Security <small v-if="devMode">- Developer Mode</small>
            </span>
            <div class="menus">
                <activeUsersComponent ref="activeusersArea"></activeUsersComponent>
                <div class="divider border-right"></div>
                <qaActionsComponent v-if="qaMode" ref="qaActionsArea"></qaActionsComponent>
                <usersettingsComponent ref="usersettingsArea"></usersettingsComponent>
                <notificationsComponent ref="notificationArea"></notificationsComponent>
            </div>
            
        </header>
</template>
<style lang="scss">
    #main-header .menus {
        position: absolute;
        right: 0;
        top: 0;
        .divider {
            height: 25px;
            align-self: center;
            margin: 0 5px;
            opacity:0.7;
        }
        .menu {
            z-index: 1;
            > ul > li > ul > li {
                > .content {
                    position: relative;
                    padding: 16px 70px 16px 60px;
                    overflow: hidden;
                    &.new::after {
                        content: "";
                        display: block;
                        width: 12px;
                        height: 12px;
                        background: #e73c3c8a;
                        border-radius: 50%;
                        position: absolute;
                        right: 16px;
                        top: calc(50% - 6px);
                    }
                    > .title {
                        padding-bottom: 6px;
                        font-weight:400;
                        padding-top:7px;
                        .icon  {
                            width: 2em;
                            height: 2em;
                            position: absolute;
                            border: 1.5px solid #ccc;
                            border-radius: 50%;
                            padding-top: 0.35em;
                            margin-bottom: -2em;
                            top:calc(50% - 1em);
                            left:1.25em;
                            transition: background 0.3s;
                            cursor:pointer;
                            i  {
                                position: absolute;
                                left:50%;
                                top:50%;
                                transform: translate(-50%,-50%);
                            }
                            &.selected {
                                background:#E74C3C;
                                border: 1.5px solid #E74C3C;
                                i  {
                                    color:#fff;
                                }
                            }
                            &:hover  {
                                background:#E74C3C;
                                i {
                                    color:#fff;
                                }   
                            }
                        }
                    }
                    .product {
                        opacity:0.5;
                    }
                    .date {
                        position: absolute;
                        right:16px;
                        opacity: 0.5;
                        transition: right 0.3s;
                    }
                    > .body {
                        opacity: 0.7;
                    }
                    > .deleteButton {
                        position: absolute;
                        right: -32px;
                        top: 50%;
                        transform: translate(0,-50%);
                        background: #E74C3C;
                        width: 32px;
                        height: 100%;
                        color: #fff;
                        transition: 0.3s;
                        line-height: 32px;
                        text-align: center;
                        z-index: 2;
                        
                        i {
                            position: absolute;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            left: 50%;
                            transition: transform 0.3s;
                        }
                        &:hover {
                            right: 0px;
                            i {
                                transform: translate(-50%, -50%) scale(1.2);
                            }
                        }
                    }
                    
                    &:hover .date {
                        right: 46px;
                    }
                }
                > a.toggle > .count {
                    width: fit-content;
                    height: 17px;
                    padding: 0 4px;
                }
            }
        }
    }

</style>