import devLog from "@/classes/log"
import ObjectType, { type AccountId, type AddObjectTypeObject, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType"
import jsonHelpers from "@/helpers/helpers.json"
import requestHandler from "@/queries/requests"
import config from "@/classes/config"
import tenantHelpers from "@/helpers/helpers.tenants"
import vppApi, { type VppUserOnAdd, type VppUserOnUpdate } from "./vpp"
import timeHelpers from "@/helpers/helpers.time"




export interface VppUser {
    clientUserId:string,
    email?:string,
    idHash?:string,
    inviteCode?:string,
    status?: "Registered" | "Associated" | "Retired" | "Deleted"
}

class VppUsers extends ObjectType<VppUser> {
    constructor(payload: ObjectTypePayload<VppUser>) {
        super(payload)
        const thisClass = this

        this.queries.getObjectsFromApi = async (accountId, customerId?, props?, updateStore = true) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = function() {
                 
                return store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5 
            }()
            if((store && isRequestable) || store == undefined) {
                if(store) { 
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                const propertiesString: string = props ? this.getPropertiesString(props) : ""
                let result: VppUser[] | Error
                try {
                    let response = await vppApi.getAllVppUsers(accountId)
                    if (Array.isArray(response)) {
                        result = response as VppUser[]
                    }
                    else {
                        throw new Error("Error getting Objects")
                    }
                    if (updateStore) {
                        this.useStore?.().setObjectTypeObjects(accountId, result)
                    }
                    if(store) { store.gettingObjects = false }
                    return result
                }
                catch (e: any) {
                    if(store) { store.gettingObjects = false }
                    devLog.log("VppAssets", e.message, e, "error")
                    throw e as Error
                }
            }
            else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }

        this.queries.addObjectToApi = async (accountId, object: VppUserOnAdd,customerId?, updateLocalStore = true): Promise < VppUser | Error > => {
            let result: VppUser | Error
            try {

                if (object.email && !object.managedAppleId) {
                    object.managedAppleId = object.email
                }
                else if (!object.email && object.managedAppleId) {
                    object.email = object.managedAppleId
                }

                const response = await vppApi.addVppUsers(accountId,[object])

                if (response.mdmInfo) {
                    result = response.user as VppUser
                    if (updateLocalStore) {
                        this.useStore?.().addObjectTypeObject(accountId, object)
                    }
                }
                else if (response.errors) {
                    throw response.errors
                }
                else if (response.error) {
                    throw response.error
                }
                else {
                    throw 'Error adding VppUser'
                }
            }
            catch(e: any) {
                if(e.data) {
                    result = e.data
                }
                else {
                    result = new Error(e.message)
                }
                devLog.log("VppUsersStoreClass", "Error" , result, "error")
            }
            return result
        }


        this.queries.updateObjectFromApi = async (accountId, objectId, object:VppUserOnUpdate, customerId?,updateStore=true,altMethod?): Promise <VppUser | Error> => {
            let result: VppUser | Error 
            try {
                let payload : VppUserOnUpdate = {
                    "clientUserId":objectId as string,
                }
                if(object.email) {
                    payload.email = object.email
                }
                if (object.managedAppleId) {
                    payload.managedAppleId = object.managedAppleId
                }

                const response = await vppApi.updateVppUsers(accountId,[payload])
                if (response.mdmInfo) {
                    result = response.user as VppUser
                    if (updateStore) {
                        this.useStore?.().setObjectTypeObject(accountId, String(objectId), payload)
                    }
                }
                else if (response.errors) {
                    throw response.errors
                }
                else if (response.error) {
                    throw response.error
                }
                else {
                    throw 'Error updating VppUser'
                }
            }
            catch(e: any) {
                if (e.data) {
                    result = e.data
                }
                else {
                    result = new Error(e.message)
                }
                devLog.log("VppUsersStoreClass", "Error", result, "error")
            }
            return result
        }

        this.queries.deleteObjectFromApi = async (accountId, objectId,customerId?,updateStore=true): Promise < true | Error > => {
            let result: true | Error
            try {
                const response = await vppApi.retireVppUsers(accountId,[{"clientUserId":objectId as string}])

                if(response.error) {
                    throw new Error(response.error)
                }
                else {
                    result = true
                    if (updateStore) {
                        this.useStore?.().deleteObjectTypeObjectFromStore(accountId, objectId)
                    }
                }
            }
            catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            }
            else {
                result = new Error('Error deleting VppUser')
            }
            devLog.log("VppUsersStoreClass", result.message, result, "error")
        }
        return result
        }




    }

    
}

const vppUsers = new VppUsers({
    "productType": "mobileSecurity",
    "slug": "vppusers",
    "objectType": "vppUsers",
    "hasStore": true,
    "appearance": {
        "iconClass": "fab fa-apple",
        "text": {
            "plural": "VPP Users",
            "title": "VPP Users",
            "sidebarName": "VPP Users",
            "singular": "VPP User"
        },
        "color": "red",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "clientUserId",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "clientUserId",
            "pathToPrimaryProperty": "",
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.size",
        // GET
        "getObjectListResponseProperty": "users",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/apple/vpp/v2/users",
        // ADD
        "addObjectMethod": "POST",
        "addObjectPath": "/tenants/{tenantDomain}/apple/vpp/v2/users",
        // UPDATE
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/apple/vpp/v2/users",
        // DELETE/RETIRE
        "deleteObjectMethod":"POST",
        "deleteObjectPath": "/tenants/{tenantDomain}/apple/vpp/v2/users/retire",
    }
})
export default vppUsers