<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row media-screen-only" v-if="accountInitialized != undefined">
            <div class="col-xs-24 padding-xs-b">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="padding-xs-t padding-xs-l">
                            <div class="toolbar flexrow">
                                <div class="col-xs" style="margin-bottom:8px; margin-right:8px;"
                                    :title="T('Period')">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-clock"></i></span>
                                        <select v-model="period" class="form-control">
                                            <option value="today">{{ T('Today') }}</option>
                                            <option value="lastday">{{ T('Yesterday') }}</option>
                                            <option value="currentweek">{{ T('Current week') }}</option>
                                            <option value="currentmonth">{{ T('Current month') }}</option>
                                            <option value="last30days">{{ T('Last 30 days') }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-xs" style="margin-bottom:8px; margin-right:8px;" :title="T('Domain type')">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-server"></i></span>
                                        <select v-model="domainType" class="form-control">
                                            <option value="fullDomain">{{ T('Full domains') }}</option>
                                            <option value="rootDomain">{{ T('Root domains') }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r-3" :title="T('Period')">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-chart-line"></i></span>
                                        <select v-model="yAxeType" class="form-control">
                                            <option value="logarithmic">{{ T('Logarithmic') }}</option>
                                            <option value="linear">{{ T('Linear') }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-xs-3" style="margin-bottom:8px; margin-right:4px;" :title="T('Automatic refresh')">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"><i class="fal fa-stopwatch"></i></span>
                                        <select v-model="autoRefreshInterval" class="form-control">
                                            <!-- value = seconds -->
                                            <option value="0">{{ T('Disabled') }}</option>
                                            <option value="30">{{ `30 ${T('seconds')}` }}</option>
                                            <option value="60">{{ `1 ${T('minute')}` }}</option>
                                            <option value="300">{{ `5 ${T('minutes')}` }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div class="btn-toolbar" style="margin-bottom:8px; margin-right:8px;">
                                    <div class="btn-group">
                                        <a id="itemlistButtonRefresh" class="btn btn-menu btn-loader twist-in" 
											style="max-height: 34.5px; max-width: 35.75px;"
											v-on:click="load()" :class="{ 'btn-loading': refreshing }">
											<span class="animate">
												<i v-if="refreshing" class="progress-circular no-progress"
													style="font-size:1.5em;top:50%;position:absolute;left:50%;transform:translate(-50%,-50%);">
													<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
														viewBox="0 0 50 50" style="stroke-width: 4px;">
														<circle cx="25" cy="25" r="20"
															style="stroke: rgba(0, 0, 0, 0.1); fill: none;"></circle>
														<g transform="translate(25,25) rotate(-90)">
															<circle stroke-dasharray="110" stroke-dashoffset="0" cx="0"
																cy="0" r="20" style="fill: none; stroke-linecap: round;"
																transform="rotate(14.0181)">
																<animate attributeName="stroke-dashoffset" values="360;140"
																	dur="2.2s" keyTimes="0;1" calcMode="spline"
																	fill="freeze" keySplines="0.41,0.314,0.8,0.54"
																	repeatCount="indefinite" begin="0"></animate>
																<animate attributeName="stroke" fill="freeze" dur="8s"
																	begin="0" repeatCount="indefinite"></animate>
															</circle>
														</g>
													</svg>
												</i>
											</span>
											<span v-if="!refreshing"><i :title="T('Refresh')"
													class="fal fa-fw fa-sync"></i></span>
											<span v-if="refreshing">
												<loaderComponent class="text-size-2 color-red" />
											</span>
										</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-24 col-md-8 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-green">
                                <i class="fa fa-signal"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="!loadingAnimation.countStats">
                                {{ countStats.total }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <div class="text-center padding-xs-b-2">
                            {{ T('Hits total') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-8 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-red">
                                <i class="fa fa-ban"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="!loadingAnimation.countStats">
                                {{ countStats.totalBlocked }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <div class="text-center padding-xs-b-2">
                            {{ T('Blocked domains') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-8 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-aqua">
                                <i class="fa fa-percent"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="!loadingAnimation.countStats">
                                {{ countStats.totalBlockedPercentage }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <div class="text-center padding-xs-b-2">
                            {{ T('blocked') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-area"></i>
                                {{ T('Hits total') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template
                                v-if="!loadingAnimation.requestChart && requestChart.series[0].data.length === 0 && requestChart.series[1].data.length === 0">
                                <div style="text-align:center; font-size:1.5em; padding:72px 0;">
                                    <i class="fal fa-empty-set" style="font-size:3em;"></i>
                                    <p style="margin-top: 1.5em;">{{ T("No data available") }}</p>
                                </div>
                            </template>
                            <template v-else-if="!loadingAnimation.requestChart">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="requestChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-area"></i>
                                {{ T('Client activity') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.clientActivityChart && clientActivityChart.options.series.length === 0">
                                <div style="text-align:center; font-size:1.5em; padding:72px 0;">
                                    <i class="fal fa-empty-set" style="font-size:3em;"></i>
                                    <p style="margin-top: 1.5em;">{{ T("No data available") }}</p>
                                </div>
                            </template>
                            <template v-else-if="!loadingAnimation.clientActivityChart">
                                <highcharts class="highcharts-nobg highcharts-gauge"
                                    :options="clientActivityChart.options" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-check-circle"></i>
                                {{ T('Top Permitted Domains') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.topPermittedDomains">
                                <tableNext :loading="loadingAnimation.topPermittedDomains" :objectList="topPermittedDomains.rows"
                                    :selectable-columns="topDomainTitles" :isSearchable="false" :isFilterable="false"
                                    :hasOptions="false" maxheight="250">
                                </tableNext>
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-ban"></i>
                                {{ T('Top Blocked Domains') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.topBlockedDomains">
                                <tableNext :objectList="topBlockedDomains.rows" :selectable-columns="topDomainTitles"
                                    :isSearchable="false" :isFilterable="false" :hasOptions="false" maxheight="250">
                                </tableNext>
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-pie"></i>
                                {{ T('Top Block Reasons') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.topBlockReasonsChart && topBlockReasonsChart.series[0].data.length === 0">
                                <div style="text-align:center; font-size:1.5em; padding:72px 0;">
                                    <i class="fal fa-empty-set" style="font-size:3em;"></i>
                                    <p style="margin-top: 1.5em;">{{ T("No data available") }}</p>
                                </div>
                            </template>
                            <template v-else-if="!loadingAnimation.topBlockReasonsChart">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="topBlockReasonsChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-pie"></i>
                                {{ T('Top Clients') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.topClientChart && topClientChart.series[0].data.length === 0">
                                <div style="text-align:center; font-size:1.5em; padding:72px 0;">
                                    <i class="fal fa-empty-set" style="font-size:3em;"></i>
                                    <p style="margin-top: 1.5em;">{{ T("No data available") }}</p>
                                </div>
                            </template>
                            <template v-else-if="!loadingAnimation.topClientChart">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="topClientChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-pie"></i>
                                {{ T('Top Request Types') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.topTypesChart && topTypesChart.series[0].data.length === 0">
                                <div style="text-align:center; font-size:1.5em; padding:72px 0;">
                                    <i class="fal fa-empty-set" style="font-size:3em;"></i>
                                    <p style="margin-top: 1.5em;">{{ T("No data available") }}</p>
                                </div>
                            </template>
                            <template v-else-if="!loadingAnimation.topTypesChart">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="topTypesChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-chart-pie"></i>
                                {{ T('Top Profiles') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.topProfilesChart && topProfilesChart.series[0].data.length === 0">
                                <div style="text-align:center; font-size:1.5em; padding:72px 0;">
                                    <i class="fal fa-empty-set" style="font-size:3em;"></i>
                                    <p style="margin-top: 1.5em;">{{ T("No data available") }}</p>
                                </div>
                            </template>
                            <template v-else-if="!loadingAnimation.topProfilesChart">
                                <highcharts class="highcharts-nobg highcharts-gauge" :options="topProfilesChart" />
                            </template>
                            <template v-else>
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-24 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-globe"></i>
                                {{ T('Traffic-Map') }}
                            </h3>
                            <div class="row-2">
                                <hr class="margin-xs-b-2">
                            </div>

                            <template v-if="!loadingAnimation.worldMap">
                                <highcharts class="highcharts-nobg highcharts-gauge" :constructorType="'mapChart'"
                                    :options="worldMap" />
                            </template>
                            <template v-else="!loading.worldMap">
                                <div style="text-align:center; padding:122px 0;">
                                    <loaderComponent class="color-red text-size-3"></loaderComponent>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { T } from "@/classes/i18n";
import requestHandler from '@/queries/requests';
import tenantHelpers from '@/helpers/helpers.tenants';
import loaderComponent from "@/templates/components/loader.vue";
import tableNext, { type TableEntryInfo } from "../components/tableNext.vue";
import products from '@/classes/objectTypes';
import worldGeo from '@highcharts/map-collection/custom/world.geo.json'

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})
const accountInitialized = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value || ''].initialized
})
const statsUrl = computed(() => {
    return "/sms-mgt-api/api/2.0/tenants/" + tenantHelpers.getTenantDomain(activeAccountId.value || "") + "/stats/execute"
})

const yAxeTypeStorageKey = `secdns:stats:${activeAccountId.value}:yAxeType`
const yAxeType = ref(localStorage.getItem(yAxeTypeStorageKey) || "logarithmic")
watch(yAxeType, () => localStorage.setItem(yAxeTypeStorageKey, yAxeType.value))

const periodStorageKey = `secdns:stats:${activeAccountId.value}:period`
const period = ref(localStorage.getItem(periodStorageKey) || "today")
watch(period, () => localStorage.setItem(periodStorageKey, period.value))

const domainTypeStorageKey = `secdns:stats:${activeAccountId.value}:domainType`
const domainType = ref(localStorage.getItem(domainTypeStorageKey) || "fullDomain")
watch(domainType, () => localStorage.setItem(domainTypeStorageKey, domainType.value))

const autoRefreshIntervalStorageKey = `secdns:stats:${activeAccountId.value}:autoRefreshInterval`
const autoRefreshInterval = ref(localStorage.getItem(autoRefreshIntervalStorageKey) || '30')
watch(autoRefreshInterval, () => localStorage.setItem(autoRefreshIntervalStorageKey, autoRefreshInterval.value))

const countStats = ref({
    totalBlocked: "0",
    total: "0",
    totalBlockedPercentage: "0",
})

const autoRefreshTimer = ref<ReturnType<typeof setInterval> | null>(null)

const requestChart = computed(() => ({
    chart: {
        height: "50%",
        zooming: {
            type: "x"
        },
        type: 'area'
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    xAxis: {
        type: 'datetime',
        values: {
            enabled: true,
            format: '{value:%H:%M}',
        }
    },
    yAxis: {
        type: yAxeType.value,
        title: {
            text: ''
        }
    },
    legend: {
        enabled: true
    },
    series: [{
        name: T("Permitted domains"),
        data: [],
        color: '#2DCC71',
    },
    {
        name: T("Blocked domains"),
        data: [],
        color: '#E74C3C'
    }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
}))

const topBlockReasonsChart = ref({
    chart: {
        type: 'pie',
        height: "35%",
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    legend: {
        enabled: true
    },
    series: [
        {
            name: T('Blocked requests'),
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            color: '#ff0000',
            data: [],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '13px'
                }
            }
        }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
})

const topClientChart = ref({
    chart: {
        type: 'pie',
        height: "35%",
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            }
        }
    },
    series: [
        {
            name: T("Total Requests"),
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            color: '#ff0000',
            data: [],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '13px'
                }
            }
        }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
})

const topTypesChart = ref({
    chart: {
        type: 'pie',
        height: "35%",
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            }
        }
    },
    series: [
        {
            name: T("Total Requests"),
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            color: '#ff0000',
            data: [],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '13px'
                }
            }
        }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
})

const topProfilesChart = ref({
    chart: {
        type: 'pie',
        height: "35%",
    },
    title: {
        text: "",
        align: 'left'
    },
    subtitle: {
        text: "",
        align: 'left'
    },
    legend: {
        enabled: true
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            }
        }
    },
    series: [
        {
            name: T("Total Requests"),
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            color: '#ff0000',
            data: [] as any[],
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '13px'
                }
            }
        }],
    credits: {
        enabled: false
    },
    time: {
        useUTC: false
    }
})

const clientActivityChart = computed(() => ({
    options: {
        chart: {
            zooming: {
                type: "x"
            },
            type: 'area',
            height: "50%",
        },
        title: {
            text: "",
            align: 'left'
        },
        subtitle: {
            text: "",
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            values: {
                enabled: true,
                format: '{value:%H:%M}',
            }
        },
        yAxis: {
            type: yAxeType.value,
            title: {
                text: ''
            }
        },
        legend: {
            enabled: true
        },
        series: [] as any[],
        credits: {
            enabled: false
        },
        time: {
            useUTC: false
        }
    }
}))

const topDomainTitles = ref(<TableEntryInfo[]>[
    {
        "property": "domain",
        "text": "Domain",
        "sortable": false,
        "displayType": "text",
        "getValue": (item: any) => item.data.domain
    },
    {
        "property": "count",
        "text": T("Requests"),
        "sortable": false,
        "displayType": "text",
        "getValue": (item: any) => item.data.count
    }
])

const topPermittedDomains = ref({
    rows: []
})

const topBlockedDomains = ref({
    rows: []
})

type CountryDomainItem = {
    domain: string
    count: number
}
const topCountryDomains = ref<{[country: string]: CountryDomainItem[]}>({})

const worldMap = computed(() => ({
    chart: {
        map: worldGeo
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    mapNavigation: {
        enabled: true,
        buttonOptions: {
            alignTo: 'spacingBox'
        }
    },
    mapView: {
        maxZoom: 1
    },
    legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'bottom'
    },
    colorAxis: {
        min: 0
    },
    credits: {
        enabled: false
    },
    series: [{
        name: T("Requests"),
        states: {
            hover: {
                color: '#BADA55'
            }
        },
        dataLabels: {
            enabled: false
        },
        allAreas: true,
        data: []
    }],
    tooltip: {
        formatter: function(): any {
            const _this = this as any
            const numberFormatter = Intl.NumberFormat()

            const country = _this.point.name as string
            const countryIso = _this.point["hc-key"] as string
            const countryRequests = _this.point.value as number
            const topDomains = topCountryDomains.value[countryIso] || []

            let ret = ''

            ret += '<div class="highcharts-toolbar-text">'

            ret += `<span class="fi fi-${countryIso} "></span> <b>${T(country)}</b><br>`
            ret += `${numberFormatter.format(countryRequests)} ${T("Request" + (countryRequests > 1 ? "s" : ""))}<br><br>`

            ret += `<b>${T("Top Domains")}</b>:<br>`
            topDomains.forEach((item) => {
                const plural = item.count > 1 ? "s" : ""

                ret += `${item.domain}: ${numberFormatter.format(item.count)} ${T("Request" + plural)}<br>`
            })

            ret += '</div>'

           return ret
        },
        useHTML: true
    }
}))

const refreshing = ref(false)

const loadingAnimation = ref({
    countStats: false,
    requestChart: false,
    topPermittedDomains: false,
    topBlockedDomains: false,
    topBlockReasonsChart: false,
    topClientChart: false,
    topTypesChart: false,
    clientActivityChart: false,
    topProfilesChart: false,
    worldMap: false
})

async function load(animate = true) {
    if (animate) {
        for (const key in loadingAnimation.value) {
            loadingAnimation.value[key as keyof typeof loadingAnimation.value] = true
        }
    }

    refreshing.value = true

    await Promise.all([
        loadCountStats(),
        loadRequestChart(),
        loadClientActivityChart(),
        loadTopPermittedDomains(),
        loadTopBlockedDomains(),
        loadTopBlockedReasonsChart(),
        loadTopClientChart(),
        loadTopTypesChart(),
        loadTopProfilesChart(),
        loadWorldMap()
    ])

    refreshing.value = false
}

async function loadCountStats() {
    const numberFormatter = Intl.NumberFormat()

    const counts = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "Counts",
            "options": {
                "relate": "count",
                "period": period.value,
                "size": 10,
                "offset": 0,
                "order": {
                    "asc": false
                }
            }
        }
    })

    countStats.value.total = numberFormatter.format(parseInt(counts.result[0].total_requests))
    countStats.value.totalBlocked = numberFormatter.format(parseInt(counts.result[0].blocked_requests))
    countStats.value.totalBlockedPercentage = parseFloat(counts.result[0].blocked_percentage || 0).toFixed(2) + " %"

    loadingAnimation.value.countStats = false
}

async function loadRequestChart() {
    const requestHistogramm = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "RequestHistogramm",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                }
            }
        }
    })

    requestChart.value.series[0].data = requestHistogramm.result.map((item: any) => {
        return [new Date(item.interval + "Z").getTime(), item.count]
    })

    const requestBlockedHistogramm = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "RequestBlockedHistogramm",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                }
            }
        }
    })

    requestChart.value.series[1].data = requestBlockedHistogramm.result.map((item: any) => {
        return [new Date(item.interval + "Z").getTime(), item.count]
    })

    loadingAnimation.value.requestChart = false
}

async function loadTopPermittedDomains() {
    const topPermitted = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopPermitted",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                },
                "filter": [{
                    "field": "domainType",
                    "operator": "equal",
                    "value": domainType.value
                }]
            }
        }
    })
    topPermittedDomains.value.rows = topPermitted.result.map((item: any) => {
        return {
            data: {
                domain: item.domain,
                count: parseInt(item.count)
            }
        }
    })

    loadingAnimation.value.topPermittedDomains = false
}

async function loadTopBlockedDomains() {
    const topBlocked = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopBlocked",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                },
                "filter": [{
                    "field": "domainType",
                    "operator": "equal",
                    "value": domainType.value
                }]
            }
        }
    })
    topBlockedDomains.value.rows = topBlocked.result.map((item: any) => {
        return {
            data: {
                domain: item.domain,
                count: parseInt(item.count)
            }
        }
    })

    loadingAnimation.value.topBlockedDomains = false
}

async function loadTopBlockedReasonsChart() {
    const topBlockReasons = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "BlockReasons",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                }
            }
        }
    })
    topBlockReasonsChart.value.series[0].data = topBlockReasons.result.map((item: any) => {
        return [tenantHelpers.secureDnsCategoryIdToName(parseInt(item.blockReason)), parseInt(item.count)]
    })

    loadingAnimation.value.topBlockReasonsChart = false
}

async function loadTopClientChart() {
    const topClients = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopClients",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                }
            }
        }
    })
    topClientChart.value.series[0].data = topClients.result.map((item: any) => {
        return [item.deviceName ?? T("Unknown"), parseInt(item.count)]
    })

    loadingAnimation.value.topClientChart = false
}

async function loadTopTypesChart() {
    const topTypes = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopTypes",
            "options": {
                "period": period.value
            }
        }
    })
    topTypesChart.value.series[0].data = topTypes.result.map((item: any) => {
        return [item.type || T("Unknown"), parseInt(item.count)]
    })

    loadingAnimation.value.topTypesChart = false
}

async function loadClientActivityChart() {
    const clientActivity = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "ClientHistogramm",
            "options": {
                "relate": "count",
                "period": period.value,
                "order": {
                    "asc": false
                }
            }
        }
    })

    clientActivityChart.value.options.series = []
    Object.entries(clientActivity.result).forEach(([clientName, data]) => {
        clientActivityChart.value.options.series.push({
            name: T(clientName),
            borderWidth: 0,
            pointWidth: 0,
            borderColor: 'none',
            data: (data as any[]).map((d) => {
                return [new Date(d.interval + "Z").getTime(), d.count]
            })
        })
    })

    loadingAnimation.value.clientActivityChart = false
}

async function loadTopProfilesChart() {
    const topProfiles = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopProfiles",
            "options": {
                "period": period.value
            }
        }
    })

    const profiles = await products.secureDns.secureDnsProfiles.queries.getObjectsFromApi(activeAccountId.value, undefined, [
        { "property": "props[]", "value": "name" },
        { "property": "props[]", "value": "id" }
    ], false, false)

    topProfilesChart.value.series[0].data = []
    topProfiles.result.forEach((profile: any) => {
        if (profiles instanceof Error) {
            return
        }

        const name = profiles.find((p: any) => p.id === profile.profileId)?.name || T("Unknown")

        topProfilesChart.value.series[0].data.push([name, parseInt(profile.count)])
    })

    loadingAnimation.value.topProfilesChart = false
}

async function loadWorldMap() {
    const topCountries = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopCountries",
            "options": {
                "period": period.value
            }
        }
    })
    const _topCountryDomains = await requestHandler.request("POST", statsUrl.value, {
        "query": {
            "modul": "SecureDns",
            "name": "TopCountryDomains",
            "options": {
                "period": period.value,
                "filter": [{
                    "field": "domainType",
                    "operator": "equal",
                    "value": domainType.value
                }]
            }
        }
    })

    topCountryDomains.value = _topCountryDomains.result

    worldMap.value.series[0].data = topCountries.result.map((item: any) => {
        return [item.country, parseInt(item.count)]
    })

    loadingAnimation.value.worldMap = false
}

function updateAutoRefreshTimer(val: string) {
    if(val === "0") {
        return
    }

    autoRefreshTimer.value = setInterval(async () => {
        await load(false)
    }, parseInt(val) * 1000)
}

onMounted(async () => {
    await load()
    updateAutoRefreshTimer(autoRefreshInterval.value)
})

onUnmounted(() => {
    if (autoRefreshTimer.value) {
        clearInterval(autoRefreshTimer.value)
    }
})

watch([period, yAxeType, domainType], async () => {
    await load()
})

watch(autoRefreshInterval, (newVal) => {
    if (autoRefreshTimer.value) {
        clearInterval(autoRefreshTimer.value)
    }

    updateAutoRefreshTimer(newVal)
})

</script>