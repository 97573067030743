import ObjectType, { type ItemlistItemDetail, type Label, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import i18n, { T } from "@/classes/i18n"
import Button from "@/templates/components/button/button"

import jsonHelpers from "@/helpers/helpers.json"
import router from "@/router/router"
import tenantHelpers from "@/helpers/helpers.tenants";
import numberHelpers from "@/helpers/helpers.numbers";
import ClipboardHandler from "@/classes/clipboard";
import frontendNotifications from "@/classes/notifications";
import miscDialogs from "@/dialogs/dialogs.misc";
import timeHelpers from "@/helpers/helpers.time";

export interface WindowsEnrollmentToken {
    "id":string,
    "name"?: string,
    "comment"?: string,
    "details"?: {
        "tags"?: string[],
        "expiresIn": number,
        "maxDevices": number
    },
    "tags"?: string[],
    "token"?:string,
    "isExpired"?:boolean
    "enrolledDevices"?: number,
    "expiresAt"?: number,
    "lastInvalidEnrollmentAttempt"?: number,
    "lastEnrollmentAttempt"?: number,
    "enrolledDevicesCount": number
};

class WindowsEnrollmentTokens extends ObjectType<WindowsEnrollmentToken> {
    constructor(payload: ObjectTypePayload<WindowsEnrollmentToken>) {
        super(payload)
        const thisObjectType = this
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    "id": "lastEnrollmentAttempt",
                    "text": T("Last use")
                },
                {
                    "id": "name",
                    "text": T("Name")
                },
                {
                    "id": "isExpired",
                    "text": T("Validity")
                }
            ]
        }

        this.itemlistItem.getLabels = (accountId: string, item: ObjectTypeObject<WindowsEnrollmentToken>) => {
            let result: Label[] = []
            if (item.hasOwnProperty('isExpired') && item.isExpired) {
                result.push({
                    "icon":"fal fa-clock",
                    "class":"bg-red",
                    "displayType":"label",
                    "text":T("Expired"),
                    "title":T("Expired")
                })
            }
            if(typeof item?.lastInvalidEnrollmentAttempt == "number" && item.lastInvalidEnrollmentAttempt > 0 && this.checkLastInvalidAttempt(item.lastInvalidEnrollmentAttempt)) {
                result.push({
                    "icon":"fal fa-triangle-exclamation",
                    "class":"bg-red",
                    "displayType":"label",
                    "text":T("Used After Expiery"),
                    "title":T("Used After Expiery")
                })
            }
            return result
        }

        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let toolBarEntries = []
            toolBarEntries.push({
                id: 'addEnrollmentToken',
                icon: 'fal fa-plus',
                title: T('Add enrollment token'),
                text: T('Add enrollment token'),
                link: '#add-tenant-' + tenantHelpers.getTenantDomain(accountId) + '-windows-enrollmenttoken',
            })
            toolBarEntries.push({
                id: "toggleExpiredTokensButton",
                icon: "fal fa-clock",
                title: T("Show expired tokens"),
                onClick: async () => {
                    itemlistComponent.exposed.toggleExpiredTokens()
                },
                class: itemlistComponent.exposed.showExpiredTokens.value == true ? 'active' : ''
            })
            return toolBarEntries
        }

        this.itemlistItem.hasCheckbox = () => {
            return true
        }

        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuLinks = [
                new Button({
                    title: T('Delete'),
                    text: T('Delete'),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: 'fal fa-trash',
                })
            ]
            return menuLinks
        }

        this.itemlistItem.getDetails = (accountId, item) => {
            const isExpired = item ? item.hasOwnProperty('isExpired') && item.isExpired : false
            let expiryString = T("Expired")
            if (item.hasOwnProperty('isExpired') && item.isExpired == false && item.expiresAt) {
                const unixTimetamp: number = item.expiresAt * 1000
                const today = new Date()
                const expiryDay = new Date(unixTimetamp)
                const diff = expiryDay.getTime() - today.getTime()
                const dayDiff = Math.floor((diff / (60 * 60 * 1000) / 24))
                expiryString = timeHelpers.formatDate(unixTimetamp,timeHelpers.getDateFormatI18n(i18n.getLanguage(),true))
                if(dayDiff > 366) {
                    expiryString = T("Never")
                }
            }

            let lastUseString = item.lastEnrollmentAttempt ? timeHelpers.formatDate(item.lastEnrollmentAttempt * 1000,timeHelpers.getDateFormatI18n(i18n.getLanguage(),true)) : T('Not used yet')


            
            let thisDetails: ItemlistItemDetail[] = [
                {
                    iconClass: 'fal fa-fw fa-tags',
                    title: 'Tags',
                    key: 'Tags',
                    [item ? "labels" : "value"]: item ? (item?.tags || item?.details?.tags)?.map(function (tag: string) {
                        return {
                            "id": tag,
                            "text": tag,
                            "title": tag,
                            "onClick": undefined,
                            "displayType": "label"
                        }
                    }) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                },
                {
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Expiry date"),
                    key: T("Expiry date"),
                    value: item ? expiryString : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Last use"),
                    key: T("Last use"),
                    value: item ? lastUseString : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-computer",
                    title: T("Devices"),
                    key: T("Devices"),
                    value: item ? item.enrolledDevicesCount : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-comment",
                    title: T("Comment"),
                    key: T("Comment"),
                    value: item ? item.comment : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                },
                {
                    iconClass: 'fal fa-fw fa-key',
                    title: T('Key'),
                    key: T('Key'),
                    [item ? "labels" : "content"]: item ? [{
                        title: T("Copy to clipboard"),
                        text: T("Copy to clipboard"),
                        onClick: isExpired == true ? undefined :function () {
                            if (item.token) {
                                const clipboard = new ClipboardHandler()
                                clipboard.copy(item.token || "")
                                frontendNotifications.addNotification(accountId, {
                                    "accountId": accountId,
                                    "content": {
                                        "body": {
                                            "content": item.token?.substring(0, 20) + "...",
                                        },
                                        "title": {
                                            "text": T("Enrollment token") + " " + thisObjectType.itemlistItem.getTitle(item, undefined).title + " " + T("copied"),
                                            "icon": "fal fa-check"
                                        }
                                    },
                                    "highlightNew": true,
                                })
                            }
                        },
                        icon: 'fal fa-copy',
                        class: isExpired == true ? "bg-grey" : "bg-red",
                        displayType: "label"
                    }] : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
                }
            ]
            return thisDetails
        }

        this.itemlistItem.onClick = (accountId, item) => {
            router.navigate("edit-tenant-"+accountId+".sms-windows-enrollmenttoken-"+item.id)
        }

        /**
        * Converts Object for ObjectTypeStore
        */
        this.convertObjectForStore = (accountId, objectBase) => {
            objectBase.$itemlist = {
                "isCheckboxChecked": false,
                "isCheckboxHovering": false,
            }
            let newObject = jsonHelpers.copyObject(objectBase) as ObjectTypeObject<WindowsEnrollmentToken>

            objectBase.toJSON = () => {
                delete newObject.$itemlist
                delete newObject.toJSON
                return newObject as WindowsEnrollmentToken
            }
        }
        this.dialogs = {
            getDeleteObjectDialog: (accountId, object, customerId, confirm = false) => {
                let objectId = this.getObjectId(object)
                let objectName = this.itemlistItem.getTitle(object as ObjectTypeObject<WindowsEnrollmentToken>, undefined).title
                miscDialogs.confirmDialog(accountId, T("Confirm delete"), T('Do you really want to delete this enrollment token?') + (objectName ? " (" + objectName + ")" : ""), async () => {
                    await this.queries.deleteObjectFromApi(accountId, objectId, customerId)
                }, confirm ? T("Delete") : undefined)
            }
        }
    }
    copyItemToClipboard = (objectId: string, accountId: string) => {
        const thisClass = this
        const enrollmentToken = thisClass.useStore?.().getObjectStoreObject(accountId, objectId)
        
        if (enrollmentToken && enrollmentToken.token) { 
            const clipboard = new ClipboardHandler()

            clipboard.copy(enrollmentToken.token)
            frontendNotifications.addNotification(accountId, {
                "accountId": accountId,
                "content": {
                    "body": {
                        "content": enrollmentToken.token.substring(0, 20) + "...",
                    },
                    "title": {
                        "text": T("Enrollmenttoken") + " " + thisClass.itemlistItem.getTitle(enrollmentToken, undefined).title + " " + T("copied"),
                        "icon": "fal fa-check"
                    }
                },
                "highlightNew": true,
            })
        }
    }
    /**
     * 
     * @param lastInvalidEnrollmentAttempt Timestamp, when the last enroll attempt was tried
     * @returns Whether the last attempt is within the last 30 days
     */
    checkLastInvalidAttempt = (lastInvalidEnrollmentAttempt: number | undefined) => {
        let result: boolean = false
        if (typeof lastInvalidEnrollmentAttempt == "number" && lastInvalidEnrollmentAttempt > 0) {
            const unixTimetamp = lastInvalidEnrollmentAttempt * 1000
            const today = new Date()
            const lastInvalidDate = new Date(unixTimetamp)

            if (Math.floor(Math.abs(today - lastInvalidDate) / (1000 * 60 * 60 * 24)) < 31) {
                result = true
            }
        }
        return result
    }
}

const windowsEnrollmentTokens = new WindowsEnrollmentTokens({
    "productType": "unifiedSecurityConsole",
    "slug": "windowsEnrollmentTokens",
    "objectType": "windowsEnrollmentTokens",
    "hasStore": true,
    "appearance": {
        "iconClass": "fal fa-sign-in",
        "text": {
            "plural": "Enrollmenttokens",
            "title": "Enrollmenttokens",
            "sidebarName": "Enrollmenttokens",
            "singular": "Enrollmenttoken"
        },
        "color": "blue",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "id",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "name",
            "pathToPrimaryProperty": undefined,
            "secondary": undefined,
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.count",
        // GET
        "getObjectListResponseProperty": "enrollmentTokens",
        "getObjectListPath": "/tenants/{tenantDomain}/windows/enrollmentTokens",
        // POST 
        "addObjectPath": "/tenants/{tenantDomain}/windows/enrollmentTokens",
        // PUT 
        "updateObjectPath": "/tenants/{tenantDomain}/windows/enrollmentTokens/{objectId}",
        // DELETE
        "deleteObjectPath": "/tenants/{tenantDomain}/windows/enrollmentTokens/{objectId}"

    }
})


export default windowsEnrollmentTokens