<script setup lang="ts"
height.value = newHeight>
import { T } from '@/classes/i18n';
import { useUserLogStore } from '@/classes/userLogStore';
import getterHelpers from '@/helpers/helpers.getters';
import jsonHelpers from '@/helpers/helpers.json';
import { debounce } from 'throttle-debounce';
import { computed, onMounted, ref, watch } from 'vue';
import VueResizable from 'vue-resizable'

// Props
const props =defineProps<{
    setUserLogHeight:(height:number) => void
}>()

const onlyIncoming = ref(false)
const onlyOutgoing = ref(false)
const autoScroll = ref(true)
const search = ref("")
const debouncedSearch = ref("")
const thisHeight = ref(400)
const autoScrollDiv = ref(<null | HTMLDivElement>null)

const logEntrys = computed(() => {
    let logEntrys = useUserLogStore().$state.logEntrys
    if(onlyIncoming.value == true || onlyOutgoing.value == true || search.value != "") {
        logEntrys = logEntrys.filter((logEntry) => {
            let result = true
            if(onlyIncoming.value == true && logEntry.icon.indexOf('fa-server') != -1) result = false;
            if(onlyOutgoing.value == true && logEntry.icon.indexOf('fa-mobile-alt') != -1) result = false;
            if(search.value != "" && logEntry.title.toLowerCase().indexOf(search.value.toLowerCase()) == -1) result = false;

            return result
        })
    }
    return logEntrys
})
const logEntryLength = computed(() => {
    return logEntrys.value.length
})

function reset() {
    onlyIncoming.value = false
    onlyOutgoing.value = false
    autoScroll.value = true
    search.value = ""
    debouncedSearch.value = ""
}

function clearLog() {
    useUserLogStore().resetLog()
}

onMounted(() => {
    reset()
    props.setUserLogHeight(thisHeight.value)
})

function filterOnlyIncoming() {
    if(onlyIncoming.value == true) {
        onlyIncoming.value = false;
    }
    else {
        onlyOutgoing.value = false
        onlyIncoming.value = true
    }
}
function filterOnlySent() {
    if(onlyOutgoing.value == true) {
        onlyOutgoing.value = false
    }
    else {
        onlyIncoming.value = false
        onlyOutgoing.value = true
    }
}
function closeLog() {
    getterHelpers.useVue()?.toggleLog()
}
function toggleAutoScroll() {
    autoScroll.value = !autoScroll.value
}

watch(thisHeight, () => {
    props.setUserLogHeight(thisHeight.value)
})
watch(debouncedSearch, () => {
    setSeachValue(debouncedSearch.value)
})
watch(logEntryLength, () => {
    if (autoScroll.value == true && autoScrollDiv.value) {
        const div = autoScrollDiv.value
        setTimeout(() => {
            div.scrollIntoView({ behavior: "instant", block: "end" })
        }, 1)
    }
})

const setSeachValue = debounce(500, (debouncedSearch: string) => {
    search.value = debouncedSearch
})

const onResize = (e:any) => {
    let newHeight = e.height
    
        thisHeight.value = newHeight
        props.setUserLogHeight(newHeight)
    
}
</script>
<template>
    <VueResizable id="log" class="media-screen-only active" :height="thisHeight" :active="['t']" style="top: initial; left:270px" @resize:move="onResize">
        <div class="log-toggle-wrapper">
            <span class="log-toggle"></span>
        </div>
        <div class="log-wrapper">
            <div class="head padding-xs">
                <div class="row flexrow">
                    <div class="col text-left padding-xs-l color-white" style="flex-grow:1">

                        <a class="color-white" @click="reset" :title="T('Reset filter')">
                            <i class="fas fa-fw fa-filter-slash"></i>
                        </a>
                        &nbsp;|&nbsp;

                        <a :class="{
                            'color-white': onlyOutgoing == false,
                            'active color-red': onlyOutgoing == true
                        }" @click="filterOnlySent" :title="T('Only sent messages')">
                            <i class="fas fa-fw fa-filter"></i> {{ T('Sent') }}
                        </a>
                        &nbsp;|&nbsp;

                        <a :class="{
                            'color-white': onlyIncoming == false,
                            'active color-red': onlyIncoming == true
                        }" @click="filterOnlyIncoming" :title="T('Only incoming messages')">
                            <i class="fas fa-fw fa-filter"></i> {{ T('Received') }}
                        </a>
                        &nbsp;|&nbsp;

                        <i class="fas fa-fw fa-search color-white"></i>
                        <input id="userLogSearch" :placeholder="T('Search')"
                            style="border:1px solid rgba(255,255,255,0.2) !important; color:#fff; display: inline-block;width: 200px;min-height: 0;height: 24px;margin-top: -3px;margin-left: 6px;"
                            type="search" v-model="debouncedSearch">
                        &nbsp;

                    </div>
                    <div class="col text-right padding-xs-r">
                        <a :class="{
                                'log-panel-button': true,
                                'color-white': autoScroll == false,
                                'color-red active': autoScroll == true
                            }" @click="toggleAutoScroll" :title="T('Toggle autoscroll')">
                            <i class="fas fa-fw fa-angle-down"></i>
                        </a>
                        &nbsp;

                        <a class="log-panel-button color-white" @click="clearLog" :title="T('Clear log')">
                            <i class="fas fa-fw fa-eraser"></i>
                        </a>
                        &nbsp;

                        <a class="log-panel-button color-white" @click="closeLog" :title="T('Close log')">
                            <i class="fas fa-fw fa-times"></i>
                        </a>
                    </div>

                </div>
            </div>
            <div id="logarea">
                <template v-for="logEntry of logEntrys">
                    <div :class="'entry flexrow ' + logEntry.colorClass">

                        <div class="display-xs-inline-flex" style="opacity: 0.6; margin-right: 8px;">
                            {{ logEntry.timestamp }}
                        </div>

                        <div v-if="logEntry.entryType == 'DATA'" class="col-xs">
                            <i :class="logEntry.icon"></i>
                            {{ logEntry.title }}
                        </div>

                        <template v-if="logEntry.entryType == 'FOLDABLE'">
                            <div :class="{
                                'col-xs entry-foldable ': true,
                                'active': logEntry.showFoldableContent == true
                            }">

                                <div class="clickable"
                                    @click="() => { logEntry.showFoldableContent = !logEntry.showFoldableContent }">
                                    <i :class="{
                                        'fal fa-fw fa-angle-right': true,
                                        'toggleIcon': true
                                    }"></i>
                                    <i v-if="logEntry.icon" :class="logEntry.icon"></i>
                                    {{ " " + logEntry.title }}
                                </div>
                                <pre v-show="logEntry.showFoldableContent == true" :class="'margin-xs-l-2'"
                                    v-html="jsonHelpers.syntaxHighlight(logEntry.payload)"></pre>

                            </div>
                        </template>

                    </div>
                </template>
                <div id="autoScrollDiv" ref="autoScrollDiv" style="height: 1px;">
                </div>
            </div>
        </div>
        <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    </VueResizable>
</template>
<style>
    div#log {
        transition: 0.0s !important;
        left:270px;
        right:0;
        width:auto !important;
    }
</style>