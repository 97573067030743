
import licenseHelpers from "@/helpers/helpers.license"
import ObjectType, { type Label, type ItemlistItemDetail, type ObjectTypeObject, type ObjectTypePayload } from "../../objectType";
import { T } from "@/classes/i18n"
import Button from "@/templates/components/button/button"

import jsonHelpers from "@/helpers/helpers.json"
import router from "@/router/router"
import deviceHelpers from "@/helpers/helpers.devices";
import numberHelpers from "@/helpers/helpers.numbers";
import getterHelpers from "@/helpers/helpers.getters";
import { MutationTypes, useStore } from "@/store/vuex.store";
import arrayHelpers from "@/helpers/helpers.arrays";
import tenantHelpers from "@/helpers/helpers.tenants";
import dialogs from "@/dialogs/dialogs";
import requestHandler from "@/queries/requests";
import config from "@/classes/config";
import windownBuildMap from "@/resources/windowsVPN/windowsBuildMap.json"
import products from "../..";
import { type WindowsProfile } from "./windowsProfiles";
import stringHelpers from "@/helpers/helpers.strings";

export interface WindowsVpn {
    id: string
    alias?: string
    tags?: string[]
    inventory?:ObjectInventory,
    securityConditionState?: number,
    enrollment?: {
        hostname?: string,
        username?: string,
        timestamp: number
    },
    messages: {
        "vpn-system-info": VPNSystemInfoMessage,
        "vpn-connection-get": VPNConnectionGetMessage,
        "vpn-client-action": any
    },
    appliedProfileId?: string,
    lastAppliedProfileChecksum?: string,
    appliedProfileState?: AppliedProfileState
};
export type AppliedProfileState = "NONE" | "PENDING" | "APPLIED" | "ERROR" | "DISABLED";
export type WindowsVpnSendMessagePayload = {
    name: string,
    data: {
        connectionId?: string
    }
    connectionId?: string
}
export type VPNMessageKeys = 'vpn-system-info' | 'vpn-connection-get' | 'vpn-client-action'
export type VPNSendMessageType = 'system-info' | 'connection-get' | 'connection-diagnose' | "connection-stop" | "connection-start" | "connection-remove" | "client-action" | "connection-logs"
export type VPNWebsocketContexts = 'connection-diagnose' | 'vpn-disconnect' | 'vpn-startup' | 'vpn-system-info' | 'vpn-systen-info' | 'vpn-connection-get' | 'vpn-connection-stop' | "vpn-connection-start" | "vpn-connection-remove" | "vpn-client-action" | "vpn-connection-logs"
export type SendInvitePayload = {
    enrollmentTokenUUID: string,
    to: string[]
}
export type VPNStatsResponse = {
    "isodate": string,
    "tenant": string,
    "device": string,
    "providerFirewall": string,
    "providerSettings": string,
    "providerAntivirus": string,
    "providerInternetSettings": string,
    "providerUAC": string,
    "providerService": string,
    // Frontend Properties
    "fromSystemInfo"?: boolean
}

export interface VPNSystemInfoMessage {
    timestamp: number
    system: {
        hostname: string
        hardwareSerial: string
        uptimeSeconds: number
        hdd: HDD[]
        ram: RAM
        cpu: {
            name: string
            cores: number
            utilization: number
        }
        interfaces: {
            name: string
            ips: string[]
        }[]
    }
    os: {
        version: string
        platform: string
        servicePack: string
    }
    currentUser: {
        domain: string
        userIsAvailable: boolean
        name: string
        sid: string
    }
    client: {
        rulesetProfile: string
        lastUpdate: string
        currentVersion: string
        uptimeSeconds: number
    }
    security: {
        wsc: WSC
    }
}
export type WSC = {
    providerFirewall: number
    providerSettings: number
    providerAntivirus: number
    providerInternetSettings: number
    providerUAC: number
    providerService: number
}
export type HDD = {
    name: string
    size: number
    used: number
    free: number
}
export type RAM = {
    size: number
    used: number
    free: number
}

export type FlagOptions = "Autostart" | "CredentialsSaved" | "Cloud" | "System" | "User" | "Wireguard" | "SslVpn" | "Active" | "Inactive" | "Otp" | "Favorite" | "Pin" | "OTP deactivated"
export interface VPNConnectionGetMessage {
    timestamp: number
    currentUser: string
    currentSID: string
    connections: Connection[]
}
export interface VPNClientActionState { 
    "error"?: boolean 
    "state"?: "OPEN" | "CLOSED",
    // Manually Created in Frontend:
    "timestamp"?: number
}
export interface Connection {
    id: string
    name: string
    currentState: string
    flags: FlagOptions[]
    lastConnect: number
    lastStateChange: number
    lastErrorText: string
}

export type BuildMapEntry = {
    description: string,
    product: string,
    majorVersion: string,
    supportEnded: boolean,
    supportEndedDate: string,
    versionName: string
}
export type WindowsVpnJob = {
    jobId: string,
    queue: "in" | "out",
    issued: number,
    lastUpdate: number,
    type: "vpn",
    status: "RECEIVED" | "ACKNOWLEDGED" | "ERROR" | "PENDING" | "SENT",
    statusText: string,
    context: string
}

class WindowsVpns extends ObjectType<WindowsVpn> {
    constructor(payload: ObjectTypePayload<WindowsVpn>) {
        super(payload)
        const thisObjectType = this
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    "id":"alias",
                    "text":"Name"
                }
            ]
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            itemlistComponent = itemlistComponent?.exposed ?? itemlistComponent
            let toolBarEntries = []

            if (this.isEnrollmentAllowed(accountId) && itemlistComponent.accountRestrictions.value == 0) {
                toolBarEntries.push(                    
                    {
                        icon: 'fal fa-plus',
                        title: T('Enroll new VPN Client'),
                        onClick: () => {
                            dialogs.unifiedNetwork.inviteWindowsVpnClient(accountId)
                        },
                        id: 'vpnClientsButtonEnroll',
                        vIf: false
                    }
                )
            } else {
                toolBarEntries.push(
                    {
                        icon: 'fal fa-exclamation-triangle',
                        title: T('Enrollment disabled'),
                        link: '',
                        id: 'vpnClientsButtonEnroll',
                        disabled: true,
                        vIf: false
                    }
                )
            }
            
            return toolBarEntries
        }

        this.itemlistItem.hasCheckbox = () => {
            return true
        }
        this.itemlistItem.getTitle = (item, component) => {
            component = component?.exposed ? component?.exposed : component

            let result: any = {}
            if (item?.alias) {
                result.title = item?.alias
                result.small = '(' + deviceHelpers.getShortDeviceId(item?.id) + ')'
            }
            else if(item?.enrollment?.hostname) {
                result.title = item.enrollment.hostname
                result.small = '(' + deviceHelpers.getShortDeviceId(item?.id) + ')'
            }
            else {
                result.title = deviceHelpers.getShortDeviceId(item?.id)
            }
            
            result.link = {
                "innerHtml": "<i class=\"fal fa-edit\"></i>",
                "onClick": function () {
                    component.editAlias.value = true
                },
                "showIf": function () {
                    return component.editAlias.value == false
                }
            }
            
            return result
        }
        this.itemlistItem.getLabels = (accountId, item) => {
            let result: Label[] = []
            const buildNumber: string = item?.messages?.["vpn-system-info"]?.os?.version?.split(".")[2] || ""
            const buildMapEntry: BuildMapEntry | undefined = windownBuildMap[buildNumber as keyof typeof windownBuildMap]
            const systemInfoMessage = item?.messages?.["vpn-system-info"]
            const connectionGetMessage = item?.messages?.["vpn-connection-get"]
            const vpnState = products.unifiedSecurityConsole.windowsVpnStates.useStore?.().getObjectStoreObject(accountId, item?.id)
            
            if (vpnState && vpnState.online == true) result.push({
                title: T("Connected"),
                text: T("Connected"),
                class: "bg-green",
                icon: "fa fa-cloud"
            });
            else if (vpnState && vpnState.online == false) result.push({
                title: T("Disconnected"),
                text: T("Disconnected"),
                class: "bg-red",
                icon: "fa fa-cloud-slash"
            });
            if (buildMapEntry?.supportEnded == true) result.push({
                title: T("Support for the current Windows build version has expired"),
                text: T("End of servicing"),
                class: 'bg-red',
                icon: "fal fa-exclamation-triangle"
            });
            if (typeof item?.securityConditionState == "number" && item.securityConditionState > 0 && item.securityConditionState < 4) result.push({
                title: this.getTitleForWscLabel(T("WSC-Risiko"), systemInfoMessage?.security?.wsc),
                text: T("WSC-Risiko"),
                class: "bg-yellow",
                icon: "fal fa-exclamation-circle"
            });
            else if (typeof item?.securityConditionState == "number" && item.securityConditionState > 3) result.push({
                title: this.getTitleForWscLabel(T("WSC-Gefährdung"), systemInfoMessage?.security?.wsc),
                text: T("WSC-Gefährdung"),
                class: "bg-red",
                icon: "fal fa-exclamation-triangle"
            })
            
            return result
        }      
        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuLinks = []
            
            menuLinks.push(new Button({
                title: T('Details'),
                text: T('Details'),
                icon: 'fal fa-info-circle',
                onClick: () => {
                    router.navigate('#show-tenant-' + accountId + '.sms-windows-vpns-' + item?.id + '-details')
                }
            }))

            menuLinks.push(new Button({
                title: T('Delete'),
                text: T('Delete'),
                onClick: () => {
                    this.dialogs.getDeleteObjectDialog(accountId, item)
                },
                icon: 'fal fa-trash',
            }))
            return menuLinks
        }
        this.itemlistItem.getDetails = (accountId, item, component) => {
            component = component?.exposed ? component?.exposed : component
            let thisTagsInlineEditable: boolean = licenseHelpers.hasOneOfLicenses(accountId, ['Mobile Security', 'MDM'], 'valid') ? true || false : false
            const systemInfoMessage = item?.messages?.["vpn-system-info"]
            const connectionGetMessage = item?.messages?.["vpn-connection-get"]
            const ram = systemInfoMessage?.system?.ram?.size ? numberHelpers.formatBytes(systemInfoMessage.system.ram.size, 2).value + " " + numberHelpers.formatBytes(systemInfoMessage.system.ram.size, 2).unit : ""
            const windowsProfiles = products?.unifiedSecurityConsole.windowsProfiles.useStore?.().getObjectStoreObjects(accountId) || []
            const thisProfile = item?.appliedProfileId ? windowsProfiles.find((profile: WindowsProfile) => { return profile.id == item.appliedProfileId }) : undefined

            // https://redmine.intern.securepoint.de/issues/38840#note-32
            let version = "";
            if (typeof systemInfoMessage?.os?.version == "string") {
                const buildNumber: string = systemInfoMessage.os.version.split(".")[2] || ""
                const buildMapEntry: BuildMapEntry | undefined = windownBuildMap[buildNumber as keyof typeof windownBuildMap]
                if (buildMapEntry) {
                    version = buildMapEntry.product + " " + buildMapEntry.majorVersion + " " + buildMapEntry.versionName
                }
                else if (Number(buildNumber) > 22000) {
                    version = "Windows 11"
                }
                else if (Number(buildNumber) > 11000) {
                    version = "Windows 10"
                }
                else {
                    version = windownBuildMap['fallback'].product + " " + windownBuildMap['fallback'].majorVersion + " " + windownBuildMap['fallback'].versionName
                }
            }

            let lastUser = ""
            if(systemInfoMessage?.currentUser?.name && systemInfoMessage.currentUser.domain) 
                lastUser = systemInfoMessage?.currentUser?.name + "@" + systemInfoMessage.currentUser.domain;

            return [{
                iconClass: 'fal fa-fw fa-hashtag',
                title: T('ID'),
                key: T('ID'),
                value: item ? (item.id) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
            },{
                iconClass: "fal fa-laptop",
                title: T("Hostname"),
                key: T("Hostname"),
                value: item ? item.enrollment?.hostname || "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-fw fa-microchip",
                title: T("CPU"),
                key: T("CPU"),
                value: item ? systemInfoMessage?.system?.cpu?.name || "": "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-fw fa-microchip",
                title: T("RAM"),
                key: T("RAM"),
                value: item ? ram  : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-fw fa-hdd",
                title: T("HDD"),
                key: T("HDD"),
                [item && Array.isArray(systemInfoMessage?.system?.hdd) ? "labels" : "value"]: item ? Array.isArray(systemInfoMessage?.system?.hdd) ? systemInfoMessage.system.hdd.map((hddInfo) => {
                    return {
                        "id": hddInfo.name.replace(/\\/g, '') + "-" + hddInfo.size,
                        "text": hddInfo.name.replace(/\\/g, '') + " " + numberHelpers.formatBytes(hddInfo.size, 2).value + " " + numberHelpers.formatBytes(hddInfo.size, 2).unit,
                        "title": hddInfo.name.replace(/\\/g, '') + " " + numberHelpers.formatBytes(hddInfo.size, 2).value + " " + numberHelpers.formatBytes(hddInfo.size, 2).unit,
                        "displayType": "label"
                    }
                }) : "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-fw fa-globe",
                title: T("OS"),
                key: T("OS"),
                value: item ? version || "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-fw fa-hashtag",
                title: T("Version"),
                key: T("Version"),
                value: item ? systemInfoMessage?.client?.currentVersion || "" : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-fw fa-globe",
                title: T("Last user"),
                key: T("Last user"),
                value: item ? lastUser : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: 'fal fa-fw fa-tags',
                title: T('Tags'),
                key: T('Tags'),
                [item ? "labels" : "value"]: item ? item?.tags?.map(function (tag: string) {
                    return {
                        "id": tag,
                        "text": tag,
                        "title": tag,
                        "onClick": undefined,
                        "displayType": "label"
                    }
                }) : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>",
                editableContent: thisTagsInlineEditable ? ({
                    "type": "select2",
                    "options": "tags",
                    "select2Settings": {
                        "tags": true,
                        "multiple": true,
                        "placeholder": "Select tags"
                    },
                    "ref": "editTags",
                    "value": item?.tags || [],
                    "editingBoolProperty": "editTags",
                    "editButton": new Button({
                        "onClick": function () {
                            component.editTags.value = true
                        },
                        "icon": "fal fa-edit"
                    }),
                    "submitFunction": async (value: string[]) => {
                        try {
                            await this.queries.updateObjectPropertiesFromApi(accountId, item.id, { "tags": value }, 'tags', undefined, undefined, "/properties", "PUT")
                            getterHelpers.useStore().commit(MutationTypes.setObjectInfos, {
                                'accountId': accountId, 'products': {
                                    'mobileSecurity': {
                                        'tags': value.filter(arrayHelpers.onlyUniqueFilter).map(function (tag: string) {
                                            return { "id": tag, "text": tag }
                                        })
                                    }
                                }
                            })
                            component.editTags.value = false
                        }
                        catch (e: any) {
                            component.editTags.value = false
                            component.error.value = true
                            console.error(e)

                            if (e.responseJSON != undefined) {
                                e = e.responseJSON
                            }

                            if (e?.errors?.errors?.[0]?.message != undefined) {
                                component.errorMsg.value = e?.errors?.errors?.[0]?.message + ". " + T("Tags may not contain spaces or umlauts.")
                            }
                            setTimeout(function () {
                                component.error.value = false
                                component.errorMsg.value = ""
                            }, 4000)
                        }
                    },
                    "abortFunction": function () {
                        component.editTags.value = false
                    }
                }) : undefined
            },
            {
                iconClass: "fal fa-shield",
                title: T("Profile"),
                key: T("Profile"),
                [thisProfile ? "labels" : "value"]: item ? (thisProfile ? function() {
                    let result : Label[] = [{
                        "text": thisProfile.name,
                        "title": thisProfile.name,
                        "onClick": function () {
                            router.navigate('#edit-tenant-' + accountId + '.sms-windows-profiles-' + thisProfile.id)
                        },
                        "displayType": "label"
                    }]

                    if (item.appliedProfileState && item.appliedProfileState != "NONE" && item.appliedProfileState != "APPLIED") {
                        result.push({
                            "text": T(stringHelpers.capitalizeFirstLetter(item.appliedProfileState)),
                            "title": T(stringHelpers.capitalizeFirstLetter(item.appliedProfileState)),
                            "displayType": "label",
                            "onClick":() => {},
                            "class": item.appliedProfileState == "ERROR" ? "bg-red" : "bg-yellow"
                        })
                    }

                    return result
                }() : "") : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            },
            {
                iconClass: "fal fa-barcode",
                title: "Hardware SN",
                key: "Hardware SN",
                value: item ? item.messages?.["vpn-system-info"]?.system?.hardwareSerial || '' : "<span class='content-placeholder' style='width:" + numberHelpers.getRandomArbitrary(50, 250) + "px;'></span>"
            }]

        }
        this.itemlistItem.onClick = (accountId, item) => {
            router.navigate('#show-tenant-' + accountId + '.sms-windows-vpns-' + item?.id + '-details')
        }


        /**
         * Converts Object for ObjectTypeStore
      */
        this.convertObjectForStore = (accountId, objectBase) => {
            objectBase.$itemlist = {
                "isCheckboxChecked": false,
                "isCheckboxHovering": false,
            }
            let newObject = jsonHelpers.copyObject(objectBase) as ObjectTypeObject<WindowsVpn>

            objectBase.toJSON = () => {
                delete newObject.$itemlist
                delete newObject.toJSON
                return newObject as WindowsVpn
            }
        }

        
    }
    getTitleForWscLabel = (defaultValue: string, wsc: WSC | undefined): string => {
        let title = ""
        const wscKeyToNameMap: { [key: string]: string } = {
            "providerFirewall": T("Firewall"),
            "providerSettings": T("Automatische Updates"),
            "providerAntivirus": T("Antivirus"),
            "providerInternetSettings": T("Internet-Einstellungen"),
            "providerService": T("WSC-Service-Status"),
            "providerUAC": T("UAC-Einstellungen")
        }
        const wscValueToStatusMap: { [key: number]: string } = {
            0: T("Keine Aktion erforderlich"),
            1: T("Status wird nicht von der WSC überwacht"),
            2: T("Computer ist möglicherweise gefährdet"),
            3: T("WSC schützt den Computer nicht aktiv")
        }
        if (wsc) Object.keys(wsc).forEach((key: string) => {
            if (wsc[key as keyof typeof wsc] > 0) title = title + wscKeyToNameMap[key] + ": " + wscValueToStatusMap[wsc[key as keyof typeof wsc]] + "\n"  
        });

        return title == "" ? defaultValue : title
    }
    getVpnMessage(device: WindowsVpn | undefined, messageType: VPNMessageKeys): undefined | VPNConnectionGetMessage | VPNSystemInfoMessage {
        if(!device || !device.messages || !device.messages[messageType]) return undefined;
        else return device.messages[messageType]
    }
    async sendVpnMessage(accountId: string, deviceId: string, messageType: VPNSendMessageType, connectionId?: string, data = {}) {
        try {
            let payload: WindowsVpnSendMessagePayload = {
                name: messageType,
                data: data
            }
            if (connectionId) payload.data.connectionId = connectionId;
            await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/windows/devices/" + deviceId + "/jobs/vpn", payload)
        }
        catch (e: unknown) {
            console.error(e)
        }
    }

    isMessageTooOld(timestamp: number, days: number) {
        const diff = Date.now() - (timestamp * 1000);
        const dayDiff = Math.ceil(diff / (1000 * 3600 * 24));
        return dayDiff > days
    }
    async getOperationsLogEntries(accountId: string, deviceId: string) {
        let result: {
            count: number,
            jobs: WindowsVpnJob[],
            limit: number,
            total: number,
            offset: number
        } | undefined = undefined
        try {
            result = await requestHandler.request("GET", config.mgtApiUriNext + "/tenants/" + tenantHelpers.getTenantDomain(accountId) + "/windows/devices/" + deviceId + "/jobs")

            if (result && Array.isArray(result.jobs)) {
                return result.jobs
            }
            else throw T("Could not receive Jobs for VPN")
        }
        catch (e: unknown) {
            console.error(e)
        }
    }
    async revokeJob(accountId: string, deviceId: string, jobId:string) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request("POST", config.mgtApiUriNext + "/tenants/" + tenantDomain + "/windows/devices/" + deviceId + "/jobs/"+jobId+"/revoke")
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        }
        catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            }
            else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            }
            else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(
                accountId,
                T('Error'),
                T(errorMessage)
            )
        }
        return result
    }
    async getStatsForDevice(accountId: string, deviceId: string, period: "lastday" | "lastweek" | "currentmonth") {
        try {
            const timeZone = useStore().state.session.accounts[accountId]?.unifiedSecurity?.account?.timezone as string || ""
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)

            const payload = {
                "query": {
                    "modul": "CloudVpn",
                    "name": "SecurityHistory",
                    "options": {
                        "relate": "count",
                        "period": period,
                        "size": 20,
                        "offset": 0,
                        "timezone": timeZone,
                        "order": {
                            "asc": false
                        },
                        "filter": [
                            {
                                "field": "device",
                                "operator": "equal",
                                "value": deviceId
                            }
                        ]
                    }
                }
            }

            const result = await requestHandler.request("POST", "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/stats/execute", payload)
            if (Array.isArray(result?.result)) return result.result as VPNStatsResponse[]
            else return false
        }
        catch (e: unknown) {
            console.error(e)
            return false
        }
    }
    isEnrollmentAllowed (accountId: string) {
        return <boolean | undefined>getterHelpers.useStore().state.session.accounts[accountId]?.mobileSecurity?.settings?.enrollmentAllowed ?? false
    }
}

const windowsVpns = new WindowsVpns({
    "productType": "unifiedSecurityConsole",
    "slug": "windowsVpns",
    "objectType": "windowsVpns",
    "hasStore": true,
    "appearance": {
        "iconClass": "fal fa-laptop",
        "text": {
            "plural": "VPN Clients",
            "title": "VPN Clients",
            "sidebarName": "VPN Clients",
            "singular": "VPN Client"
        },
        "color": "blue",
        "showInSidebar": true,
        "showOnDashboard": true,
    },
    "objectTypeInfo": {
        "primaryKeyProperty": {
            "property": "id",
            "pathToPrimaryProperty": undefined
        },
        "nameProperty": {
            "primary": "alias",
            "pathToPrimaryProperty": undefined,
            "secondary": "id",
            "pathToSecondaryProperty": undefined
        }
    },
    "apiInfo": {
        "url": "/sms-mgt-api/api/2.0",
        "getCountGETProperties": "?props[]=null&select=data.count",
        // GET
        "getObjectListResponseProperty": "devices",
        "getObjectListMethod": "GET",
        "getObjectListPath": "/tenants/{tenantDomain}/windows/devices",
        // PUT
        "updateObjectMethod": "PUT",
        "updateObjectPath": "/tenants/{tenantDomain}/windows/devices/{objectId}"
    },
})


export default windowsVpns