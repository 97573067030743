import { T } from "@/classes/i18n";
import { useGlobalMixin } from "@/mixins/mixins.global";
import loaderComponent from "./loader.vue";
import { debounce, throttle } from 'throttle-debounce';
import validationHelpers from "@/helpers/helpers.validation";
import stringHelpers from "@/helpers/helpers.strings";
import buttonComponent from "@/templates/components/button/button.vue"

const table2Component = {
	mixins: [useGlobalMixin()],
	"name":"table2",
	"template":`
		<div class="sms-table">
			<template v-if="searchable">
				<div class="media-screen-only" style="overflow:hidden">
					<div class="row flexrow margin-xs margin-xs-y-2">
						<div class="col-xs-24 col-lg-6 text-left" v-if="table.titles != undefined && table.titles.length > 0">
							<label class="form-inputgroup margin-xs-b-0">
								<span class="form-icon-prefix"><i class="fal fa-filter"></i></span>
								<select v-model="searchEntries.filter">
									<option value="">${T('All')}</option>
									<template v-for="option in table.titles">
										<option :value="option.id" v-if="option.visible !== false">
											{{ T(option.text) }}
										</option>
									</template>
								</select>
							</label>
						</div>
						<div class="col-xs padding-xs-x text-right">
							<label class="form-inputgroup margin-xs-b-0">
								<span class="form-icon-prefix"><i class="fal fa-search"></i></span>
								<input type="text" placeholder="${T('Search')}" v-model="searchEntries.value">
							</label>
						</div>
						<div class="col-xs padding-xs-x text-right flex-shrink" v-if="buttons">
							<template v-for="button in buttons">
								<buttonComponent
									v-if="button"
									:button-options="{
										text: button.text,
										title: T(button.title),
										icon: button.icon,
										onClick: button.onClick != undefined ? button.onClick : undefined,
										link: button.href,
										target: button.target,
										loading: button.loading,
										disabled: button.disabled,
										topRightCircle: button.topRightCircle
									}"
								></buttonComponent>
								&nbsp;
							</template>
						</div>
					</div>
				</div>
			</template>


			<div style="width:100%;" v-bind:style="{ 'padding-right': (scrollbar ? '6px' : '0px') }">
				<table>
					<thead>
						<tr>
							<template v-for="title in table.titles">
								<template v-if="title.visible !== false">
									<th
										colspan="1"
										v-on:click="title.sortable ? sortByTitle(title.id) : null"
										:style="{width: title.width ? (title.width + 'px') : 'auto' }"
										:class="[{
											'sortable':title.sortable !== false,
											'sort-desc':title.id == sorting.property && 'asc' == sorting?.direction,
											'sort-asc':title.id == sorting.property && 'desc' == sorting?.direction,
										},
										title.classes]"
									>
										<span>{{ T(title.text) }}</span>
									</th>
								</template>
							</template>
						</tr>
					</thead>
				</table>
			</div>


			<div
				:id="'smsTableWrapper'+thisID"
				ref="tablewrapper"
				class="tablewrapper"
				v-bind:style="{
					'max-height': scrollbar ? (maxheight + 'px') : 'auto',
					'min-height': minheight + 'px',
					'overflow-y': scrollbar ? 'scroll' : 'auto'
				}"
				style="width:100%;"
			>
				<table 
					ref="thRelativeToThis"
					:style="{
						'margin-top': + tableTopPadding + 'px',
						'margin-bottom': + tableBottomPadding + 'px'
					}"	
				>
					<tbody>
						<template v-if="getEntries(table) && getEntries(table).length">
							<template v-for="i in virtualScrolling.renderedRows">
								<tr 
									:index="i + virtualScrolling.fromIndex - 1" 
									:class="{'highlight':(filteredEntries[i + virtualScrolling.fromIndex - 1] && filteredEntries[i + virtualScrolling.fromIndex - 1].highlight === true) }"
								>
									<template v-for="title in table.titles">
										<td
											colspan="1"
											v-if="title.visible !== false"
											:style="{width: title.width ? (title.width + 'px') : 'auto' }"
											:class="[title.classes]"
										>
											<template v-if="['string','number'].indexOf(typeof filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]) != -1">
												<div :title="T(filteredEntries[i + virtualScrolling.fromIndex - 1].data[title.id])">
													{{ filteredEntries[i + virtualScrolling.fromIndex - 1].data[title.id] }}
												</div>
											</template>

											<template v-else-if="isArray(filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id])">
												<template v-for="label in filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]">
													<span
														style="margin-right:2px"
														class="label"
														:title="label"
													>
														{{ label }}
													</span>
												</template>
											</template>
											<template v-else>
												<template v-if="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.text">
													<template v-if="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.href">
														<a 
														:href="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.href" 
														:title="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.text">
															{{ filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.text }}
														</a>&nbsp;
													</template>
													<template v-else>
														<span :title="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.text">
															{{ filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.text }}
														</span>&nbsp;
													</template>
													
												</template>
												<template v-if="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.buttons">
													<template v-for="button in filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.buttons">
														<buttonComponent
															v-if="button"
															:button-options="{
																text: button.text,
																title: T(button.title),
																icon: button.icon,
																onClick: button.onClick != undefined ? typeof button.onClick == 'string' ? () => { execEntryFunction(filteredEntries[i + virtualScrolling.fromIndex - 1], button.onClick) } : button.onClick : undefined,
																link: button.href,
																target: button.target,
																loading: button.loading,
																disabled: button.disabled,
																topRightCircle: button.topRightCircle
															}"
														></buttonComponent>
														&nbsp;
													</template>
												</template>
												<template v-if="filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.labels">
													<template v-for="label in filteredEntries[i + virtualScrolling.fromIndex - 1]?.data[title.id]?.labels">
														<template v-if="label">
															<span
																class="label"
																:class="[label.class]"
																:title="T(label.title)"
																v-on:click="label.onClick != undefined ? label.onClick() : null"
																:style="(label.onClick != undefined ? 'cursor:pointer;' : '')"
															>
																<span style="padding:1px 4px">
																	<i class="fa-fw" :class="label.icon"></i>
																	<template v-if="label.text">&nbsp;<span>{{ label.text }}</span></template>
																</span>
															</span>
														</template>
													</template>
												</template>
											</template>
										</td>
									</template>
								</tr>
								<tr v-if="filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable && filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable?.active">
									<td :colspan="table.titles.length">
										<table class="border">
											<thead>
												<tr>
													<template v-for="title in filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable.titles">
														<th colspan="1">
															<span>{{ T(title.text) }}</span>
														</th>
													</template>
												</tr>
											</thead>
											<tbody>
												<template v-if="getSubEntries(filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable)?.length">
													<template v-for="subEntry in getSubEntries(filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable)">
														<tr>
															<template v-for="title in filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable.titles">
																<td
																	colspan="1"
																	v-if="title.visible !== false"
																	:style="{width: title.width ? (title.width + 'px') : 'auto' }"
																	:class="[title.classes]"
																>
																	<template v-if="['string','number'].indexOf(typeof subEntry.data[title.id]) != -1">
																		<div :title="T(subEntry.data[title.id])">
																			{{ T(subEntry.data[title.id]) }}
																		</div>
																	</template>

																	<template v-else-if="isArray(subEntry.data[title.id])">
																		<template v-for="label in subEntry.data[title.id]">
																			<span
																				style="margin-right:2px"
																				class="label"
																				:title="T(label)"
																			>
																				{{ label }}
																			</span>
																		</template>
																	</template>
																	<template v-else>
																		<template v-if="subEntry.data[title.id].text">
																			<span v-html="T(subEntry.data[title.id].text)" :title="T(subEntry.data[title.id].text)"></span>&nbsp;
																		</template>
																		<template v-if="subEntry.data[title.id].buttons">
																			<template v-for="button in subEntry.data[title.id].buttons">
																				<a
																					class="btn btn-loader btn-sm margin-xs-0 twist-in"
																					v-if="button"
																					:class="[
																						button.class,
																						{'btn-loading':button.loading}
																					]"
																					:disabled="button.loading || (typeof button.disabled == 'boolean' ? button.disabled || null : typeof button.disabled == 'function' ? button.disabled() || null : null)"
																					:title="T(button.title)"
																					:href="button.href"
																					:target="button.target"
																					rel="noreferrer noopener"
																					v-on:click="button.onClick != undefined ? typeof button.onClick == 'string' ? () => { execEntryFunction(subEntry, button.onClick) } : button.onClick : undefined"
																				>
																					<span class="animate" style="padding:1px 4px">
																						<loader v-if="button.loading" />
																					</span>
																					<span style="padding:1px 4px">
																						<i
																							class="fa-fw"
																							:class="button.icon"
																						></i>
																						<template v-if="button.text">&nbsp;<span>{{ T(button.text) }}</span></template>
																					</span>
																				</a>&nbsp;
																			</template>
																		</template>
																		<template v-if="subEntry.data[title.id].labels">
																			<template v-for="label in subEntry.data[title.id].labels">
																				<template v-if="label">
																					<span
																						class="label"
																						:class="[label.class]"
																						:title="T(label.title)"
																						v-on:click="label.onClick != undefined ? label.onClick() : null"
																						:style="(label.onClick != undefined ? 'cursor:pointer;' : '')"
																					>
																						<span style="padding:1px 4px">
																							<i class="fa-fw" :class="label.icon"></i>
																							<template v-if="label.text">&nbsp;<span>{{ label.text }}</span></template>
																						</span>
																					</span>&nbsp;
																				</template>
																			</template>
																		</template>
																	</template>
																</td>
															</template>
														</tr>
													</template>
												</template>
												<template v-else>
													<tr>
														<td :colspan="filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable.titles && filteredEntries[i + virtualScrolling.fromIndex - 1]?.subTable.titles.length">
															{{ T('No entries found') }}
														</td>
													</tr>
												</template>
											</tbody>
										</table>
									</td>
								</tr>
							</template>
						</template>
						<template v-else>
							<tr>
								<td :colspan="table.titles && table.titles.length">
									{{ T('No entries found') }}
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<template v-if="maxheight > 800">
				<div style="width:100%;" v-bind:style="{ 'padding-right': (scrollbar ? '6px' : '0px') }">
					<table>
						<thead>
							<tr>
								<template v-for="title in table.titles">
									<template v-if="title.visible !== false">
										<th
											colspan="1"
											v-on:click="title.sortable ? sortByTitle(title.id) : null"
											:style="{width: title.width ? (title.width + 'px') : 'auto' }"
											:class="[{
												'sortable':title.sortable !== false,
												'sort-asc':title.id == sorting.property && 'asc' == sorting?.direction,
												'sort-desc':title.id == sorting.property && 'desc' == sorting?.direction,
											},
											title.classes]"
										>
											<span>{{ T(title.text) }}</span>
										</th>
									</template>
								</template>
							</tr>
						</thead>
					</table>
				</div>
			</template>
		</div>
	`,
	"data": function(){
		return {
			"fromIndex":0,
			"length":9999999,
			"thisID":stringHelpers.generateRandomString(24),
			"searchEntries": {
				"filter":"",
				"value":""
			},
			"activeTablePage":0,
			"sorting":{
				"direction":"asc",
				"property":""
			},
			"virtualScrolling":{
				"containerHeight": <number>0,
				"lastScrollDistance": 0,
				"scrollDistance": 0,
				"fromIndex": 0,
				"renderedRows": 15,
				"heightList": <any>[],
				"paddingsTop": <any>[],
				"paddingsBottom": <any>[]
			}
		}
	},
	"computed": {
		"itemsLength":function(this:any) { 
			return this.table.rows.length || 0 
		},
		"tableTopPadding":function(this:any) {
			return (<number[]>this.virtualScrolling.paddingsTop).reduce(function (pv, cv) { return pv + cv; }, 0)
		},
		"tableBottomPadding":function(this:any) {
			return (<number[]>this.virtualScrolling.paddingsBottom).reduce(function (pv, cv) { return pv + cv; }, 0)
		},
		"filteredEntries":function(this:any) {
			return this.getEntries(this.table)
		}
	},
	"props": {
		"searchable": {
			required:false,
			default:true
		},
		"scrollbar": {
			required:false,
			default:true
		},
		"maxheight": {
			required:false,
			default:500
		},
		"minheight": {
			required:false,
			default:0
		},
		"table": {
			required:true,
			default:function() {
				return {
					titles:[],
					rows:[]
				}
			}
		},
		"loading": {
			required:false,
			default:false
		},
		"itemsPerPage": {
			required:false,
			default:0
		},
		"buttons": {
			required:false,
			default:function() { return[] }
		}
	},
	methods: {
		"execEntryFunction": function(this:any, entry:any, functionName:string) {
			typeof this[functionName] == "function" ? this[functionName](entry) : null
		},
		"toggleSubTable": function(this:any, entry:any) {
			entry.subTable.active = !entry.subTable.active
		},
		"getFieldValue": function (this:any,currentValue: any) {
			// value is Object
			if (typeof currentValue == "object" && currentValue != undefined && !Array.isArray(currentValue)) {

				if (Object.hasOwn(currentValue,"text")) {
					currentValue = currentValue.text
				}
				// pass buttons to Array
				else if (currentValue.buttons && Array.isArray(currentValue.buttons)) {
					currentValue = this.getFieldValue(currentValue.buttons)
				}
				// pass labels to Array
				else if (currentValue.labels && Array.isArray(currentValue.labels)) {
					currentValue = this.getFieldValue(currentValue.labels)
				}
				// repeat for label with child object
				else if (currentValue.label && Array.isArray(currentValue.label)) {
					currentValue = this.getFieldValue(currentValue.label)
				}
				// empty Object
				else if (currentValue && Array.isArray(currentValue.label)) {
					currentValue = this.getFieldValue(currentValue.label)
				}
			}
			// value is Array
			else if (typeof currentValue == "object" && Array.isArray(currentValue)) {
				if (currentValue[0]?.text != undefined) {
					currentValue = currentValue.map((labelInfo) => {
						return typeof labelInfo == 'string' ? labelInfo : labelInfo?.text || ""
					}).join(" ")
				}
				else {
					currentValue = this.getFieldValue(currentValue.join(" "))
				}
			}
			
			if (typeof currentValue == "number") {
				currentValue = String(currentValue)
			}
			return (currentValue || "").toLowerCase()
		},
		"getEntries": function(this:any,table:any,useFilters:boolean=true) {
			let entries : any = []
			if(this.itemsPerPage == 0 && table?.rows?.length) {
				table.rows.forEach((entry : any) => {
					// FILTER
					if (!this.isEntryFiltered(entry) || !useFilters) {
						entries.push(entry)
					}
				});
			}
			else if (table?.rows?.length){
				let offset = this.activeTablePage * this.itemsPerPage
				for (var i = offset; i <= (offset + this.itemsPerPage); i++) {
					if(table.rows[i]) {
						let entry : any = table.rows[i]
						// FILTER
						if (!this.isEntryFiltered(entry) || !useFilters) {
							entries.push(entry)
						}
					}
					else {
						break;
					}
				}
			}

			let sortingTitleInfo = this.table.titles.find((titleInfo:any) => {
				return titleInfo.id == this.sorting.property
			})

			if(sortingTitleInfo) {
				let sortingProperty = sortingTitleInfo.sortBy || sortingTitleInfo.id
				entries.sort((a:any,b:any) => {
					let valueA = this.getFieldValue(a.data[sortingProperty])
					let valueB = this.getFieldValue(b.data[sortingProperty])

					if (typeof valueB == "object" && !Array.isArray(valueB)) {
						if (valueA.text) valueA = valueA.text
						if (valueB.text) valueB = valueB.text
					}
					if (typeof valueA == "number") {
						valueA = String(valueA)
						valueB = String(valueB)
					}
					if(this.sorting.direction.toLowerCase() == 'desc') {				
						return valueA.toLowerCase() > valueB.toLowerCase() ? -1 : 1
					}
					else {
						return valueA.toLowerCase() < valueB.toLowerCase() ? -1 : 1
					}
				})
			}
			return entries
		},
		"getSubEntries": function(this:any,table:any,useFilters:boolean=true) {
			let entries : any = []
			table.rows.forEach((entry : any) => {
				entries.push(entry)
			});
			return entries
		},
		"setThWidth":function(this:any) {

		},
		"getSortClassForTitle":function(this:any,title:any) {

		},
		"sortByTitle":function(this:any,property:any) {
			if (this.sorting.property == property) {
				this.sorting.direction == "asc" ? this.sorting.direction = "desc" : this.sorting.direction = "asc"
			}
			else {
				this.sorting.property = property
				this.sorting.direction = "desc"
			}
		},
		"isArray": validationHelpers.isArray,
		"isEntryFiltered":function(this:any,entry:any) {
			let result : boolean = false
			let hasFound : boolean = false
			let searchFor : string = this.searchEntries.value.toLowerCase()
			let searchForValues = searchFor.split(' ')

			let value : string = ""

			if(searchFor != "") {
				let searchResults = function () {
					return searchForValues.map(() => { return false })
				}()
				searchForValues.forEach((searchForValue, index) => {
					if(this.searchEntries.filter == "") {
						for (let i : number = 0; this.table.titles.length > i; i++) {
							let property = this.table.titles[i].id
							value = this.getFieldValue(entry.data[property]).toLowerCase()
							if (value?.indexOf(searchForValue) != -1) {
								searchResults[index] = true
								break;
							}
						}
					}
					else if(entry.data[this.searchEntries.filter]) {
						let property = this.searchEntries.filter
						value = this.getFieldValue(entry.data[property])
						if (value?.indexOf(searchForValue) != -1) {
							searchResults[index] = true
						}
					}
				});

				result = searchResults.indexOf(false) != -1
				
			}
			return result
		},
		onScroll:function(this:any) {
			// scrolled
			// get height of scrolling frame
			const tableWrapper = this.$el.getElementsByClassName('tablewrapper')[0]
			this.getContainerHeight();
			const frameHeight = tableWrapper?.clientHeight
			// calculate the scrolled distance
			const scrollDistance = this.virtualScrolling.scrollDistance = tableWrapper?.scrollTop || 0
			// get scrolling direction
			const scrollDirection = this.virtualScrolling.scrollDistance > this.virtualScrolling.lastScrollDistance ? "down" : this.virtualScrolling.scrollDistance == this.virtualScrolling.lastScrollDistance ? "ignored" : "up"
			// calculate the height of each row
			this.calcRowHeights()
			const firstRowHeight = this.virtualScrolling.heightList.length ? this.virtualScrolling.heightList[0] : 0
			const secondRowHeight = this.virtualScrolling.heightList.length ? this.virtualScrolling.heightList[1] : 0
			const thirdRowHeight = this.virtualScrolling.heightList.length ? this.virtualScrolling.heightList[2] : 0
			const fourthRowHeight = this.virtualScrolling.heightList.length ? this.virtualScrolling.heightList[3] : 0
			if(scrollDistance == 0) {
				this.virtualScrolling.paddingsTop = []
				this.calcRenderedRows()
				this.calcRowHeights()
			}
			else if (scrollDirection == "down") {
				// Scrolled down
				let paddingsTop = this.virtualScrolling.paddingsTop.length > 0 ? (<number[]>this.virtualScrolling.paddingsTop).reduce(function (pv, cv) { return pv + cv; }, 0) : 0
				if (scrollDistance > (firstRowHeight + secondRowHeight + thirdRowHeight + fourthRowHeight + paddingsTop)) {
					// if scroll distance is higher than the first row's heihgt -> all top-paddings
					// update starting item index 
					this.virtualScrolling.fromIndex = this.virtualScrolling.fromIndex + 4
					// add top padding
					if(firstRowHeight) { this.virtualScrolling.paddingsTop.push(firstRowHeight) }
					if (secondRowHeight) { this.virtualScrolling.paddingsTop.push(secondRowHeight) }
					if (thirdRowHeight) { this.virtualScrolling.paddingsTop.push(secondRowHeight) }
					if (fourthRowHeight) { this.virtualScrolling.paddingsTop.push(secondRowHeight) }
					// recalculate row heights
					this.calcRowHeights()
				}
			}
			else if (scrollDirection == "up" && this.virtualScrolling.paddingsTop.length > 0) {
				// Scrolled up
				let paddingsTop = this.virtualScrolling.paddingsTop.length > 0 ? (<number[]>this.virtualScrolling.paddingsTop).reduce(function (pv, cv) { return pv + cv; }, 0) : 0
				if (scrollDistance < (firstRowHeight + secondRowHeight + thirdRowHeight + fourthRowHeight + paddingsTop)) {
					//scroll distance is smaller than first row + all top-paddings
					// update starting item index 
					this.virtualScrolling.fromIndex = this.virtualScrolling.fromIndex - 4
					// remove padding from top
					this.virtualScrolling.paddingsTop.pop()
					this.virtualScrolling.paddingsTop.pop()
					this.virtualScrolling.paddingsTop.pop()
					this.virtualScrolling.paddingsTop.pop()
					// recalculate row heights
					this.calcRowHeights()
				}
			}
			else if (scrollDirection == "ignored" && this.virtualScrolling.paddingsTop.length) {
				// scrolling ignored, should be at the top
				// reset paddings
				this.virtualScrolling.paddingsTop = []
				// reset starting item index
				this.virtualScrolling.fromIndex = 0
				// recalculate row heights
				this.calcRowHeights()
			}
			// set last scroll distance in order to detect the next scrolling direction
			this.virtualScrolling.lastScrollDistance = this.virtualScrolling.scrollDistance
			this.calcRenderedRows()
			this.calcRowHeights()
		},
		calcRenderedRows: function(this:any) {
			let renderedRows = 15
			let avgHeight: number = 51
			if (this.virtualScrolling.heightList.length) {
				avgHeight = this.virtualScrolling.heightList[0]
			}
			if (this.virtualScrolling.containerHeight > 0) {
				const itemLengthPerView = Math.round(this.virtualScrolling.containerHeight / avgHeight) + 8
				if ((this.virtualScrolling.fromIndex + itemLengthPerView) > this.getEntries(this.table).length) {
					const overlappingCount = (this.virtualScrolling.fromIndex + itemLengthPerView) - this.getEntries(this.table).length
					renderedRows = itemLengthPerView - overlappingCount
				}
				else {
					renderedRows = itemLengthPerView
				}
			}
			this.virtualScrolling.renderedRows = this.itemsLength.value < renderedRows ? this.itemsLength.value : renderedRows
		},
		calcRowHeights:function(this:any) {
			let heightList: number[] = []
			const tableWrapper = this.$el.getElementsByClassName('tablewrapper')[0]
			const renderedRows = this.virtualScrolling.renderedRows
			const rowElements = tableWrapper?.children[0].children[0].children
			if (rowElements) {
				for (let i = 0; renderedRows > i; i++) {
					if (rowElements[i]) {
						heightList.push(rowElements[i].clientHeight)
					}
				}
			}
			this.virtualScrolling.heightList = heightList
			this.calcRenderedRows()
		},
		getContainerHeight:function (this:any) {
			const tableWrapper = this.$el.getElementsByClassName('tablewrapper')[0]
			this.virtualScrolling.containerHeight = tableWrapper?.clientHeight || 0
		}
	},
	created: function(this:any) {
	},
	mounted(this:any) {
		let thisComponent : any = this;
		$(window).resize(function() {
			thisComponent.setThWidth()
		})

		this.getContainerHeight();
		this.calcRenderedRows();
		this.calcRowHeights();
		const tableWrapper = this.$el.getElementsByClassName('tablewrapper')[0]
		tableWrapper?.addEventListener("scroll", this.onScroll)
		window.addEventListener("resize", () => {
			this.getContainerHeight();
			this.calcRenderedRows()
			this.calcRowHeights()
		})

		setTimeout(() => {
			this.calcRowHeights()
		}, 1000)
	},
	"watch":{
		"table.rows":{
			handler:function(this:any,table:any) {
				this.getContainerHeight()
				this.calcRenderedRows()
				this.calcRowHeights()
			},
			deep:true
		},
		"searchEntries": {
			handler: function (this: any) {
				setTimeout(() => {
					this.onScroll()
					this.calcRenderedRows()
					this.calcRowHeights()
				}, 100)
			},
			deep:true
		}
	},
	"components": {
		"loader": loaderComponent,
		"buttonComponent": buttonComponent,
	}
}
export default table2Component
